import React, {
  useMemo,
} from 'react';
import mainStyles from '../../../../pages/main.module.css';
import styles from './paginationJp.module.css';

export interface Paginated {
  pageLimit: number
  page: number
  total: number
}

interface ManualPaginationJpProps {
  pageInfo: Paginated
  totalPage: number
  onBtFirst?: () => void
  onBtLast?: () => void
  onBtNext?: () => void
  onBtPrevious?: () => void
}

// data control depends on parent component
function ManualPaginationJp(props:ManualPaginationJpProps) {
  // Props
  const {
    totalPage, pageInfo, onBtFirst, onBtLast, onBtNext, onBtPrevious,
  } = props;

  // Memos
  const startResultCount = useMemo(() => (pageInfo.page - 1) * pageInfo.pageLimit + 1, [pageInfo.page, pageInfo.pageLimit]);
  const endResultCount = useMemo(() => Math.min(startResultCount + pageInfo.pageLimit - 1, pageInfo.total), [startResultCount, pageInfo.pageLimit, pageInfo.total]);

  return (
    <div className={[styles.paginationButtonsContainer].join(' ')}>
      <div className={[mainStyles['ml-3']].join(' ')}>
        <div>
          <span className={[mainStyles['text-bold'], mainStyles['mr-4']].join(' ')}>
            { pageInfo.total }
            件中
          </span>

          <span className={[mainStyles['text-bold']].join(' ')}>{ startResultCount }</span>
          {' - '}
          <span className={[mainStyles['text-bold'], mainStyles['mr-4']].join(' ')}>
            { endResultCount }
            件
          </span>
        </div>
      </div>
      <img src="/images/first.svg" alt="first-page" onClick={onBtFirst} className={[styles.image, pageInfo.page === 1 && styles.imageDisabled].join(' ')} />
      <img src="/images/previous.svg" alt="previous-page" onClick={onBtPrevious} className={[styles.image, mainStyles['ml-10'], pageInfo.page === 1 && styles.imageDisabled].join(' ')} />
      <span className={[mainStyles['ml-10'], mainStyles['text-bold']].join(' ')}>{ pageInfo.page }</span>
      /
      <span className={[mainStyles['mr-10'], mainStyles['text-bold']].join(' ')}>{ totalPage }</span>
      <span>ページ </span>
      <img src="/images/next.svg" alt="next-page" onClick={onBtNext} className={[styles.image, mainStyles['mr-3'], mainStyles['ml-10'], pageInfo.page === totalPage && styles.imageDisabled].join(' ')} />
      <img src="/images/last.svg" alt="last-page" onClick={onBtLast} className={[styles.image, mainStyles['mr-20px'], pageInfo.page === totalPage && styles.imageDisabled].join(' ')} />
    </div>
  );
}

export default ManualPaginationJp;
