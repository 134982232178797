import {
  DependencyList, useCallback, useEffect, useRef,
} from 'react';

/**
 * コンポーネント外をクリックした時に、特定のアクションを実行するカスタムフック
 *
 * @template T リファレンスに使用する DOM 要素の型
 * @param onClickOutside - onClickOutside コンポーネント外をクリックした時に実行する関数
 * @param deps - useEffect の依存関係
 * @returns リファレンスとして使用する DOM 要素
 */
export function useOutsideClick<T extends HTMLElement>(onClickOutside: () => void, deps: DependencyList = []) {
  const ref = useRef<T>(null);
  const callback = useCallback(onClickOutside, []);
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, deps);
  return { ref };
}

// added this function to prevent eslint error ESLint: Prefer default export.(import/prefer-default-export)
export function foo() {

}
