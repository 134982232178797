import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import styles from './changePasswordPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import Input from '../../components/Input/input';
import { useSetPasswordApi } from '../../hooks/api/auth.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import AlertModal from '../../components/AlertModal';
import routes from '../../utils/routes';
import usePageTitle from '../../hooks/title.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * パスワード変更ページ
 */
function ChangePasswordPage() {
  // Context
  const navigator = useNavigate();
  usePageTitle('パスワード変更');

  const openMessageModal = useMessageModal();
  const { request: createLog } = useCreateLogApi();

  // States
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [validForm, setValidForm] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  const setPasswordApi = useSetPasswordApi();

  const validateForm = (): boolean => {
    try {
      Validator.validPasswordLength(newPassword);
      Validator.validPasswordTypeChars(newPassword);
      Validator.validPasswordChars(newPassword);
      Validator.comparePassword(newPassword, passwordConfirmation);
      setValidForm(true);
      setError('');
      return true;
    } catch (e) {
      setError((e as Error).message);
      setValidForm(false);
      return false;
    }
  };

  const handleCancel = () => {
    navigator(routes.main);
  };

  const handleForm = async () => {
    if (!validateForm()) return;
    setAlertModal(true);
  };

  const request = async () => {
    createLog(LogFormName.ChangePassword, LogControlName.Create);

    try {
      setAlertModal(false);
      await setPasswordApi.request({
        password: oldPassword,
        newPassword,
      });
      navigator(routes.main);
    } catch (e) {
      setError((e as Error).message);
      openMessageModal((e as ApiError)?.message);
    }
  };

  useEffectOnce(() => {
    createLog(LogFormName.ChangePassword, LogControlName.Show);
  });

  return (
    <CustomFrame
      title="パスワード変更"
      desc={(
        <div>
          <div className={styles.formDesc}>
            <p className={styles.firstParagraph}>{`${Validator.passwordLengthMin}~${Validator.passwordLengthMax}文字で、大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください`}</p>
            <p>{`利用可能な文字は半角英数字及び${Validator.passwordSymbolsLength()}種類の記号(${Validator.passwordSymbols})となります`}</p>
          </div>

          <div className={styles.form}>
            <div className={styles.formItem}>
              <div className={styles.formIteField}>現在のパスワード</div>
              <div><Input type="password" value={oldPassword} onChange={setOldPassword} /></div>
            </div>

            <div className={styles.formItem}>
              <div className={styles.formIteField}>新しいパスワード</div>
              <div><Input type="password" value={newPassword} onChange={setNewPassword} onBlur={validateForm} /></div>
            </div>

            <div className={styles.formItem}>
              <div className={styles.formIteField}>新しいパスワード(確認)</div>
              <div><Input type="password" value={passwordConfirmation} onChange={setPasswordConfirmation} onBlur={validateForm} /></div>
            </div>
            <div className={styles.errorText}>{error}</div>
          </div>
          {setPasswordApi.loading && <LoadingOverlay />}
          <AlertModal open={alertModal} text="パスワードを変更します。よろしいですか？" onConfirm={request} onCancel={() => setAlertModal(false)} />
        </div>
      )}
      body={(
        <div className={styles.confirm}>
          <div><Button content="キャンセル" onClick={handleCancel} /></div>
          <div><Button content="決定" onClick={handleForm} disabled={!validForm} /></div>
        </div>
      )}
    />
  );
}

export default ChangePasswordPage;
