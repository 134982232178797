import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { Supplier, SupplierDetails, SupplierListSearchForm } from '../../services/http/supplier.api';
import MainFrame from '../../components/MainFrame/mainFrame';
import usePageTitle from '../../hooks/title.hook';
import styles from './supplierListScreenPage.module.css';
import mainStyles from '../main.module.css';
import SupplierDetailModal from './supplierDetailModal';
import { useSearchSupplierListApi } from '../../hooks/api/supplier.hook';
import Input from '../../components/Input/input';
import Button from '../../components/Button/button';
import BaseTable from '../../components/BaseTable';
import ButtonTableDownload from '../../components/BaseTable/ButtonTableDownload';
import routes from '../../utils/routes';
import Breadcrumb from '../../components/Breadcrumb';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 取引先一覧ページのプロップスインターフェース
 */
export interface TableBtnCellRenderProps {
  /** 詳細ボタンクリックハンドラー */
  onInfoClick: (supplier: SupplierDetails) => void;
  /** 権限ボタンクリックハンドラー */
  onPermissionClick: (supplier: SupplierDetails) => void;
  /** 取引先詳細データ */
  data: SupplierDetails;

  onIpClick: (supplier: SupplierDetails) => void;
}

/**
 * 取引先一覧ページのコンポーネント
*/
export function TableBtnCellRender({
  data, onInfoClick, onPermissionClick, onIpClick,
}: TableBtnCellRenderProps) {
  return (
    <div>
      <button
        type="button"
        className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}
        onClick={() => onInfoClick(data)}
      >
        詳細
      </button>
      <button
        type="button"
        className={[styles['table-button'], mainStyles['btn-gray'], mainStyles['ml-1']].join(' ')}
        onClick={() => onPermissionClick(data)}
      >
        権限
      </button>
      <button
        type="button"
        style={{ margin: '0px 3px' }}
        className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}
        onClick={() => onIpClick(data)}
      >
        IP制限
      </button>
    </div>
  );
}

/**
 * 取引先一覧ページ
 */
function Component() {
  usePageTitle('取引先一覧');
  const navigate = useNavigate();
  const openMessageModal = useMessageModal();

  // Refs
  const gridRef = useRef<AgGridReact<Supplier>>(null);

  // States
  const [searchForm, setSearchForm] = useState<SupplierListSearchForm>({});
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);

  // APIs
  const searchSupplierListApi = useSearchSupplierListApi();
  const { request: createLog } = useCreateLogApi();

  // Methods
  const resetForm = () => {
    setSearchForm({});
  };

  const formatSupplierList = (params: Supplier[]) => {
    const newList = params.map((item) => ({
      customerId: item.customerId,
      customerCd: item.customerCd,
      customerName: item.customerName,
      tenantCode: item.tenantCode,
      existFirstUser: item.existFirstUser,
      ownerCd: item.ownerCd,
      ownerName: item.ownerName,
      contractGroupCode: item.contractGroupCode,
      contractGroupName: item.contractGroupName,
      contractCode: item.contractCode,
      contractName: item.contractName,
      firewallUseFlg: item.firewallUseFlg,
      blockingUseFlg: item.blockingUseFlg,
      registerDate: item.registerDate,
      registerUser: item.registerUser,
      updateDate: item.updateDate,
      updateUser: item.updateUser,
    }));
    setSupplierList(newList);
  };

  // Callbacks
  const onSearchClick = useCallback(async () => {
    try {
      const geSupplierList = await searchSupplierListApi.request(searchForm);
      formatSupplierList(geSupplierList);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [searchForm]);

  // Memos
  const colDefs = useMemo((): ColDef<Supplier>[] => [
    AGUtils.colAutoIncrement('customerId'),
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 190,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onInfoClick: (supplier: Supplier) => {
          setSelectedSupplier(supplier);
        },
        onPermissionClick: (supplier: Supplier) => {
          navigate(routes.supplierPermissionScreen, { state: { customerId: supplier.customerId } });
        },
        onIpClick: (supplier: Supplier) => {
          navigate(routes.adminIpListScreen, {
            state: {
              customerId: supplier.customerId,
              customerCd: supplier.customerCd,
              customerName: supplier.customerName,
              customerTenantCode: supplier.tenantCode,
            },
          });
        },
      },
    },
    AGUtils.colDefault('customerCd', '取引先コード', 140),
    AGUtils.colDefault('customerName', '取引先名称'),
    AGUtils.colDefault('tenantCode', 'テナントコード', 140),
    AGUtils.colDefault('ownerCd', '荷主コード', 140),
    AGUtils.colDefault('ownerName', '荷主名'),
    AGUtils.colDefault('contractCode', '契約商品コード', 140),
    AGUtils.colDefault('contractName', '契約商品名'),
    AGUtils.colCheckboxReadOnly('existFirstUser', 'ファーストユーザー登録', 200),
    {
      field: 'firewallUseFlg',
      headerName: 'グローバルIPアドレス制限機能',
      resizable: true,
      sortable: true,
      width: 220,
      valueFormatter: (params) => {
        const booleanValue = params.value === 1;
        return booleanValue ? '利用する' : '利用しない';
      },
    },
    {
      field: 'blockingUseFlg',
      headerName: 'グローバルIPアドレス制限',
      resizable: true,
      sortable: true,
      width: 200,
      valueFormatter: (params) => {
        const booleanValue = params.value === 1;
        return booleanValue ? '制限する' : '制限しない';
      },
    },
    AGUtils.colUsername('registerUser', '登録者'),
    AGUtils.colDate('registerDate', '登録日時'),
    AGUtils.colUsername('updateUser', '更新者'),
    AGUtils.colDate('updateDate', '更新日時'),
    {
      field: '',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 100,
    },
  ], [supplierList]);

  const columnsToExport = useMemo(() => [
    'customerId',
    'customerCd',
    'customerName',
    'tenantCode',
    'ownerCd',
    'ownerName',
    'contractCode',
    'contractName',
    'existFirstUser',
    'registerUser',
    'registerDate',
    'updateUser',
    'updateDate',
  ], [supplierList]);

  useEffectOnce(() => {
    createLog(LogFormName.SupplierListScreen, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles.mainFrameBody}>
            <div className={styles['mainframe-leftsection']}>
              <div className={styles['leftsection-title-line']}>
                <div className={styles['leftsection-title']}>検索条件</div>
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>取引先</div>
                <Input className={`${styles['w-100']}`} value={searchForm.customerName || ''} onChange={(value) => setSearchForm({ ...searchForm, customerName: value })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>テナントコード</div>
                <Input className={`${styles['w-100']}`} value={searchForm.tenantCode || ''} onChange={(value) => setSearchForm({ ...searchForm, tenantCode: value })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>荷主</div>
                <Input className={`${styles['w-100']}`} value={searchForm.ownerName || ''} onChange={(value) => setSearchForm({ ...searchForm, ownerName: value })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>契約商品</div>
                <Input className={`${styles['w-100']}`} value={searchForm.contractName || ''} onChange={(value) => setSearchForm({ ...searchForm, contractName: value })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>
                  所属ユーザー
                  <div>メールアドレス</div>
                </div>
                <Input className={`${styles['w-100']}`} value={searchForm.email || ''} onChange={(value) => setSearchForm({ ...searchForm, email: value })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>所属ユーザー名</div>
                <Input className={`${styles['w-100']}`} value={searchForm.userName || ''} onChange={(value) => setSearchForm({ ...searchForm, userName: value })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>
                  ファーストユーザー
                  <div>未登録</div>
                </div>
                <div className={[mainStyles['w-100'], mainStyles['d-flex']].join(' ')}>

                  <input
                    type="checkbox"
                    className={styles.inputCheckbox}
                    checked={searchForm.existFirstUser === false}
                    onChange={(e) => setSearchForm({ ...searchForm, existFirstUser: !e.target.checked })}
                  />
                </div>
              </div>
              <div className={styles['leftsection-footer-buttons']}>
                <Button
                  className={[mainStyles['mt-10'], mainStyles['ml-10']].join(' ')}
                  size="smaller"
                  color="lighterGray"
                  onClick={() => resetForm()}
                >
                  条件リセット
                </Button>
                <Button
                  className={[mainStyles['mt-10'], mainStyles['mr-10']].join(' ')}
                  size="smaller"
                  onClick={onSearchClick}
                  loading={searchSupplierListApi.loading}
                >
                  検索
                </Button>
              </div>
            </div>

            <div className={styles['mainframe-rightsection']}>
              <div className={styles['mainframe-rightsection-top']}>
                <div className={styles['mainframe-rightsection-top-buttons']}>
                  <ButtonTableDownload
                    logFormName={LogFormName.SupplierListScreen}
                    tableRef={gridRef}
                    columnsToExport={columnsToExport}
                  />
                </div>
              </div>
              <BaseTable<Supplier>
                tableRef={gridRef}
                formName="supplierTable"
                rowData={supplierList}
                columnDefs={colDefs}
                sideBar
                createLogOnDownloadTableData={LogFormName.SupplierListScreen}
              />
            </div>
            {selectedSupplier
              && <SupplierDetailModal supplier={selectedSupplier} handleCancel={() => { setSelectedSupplier(null); }} />}
          </div>
        </div>
      )}
    />
  );
}

export default Component;
