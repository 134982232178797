import { useMemo, useState } from 'react';
import http, { ApiError } from '../../services/http';
import authApi, {
  ChangeInitPasswordForm,
  LoginForm,
  LoginResponse,
  SetPasswordForm,
  UserResponse,
} from '../../services/http/auth.api';
import { ManagementType, useAuthContext, UserType } from '../../store/auth.store';
import useAsyncState from '../AsyncState.hook';

function use<T>(fn: () => Promise<T>) {

}

/**
 * ログインAPIへのリクエストを行うためのフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useLoginApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LoginResponse | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const authContext = useAuthContext();

  async function request(form: LoginForm): Promise<LoginResponse> {
    setLoading(true);
    try {
      const doc = await http.auth.login(form);
      authContext.setToken(doc.token);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, data, error, request, setError,
  };
}

export function useLoginSso() {
  const [tenantCode, setTenantCode] = useState('');
  const form = { tenantCode: { value: tenantCode, set: setTenantCode } };
  const asyncState = useAsyncState(() => authApi.loginSso(form.tenantCode.value));

  const isValid = useMemo(() => form.tenantCode.value.length > 0, [form.tenantCode.value]);

  return {
    loading: asyncState.loading,
    form,
    isValid,
    error: asyncState.error,
    execute: asyncState.execute,
  };
}

/**
 *ログアウトAPIへのリクエストを行うためのカスタムフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:ログアウトAPIにリクエストを行う関数。
 *   リクエストが成功した場合は、 authContextからトークンとユーザーを削除します。
 */
export function useLogoutApi() {
  const authContext = useAuthContext();
  async function request() {
    authContext.removeToken();
    authContext.removeUser();
    try {
      await http.auth.logout();
    } catch (e) {
      console.log(e);
    }
  }
  return {
    request,
  };
}

/**
 * 認証されたユーザーのデータをサーバーから取得するためのフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `data`:リクエストが成功した場合のユーザーデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request()`: ユーザーデータを取得するための関数
 */
export function useGetUserApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserResponse | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const authContext = useAuthContext();

  async function request(): Promise<UserResponse> {
    setLoading(true);
    try {
      const doc = await http.auth.getUser();
      authContext.setUser({
        ...doc,
        userType: doc.userType === UserType.Terada ? UserType.Terada : UserType.General,
        managementType: doc.managementType === ManagementType.General ? ManagementType.General : ManagementType.Admin,
      });
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, data, error, request, setError,
  };
}

/**
 * 初期パスワードAPIフックの変更
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useChangeInitPasswordApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserResponse | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const authContext = useAuthContext();

  async function request(form: ChangeInitPasswordForm): Promise<null> {
    setLoading(true);
    if (!authContext.user) throw Error('User not Found');
    try {
      await http.auth.changeInitPassword(form);
      authContext.setUser({ ...authContext.user, passwordUpdate: true });
      setData(null);
      return null;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, data, error, request,
  };
}

/**
 * パスワードAPIフックの設定
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useSetPasswordApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: SetPasswordForm): Promise<null> {
    setLoading(true);
    try {
      await http.auth.setPassword(form);
      return null;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request,
  };
}
