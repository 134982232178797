import axios from 'axios';
import * as uuid from 'uuid';
import TagFormat from '../../utils/tagFormat';

export interface TagBulk {
  id: string,
  tagLabel: string,
  format: TagFormat,
  required: boolean,
  value: string,
  count?: number,
}

export interface GetTagBulkForm {
  documentList: TagBulkDocumentListItem[]
}

export interface GetTagBulkResponse {
  tagList: {
    tagLabel: string,
    format: TagFormat,
    count: number,
    require: boolean,
  }[]
}

export interface TagBulkDocumentListItem {
  documentId: number,
}

export interface UpdateTagBulkForm {
  documentList: TagBulkDocumentListItem[],
  tagList: TagBulk[]
}

export default {
  async getTagBulk(form: GetTagBulkForm): Promise<TagBulk[]> {
    const res = await axios.post<GetTagBulkResponse>('/BulkTagChange/getTags', form);
    const { data } = res;
    const tagList = data.tagList.map((tag) => ({
      ...tag, id: uuid.v1(), value: '', required: tag.require,
    }));
    return tagList;
  },
  async updateTagBulk(form: UpdateTagBulkForm): Promise<void> {
    return axios.post('/BulkTagChange/updateAll', {
      documentList: form.documentList,
      tagList: form.tagList.map((tag) => ({
        tagLabel: tag.tagLabel,
        format: tag.format,
        value: tag.value,
      })),
    });
  },
};
