// import router from "../router";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import auth from './auth.api';
import globalConfig from '../../rest/config';
import routes from '../../utils/routes';

const URL = `${process.env.REACT_APP_URL}/api`;
axios.defaults.baseURL = URL;

interface ErrorResponse {
  message: string;
}

export enum ErrorMessages {
  Network = 'Network Error',
  Unknown = 'Unknown Error',
  IpAuth = 'IP Not Authorized',
}

class ApiError extends Error {
  public code: number;

  constructor(message: string, code: number) {
    super(message);
    this.code = code;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  toString() {
    return `${this.message}`;
  }
}

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const newConfig = config;
  if (newConfig.headers) {
    newConfig.headers.authorization = `Bearer ${localStorage.getItem('token')}` || '';
  }
  return newConfig;
});

axios.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
  const { response } = error;
  if (response && response.status === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return Promise.reject(new ApiError(globalConfig.tokenErrorMsg, response.status));
  }

  if (response && response.status === 403) {
    const data = response.data as ErrorResponse;

    // This session storage is being used to check if the user has accessed the IP Error Page directly by the URL
    sessionStorage.setItem('errorMessage', data.message);
    window.location.href = routes.ipError;

    return Promise.reject(new ApiError(ErrorMessages.IpAuth, response.status));
  }

  if (response && response.data) {
    const data = response.data as ErrorResponse;
    if (data.message != null) {
      return Promise.reject(new ApiError(data.message, response.status));
    }
    return Promise.reject(new ApiError(ErrorMessages.Unknown, response.status));
  }
  return Promise.reject(error);
});

export type {
  ApiError,
};

export default {
  auth,
};
