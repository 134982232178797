import React, {
  MouseEvent, useCallback, useRef, useState,
} from 'react';

interface ScrollDragProps {
  className?: string,
  children: React.ReactNode,
}

export default function ScrollDrag({ className, children }: ScrollDragProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const onMouseDown = useCallback((e: MouseEvent) => {
    setIsScrolling(true);
    setClientX(e.clientX);
    setScrollX(ref.current?.scrollLeft || 0);
    setClientY(e.clientY);
    setScrollY(ref.current?.scrollTop || 0);
    if (ref.current) ref.current.style.cursor = 'grabbing';
  }, []);

  const onMouseUp = useCallback((e: MouseEvent) => {
    setIsScrolling(false);
    setClientX(e.clientX);
    setClientX(e.clientY);
    if (ref.current) ref.current.style.cursor = 'grab';
  }, []);

  const onMouseMove = (e: MouseEvent) => {
    if (isScrolling) {
      const dx = clientX - e.clientX;
      const dy = clientY - e.clientY;
      if (ref.current) ref.current.scrollLeft = scrollX + dx;
      if (ref.current) ref.current.scrollTop = scrollY + dy;
    }
  };

  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      style={{
        cursor: 'grab',
        maxWidth: '100%',
        maxHeight: '100%',

      }}
    >
      {children}
    </div>
  );
}
