import axios from 'axios';
import { DateTime } from 'luxon';
import Formatter from '../../utils/formatters';

export interface CollectAndDeliverRegisterForm {
  deliveryCd: string,
  deliveryName: string,
  deliverySName?: string,
  deliveryName3: string,
  deliveryPostNo: string,
  deliveryAddress1: string,
  deliveryAddress2?: string,
  deliveryAddress3?: string,
  deliveryPhone: string,
  deliveryFax?: string,
  deliveryName4?: string,
}

export interface CollectAndDeliverUpdateForm extends CollectAndDeliverRegisterForm {
  managementCd3?: string,
  actFlg?: string,
  updateDatetime?: Date | null,
  updateUser: string,
}
export interface CollectAndDeliverDeleteForm {
  deliveryCd: string,
  updateDatetime?: Date | null,
  updatedBy: string,
}

export interface CollectAndDeliver {
  ownerCd: string,
  ownerSName: string,
  deliveryCd: string,
  deliveryName: string,
  deliveryName3: string,
  deliveryPostNo: string,
  deliveryAddress1: string,
  deliveryAddress2: string,
  deliveryAddress3: string,
  deliveryPhone: string,
  deliveryFax: string,
  deliveryName4: string,
  actFlg: string,
  createDatetime: Date | null,
  registerUser: string,
  updateDatetime: Date | null,
  updateUser: string,
}
/* eslint-disable no-underscore-dangle */
export default {
  /**
   * 集配を検索する関数
   *
   * @returns - 集配のリスト
   */
  async search(): Promise<CollectAndDeliver[]> {
    const res = await axios.get('/CollectAndDeliver/search');
    const { data } = res;
    return data.deliveryList.map((item: CollectAndDeliver & { createDatetime: string, updateDatetime: string }) => ({
      ...item,
      createDatetime: DateTime.fromISO(item.createDatetime).toJSDate(),
      updateDatetime: DateTime.fromISO(item.updateDatetime).toJSDate(),
    }));
  },
  /**
   * 集配を登録する関数
   *
   * @param form - 集配登録フォーム
   * @returns - 登録した集配
   */
  async register(form: CollectAndDeliverRegisterForm): Promise<void> {
    const res = await axios.post('/CollectAndDeliver/register', form);
    const { data } = res;
    return data;
  },

  /**
   * 集配を更新する関数
   *
   * @param form - 集配更新フォーム
   * @returns - 更新した集配
   */
  async update(form: CollectAndDeliverUpdateForm): Promise<void> {
    const res = await axios.post('/CollectAndDeliver/update', {
      ...form,
      actFlg: form.actFlg !== undefined ? String(Number(form.actFlg)) : undefined,
      updateDatetime: Formatter.fromDateTimeToString((form.updateDatetime ? DateTime.fromJSDate(form.updateDatetime) : DateTime.now()), Formatter.defaultFullDateFormat),
    });
    const { data } = res;
    return data;
  },
  /**
   * 集配を削除する関数
   *
   * @param form - 集配削除フォーム
   */
  async delete(form: CollectAndDeliverDeleteForm): Promise<void> {
    return axios.post('/CollectAndDeliver/delete', {
      ...form,
      updateDatetime: Formatter.fromDateTimeToString((form.updateDatetime ? DateTime.fromJSDate(form.updateDatetime) : DateTime.now()), Formatter.defaultFullDateFormat),
    });
  },
};
