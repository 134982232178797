import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { v1 as uuid } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextareaAutosize from '@mui/base/TextareaAutosize';
import mainStyles from '../main.module.css';
import styles from './docRegisterScreenPage.module.css';
import { DocumentRegisterForm, Document, DocumentClassificationOption } from '../../services/http/documents.api';
import Input from '../../components/Input/input';
import Button from '../../components/Button/button';
import { DocumentClassificationAndType } from '../../services/http/documentManagement.api';
import { useGetRelatedTagsApi } from '../../hooks/api/tag.hook';
import FileInputButton from '../../components/ButtonFileInput';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import { useGetDocumentClassificationAndTypeApi } from '../../hooks/api/documentManagement.hook';
import SelectInput from '../../components/SelectInput';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import RadioGroupInput from '../../components/RadioGroupInput';
import { Folder } from '../../services/http/folder.api';
import TagInput from '../../components/TagInput';
import ChooseRelatedDocument from './chooseRelatedDocument';

/**
 * 文書登録フォームのプロップスインターフェース
 */
export interface DocumentRegisterFormProps {
  /** 使用されるフォーム */
  form: DocumentRegisterForm;
  /** 使用されるフォルダ */
  folder: Folder,
  /** フォームが変更された時に実行されるハンドラー */
  onChangeForm: (form: DocumentRegisterForm) => void;
  /** CSVページから選択されたオプション */
  selectOptionFromCsvPage: DocumentClassificationOption | null;
  /** 文書が選択されているかどうか */
  documentIsSelected: boolean;
  /** リクエスト中のフラグの設定 */
  setIsRequesting?: (isRequesting: boolean) => void;
}

/**
 * 文書登録フォームコンポーネント
*/
function Component({
  form, folder, onChangeForm, selectOptionFromCsvPage, documentIsSelected, setIsRequesting,
}: DocumentRegisterFormProps) {
  const inputAttachmentFile = useRef<HTMLInputElement | null>(null);

  const [documentClassifications, setDocumentClassifications] = useState<DocumentClassificationAndType[]>([]);
  const [documentPrintedTypeSelected, setDocumentPrintedTypeSelected] = useState<'ELECTRONIC' | 'PAPER'>('ELECTRONIC');
  const [optionWasSelected, setOptionWasSelected] = useState(false);
  const [choosingRelatedDocument, setChoosingRelatedDocument] = useState(false);

  const getDocumentClassificationAndTypeApi = useGetDocumentClassificationAndTypeApi();
  const getDefaultTag = useGetRelatedTagsApi();

  const openMessageModal = useMessageModal();

  const documentTypeOptions = useMemo(() => {
    const result: DropDownMenuItem<DocumentClassificationOption>[] = [];
    documentClassifications.forEach((documentClassification) => {
      documentClassification.types.forEach((type) => {
        result.push({
          text: `${documentClassification.name}: ${type.documentTypeName}`,
          value: { documentClassification, type },
        });
      });
    });

    return result;
  }, [documentClassifications]);

  const selectedClassification = useMemo((): DropDownMenuItem<DocumentClassificationOption> | undefined => {
    const isNewForm = form.documentTypeId === 0;
    if (isNewForm) return undefined;

    if (optionWasSelected && (form.documentClassification === 0 || form.documentClassification)) {
      return documentTypeOptions.find((item) => item.value.type.documentTypeId === form.documentTypeId && item.value.documentClassification.id === form.documentClassification);
    }
    return documentTypeOptions.find((item) => item.value.type.documentTypeId === selectOptionFromCsvPage?.type.documentTypeId && item.value.documentClassification.id === selectOptionFromCsvPage.documentClassification.id);
  }, [documentTypeOptions, form.documentClassification, form.documentTypeId, optionWasSelected, selectOptionFromCsvPage?.documentClassification.id, selectOptionFromCsvPage?.type.documentTypeId]);

  const onFileSelected = useCallback((file: File | undefined) => {
    if (file?.type !== 'application/pdf') {
      openMessageModal('PDFファイルを選択してください。');
      return;
    }
    const fileName = file?.name.split('.').slice(0, -1).join('.');
    onChangeForm({
      ...form, documentFile: file, documentFileName: file?.name || '', documentName: form.documentName ? form.documentName : fileName || '',
    });
  }, [form, onChangeForm, openMessageModal]);

  const updateClassificationType = useCallback(async (option: DocumentClassificationOption) => {
    setOptionWasSelected(true);
    const newForm = {
      ...form,
      documentClassification: option.documentClassification.id || 0,
      documentTypeId: option.type.documentTypeId || 0,
      documentTypeName: option.type.documentTypeName,
      barcodePrinting: '',
    };
    try {
      const res = await getDefaultTag.request(option.documentClassification.id || 0, option.type.documentTypeId || 0);
      onChangeForm({
        ...newForm,
        electronicFlg: 1,
        tagList: res.map((item) => ({
          format: item.format, value: '', tagLabel: item.tagLabel, required: item.require,
        })),
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [form, getDefaultTag, onChangeForm, openMessageModal]);

  const setDocumentTagValue = (tag: DocumentRegisterForm['tagList'][number], value: string) => {
    onChangeForm({ ...form, tagList: form.tagList.map((item) => (tag === item ? { ...item, value } : item)) });
  };

  const addAttachment = useCallback(() => {
    inputAttachmentFile.current?.click();
    if (inputAttachmentFile.current) {
      inputAttachmentFile.current.value = '';
    }
  }, []);

  const onAttachmentFileSelected = useCallback((files: FileList | null) => {
    if (!files) return;
    const newAttachments = Array.from(files).map((file) => ({
      attachmentFile: file,
      attachmentFileName: file.name,
      id: uuid(),
    }));
    onChangeForm({ ...form, attachmentFileList: [...form.attachmentFileList, ...newAttachments] });
  }, [form, onChangeForm]);

  const removeAttachment = useCallback((attachment: DocumentRegisterForm['attachmentFileList'][number]) => {
    onChangeForm({ ...form, attachmentFileList: form.attachmentFileList.filter((item) => item !== attachment) });
  }, [form, onChangeForm]);

  const setAttachmentFile = useCallback((attachment: DocumentRegisterForm['attachmentFileList'][number], file: File | undefined) => {
    onChangeForm({
      ...form,
      attachmentFileList: form.attachmentFileList.map((item) => (item === attachment ? {
        attachmentFile: file,
        attachmentFileName: file?.name || '',
      } : item)),
    });
  }, [form, onChangeForm]);

  const onDocumentPrintedTypeSelected = useCallback((e: 'ELECTRONIC' | 'PAPER') => {
    if (!selectedClassification?.text.startsWith('契約書')) {
      onChangeForm({ ...form, electronicFlg: e === 'ELECTRONIC' ? 1 : 0, paperFlg: e === 'PAPER' ? 1 : 0 });
    } else if (documentPrintedTypeSelected === 'PAPER' && e === 'ELECTRONIC') {
      onChangeForm({ ...form, electronicFlg: 1, paperFlg: 0 });
    } else {
      onChangeForm({ ...form, electronicFlg: e === 'ELECTRONIC' ? 1 : 0 });
    }
    setDocumentPrintedTypeSelected(e);
  }, [documentPrintedTypeSelected, form, onChangeForm, selectedClassification?.text]);

  useEffect(() => {
    try {
      getDocumentClassificationAndTypeApi.request().then(setDocumentClassifications);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, []);

  const removeSameDocuments = useCallback((documents: Document[]) => documents.filter((document) => !form.relationalDocumentList?.some((item) => Number(item.id) === Number(document.id))), [form]);

  const handleRelatedDocuments = useCallback((documents: Document[]) => {
    const filteredDocuments = removeSameDocuments(documents);
    onChangeForm({ ...form, relationalDocumentList: [...form.relationalDocumentList, ...filteredDocuments] });
    setChoosingRelatedDocument(false);
  }, [form, onChangeForm, removeSameDocuments]);

  const removeRelatedDocument = useCallback((document: Document) => {
    onChangeForm({ ...form, relationalDocumentList: form.relationalDocumentList.filter((item) => item.id !== document.id) });
  }, [form, onChangeForm]);

  useEffect(() => {
    if (setIsRequesting) {
      setIsRequesting(getDefaultTag.loading);
    }
  }, [getDefaultTag.loading]);

  return (
    <div className={styles.mainFrameRightSectionCol1}>
      <div className={styles.mainFrameRightSectionCol1Header}>
        詳細設定
      </div>
      {documentIsSelected && (
      <div className={styles.mainFrameRightSectionCol1Body}>

        <div className={`${mainStyles['d-flex']} ${mainStyles['align-items-center']}`}>
          <div className={styles.label}>
            文書名
            <span className={` ${mainStyles['text-danger']} }`} style={{ marginLeft: '60px' }}>※</span>
          </div>
          <div>
            <Input className={`${mainStyles.input} ${styles.width280px}`} value={form.documentName} title={form.documentName} onChange={(name) => onChangeForm({ ...form, documentName: name })} />
          </div>
        </div>
        <div className={`${mainStyles['d-flex']} ${mainStyles['align-items-center']} ${mainStyles['mt-15px']}`}>
          <div className={styles.label}>
            電子データ
          </div>
          <div className={mainStyles['flex-1']}>
            <Input className={`${mainStyles.input} ${styles.width280px}`} value={form.documentFileName} onChange={(fileName) => onChangeForm({ ...form, documentFileName: fileName })} />
          </div>
          <div className={`${mainStyles['flex-4']} ${mainStyles['d-flex']} ${mainStyles['ml-10']}`}>
            <FileInputButton icon accept="application/pdf" onSelect={(e) => onFileSelected(e as File)} />
            {form.documentFile && (
              <Button
                className={[mainStyles['small-button'], mainStyles['ml-10']].join(' ')}
                color="lightPrimary"
                size="smaller"
                onClick={() => onChangeForm({ ...form, documentFile: undefined, documentFileName: '' })}
              >
                <img src="/images/Icon-feather-trash-2.svg" alt="" />
              </Button>
            )}
          </div>
        </div>
        <div className={`${mainStyles['d-flex']} ${mainStyles['align-items-center']} ${mainStyles['mt-15px']} `}>
          <div className={styles.label}>
            文書種類
            <span className={` ${mainStyles['text-danger']} }`} style={{ marginLeft: '45px' }}>※</span>
          </div>
          <div>
            <SelectInput
              className={styles.width280px}
              value={selectedClassification?.value}
              options={documentTypeOptions}
              onChange={updateClassificationType}
            />
          </div>
        </div>
        { selectedClassification?.text.startsWith('契約書') && (
          <>
            <div
              className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}
            >
              <div className={styles.label}>契約種類</div>
              <div
                className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['w-100'], mainStyles['ml-4'], styles.text].join(' ')}
              >
                <RadioGroupInput
                  value={form.electronicFlg === 1 ? 'ELECTRONIC' : 'PAPER'}
                  onChange={(e) => {
                    onDocumentPrintedTypeSelected(e);
                  }}
                >
                  <RadioGroupInput.RadioInput value="ELECTRONIC">電子契約</RadioGroupInput.RadioInput>
                  <RadioGroupInput.RadioInput value="PAPER">書面契約</RadioGroupInput.RadioInput>
                </RadioGroupInput>
              </div>
            </div>
            <div
              className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}
            >
              <div className={styles.label}>書面有無</div>
              <div
                className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['w-100'], mainStyles['ml-4'], styles.text].join(' ')}
              >
                { form.electronicFlg === 1 ? (
                  <RadioGroupInput value="0" onChange={() => onChangeForm({ ...form, paperFlg: 1 })}>
                    <RadioGroupInput.RadioInput disabled value="1">有り</RadioGroupInput.RadioInput>
                    <RadioGroupInput.RadioInput disabled value="0" style={{ marginLeft: '67px' }}>無し</RadioGroupInput.RadioInput>
                  </RadioGroupInput>
                ) : (
                  <RadioGroupInput
                    value={form.paperFlg === 1 ? '1' : '0'}
                    onChange={(value) => onChangeForm(
                      {
                        ...form,
                        paperFlg: value === '1' ? 1 : 0,
                        barcodePrinting: value === '0' ? '' : form.barcodePrinting,
                      },
                    )}
                  >
                    <RadioGroupInput.RadioInput value="1">有り</RadioGroupInput.RadioInput>
                    <RadioGroupInput.RadioInput value="0" style={{ marginLeft: '67px' }}>無し</RadioGroupInput.RadioInput>
                  </RadioGroupInput>
                )}
              </div>
            </div>
            <div className={`${mainStyles['d-flex']} ${mainStyles['align-items-center']} ${mainStyles['mt-15px']}`}>
              <div className={styles.label}>
                <div>
                  バーコード
                </div>
                {' '}
                印字欄
              </div>
              <div>
                <Input disabled={!form.paperFlg} className={`${mainStyles.input} ${styles.width280px}`} value={form.barcodePrinting} onChange={(barcodePrinting) => onChangeForm({ ...form, barcodePrinting })} />
              </div>
            </div>
          </>
        )}
        {form.tagList?.map((tag, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={`${mainStyles['d-flex']} ${mainStyles['mt-15px']}`} key={String(index)}>
            <div className={styles.label}>
              { index === 0 && 'タグ'}
            </div>
            <div className={`${styles.tagTextContent} ${styles.text}`}>
              <div className={[mainStyles['d-flex']].join(' ')}>
                <div
                  className={[styles.textTag, mainStyles.expandDiv].join(' ')}
                >
                  {tag.tagLabel}
                </div>
                <div style={{ width: '20px' }}>
                  { tag.required
                    && (
                    <div className={`${mainStyles['text-danger']} ${mainStyles['d-flex']} ${mainStyles['justify-content-center']}`} style={{ marginRight: '10px' }}>
                      ※
                    </div>
                    )}
                </div>

                <TagInput
                  tag={tag}
                  onChange={(value) => setDocumentTagValue(tag, value)}
                  className={styles.width280px}
                />
              </div>

            </div>
          </div>
        ))}
        <div className={`${mainStyles['d-flex']} ${mainStyles['mt-15px']}`}>
          <div className={styles.label}>
            メモ
          </div>
          <div style={{ width: 'calc(100% - 110px)' }}>
            <textarea value={form.memo || ''} className={styles.textArea} onChange={(e) => onChangeForm({ ...form, memo: e.target.value })} />
          </div>
        </div>

        <div className={`${mainStyles['d-flex']} ${mainStyles['mt-15px']} `}>
          <div className={styles.label}>
            関連文書
          </div>

          <div style={{ width: '100%' }}>
            {form.relationalDocumentList.map((document) => (
              <div className={styles.relationalDocumentDetail}>
                <div style={{ width: '200px' }} className={mainStyles['text-overflow-ellipsis']}>
                  文書名:
                  {' '}
                  {document.name}
                </div>
                <div style={{ width: '200px' }} className={mainStyles['text-overflow-ellipsis']}>
                  文書種類:
                  {' '}
                  {document.typeName}
                </div>
                <div className={styles.tagsDiv}>
                  {document.tagList?.map((tag) => (
                    <>
                      {tag.label}
                      {' : '}
                      {tag.value}
                      {'\xa0'.repeat(5)}
                    </>
                  ))}
                </div>
                <button
                  type="button"
                  className={`${mainStyles.button} ${mainStyles['btn-primary-light']} ${mainStyles['small-button']} ${mainStyles['d-flex']} ${mainStyles['align-items-center']} ${mainStyles['justify-content-center']}`}
                  onClick={() => { removeRelatedDocument(document); }}
                >
                  <img src="/images/Icon-feather-trash-2.svg" alt="" />
                </button>

              </div>
            ))}
            <Button
              color="lighterGray"
              className={`${mainStyles.button} ${mainStyles['btn-gray']}`}
              size="smaller"
              onClick={() => setChoosingRelatedDocument(true)}
            >
              文書参照
            </Button>
          </div>
        </div>

        <div className={`${mainStyles['d-flex']} ${mainStyles['align-items-center']} ${mainStyles['mt-15px']} `}>
          <div className={styles.label}>
            フォルダ
          </div>
          <div className={styles.text}>
            {folder.name}
          </div>
        </div>
        <div className={`${mainStyles['d-flex']} ${mainStyles['mt-15px']} `}>
          <div className={styles.label}>
            添付ファイル
          </div>
          <div style={{ width: '100%' }}>
            {form.attachmentFileList.map((attachment) => (
              <div className={`${mainStyles['d-flex']} ${mainStyles['mb-15px']} `}>
                <div>
                  <img src="/images/CloseMidGray.svg" alt="" style={{ marginLeft: '-25px', width: '25px', cursor: 'pointer' }} onClick={() => removeAttachment(attachment)} />
                </div>
                <div className={mainStyles['flex-1']}>
                  <TextareaAutosize
                    disabled
                    style={{ minHeight: '25px', paddingTop: '4px' }}
                    className={`${mainStyles.textareaAutosize} ${styles.width280pxImportant} ${styles.disabledColor}`}
                    value={attachment.attachmentFile?.name}
                    placeholder="xxxxx.txt"
                  />
                </div>
                <div className={`${mainStyles['flex-4']} ${mainStyles['d-flex']} ${mainStyles['justify-content-end']} ${mainStyles['ml-10']}`}>
                  <FileInputButton icon onSelect={(e) => setAttachmentFile(attachment, e as File)} />
                </div>
              </div>
            ))}
            <Button
              className={`${mainStyles.button} ${mainStyles['btn-gray']}`}
              color="lighterGray"
              size="smaller"
              onClick={addAttachment}
            >
              添付ファイルを追加
            </Button>
          </div>
        </div>
        <div className={mainStyles.footerButton} />
      </div>
      )}
      {choosingRelatedDocument && (
        <ChooseRelatedDocument
          onChooseRelatedDocuments={handleRelatedDocuments}
          onCancelAddingRelatedDocuments={() => setChoosingRelatedDocument(false)}
        />
      )}
      <input
        type="file"
        id="file"
        ref={inputAttachmentFile}
        style={{ display: 'none' }}
        onChange={(e) => onAttachmentFileSelected(e.target.files)}
      />
    </div>
  );
}

export default Component;
