import Spinner from '../../../components/Spinner';
import Button from '../../../components/Button/button';
import { FileImportStatus } from '../signSystemConstants';
import styles from './baseSignItem.module.css';
import mainStyles from '../../main.module.css';

/**
 * ファイルインポート実行ボタンのプロップスインターフェース
 */
interface FileImportExecButtonProps {
  /** ファイルインポートのステータス */
  status: FileImportStatus,
  /** ファイル連携コールバック */
  handleFileCooperate: () => void,
  /** 再読み込みコールバック */
  handleReload: () => void,
  /** ローディング中かどうか */
  loading: boolean
  /** ファイル連携を取得中かどうか */
  isGettingFileCooperate: boolean
  /** ファイル連携を取得の更新 */
  setIsGettingFileCooperate: (status: boolean) => void
}

/**
 * ファイルインポート実行ボタンコンポーネント
 */
function FileImportExecButton({
  status, handleFileCooperate, handleReload, loading, isGettingFileCooperate, setIsGettingFileCooperate,
}: FileImportExecButtonProps) {
  if (status === FileImportStatus.CONNECTING || isGettingFileCooperate) {
    return (
      <div className={styles.importButtonContainer}>
        <div className={styles.refreshContainer}>
          {
            (loading && !isGettingFileCooperate)
              ? (<div className={styles.loadingContainer}><Spinner borderWidth={3} size={20} /></div>)
              : (
                <img
                  onClick={handleReload}
                  alt="reload"
                  src="/images/Reload.svg"
                  className={styles.refreshButton}
                />
              )
          }
        </div>
        <Button
          size="smaller"
          color="lighterGray"
          loading={loading && !isGettingFileCooperate}
          onClick={handleReload}
          style={{ cursor: 'pointer' }}
          className={[styles.itemLargeButton, mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-center'], styles.disabledButton].join(' ')}
        >
          <>
            <img alt="" src="/images/Icon-cloud.svg" className={styles.buttonImage} />
            ファイル連携
          </>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.importButtonContainer}>
      <button
        type="button"
        className={[mainStyles.button, styles.itemLargeButton, mainStyles['btn-primary'], mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-center']].join(' ')}
        onClick={() => {
          setIsGettingFileCooperate(true);
          handleFileCooperate();
        }}
      >
        <img alt="" src="/images/Icon-cloud.svg" className={styles.buttonImage} />
        ファイル連携
      </button>
    </div>
  );
}

export default FileImportExecButton;
