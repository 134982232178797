import React from 'react';

export enum SamlAuthStatus {
  Valid = 'valid',
  Warning = 'warning',
  Invalid = 'invalid',
}

interface StatusImageProps {
  status: SamlAuthStatus;
}

function StatusImage({ status }: StatusImageProps): JSX.Element {
  let imageUrl: string;
  switch (status) {
    case SamlAuthStatus.Valid:
      imageUrl = '/images/Check.svg';
      break;
    case SamlAuthStatus.Warning:
      imageUrl = '/images/Warning.svg';
      break;
    default:
      imageUrl = '/images/Error.svg';
      break;
  }

  return <img src={imageUrl} alt={status} />;
}

export default StatusImage;
