import React, { CSSProperties, useEffect, useRef } from 'react';
import styles from './button.module.css';
import theme, { Colors, colorToGray, HEX } from '../../utils/theme/theme';
import Spinner from '../Spinner';
/* eslint-disable react/button-has-type */
interface SizesType {
  small: string,
  smaller: string,
  larger: string,
}

const Sizes: SizesType = {
  small: styles.sm,
  smaller: styles.xs,
  larger: '',
};

interface ButtonProps {
  className?: string,
  content?: JSX.Element | string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  loading?: boolean,
  size?: keyof SizesType;
  fullWidth?: boolean
  outline?: boolean
  color?: keyof typeof Colors | HEX | string,
  background?: keyof typeof Colors | HEX | string,
  children?: JSX.Element | string | JSX.Element[] | string[],
  style?: CSSProperties,
  isRequestingButton?: boolean,
}

function Button(props: ButtonProps) {
  const {
    className,
    content,
    disabled = false,
    loading = false,
    background,
    onClick,
    onMouseDown,
    size,
    outline,
    color = 'primary',
    fullWidth,
    children,
    style = {},
    isRequestingButton = false,
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const computeClasses = () => {
    const result: string[] = [];
    result.push(outline ? styles.outline : styles.solid);
    if (size) result.push(Sizes[size]);
    return result.join(' ');
  };
  const computeStyle = () => {
    if (disabled) return { cursor: 'default' };
    if (outline) {
      return {
        color: theme.colors[color] || color,
        borderColor: theme.colors[color] || color,
        backgroundColor: background ? theme.colors[background] || theme.colors.white : theme.colors.white,
        ...style,
      };
    }

    return {
      color: colorToGray(theme.colors[color] || color) > 170 ? theme.colors.black : theme.colors.white,
      backgroundColor: background ? theme.colors[background] || theme.colors[color] || color : theme.colors[color] || color,
      ...style,
    };
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.disabled = loading;
    }
  }, [loading]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!loading && onClick) {
      onClick(event);
      if (buttonRef.current && isRequestingButton) {
        buttonRef.current.disabled = true;
      }
    }
  };

  return (
    <button
      ref={buttonRef}
      type="button"
      className={`${styles.button} ${computeClasses()} ${fullWidth && styles.fullWidth} ${className} `}
      disabled={disabled || loading}
      style={computeStyle()}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    >
      {
        loading
          ? <div className={styles.loadingContainer}><Spinner borderWidth={3} size={20} /></div>
          : (children || content)
      }
    </button>
  );
}

export default Button;
