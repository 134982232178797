import { useEffect, useState } from 'react';
import styles from './detailCellRender.module.css';
import reserveManagementApi, { ReserveManagementDetailList } from '../../services/http/reserveManagement.api';
import Spinner from '../../components/Spinner';
import { ApiError } from '../../services/http';
import { STATUS_MESSAGES } from '../../utils/messages';

/**
 * 詳細セルレンダラのプロップスインターフェース
 */
export interface Props {
  /** 予約番号 */
  reserveNo: number
}

/**
 * 文書詳細セル表示のコンポーネント
 */
function DetailCellRenderer({ reserveNo }: Props) {
  // STATES
  const [reserveManagementDetailList, setReserveManagementDetailList] = useState<ReserveManagementDetailList[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(id: number): Promise<void> {
    setLoading(true);
    try {
      const doc = await reserveManagementApi.getReserveManagementDetail(id);
      setReserveManagementDetailList(doc);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  // Initial State
  useEffect(() => {
    request(reserveNo);
  }, []);

  return (
    <div className={styles['detail-row-container']}>
      {loading && reserveManagementDetailList.length === 0 && (
      <div className={styles['loading-row']}>
        <Spinner size={25} />
      </div>
      )}
      {!loading && reserveManagementDetailList.length === 0 && (
      <div className={styles['loading-row']}>
        { STATUS_MESSAGES.NO_DATA }
      </div>
      )}
      { reserveManagementDetailList.map((item) => (
        <div className={styles['detail-row']} key={item.documentName}>
          <div>
            <label>文書ID：</label>
            <span>{item.ccControlNumber}</span>
          </div>
          <div>
            <label>文書名：</label>
            <span>{item.documentName}</span>
          </div>
          <div>
            <label>文書種類：</label>
            <span>{item.documentTypeName}</span>
          </div>
          <div>
            <label>バーコード印字欄：</label>
            <span>{item.barcodePrinting}</span>
          </div>
          <div>
            <label>保管品バーコード：</label>
            <span>{item.itemCode}</span>
          </div>
          <div>
            <label>フォルダ：</label>
            <span>{item.folderName}</span>
          </div>
          <div>
            <label>登録者：</label>
            <span>{item.registerUser}</span>
          </div>

        </div>
      ))}
    </div>
  );
}

export default DetailCellRenderer;
