import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import userAuthorityPresetApi, {
  DeleteUserAuthorityPresetRequest,
  GetUserAuthorityPresetDetail,
  GetUserAuthorityPresetResponse,
  PostUserAuthorityPresetRequest,
  UpdateUserAuthorityPresetRequest,
  UserAuthorityPreset,
} from '../../services/http/userAuthorityPreset';

/**
 * ユーザー権限プリセットを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export default function useGetUserAuthorityPresetApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserAuthorityPreset[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (): Promise<GetUserAuthorityPresetResponse> => {
    setLoading(true);
    try {
      const doc = await userAuthorityPresetApi.Get();
      setData(doc.presetList);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

/**
 * ユーザー権限プリセット詳細を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetUserAuthorityPresetDetail() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetUserAuthorityPresetDetail | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (id: number): Promise<GetUserAuthorityPresetDetail> => {
    setLoading(true);
    try {
      const doc = await userAuthorityPresetApi.GetDetail({
        id,
      });
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, data, error, request,
  };
}

/**
 * ユーザー権限プリセットをポストAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function usePostUserAuthorityPresetApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (requestData: PostUserAuthorityPresetRequest): Promise<string> => {
    setLoading(true);
    try {
      const res = await userAuthorityPresetApi.Post(requestData);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request,
  };
}

/**
 * ユーザー権限プリセットを更新APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useUpdateUserAuthorityPresetApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (requestData: UpdateUserAuthorityPresetRequest): Promise<string> => {
    setLoading(true);
    try {
      const res = await userAuthorityPresetApi.Update(requestData);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request,
  };
}

/**
 * ユーザー権限プリセットを削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDeleteUserAuthorityPresetApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (requestData: DeleteUserAuthorityPresetRequest): Promise<string> => {
    setLoading(true);
    try {
      const res = await userAuthorityPresetApi.Delete(requestData);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request,
  };
}
