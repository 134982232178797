import styles from './customFrame.module.css';

export interface CustomFrameInterface {
  title: JSX.Element | string
  desc: JSX.Element
  body: JSX.Element | any
}

function CustomFrame(props: CustomFrameInterface) {
  const { title, desc, body } = props;
  return (
    <div className={styles.mainFrame}>
      <div className={styles.mainContent}>
        <h3 className={styles.title}>
          {title}
        </h3>

        <div className={styles.desc}>
          {desc}
        </div>

        <div className={styles.body}>
          {body}
        </div>
      </div>

    </div>
  );
}

export default CustomFrame;
