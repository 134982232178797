import { useCallback } from 'react';
import RadioGroupInput from '../../components/RadioGroupInput';
import styles from './userModalTabItem.module.css';
import { UserMailSetting, UserMailSettingGroup } from '../../services/http/user.api';

export interface UserModalTabItemProps {
  item: UserMailSettingGroup,
  onChange: (data: UserMailSettingGroup) => void
}

function UserModalTabItem({
  item,
  onChange,
}: UserModalTabItemProps) {
  const handleChange = useCallback((oldItem: UserMailSetting, value: number) => {
    const newItem = {
      ...oldItem,
      mailStopFlg: Boolean(value),
    };
    const newItemGroup: UserMailSettingGroup = {
      id: item.id,
      mailSettingGroupName: item.mailSettingGroupName,
      userMailSettingList: item.userMailSettingList.map((mailSetting) => {
        if (mailSetting.id === newItem.id) {
          return newItem;
        }
        return mailSetting;
      }),
    };
    onChange(newItemGroup);
  }, [item, onChange]);

  return (
    <div className={styles.tabItem}>
      <div className={styles.tabItemTitle}>
        {item.mailSettingGroupName}
      </div>
      {item.userMailSettingList.map((mailSetting: UserMailSetting) => (
        <div key={mailSetting.id} className={styles.tabItemCard}>
          <div className={styles.tabItemSubTitle}>
            {mailSetting.mailSettingName}
          </div>
          <div className={styles.tabCardRow}>
            <RadioGroupInput<number>
              value={Number(mailSetting.mailStopFlg)}
              onChange={(val) => {
                handleChange(mailSetting, Number(val));
              }}
            >
              <RadioGroupInput.RadioInput value={0}>受け取る</RadioGroupInput.RadioInput>
              <RadioGroupInput.RadioInput value={1}>受け取らない</RadioGroupInput.RadioInput>
            </RadioGroupInput>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UserModalTabItem;
