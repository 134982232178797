import {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import styles from './inputCellRender.module.css';
import Input from '../../../Input/input';

export interface InputCellRenderProps<T=any> extends ICellEditorParams {
  trim?: boolean
  onTextInput?: (row: T, value: string) => void
}

export default forwardRef((props: InputCellRenderProps, ref) => {
  const [value, setValue] = useState<string>(props.value);
  const refInput = useRef<HTMLInputElement>(null);

  const onTextInput = (val: string) => {
    setValue(val);
    if (props.onTextInput) {
      props.onTextInput(props.data, val);
    }
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      if (props.trim) {
        return value.trim();
      }
      return value;
    },
  }));

  useEffect(() => {
    refInput.current?.focus();
    refInput.current?.click();
  }, []);

  return (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      <Input
        className={styles.inputStyle}
        onChange={(val) => setValue(val)}
        onBlur={(e) => onTextInput(e.target.value)}
        value={value}
      />
    </div>
  );
});
