import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './userSwitchScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import { useOrganizationApi, useOrganizationUserApi } from '../../hooks/api/organization.hook';
import {
  Organization, OrganizationSearchForm, OrganizationSearchUserForm, OrganizationUser,
} from '../../services/http/organization.api';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import { UserSwitchHookApi } from '../../hooks/api/userSwitchHook';
import { useGetUserApi } from '../../hooks/api/auth.hook';
import routes from '../../utils/routes';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import AlertModal, { AlertModalProps, alertModalInitialState } from '../../components/AlertModal';

/**
 * ユーザー切り替えページ
 */
function UserSwitchScreenPage() {
  usePageTitle('ユーザー切り替え');

  const { request: organizationApiRequest } = useOrganizationApi();
  const { request: organizationUserApiRequest } = useOrganizationUserApi();
  const { request: createLog } = useCreateLogApi();

  // useState
  const [users, setUsers] = useState<OrganizationUser[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectOrganizationValue, setSelectOrganizationValue] = useState<Organization | null>(null);
  const [selectUserValue, setSelectUserValue] = useState<OrganizationUser | null>(null);
  const [organizationSearch, setOrganizationSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);

  const defaultUserSearchText = '寺田倉庫';

  const navigator = useNavigate();

  // useMemo
  const organizationOptions = useMemo<DropDownMenuItem<Organization>[]>(() => organizations.map((o) => ({
    text: o.customerName,
    value: { ...o },
  })), [organizations]);

  const userOptions = useMemo<DropDownMenuItem<OrganizationUser>[]>(() => users.map((u) => ({
    text: u.userName,
    value: { ...u },
  })), [users]);

  // API's
  const { request: getUserApiRequest } = useGetUserApi();
  const { request: switchHookApiRequest } = UserSwitchHookApi();

  // useCallback
  const handleUsersSearch = useCallback(async (searchForm: OrganizationSearchUserForm) => {
    const usersData = await organizationUserApiRequest(searchForm);
    setUsers(usersData);

    const firstUserWithDefaultText = usersData.filter((user) => user.userName.toLowerCase().includes(defaultUserSearchText))[0];
    if (firstUserWithDefaultText) {
      setUserSearch(firstUserWithDefaultText.userName);
      setSelectUserValue(firstUserWithDefaultText);
    }
  }, [organizationUserApiRequest]);

  const handleOrganizationOnSelect = useCallback((value: Organization) => {
    setSelectOrganizationValue({ ...value });
    setSelectUserValue(null);
    setUserSearch('');
    setUsers([]);
    handleUsersSearch({ customerId: value.customerId });
  }, [handleUsersSearch]);

  const handleUserOnSelect = useCallback((value: OrganizationUser) => {
    setSelectUserValue({ ...value });
  }, []);

  const handleDoSwitch = useCallback(async (selectedOrganization: Organization | null, selectedUser: OrganizationUser | null) => {
    if (selectedOrganization == null) {
      return;
    }

    if (selectedUser == null) {
      return;
    }

    // call switch api
    try {
      createLog(LogFormName.UserSwitchScreen, LogControlName.Create);

      await switchHookApiRequest({ customerId: `${selectedOrganization.customerId}`, changeUserId: `${selectedUser.userId}` });
    } catch (e) {
      console.error(e);
    }

    // get User again
    try {
      await getUserApiRequest();
    } catch (e) {
      console.error(e);
    }

    // redirect after switch
    navigator(routes.main);
  }, [switchHookApiRequest, getUserApiRequest, navigator]);

  const handleCancelSwitch = useCallback(() => {
    navigator('/');
  }, [navigator]);

  const handleOpenConfirmModal = useCallback(() => {
    if (!selectOrganizationValue || !selectUserValue) return;
    setModal({
      open: true,
      text:
  <div>
    以下の取引先に切替を行います。よろしいですか？
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
      }}
    >
      <span>{`・取引先：${selectOrganizationValue.customerName}`}</span>
      <span>{`・ユーザー：${selectUserValue.userName}`}</span>
    </div>
  </div>,
      onConfirm: async () => {
        await handleDoSwitch(selectOrganizationValue, selectUserValue);
      },
      onCancel: async () => setModal({ ...modal, open: false }),
    });
  }, [handleDoSwitch, modal, selectOrganizationValue, selectUserValue]);

  // useEffect
  useEffect(() => {
    const init = async () => {
      const requestData: OrganizationSearchForm = {
        customerName: '',
        tenantCode: '',
        ownerName: '',
        contractName: '',
        email: '',
        userName: '',
        existFirstUser: true,
      };
      const organizationsData = await organizationApiRequest(requestData);
      setOrganizations(organizationsData);
    };

    init();
  }, [organizationApiRequest]);

  useEffectOnce(() => {
    createLog(LogFormName.UserSwitchScreen, LogControlName.Show);
  });

  return (
    <>
      <div>
        <div className={styles.container}>
          <h1 className={styles.title}>ユーザの切り替え</h1>
          <p className={styles['text-style']}>
            文書管理システム利用ユーザーに成り代わって作業をする場合のみ利用してください。
          </p>
          <h2 className={[styles['sub-title'], mainStyles['mt-4']].join(' ')}>
            ご注意
          </h2>
          <ul className={styles['text-style']}>
            <li>
              成り代わり後、顧客文書の参照・更新・削除が実施できます。誤操作にご注意ください。
            </li>
            <li className={mainStyles['mt-1']}>
              顧客、寺田倉庫管理者の許可を得てから操作するようにしてください。
            </li>
          </ul>
          <form>
            <div className={[styles.flex, mainStyles['mt-4']].join(' ')}>
              <label className={styles['drop-down-menu-label']}>
                取引先
              </label>
              <div className={styles.selection}>
                <AutoCompleteInput
                  value={organizationSearch}
                  onTextInput={setOrganizationSearch}
                  onSelect={handleOrganizationOnSelect}
                  options={organizationOptions}
                  placeholder=""
                  menuStyle={{ width: '100%' }}
                  deselectOption
                  onDeselect={() => {
                    setSelectOrganizationValue(null);
                    setSelectUserValue(null);
                    setUserSearch('');
                    setUsers([]);
                  }}
                />
              </div>
            </div>

            <div className={[styles.flex, mainStyles['mt-4']].join(' ')}>
              <label className={styles['drop-down-menu-label']}>
                ユーザー
              </label>
              <div className={styles.selection}>
                <AutoCompleteInput
                  value={userSearch}
                  onTextInput={setUserSearch}
                  onSelect={handleUserOnSelect}
                  options={userOptions}
                  placeholder=""
                  menuStyle={{ width: '100%' }}
                  deselectOption
                  onDeselect={() => {
                    setSelectUserValue(null);
                    setUserSearch('');
                  }}
                />
              </div>
            </div>
          </form>

          <div className={mainStyles['mt-5']}>
            <button type="button" onClick={handleCancelSwitch} className={[mainStyles.btn, mainStyles['btn-gray']].join(' ')}>キャンセル</button>
            <button type="button" onClick={handleOpenConfirmModal} className={[mainStyles.btn, mainStyles['btn-primary']].join(' ')}>切り替え</button>
          </div>
        </div>
      </div>
      <AlertModal
        open={modal.open}
        text={modal.text}
        onConfirm={modal.onConfirm}
        onCancel={modal.onCancel}
      />
    </>
  );
}

export default UserSwitchScreenPage;
