import Formatter from './formatters';

export default class Validator {
  /**
   * パスワードに使用できる記号の文字列。
   */
  static passwordSymbols = '.-_&$#@!=';

  /**
   * パスワードに使用できる記号の数を返す。
   */
  static passwordSymbolsLength = () => Validator.passwordSymbols.length;

  /**
   * パスワードの最小文字数。
   */
  static passwordLengthMin = 8;

  /**
   * パスワードの最大文字数。
   */
  static passwordLengthMax = 20;

  /**
   * ユーザー名の最大文字数。
   */
  static userNameLengthMax = 20;

  /**
   * 連絡先のメッセージの最大文字数。
   */
  static contactMessageMax = 100;

  /**
   * csvDateRegexは、CSVファイル内の日付書式のバリデーションに使用する正規表現。
   */
  static csvDateRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  /**
   * ユーザー名が有効かどうかを確認する。
   *
   * @param name - ユーザー名
   */
  static validUserName(name: string) {
    if (!name || name.length > Validator.userNameLengthMax) throw new Error(`${Validator.userNameLengthMax}文字以内で入力してください`);
  }

  /**
   * パスワードが有効な文字であるかどうかを確認する。
   *
   * @param password - パスワードの文字列
   * @throws - パスワードが有効な文字でない場合にエラーを投げる
   */
  static validPasswordTypeChars(password: string) {
    if (!password.match(/^[a-zA-Z0-9.\-_&$#@!=]{8,20}$/)) throw new Error(`利用可能な文字は半角英数字及び${Validator.passwordSymbolsLength()}種類の記号(${Validator.passwordSymbols})となります`);
  }

  /**
   * パスワードが有効な文字であるかどうかを確認する。
   *
   * @param password - パスワードの文字列
   * @throws - 大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください
   */
  static validPasswordChars(password: string) {
    if (!password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) throw new Error('大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください');
  }

  /**
   * パスワードの長さが有効かどうかを確認する。
   *
   * @param password - パスワードの文字列
   * @throws - パスワードの長さが有効でない場合にエラーを投げる
   */
  static validPasswordLength(password: string) {
    if (password.length < Validator.passwordLengthMin || password.length > Validator.passwordLengthMax) throw new Error(`パスワードの文字数が使用可能範囲外です。パスワードの文字数は半角${Validator.passwordLengthMin}文字以上${Validator.passwordLengthMax}文字以内で入力してください。`);
  }

  /**
   * 2つのパスワードが一致するかどうかを確認する。
   *
   * @param password - パスワードの文字列
   * @param other - 確認するパスワードの文字列
   * @throws パスワードが一致しません
   */
  static comparePassword(password: string, other: string) {
    if (password !== other) throw new Error('パスワードが一致しません');
  }

  /**
   * テナントコードが有効かどうかを確認する。
   *
   * @param tenant - テナントコードの文字列
   * @throws - テナントコード無効
   */
  static validateTenantCode(tenant: string) {
    if (tenant.length < 1) throw Error('テナントコード無効');
  }

  /**
   * メールアドレスが有効かどうかを確認する。
   *
   * @param email - メールアドレスの文字列
   * @throws メールが無効です
   */
  static validateEmail(email: string) {
    if (!email.match(/.+?@.+?\..+/)) throw new Error('メールが無効です');
  }

  /**
   * 文字列が有効な日付形式かどうかを確認する。
   *
   * @param val - CSVの文字列
   * @returns 日付形式であればtrue, そうでなければfalse
   */
  static csvStringIsValidDate(val?: string): boolean {
    if (val) return Formatter.fromFormatToDateTime(val, 'yyyy/MM/dd').isValid;
    return false;
  }

  /**
   * 文字列が0か1かどうかを確認する。
   *
   * @param val - CSVの文字列
   * @returns 0か1であればtrue, そうでなければfalse
   */
  static csvStringIsZeroOrOne(val?: string): boolean {
    return val === '0' || val === '1';
  }

  /**
   * 文字列が１文字目が0であれば2字目も0であるかどうかを確認する。
   *
   * @param val1 - CSVの文字列1
   * @param val2 - CSVの文字列2
   * @returns val1が1でval2が0であればtrue, そうでなければfalse
   */
  static csvStringIsZeroWhenFirstIsOne(val1?: string, val2?: string): boolean {
    return !(val1 === '0' && val2 === '0');
  }

  /**
   * 文字列が空でないかどうかを確認する。
   *
   * @param val - CSVの文字列
   * @returns - 空でなければtrue、空であればfalse
   */
  static csvStringIsNotEmpty(val?: string): boolean {
    return Formatter.validString(val) !== null;
  }
}
