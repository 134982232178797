import axios from 'axios';
import { DateTime } from 'luxon';
import { IconChangeStatus } from './user.api';

export interface AdminUserSearchForm {
  userName?: string,
  email?: string,
  deleteFlg?: boolean,
}

export interface AdminUser {
  id: string,
  userName: string,
  email: string,
  emailConfirmed: boolean,
  passwordUpdate: boolean,
  deleteFlg: boolean,
}
export interface AdminUserFull extends AdminUser {
  registerDate: Date,
  registerUser: string,
  updateDate: Date,
  updateUser: string,
  icon: string,
}
export interface AdminUserRegisterForm {
  userName: string,
  email: string,
  password: string,
  icon?: File | string,
}
export interface AdminUserUpdateForm {
  id: string,
  userName: string,
  email: string,
  iconChangeStatus: IconChangeStatus,
  icon?: File | string,
  deleteFlg: boolean,
}

function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export default {
  /**
   * 管理者ユーザーの検索を行います。
   *
   * @param form - 検索フォーム
   * @returns - 検索結果のユーザー一覧
   */
  async searchAdminUser(form: AdminUserSearchForm): Promise<AdminUser[]> {
    const res = await axios.get('/ManagementUser/searchAdminUser', {
      params: {
        userName: form.userName || null,
        email: form.email || null,
        deleteFlg: form.deleteFlg !== undefined ? Number(form.deleteFlg) : undefined,
      },
    });
    const { data } = res;
    return data.userList.map((item: {
      id: string,
      userName: string,
      email: string,
      emailConfirmed: number,
      passwordUpdate: number,
      deleteFlg: number,
      userMailSettingList: { mailType: number, mailSettingName: string, mailStopFlg: number },
    }): AdminUser => ({
      id: item.id,
      userName: item.userName,
      email: item.email,
      emailConfirmed: Boolean(item.emailConfirmed),
      passwordUpdate: Boolean(item.passwordUpdate),
      deleteFlg: Boolean(item.deleteFlg),
    }
    ));
  },
  /**
   * 管理者ユーザーの詳細情報を取得します。
   *
   * @param form - IDを含むフォームデータ
   * @returns - 管理者ユーザーの詳細情報
   */
  async getDetailAdminUser(form: { id: string }): Promise<AdminUserFull> {
    const res = await axios.get('/ManagementUser/getDetailAdminUser', {
      params: { id: form.id },
    });
    const { data } = res;
    return {
      id: data.id,
      userName: data.userName,
      email: data.email,
      emailConfirmed: Boolean(data.emailConfirmed),
      passwordUpdate: Boolean(data.passwordUpdate),
      icon: data.icon,
      deleteFlg: Boolean(data.deleteFlg),
      registerDate: DateTime.fromISO(data.registerDate).toJSDate(),
      registerUser: data.registerUser,
      updateDate: DateTime.fromISO(data.updateDate).toJSDate(),
      updateUser: data.updateUser,
    };
  },
  /**
   * 管理者ユーザーの登録を行います。
   *
   * @param form - 管理者ユーザーの登録フォーム
   * @returns - Promise<void>
   */
  async registerAdminUser(form: AdminUserRegisterForm): Promise<void> {
    let adminUserRegisterForm;
    if (typeof form.icon === 'string') {
      adminUserRegisterForm = {
        userName: form.userName,
        email: form.email,
        password: form.password,
        icon: form.icon,
      };
    } else {
      adminUserRegisterForm = {
        userName: form.userName,
        email: form.email,
        password: form.password,
        icon: form.icon ? await toBase64(form.icon) : undefined,
      };
    }
    const res = await axios.post('/ManagementUser/registerAdminUser', adminUserRegisterForm);
    return res.data;
  },
  /**
   * 管理者ユーザーの更新を行います。
   *
   * @param form - 管理者ユーザーの更新フォーム
   * @returns - Promise<void>
   */
  async updateAdminUser(form: AdminUserUpdateForm): Promise<void> {
    let adminUserUpdateForm;
    if (typeof form.icon === 'string') {
      adminUserUpdateForm = {
        id: form.id,
        userName: form.userName,
        email: form.email,
        iconChangeStatus: form.iconChangeStatus.toString(),
        icon: form.icon,
        deleteFlg: form.deleteFlg ? 1 : 0,
      };
    } else {
      adminUserUpdateForm = {
        id: form.id,
        userName: form.userName,
        email: form.email,
        iconChangeStatus: form.iconChangeStatus.toString(),
        icon: form.icon ? await toBase64(form.icon) : undefined,
        deleteFlg: form.deleteFlg ? 1 : 0,
      };
    }
    const res = await axios.post('/ManagementUser/updateAdminUser', adminUserUpdateForm);
    return res.data;
  },
  /**
   * 管理者ユーザーの削除を行います。
   *
   * @param form - 管理者ユーザーの削除フォーム
   * @returns - Promise<void>
   */
  async deleteAdminUser(form: { id: string }): Promise<void> {
    const res = await axios.post('/ManagementUser/deleteAdminUser', { id: form.id });
    return res.data;
  },
};
