import axios from 'axios';
import { UserAuthorityPresetControl } from './userAuthorityPreset';

export interface UserGetAuthorityRequest {
  userId: string
}

export interface RegisterUserAuthorityRequest {
  userId: string,
  controlList: { controlCode: string }[]
}

export default {
  /**
   * ユーザー権限を取得する関数
   *
   * @param request - ユーザーID
   * @returns - ユーザー権限プリセットコントロール一覧
   */
  async getUserAuthority(request: UserGetAuthorityRequest): Promise<UserAuthorityPresetControl[]> {
    const res = await axios.get('/User/getAuthority', {
      params: {
        userId: request.userId,
      },
    });
    const { data } = res;
    return data.controlList as UserAuthorityPresetControl[];
  },
  /**
   * ユーザー権限を登録する関数
   *
   * @param request - ユーザー権限を登録するフォーム
   * @returns - 空のプロミス
   */
  async registerUserAuthority(request: RegisterUserAuthorityRequest): Promise<void> {
    await axios.post('/User/registerAuthority', request);
  },
};
