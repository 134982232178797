import {
  useCallback, useMemo, useRef, useState, useEffect,
} from 'react';
import * as uuid from 'uuid';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef, ICellRendererParams,
} from 'ag-grid-community';
import usePageTitle from '../../hooks/title.hook';
import MainFrame from '../../components/MainFrame/mainFrame';
import mainStyles from '../main.module.css';
import styles from './tagBulkScreenPage.module.css';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import { useMessageModal } from '../../hooks/modal.hook';
import BaseTable from '../../components/BaseTable';
import { BaseTag } from '../../services/http/tag.api';
import AGUtils from '../../utils/ag-grid.utils';
import TagFormat from '../../utils/tagFormat';
import SelectInput from '../../components/SelectInput';
import { useGetAllTagsApi } from '../../hooks/api/tag.hook';
import { TagFilter, TagFormatOptions } from '../../components/SearchInputTop/SearchInputTopFilterModal';
import { Document, DocumentSearchDetailForm } from '../../services/http/documents.api';
import Formatter from '../../utils/formatters';
import { useDocumentSearchDetail } from '../../hooks/api/document.hook';
import { ApiError } from '../../services/http';
import TagBulkInputs from './tagBulkInputs';
import { TagBulk, TagBulkDocumentListItem, UpdateTagBulkForm } from '../../services/http/tagBulk.api';
import TagBulkAddCard from './tagBulkAddCard';
import AlertModal, { alertModalInitialState } from '../../components/AlertModal';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useGetTagBulkApi, useUpdateTagBulkApi } from '../../hooks/api/tagBulk.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import './tagBulkTableStyle.css';

interface TagBulkCardItem extends TagBulk {
  isValid: boolean,
}

interface UpdateTagBulkPageForm {
  tagList: TagBulkCardItem[],
  documentList: TagBulkDocumentListItem[],
}

const initialState: UpdateTagBulkPageForm = {
  tagList: [],
  documentList: [],
};

export default function TagBulkScreenPage() {
  usePageTitle('一括タグ変更');
  const navigate = useNavigate();
  const openMessageModal = useMessageModal();
  const gridRef = useRef<AgGridReact<Document>>(null);
  const { allTags, reload: getAllTags, loadingAllTags } = useGetAllTagsApi();
  const { request: documentSearchDetailRequest, loading: documentSearchDetailLoading } = useDocumentSearchDetail();
  const { request: updateTagBulkRequest, loading: updateTagBulkLoading } = useUpdateTagBulkApi();
  const { request: getTagBulkRequest, loading: getTagBulkLoading } = useGetTagBulkApi();
  const { request: createLog } = useCreateLogApi();

  const tagColStart = 'tag-col-';

  const TagFormatTextMap = TagFormat.getTextMap();

  const [documents, setDocuments] = useState<Document[]>([]);
  const [totalDocuments, setTotalDocuments] = useState<number | null>(null);
  const [tagFilter, setTagFilter] = useState<TagFilter | null>(null);
  const [form, setForm] = useState<UpdateTagBulkPageForm>(initialState);
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [selectTags, setSelectTags] = useState<TagBulk[]>([]);
  const [columnBeingMoved, setColumnBeingMoved] = useState<string | null>(null);
  const [tagAfterColEnd] = useState<string>(`${Date.now()}-after-col-end`);

  const disableSearchButton = !tagFilter || tagFilter.tags.some((tag) => !tag.condition);
  const isOverLimit = totalDocuments ? totalDocuments > 500 : false;

  const disableSendButton = useMemo(() => {
    if (form.tagList.length === 0) return true;
    if (form.documentList.length === 0) return true;
    if (form.tagList.some((tag) => !tag.tagLabel)) return true;
    if (form.tagList.some((tag) => !tag.isValid)) return true;
    return false;
  }, [form]);

  const allAvailableTags = useMemo(() => {
    if (selectTags.length === 0) return [];
    const usedTags = new Set<string>(form.tagList.map((tag) => tag.tagLabel));
    const unusedTags = selectTags.filter((tag) => !usedTags.has(tag.tagLabel));
    return unusedTags;
  }, [selectTags, form.tagList]);

  const noAvailableTagsLeft = allAvailableTags.length === 0;

  const isFormValid = useMemo(() => {
    if (!form.tagList.length) return true;
    const isAllRequiredTagsFilled = form.tagList.every((tag) => (tag.required ? tag.value : true));
    return isAllRequiredTagsFilled;
  }, [form]);

  const onDocumentSelected = useCallback((docs: Document[]) => {
    const documentsIds: TagBulkDocumentListItem[] = docs.map((doc) => ({ documentId: doc.id }));
    setForm((prev) => ({ ...prev, documentList: documentsIds }));
  }, []);

  const handleMoveBeforeColumn = useCallback((columnId: string) => {
    if (!gridRef.current) return;
    const colApi = gridRef.current.columnApi;

    const allCols = colApi.getAllGridColumns();
    if (!allCols) return;

    const colHasAfter = allCols.some((col) => col.getColId() === `${columnId}${tagAfterColEnd}`);
    const allColsWithoutHisAfter = allCols.filter((col) => col.getColId() !== `${columnId}${tagAfterColEnd}`);

    const indexedCols = colHasAfter ? allColsWithoutHisAfter : allCols;
    const beforeColIndex = indexedCols.findIndex((col) => col.getColId() === columnId);
    if (beforeColIndex === -1) return;

    const afterCol = allCols.find((col) => col.getColId() === `${columnId}${tagAfterColEnd}`);
    const afterColIndex = allCols.findIndex((col) => col.getColId() === `${columnId}${tagAfterColEnd}`);
    if (!afterCol || afterColIndex === -1) return;

    colApi.moveColumnByIndex(afterColIndex, beforeColIndex + 1);
  }, [tagAfterColEnd]);

  const handleMoveAfterColumn = useCallback((columnId: string) => {
    if (!gridRef.current) return;
    const colApi = gridRef.current.columnApi;

    const allCols = colApi.getAllGridColumns();
    if (!allCols) return;

    const colIdBefore = columnId.replace(tagAfterColEnd, '');
    const allColsWithoutHisBefore = allCols.filter((col) => col.getColId() !== colIdBefore);

    const afterColIndex = allColsWithoutHisBefore.findIndex((col) => col.getColId() === columnId);
    if (afterColIndex === -1) return;

    const beforeCol = allCols.find((col) => col.getColId() === colIdBefore);
    const beforeColIndex = allCols.findIndex((col) => col.getColId() === colIdBefore);
    if (!beforeCol || beforeColIndex === -1) return;

    colApi.moveColumnByIndex(beforeColIndex, afterColIndex);
  }, [tagAfterColEnd]);

  const moveTagColumns = useCallback((isAddingCol = false) => {
    setTimeout(() => {
      if (!gridRef.current) return;
      const colApi = gridRef.current.columnApi;

      const allCols = colApi.getColumns();
      if (!allCols) return;

      const allBeforeTagCols = allCols.filter((col) => {
        const colId = col.getColId();
        if (!colId.startsWith(tagColStart)) return false;
        if (colId.endsWith(tagAfterColEnd)) return false;
        return true;
      });

      const allAfterTagCols = allCols.filter((col) => {
        const colId = col.getColId();
        if (!colId.startsWith(tagColStart)) return false;
        if (!colId.endsWith(tagAfterColEnd)) return false;
        return true;
      });

      if (!columnBeingMoved || isAddingCol) {
        allBeforeTagCols.forEach((col) => handleMoveBeforeColumn(col.getColId()));
        return;
      }

      const isTagCol = columnBeingMoved.startsWith(tagColStart);
      if (!isTagCol) return;

      const isBeforeCol = columnBeingMoved.startsWith(tagColStart) && !columnBeingMoved.endsWith(tagAfterColEnd);
      if (isBeforeCol) {
        allBeforeTagCols.forEach((col) => handleMoveBeforeColumn(col.getColId()));
        return;
      }

      const isAfterCol = columnBeingMoved.startsWith(tagColStart) && columnBeingMoved.endsWith(tagAfterColEnd);
      if (isAfterCol) {
        allAfterTagCols.forEach((col) => handleMoveAfterColumn(col.getColId()));
      }
    }, 100);
  }, [columnBeingMoved, handleMoveAfterColumn, handleMoveBeforeColumn, tagAfterColEnd]);

  const onAddNewTag = useCallback(() => {
    setForm((prev) => ({
      ...prev,
      tagList: [
        ...prev.tagList,
        {
          id: uuid.v4(),
          tagLabel: '',
          format: TagFormat.STRING,
          required: false,
          value: '',
          isValid: true,
        },
      ],
    }));
    moveTagColumns();
  }, [moveTagColumns]);

  const handleSearch = useCallback(async () => {
    setForm((prev) => ({ ...prev, tagList: [] }));
    const sendForm: DocumentSearchDetailForm = {
      fileName: '',
      memo: '',
      name: '',
      tagList: [],
      page: 1,
    };
    if (tagFilter) {
      tagFilter.tags.forEach((tag) => {
        sendForm.tagList?.push({
          label: tagFilter.defaultTag.tagLabel,
          condition: tag.condition,
          value1: tag.date1 ? Formatter.fromDateTimeToString(tag.date1, Formatter.defaultFullDateFormat) : tag.value1,
          value2: tag.date2 ? Formatter.fromDateTimeToString(tag.date2, Formatter.defaultFullDateFormat) : tag.value2,
        });
      });
    }
    try {
      const res = await documentSearchDetailRequest({ ...sendForm, bulkTagChange: 1 });
      setDocuments(res.documents);
      setTotalDocuments(res.total);
      const documentsIds: TagBulkDocumentListItem[] = res.documents.map((doc) => ({ documentId: doc.id }));
      const getTagsRes = await getTagBulkRequest({ documentList: documentsIds });
      setSelectTags(getTagsRes);
      if (getTagsRes.length > 0) {
        onAddNewTag();
      }
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchDetailRequest, getTagBulkRequest, openMessageModal, tagFilter, onAddNewTag]);

  const onConfirmSendForm = useCallback(async () => {
    try {
      const formattedForm: UpdateTagBulkForm = {
        documentList: form.documentList,
        tagList: form.tagList.map((tag) => ({
          id: tag.id,
          tagLabel: tag.tagLabel,
          format: tag.format,
          required: tag.required,
          value: tag.value,
        })),
      };
      await updateTagBulkRequest(formattedForm);
      openMessageModal('変更しました。');
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [form, openMessageModal, updateTagBulkRequest, createLog]);

  const handleSendForm = useCallback(() => {
    if (!isFormValid) return openMessageModal('全ての必須項目を入力してください。');
    return setAlertModal({
      open: true,
      text: `${form.documentList.length}件が更新されます。\n`
        + '実施してよろしいですか？',
      onCancel: () => {
        setAlertModal({ ...alertModal, open: false });
      },
      onConfirm: () => {
        onConfirmSendForm().then(() => { handleSearch(); });
        setAlertModal({ ...alertModal, open: false });
      },
    });
  }, [isFormValid, openMessageModal, form.documentList, alertModal, onConfirmSendForm, handleSearch]);

  const handleClear = useCallback(() => {
    setTagFilter(null);
    setDocuments([]);
    setTotalDocuments(null);
    setSelectTags([]);
    setForm(initialState);
  }, []);

  const colsDefs = useMemo((): ColDef[] => {
    const cols: ColDef[] = [
      AGUtils.colDefault('ccControlNumber', '文書ID'),
      {
        headerName: 'Checkbox',
        field: 'checkbox',
        pinned: 'left',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 50,
        lockPosition: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: '文書名',
        resizable: true,
        sortable: true,
        field: 'name',
        width: 250,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => (
          <div
            className={mainStyles['ml-3']}
            style={{ alignContent: 'center', display: 'flex' }}
          >
            {params.node.data.fileName
              ? (
                <img
                  src="/images/Pdf-icon.svg"
                  alt="pdf"
                />
              )
              : (
                <img
                  src="/images/Pdf-icon-semitransparent.svg"
                  alt="pdf"
                />
              )}
            <span className={[mainStyles['ml-2'], mainStyles['text-overflow'], 'highlightTarget'].join(' ')}>
              {params.value}
            </span>
            {params.node.data.requiredTagInputFlg !== 1 && (
              <div style={{ marginLeft: 'auto' }}>
                <img src="/images/Icon-awesome-exclamation.svg" alt="exclamation" style={{ height: '12px', marginRight: '5px' }} />
              </div>
            )}
          </div>
        ),
      },
      AGUtils.colDefault('typeName', '文書種類'),
      AGUtils.colDefault('fileName', 'ファイル名', 170, true, true),
      AGUtils.colUsername('registUser', '登録者'),
      AGUtils.colDate('registDate', '登録日時'),
      AGUtils.colDefault('itemCode', '保管品バーコード'),
      AGUtils.colDefault('barcodePrinting', 'バーコード印字欄'),
    ];

    const tags = new Set<string>(allTags.map((tag) => tag.tagLabel));
    const unique = Array.from(tags);

    unique.forEach((tag) => {
      const isTagOnForm = !!form.tagList.find((t) => t.tagLabel === tag);
      const beforeCol: ColDef = {
        colId: `${tagColStart}${tag}`,
        field: `${tagColStart}${tag}`,
        headerName: tag,
        resizable: true,
        sortable: true,
        flex: 1,
        headerClass: isTagOnForm ? styles.tagHeaderOnForm : '',
        minWidth: 160,
        valueGetter: ({ data }) => {
          const docTag = data.tagList.find((t: { label: string, value: string }) => t.label === tag);
          if (docTag) {
            return docTag.value;
          }
          return '';
        },
      };
      const afterTagCol: ColDef = {
        headerName: `${tag}(変更後)`,
        field: `${tagColStart}${tag}${tagAfterColEnd}`,
        colId: `${tagColStart}${tag}${tagAfterColEnd}`,
        flex: 1,
        minWidth: 150,
        resizable: true,
        headerClass: styles.tagHeaderOnForm,
        cellClass: 'tagCellOnForm',
        initialHide: true,
        valueGetter: () => {
          const tagOnForm = form.tagList.find((t) => t.tagLabel === tag);
          if (!tagOnForm) return '';
          return tagOnForm.value;
        },
      };
      cols.push(beforeCol);
      cols.push(afterTagCol);
    });

    return cols;
  }, [allTags, form.tagList, tagAfterColEnd]);

  const onSetTagConditionChange = useCallback((filter: TagFilter, tag: TagFilter['tags'][number], condition: string) => {
    setTagFilter((tf) => {
      if (!tf || filter !== tf) return tf;
      return { ...tf, tags: tf.tags.map((t) => (t === tag ? { ...t, condition } : t)) };
    });
  }, []);

  const handleChangeTagValue = useCallback((id: string, value: string, isValid: boolean) => {
    setForm((prev) => ({
      ...prev,
      tagList: prev.tagList.map((tag) => (tag.id === id ? { ...tag, value, isValid } : tag)),
    }));
  }, []);

  const handleSelectTag = useCallback((id: string, tag: TagBulk | null) => {
    if (!tag) {
      setForm((prev) => ({
        ...prev,
        tagList: prev.tagList.map((t) => (t.id === id ? {
          id: t.id,
          tagLabel: '',
          format: TagFormat.STRING,
          required: false,
          value: '',
          isValid: true,
        } : t)),
      }));
      return;
    }
    setForm((prev) => ({
      ...prev,
      tagList: prev.tagList.map((t) => (t.id === id ? {
        ...t, tagLabel: tag.tagLabel, format: tag.format, required: tag.required,
      } : t)),
    }));
    // Focus on the tag column added
    setTimeout(() => {
      if (!gridRef.current) return;
      const colId = `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`;
      gridRef.current.api.ensureColumnVisible(colId, 'end');
    }, 50);
  }, [tagAfterColEnd]);

  const handleDeleteTag = useCallback((id: string) => {
    setForm((prev) => ({
      ...prev,
      tagList: prev.tagList.filter((tag) => tag.id !== id),
    }));
  }, []);

  const onSelectBaseTag = useCallback((tag: BaseTag | null) => {
    if (!tag) {
      setTagFilter(null);
      return;
    }

    if (tag.format === TagFormat.NUMBER) {
      setTagFilter({
        defaultTag: tag,
        tags: [{ condition: TagFormatOptions[TagFormat.NUMBER][0], value1: '', value2: '' }],
      });
      return;
    } if (tag.format === TagFormat.STRING) {
      setTagFilter({
        defaultTag: tag,
        tags: [{ condition: TagFormatOptions[TagFormat.STRING][0], value1: '', value2: '' }],
      });
      return;
    } if (tag.format === TagFormat.DATE) {
      setTagFilter({
        defaultTag: tag,
        tags: [{ condition: TagFormatOptions[TagFormat.DATE][0], value1: '', value2: '' }],
      });
    }
  }, []);

  const tagColIdsOnForm = useMemo(() => {
    if (!form.tagList.length) return [];
    return form.tagList.map((tag) => `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`);
  }, [form.tagList, tagAfterColEnd]);

  const tagColIdsNotOnForm = useMemo(() => {
    if (!allTags.length) return [];
    const tagLabelsOnForm = form.tagList.map((tag) => `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`);
    const allTagLabels = allTags.map((tag) => `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`);
    const allTagLabelsNotOnForm = allTagLabels.filter((tag) => !tagLabelsOnForm.includes(tag));
    return allTagLabelsNotOnForm;
  }, [allTags, form.tagList, tagAfterColEnd]);

  const handleColumnVisibleChange = useCallback(() => {
    if (!gridRef.current) return;
    const { columnApi } = gridRef.current;

    columnApi.setColumnsVisible(tagColIdsOnForm, true);
    columnApi.setColumnsVisible(tagColIdsNotOnForm, false);
  }, [tagColIdsOnForm, tagColIdsNotOnForm]);

  useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  useEffectOnce(() => {
    createLog(LogFormName.TagBulkScreen, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <div className={styles['mainframe-body']}>
            <header>一括タグ変更</header>
            <div className={styles.upperCard}>
              <div className={styles.row}>
                <div className={[styles.firstColumn, styles.labelColumn].join(' ')}>
                  <label>タグ検索条件</label>
                </div>
              </div>

              <div className={styles.row}>
                <div className={[styles.firstColumn, styles.column].join(' ')}>
                  <AutoCompleteInput<BaseTag>
                    options={allTags.map((tag) => ({ text: tag.tagLabel, value: tag }))}
                    onSelect={(tag) => onSelectBaseTag(tag)}
                    value={tagFilter ? tagFilter.defaultTag.tagLabel : ''}
                  />
                </div>

                <div className={[styles.secondColumn, styles.column].join(' ')}>
                  {tagFilter ? (
                    <>
                      <div className={styles.tagText}>{`（${TagFormatTextMap[tagFilter.defaultTag.format]}）が`}</div>
                      <div className={styles.secondColumnContent}>
                        <SelectInput
                          className={[mainStyles['mr-2'], styles.select].join(' ')}
                          value={tagFilter.tags[0].condition}
                          options={TagFormatOptions[tagFilter.defaultTag.format].map((item) => ({ text: item, value: item }))}
                          onChange={(value) => onSetTagConditionChange(tagFilter, tagFilter.tags[0], value)}
                        />
                        <TagBulkInputs tagFilter={tagFilter} filter={tagFilter.tags[0]} onChangeTagFilter={(value: TagFilter) => setTagFilter(value)} />
                      </div>
                    </>
                  ) : null}
                </div>

                <div className={styles.column}>
                  <Button
                    disabled={disableSearchButton}
                    color="primary"
                    size="smaller"
                    onClick={() => {
                      handleSearch();
                    }}
                  >
                    検索
                  </Button>
                </div>
                <div className={styles.column}>
                  <Button color="lighterGray" size="smaller" onClick={() => handleClear()}>リセット</Button>
                </div>
              </div>

            </div>
            <div className={styles.mainCard}>
              <div className={styles['table-container']}>
                <div className={styles.tableWrapper}>
                  <BaseTable<Document>
                    formName="tagBulkScreenTable"
                    waitToLoad={loadingAllTags}
                    tableRef={gridRef}
                    rowData={documents}
                    columnDefs={colsDefs}
                    rowSelection="multiple"
                    noRowsText={`変更対象タグを選択し、変更後の値を入力してください。\n
                    タグの値の変更は検索結果のうちチェックされたタグのみが対象となります。`}
                    suppressRowClickSelection
                    suppressCellFocus
                    useColPin
                    pagination
                    paginationPageSize={500}
                    suppressPaginationPanel
                    masterDetail
                    embedFullWidthRows
                    suppressMenuHide={false}
                    onSelectionChanged={(e) => onDocumentSelected(e.api.getSelectedRows())}
                    rowClass="tagBulkRow"
                    onRowDataUpdated={(e) => e.api.selectAll()}
                    onColumnMoved={(e) => {
                      if (!e.column) return;
                      const columnId = e.column.getColId();
                      if (!columnId.startsWith(tagColStart)) {
                        setColumnBeingMoved(null);
                        return;
                      }
                      setColumnBeingMoved(columnId);
                    }}
                    onDragStopped={() => {
                      moveTagColumns();
                      setColumnBeingMoved(null);
                    }}
                    onNewColumnsLoaded={() => handleColumnVisibleChange()}
                  />
                </div>
                <div className={styles.paginationLimitContainer}>
                  {totalDocuments ? (
                    <span>
                      {`検索結果：${totalDocuments}件`}
                    </span>
                  ) : null}
                  {isOverLimit ? (
                    <span className={styles.paginationLimitWarning}>
                      結果が最大値を超えたため最大件数を表示しています
                    </span>
                  ) : null}
                </div>
              </div>

              <div className={styles.sideCard}>
                <label className={styles.sideCardLabel}>タグ値の上書き</label>
                {form.tagList.map((tag) => (
                  <TagBulkAddCard
                    key={tag.id}
                    tag={tag}
                    allTags={allAvailableTags}
                    onChangeTagValue={(val, isValid) => handleChangeTagValue(tag.id, val, isValid)}
                    onDeleteTag={() => handleDeleteTag(tag.id)}
                    onInputFocus={() => {
                      if (!gridRef.current) return;
                      const colId = `${tagColStart}${tag.tagLabel}${tagAfterColEnd}`;
                      gridRef.current.api.ensureColumnVisible(colId, 'end');
                    }}
                    onSelectTag={(val) => handleSelectTag(tag.id, val)}
                  />
                ))}

                <Button style={{ minHeight: '30px' }} disabled={noAvailableTagsLeft} color="lighterGray" size="smaller" onClick={() => onAddNewTag()}>変更対象の追加</Button>
              </div>

            </div>
          </div>
          <footer className={styles['body-footer']}>
            <Button color="lighterGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
            <Button size="smaller" onClick={handleSendForm} disabled={disableSendButton}>変更</Button>
          </footer>
          <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
          {(updateTagBulkLoading || documentSearchDetailLoading || getTagBulkLoading) ? <LoadingOverlay /> : null}
        </div>
      )}
    />
  );
}
