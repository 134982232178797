/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

type CustomSwitchProps = SwitchProps;

const StyledSwitch = styled(({
  checked, onChange, name, ...other
}: CustomSwitchProps) => (
  <Switch
    checked={checked}
    onChange={onChange}
    name={name}
    {...other}
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
  />
))(({ theme }) => ({
  width: 40,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    transition: 'none',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#54C3F1',
        opacity: 1,
        border: 'none',
        transition: 'none',
      },
    },
    '&.Mui-disabled': {
      color: '#e8e7e7',
      opacity: 1,
      '& + .MuiSwitch-track': {
        backgroundColor: '#c6c6c6',
        transition: 'none',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    transition: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    backgroundColor: theme.palette.mode === 'dark' ? '#39393D' : '#E9E9EA',
    opacity: 1,
    transition: 'none',
  },
}));

export default function CustomSwitch(props: CustomSwitchProps) {
  return <StyledSwitch {...props} />;
}
