import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card/card';
import usePageTitle from '../../hooks/title.hook';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './masterDataSettingAdminPage.module.css';
import routes from '../../utils/routes';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * マスターデータ設定ページ（管理者）
 */
function Component() {
  usePageTitle('マスタ管理');
  const { request: createLog } = useCreateLogApi();

  useEffectOnce(() => {
    createLog(LogFormName.MasterDataSettingAdmin, LogControlName.Show);
  });

  const navigate = useNavigate();
  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.container}>
          <div className={styles.mainContainer}>
            <Card cardImageType="files-line" text="文書分類一覧" buttonText="設定する" handleButtonClick={() => navigate(routes.categoryList)} />
          </div>
        </div>
      )}
    />
  );
}

export default Component;
