/* eslint-disable eqeqeq */

import { useCallback, useState } from 'react';
import styles from './folderPermissionsSection.module.css';
import { Folder } from '../../services/http/folder.api';
import Button from '../Button/button';
import { FolderControlList, FolderSetAuthorityForm } from '../../services/http/folderPermissions.api';
import AlertModal, { alertModalInitialState, CloseAlertModal, closeModalInitialState } from '../AlertModal';
import { useSetAuthorityApi } from '../../hooks/api/folderPermissions.hook';
import FolderPermissionEditComponent, {
  FolderControlListRole,
  FolderGroupUserControlListRole,
} from './folderPermissionsEditComponent';
import { useMessageModal } from '../../hooks/modal.hook';

/**
 * フォルダー権限セクションのプロップスインターフェース
 */
interface Props {
  /** 使用されるフォルダー */
  folder: Folder | null,
  /** キャンセル時に実行されるハンドラー */
  onCancel: () => void,
  /** フォルダー一覧を更新するハンドラー */
  updateFolderList?: () => void,
}

/**
 * フォルダー権限セクションのコンポーネント
 */
function FolderPermissionsSection({ folder, onCancel, updateFolderList = () => {} }: Props) {
  const [authorityForm, setAuthorityForm] = useState<FolderSetAuthorityForm>({});
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [loading, setLoading] = useState<boolean>(false);

  const openMessageModal = useMessageModal();

  const [savedUserControlList, setSavedUserControlList] = useState<FolderControlListRole[]>([]);
  const [savedUserGroupControlList, setSavedUserGroupControlList] = useState<FolderGroupUserControlListRole[]>([]);

  const handleSavedUserControlListChanged = useCallback((list: FolderControlListRole[]) => {
    setSavedUserControlList(list);
  }, []);

  const handleSavedUserGroupControlListChanged = useCallback((list: FolderGroupUserControlListRole[]) => {
    setSavedUserGroupControlList(list);
  }, []);

  const handleFolderIdFetched = useCallback((id?: number) => {
    setAuthorityForm({ folderId: id });
  }, []);

  // APIs
  const saveFolderAuthority = useSetAuthorityApi();
  const handleOnSave = useCallback(() => {
    const controlList: FolderControlList[] = [];
    savedUserControlList.forEach((u) => {
      controlList.push({
        controlType: u.controlType,
        controlTargetId: u.controlTargetId,
        roleId: u.roleId,
      });
    });
    savedUserGroupControlList.forEach((u) => {
      controlList.push({
        controlType: u.controlType,
        controlTargetId: `${u.controlTargetId}`,
        roleId: u.roleId,
      });
    });
    const newForm = { ...authorityForm, controlList };
    setAuthorityForm(newForm);

    setAlertModal({
      open: true,
      text: '保存してよろしいですか？',
      onConfirm: async () => {
        setLoading(true);

        const fetch = async (form: FolderSetAuthorityForm) => saveFolderAuthority.save(form);
        try {
          await fetch(newForm);
          updateFolderList();
        } catch (e) {
          setAlertModal({ ...alertModal, open: false });
          openMessageModal((e as Error)?.message);
          return;
        } finally {
          setLoading(false);
        }

        setAlertModal({ ...alertModal, open: false });
        setCloseAlertModal({
          ...closeAlertModal, open: true, text: '保存が完了しました', onCancel: () => { setCloseAlertModal({ ...closeAlertModal, open: false }); onCancel(); },
        });
      },
      onCancel: () => {
        setAlertModal({
          ...alertModal, open: false,
        });
      },
    });
  }, [authorityForm, savedUserControlList, savedUserGroupControlList, alertModal, closeAlertModal, onCancel, saveFolderAuthority]);

  return (
    <div className={styles.mainFrameRightSection}>
      <div className={styles.mainFrameRightSectionTopLine}>
        <div className={styles.homeText}>HOME</div>
        <div style={{ color: '#54c3f1' }}>
          {' '}
          {'>'}
          {' '}
        </div>
        <div className={styles.topLineOptions}>{folder?.name}</div>
      </div>
      <div className={styles.mainFrameRightSectionTop} />
      <FolderPermissionEditComponent
        handleSavedUserControlListChanged={handleSavedUserControlListChanged}
        handleSavedUserGroupControlListChanged={handleSavedUserGroupControlListChanged}
        handleFolderIdFetched={handleFolderIdFetched}
        folder={folder}
      />
      <footer>
        <Button color="lightGray" size="smaller" onClick={onCancel}>キャンセル</Button>
        <Button
          size="smaller"
          onClick={() => handleOnSave()}
        >
          保存
        </Button>
      </footer>
      <AlertModal loading={loading} open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
      <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
    </div>
  );
}

export default FolderPermissionsSection;
