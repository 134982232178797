/**
 * システムタイプ
 */
export enum SystemType {
  CLOUDSIGN = '1',
  GMO = '2',
  WEBAPI = '3',
}

export const SystemTypeMap: Record<string, SystemType> = {
  1: SystemType.CLOUDSIGN,
  2: SystemType.GMO,
  3: SystemType.WEBAPI,
};

export const SystemTypeLabelMap: Record<SystemType, string> = {
  [SystemType.CLOUDSIGN]: 'CloudSign',
  [SystemType.GMO]: 'GMOサイン',
  [SystemType.WEBAPI]: 'APIキー',
};

/**
 * サインアイテムの状態の列挙型
 */
export enum SignItemStatus {
  PREPARE = 1,
  EDITING = 2,
  CONTRACT_FINISHED = 3,
}

/**
 * ファイルインポートの状態の列挙型
 */
export enum FileImportStatus {
  PREPARE = 'prepare',
  CONNECTING = 'connecting',
  FINISHED = 'finished',
}

/**
 * ファイルインポート結果の列挙型
 */
export enum FileImportResult {
  SUCCESS = '1',
  ERROR = '2',
}

export default SystemType;
