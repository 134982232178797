import { DateTime } from 'luxon';
import styles from './baseSignItem.module.css';
import mainStyles from '../../main.module.css';
import Formatter from '../../../utils/formatters';

/**
 * ファイルインポートステータスがエラーの場合のステータスバーコンポーネント
 */
function FileImportErrorStatusBar({ updateDate, cause, isGettingFileCooperate }: { updateDate: string, cause: string, isGettingFileCooperate: boolean }) {
  return (
    <div style={{ minHeight: '50px' }}>
      <div className={[styles.textLight, mainStyles['mt-1']].join(' ')}>
        <span className={mainStyles['mr-3']}>
          最終連携日
          {'  '}
          {isGettingFileCooperate
            ? Formatter.toDisplayDate(Formatter.fromStringToDate(DateTime.local().toString()), Formatter.defaultDateTimeFormat)
            : Formatter.toDisplayDate(Formatter.fromStringToDate(updateDate), Formatter.defaultDateTimeFormat)}
        </span>
      </div>
      {!isGettingFileCooperate && (
      <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-2']].join(' ')}>
        <img src="/images/Error.svg" alt="" />
        <div className={[mainStyles['text-danger'], mainStyles['ml-1']].join(' ')}>
          {cause}
        </div>
      </div>
      )}
    </div>
  );
}

export default FileImportErrorStatusBar;
