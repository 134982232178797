enum LogControlName {
  Show = 'show',
  Create = 'create',
  Edit = 'edit',
  Search = 'search',
  Delete = 'delete',
  Download = 'download',
}

enum LogFormName {
  Common = 'COMMON',
  OperationLogListScreen = 'DCA06001S',
  Login = 'DCB07001S',
  UserListScreen = 'DCC12001S',
  UserRegister = 'DCC12002M',
  UserDetails = 'DCC12003M',
  SetFirstPassword = 'DCC12005S',
  NonDocumentPermissionScreen = 'DCC12006S',
  PermissionTemplateCreation = 'DCC12007S',
  OrganizingChangeReserveDateScreen = 'DCC12008S',
  OrganizationAuthenticationSaml = 'DCC15016S',
  UserGroupSetting = 'DCC12009S',
  ChangePassword = 'DCC12010S',
  SetPassword = 'DCC12012S',
  MasterDataSettingGeneral = 'DCC22001S',
  Main = 'DCD16001C',
  SearchDetail = 'DCD16002M',
  DocRegisterScreen = 'DCD16003S',
  DocDetail = 'DCD16004C',
  DocDetail_Attachment = 'DCD16004C_1',
  DocTypeList = 'DCD16006S',
  FolderRole = 'DCD17001S',
  SignSystemSettingScreen = 'DCD19001S',
  SignSystemSettingGMOSign = 'DCD19001S_1',
  SignSystemSettingCloudSign = 'DCD19001S_2',
  SignSystemSettingAPIKey = 'DCD19001S_3',
  WareHousingRequestScreen = 'DCD20001S',
  IssueRequestScreen = 'DCD20002S',
  DisposalRequestScreen = 'DCD20003S',
  RemoteScanScreen = 'DCD20004S',
  ConfirmedRequestListScreen = 'DCD20005S',
  ShippingAddressList = 'DCD20006S',
  ExportLabel = 'DCD20007M',
  UserSwitchScreen = 'DTA02001S',
  SupplierListScreen = 'DTC09001S',
  SupplierDetail = 'DTC09002M',
  SupplierPermissionScreen = 'DTC09003S',
  SupplierFirstUserRegistration = 'DTC09004S',
  AdminUserListScreen = 'DTC11001S',
  AdminUserRegister = 'DTC11002M',
  AdminUserDetail = 'DTC11003M',
  MasterDataSettingAdmin = 'DTC22002S',
  CategoryList = 'DTD16005S',
  AdminIpListScreen = 'DTC15013S',
  UserIpListScreen = 'DCC15014S',
  TagBulkScreen = 'DCD16007S',
  TagMaintenanceScreen = 'DCD16008S',
  UserRegisterFromCsvScreen = 'DCC12001S_CSV',
}

export { LogControlName, LogFormName };
