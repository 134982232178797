import { useCallback } from 'react';
import { DateTime } from 'luxon';
import DatePickerJp from '../../components/DatePickerJp';
import { TagFilter } from '../../components/SearchInputTop/SearchInputTopFilterModal';
import TagFormat from '../../utils/tagFormat';
import mainStyles from '../main.module.css';
import styles from './tagBulkScreenPage.module.css';
import Input from '../../components/Input/input';

export function tagBulkInputs(
  tagFilter: TagFilter,
  filter: TagFilter['tags'][number],
  onSetTagValue1Change: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: string) => void,
  onSetTagValue2Change: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: string) => void,
  onSetTagDate1: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: DateTime | null) => void,
  onSetTagDate2: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: DateTime | null) => void,
): JSX.Element {
  if (!tagFilter.defaultTag.tagLabel) return <div />;
  if (filter.condition === 'ブランク') return <div />;

  if (TagFormat.STRING === tagFilter.defaultTag.format) {
    return (
      <Input
        className={[mainStyles['mr-2']].join(' ')}
        value={filter.value1}
        type="text"
        onChange={(value) => (onSetTagValue1Change(tagFilter, filter, value))}
      />
    );
  } if (TagFormat.DATE === tagFilter.defaultTag.format) {
    return (
      <>
        <div className={styles.dateInput}>
          <DatePickerJp
            value={filter.date1 || null}
            onChange={(value) => (onSetTagDate1(tagFilter, filter, value))}
          />
        </div>
        <div className={styles.dateDivider}>~</div>
        <div className={[styles.dateInput, mainStyles['mr-2']].join(' ')}>
          <DatePickerJp
            value={filter.date2 || null}
            onChange={(value) => (onSetTagDate2(tagFilter, filter, value))}
          />
        </div>
      </>
    );
  } if (TagFormat.NUMBER === tagFilter.defaultTag.format) {
    if (filter.condition === '次の範囲') {
      return (
        <>
          <Input
            value={filter.value1}
            type="number"
            onChange={(value) => (onSetTagValue1Change(tagFilter, filter, value))}
          />
          <div className={styles.dateDivider}>~</div>
          <Input
            className={[mainStyles['mr-2']].join(' ')}
            value={filter.value2}
            type="number"
            onChange={(value) => (onSetTagValue2Change(tagFilter, filter, value))}
          />
        </>
      );
    }
    if (filter.condition === '次の値と一致') {
      return (
        <Input
          className={[mainStyles['mr-2']].join(' ')}
          value={filter.value1}
          type="number"
          onChange={(value) => (onSetTagValue1Change(tagFilter, filter, value))}
        />
      );
    }
  }
  return <div />;
}

interface Props {
  tagFilter: TagFilter,
  filter: TagFilter['tags'][number],
  onChangeTagFilter: (tagFilter: TagFilter) => void,
}

export default function TagBulkInputs({
  tagFilter,
  filter,
  onChangeTagFilter,
}: Props): JSX.Element {
  const onSetTagValue1Change = useCallback((_filter: TagFilter, tag: TagFilter['tags'][number], value: string) => {
    if (_filter !== tagFilter) {
      onChangeTagFilter(tagFilter);
      return;
    }
    onChangeTagFilter({ ...tagFilter, tags: tagFilter.tags.map((t) => (t === tag ? { ...t, value1: value } : t)) });
  }, [onChangeTagFilter, tagFilter]);

  const onSetTagValue2Change = useCallback((_filter: TagFilter, tag: TagFilter['tags'][number], value: string) => {
    if (_filter !== tagFilter) {
      onChangeTagFilter(tagFilter);
      return;
    }
    onChangeTagFilter({ ...tagFilter, tags: tagFilter.tags.map((t) => (t === tag ? { ...t, value2: value } : t)) });
  }, [onChangeTagFilter, tagFilter]);

  const onSetTagDate1 = useCallback((_filter: TagFilter, tag: TagFilter['tags'][number], value: DateTime | null) => {
    if (_filter !== tagFilter) {
      onChangeTagFilter(tagFilter);
      return;
    }
    onChangeTagFilter({ ...tagFilter, tags: tagFilter.tags.map((t) => (t === tag ? { ...t, date1: value } : t)) });
  }, [onChangeTagFilter, tagFilter]);

  const onSetTagDate2 = useCallback((_filter: TagFilter, tag: TagFilter['tags'][number], value: DateTime | null) => {
    if (_filter !== tagFilter) {
      onChangeTagFilter(tagFilter);
      return;
    }
    onChangeTagFilter({ ...tagFilter, tags: tagFilter.tags.map((t) => (t === tag ? { ...t, date2: value } : t)) });
  }, [onChangeTagFilter, tagFilter]);

  return tagBulkInputs(tagFilter, filter, onSetTagValue1Change, onSetTagValue2Change, onSetTagDate1, onSetTagDate2);
}
