import React, { ReactNode } from 'react';
import styles from './baseModal.module.css';

export interface BaseModalProps {
  className?: string | string[];
  open: boolean;
  onClickOutside?: () => void;
  children: ReactNode | ReactNode[];
  style?: React.CSSProperties;
}

export default function BaseModal({
  className = '', open = false, onClickOutside = () => ({}), children, style,
}: BaseModalProps) {
  return (
    <div style={{ zIndex: 999 }}>
      <div className={styles.overlay} style={{ display: open ? 'block' : 'none' }}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={styles.modalContainer} onClick={onClickOutside}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className={[styles.modal, className].join(' ')} style={style} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
