import styles from './card.module.css';
import Button from '../Button/button';

type CardImageType = 'sso' | 'truck' | 'files-line' | 'lock' | 'ip-block' | 'tag';

export interface CardProps {
  cardImageType?: CardImageType
  text: string
  buttonText: string
  handleButtonClick?: () => void
}

function determineImage(type: CardImageType): string {
  switch (type) {
    case 'sso':
      return 'images/key.svg';
    case 'truck':
      return 'images/truck-solid.svg';
    case 'files-line':
      return 'images/file-lines-solid.svg';
    case 'lock':
      return 'images/lock-solid.svg';
    case 'ip-block':
      return 'images/ip-seigen-re.svg';
    case 'tag':
      return 'images/tag-solid.svg';
    default:
  }

  return '';
}

function Card(props: CardProps) {
  const {
    cardImageType, text, buttonText, handleButtonClick,
  } = props;
  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        {cardImageType ? <img className={styles.cardImg} src={determineImage(cardImageType)} alt="Invoice" /> : null}
        <div className={styles.cardText}>{text}</div>
      </div>
      <div className={styles.cardFooter}>
        <Button content={buttonText} size="smaller" onClick={handleButtonClick} />
      </div>
    </div>
  );
}

export default Card;
