import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './baseSignItem.module.css';
import mainStyles from '../../main.module.css';
import {
  useDigitalExecFileImportApi,
  useDigitalGetFileImportStatusApi,
  useDigitalSignRegisterApi,
  useDigitalSignRemoveApi,
  useDigitalSignUpdateApi,
} from '../../../hooks/api/digitalSign.hook';
import {
  FileImportResult, FileImportStatus, SignItemStatus, SystemType, SystemTypeLabelMap, SystemTypeMap,
} from '../signSystemConstants';
import { useMessageModal } from '../../../hooks/modal.hook';
import { useCreateLogApi } from '../../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../../utils/log.utils';
import FileImportExecButton from './fileImportButton';
import FileImportErrorStatusBar from './fileImportErrorStatusBar';
import FileImportFinishedStatusBar from './fileImportFinishedStatusBar';
import Input from '../../../components/Input/input';
import FileImportConnectingStatusBar from './fileImportConnectingStatusBar';
import { ApiError } from '../../../services/http';
import { DigitalSignExportApiKeyResponse, DigitalSignItemForm, GetFileImportStatusResponse } from '../../../services/http/digitalSign.api';
import AlertModal, { AlertModalProps, alertModalInitialState } from '../../../components/AlertModal';
import { WebApi } from './webApi';
import SelectInput from '../../../components/SelectInput';
import { DropDownMenuItem } from '../../../components/DropdownMenu';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../components/Button/button';

export interface BaseSignItemProps {
  data: DigitalSignItemForm
  page: number
  refresh: () => void
  onRemove: (item: DigitalSignItemForm) => void
}

export function BaseSignItem({
  data, page, refresh, onRemove,
}: BaseSignItemProps) {
  const findClientId = data.keyInfo.find((k) => k.key === 'clientId');
  const findSecretKey = data.keyInfo.find((k) => k.key === 'secretKey');

  const clientIdValue = findClientId ? findClientId.value : '';
  const secretKeyValue = findSecretKey ? findSecretKey.value : '';

  const [isEditing, setIsEditing] = useState(false);
  const [item, setItem] = useState<DigitalSignItemForm>(data);
  const [clientId, setClientId] = useState(clientIdValue);
  const [secretKey, setSecretKey] = useState(secretKeyValue);
  const [isGettingFileCooperate, setIsGettingFileCooperate] = useState(false);
  const [getFileImportStatus, setGetFileImportStatus] = useState<GetFileImportStatusResponse | null>();
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);

  const { request: digitalSignRequest, loading: loadingRegister } = useDigitalSignRegisterApi();
  const { request: digitalSignUpdateRequest, loading: loadingUpdate } = useDigitalSignUpdateApi();
  const { request: digitalSignRemoveRequest, loading: loadingRemove } = useDigitalSignRemoveApi();
  const { request: digitalSignGetFileImportStatusRequest, loading: loadingDigitalSignGetFileImportStatus } = useDigitalGetFileImportStatusApi();
  const { request: digitalExecFileImportRequest, loading: loadingDigitalExecFileImport } = useDigitalExecFileImportApi();
  const { request: createLog } = useCreateLogApi();

  const isLoading = loadingRegister || loadingUpdate || loadingRemove || loadingDigitalSignGetFileImportStatus || loadingDigitalExecFileImport;

  const openMessageModal = useMessageModal();

  const navigate = useNavigate();

  // 保存している価

  const disableSaveButton = useMemo(() => {
    if (!item) return true;
    if (item.name === '') return true;
    if (!item.systemType) return true;
    if (item.keyInfo.length === 0) return true;
    if (item.systemType === SystemType.CLOUDSIGN && clientId === '') return true;
    if (item.systemType === SystemType.GMO && (clientId === '' || secretKey === '')) return true;
    return false;
  }, [clientId, item, secretKey]);

  const displayValue = useMemo(() => {
    if (!clientIdValue) {
      return '';
    }

    if (clientIdValue.length < 8) {
      return `*****${clientIdValue.slice(1)}`;
    }

    return `*****${clientIdValue.slice(-5)}`;
  }, [clientIdValue]);

  const handleClearInputValues = useCallback(() => {
    setClientId('');
    setSecretKey('');
  }, []);

  const fetchUpdateDigitalSign = useCallback(async (apiKeyData?: string) => {
    if (!item.systemType) return;
    let keyParamJson = '';
    if (item.systemType === SystemType.CLOUDSIGN) {
      keyParamJson = JSON.stringify([
        { key: 'clientId', value: clientId },
      ]);
    }
    if (item.systemType === SystemType.GMO) {
      keyParamJson = JSON.stringify([
        { key: 'clientId', value: clientId },
        { key: 'secretKey', value: secretKey },
      ]);
    }
    if (item.systemType === SystemType.WEBAPI) {
      keyParamJson = JSON.stringify([
        { key: 'apiKey', value: apiKeyData },
      ]);
    }
    try {
      await digitalSignUpdateRequest({
        organizationDigitalSignId: item.organizationDigitalSignId,
        name: item.name,
        systemType: item.systemType,
        keyParamJson,
      });
      setIsEditing(false);
      refresh();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [item.systemType, item.organizationDigitalSignId, item.name, clientId, secretKey, digitalSignUpdateRequest, refresh, openMessageModal]);

  const fetchDigitalSign = useCallback(async (apiKeyData?: string) => {
    if (!item.systemType) return;
    let keyParamJson = '';

    if (item.systemType === SystemType.CLOUDSIGN) {
      keyParamJson = JSON.stringify([
        { key: 'clientId', value: clientId },
      ]);
    }
    if (item.systemType === SystemType.GMO) {
      keyParamJson = JSON.stringify([
        { key: 'clientId', value: clientId },
        { key: 'secretKey', value: secretKey },
      ]);
    }
    if (item.systemType === SystemType.WEBAPI) {
      keyParamJson = JSON.stringify([
        { key: 'apiKey', value: apiKeyData },
      ]);
    }
    try {
      await digitalSignRequest({
        systemType: item.systemType,
        name: item.name,
        keyParamJson,
      });
      setIsEditing(false);
      refresh();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [item.systemType, item.name, clientId, secretKey, digitalSignRequest, refresh, openMessageModal]);

  const handleSave = useCallback(() => {
    if (item.systemType === SystemType.CLOUDSIGN && clientId === '') {
      openMessageModal('設定項目を入力してください');
      return;
    } if (item.systemType === SystemType.GMO && (clientId === '' || secretKey === '')) {
      openMessageModal('設定項目を入力してください');
      return;
    }

    if (clientIdValue) {
      fetchUpdateDigitalSign();
      return;
    }
    fetchDigitalSign();
  }, [item.systemType, clientId, secretKey, clientIdValue, fetchDigitalSign, openMessageModal, fetchUpdateDigitalSign, createLog]);

  const handleSaveApiKey = useCallback((apiKeyData: DigitalSignExportApiKeyResponse) => {
    setItem((prev) => ({
      ...prev,
      keyInfo: [{ key: 'apiKey', value: apiKeyData.apiKey }],
    }));
    if (item.organizationDigitalSignId === 0) return;
    fetchUpdateDigitalSign(apiKeyData.apiKey);
  }, [fetchUpdateDigitalSign, item.organizationDigitalSignId]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, [createLog, item.systemType]);

  const fetchDigitalSignRemove = useCallback(async () => {
    if (!item.systemType) return;
    const { systemType, organizationDigitalSignId } = item;
    try {
      await digitalSignRemoveRequest({ organizationDigitalSignId, systemType });
      refresh();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      refresh();
    }
  }, [digitalSignRemoveRequest, openMessageModal, refresh, item]);

  const handleRemove = useCallback(() => {
    if (item.organizationDigitalSignId === 0) {
      onRemove(item);
      return;
    }
    if (!item.systemType) {
      setIsEditing(false);
      return;
    }
    setModal({
      open: true,
      text: '削除してよろしいですか？',
      onConfirm() {
        setModal({ ...modal, open: false });

        fetchDigitalSignRemove();
        setIsEditing(false);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [createLog, fetchDigitalSignRemove, item, modal, onRemove]);

  const handleCancelEdit = useCallback(() => {
    if (item.organizationDigitalSignId === 0) {
      onRemove(item);
    }
    setItem(data);
    setClientId(clientIdValue);
    setSecretKey(secretKeyValue);
    setIsEditing(false);
  }, [clientIdValue, data, item, onRemove, secretKeyValue]);

  const fetchGetFileImportStatus = useCallback(async () => {
    if (!data.systemType) return;
    try {
      const res = await digitalSignGetFileImportStatusRequest({ organizationDigitalSignId: data.organizationDigitalSignId, systemType: data.systemType });
      setGetFileImportStatus(res);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [data.systemType, data.organizationDigitalSignId, digitalSignGetFileImportStatusRequest, openMessageModal]);

  const fetchExecFileImport = useCallback(async () => {
    if (!item.systemType) return;
    const { systemType, organizationDigitalSignId } = item;
    try {
      await digitalExecFileImportRequest({ organizationDigitalSignId, systemType });
      fetchGetFileImportStatus();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [digitalExecFileImportRequest, item, fetchGetFileImportStatus, openMessageModal]);

  const handleFileCooperate = useCallback(() => {
    fetchExecFileImport();
  }, [fetchExecFileImport]);

  const handleReload = useCallback(() => {
    navigate(0);
  }, [navigate]);

  const handleNameChange = useCallback((val: string) => {
    setItem((prev) => ({ ...prev, name: val }));
  }, []);

  const handleSelect = useCallback((val: SystemType | null) => {
    if (val && val !== item.systemType) {
      handleClearInputValues();
    }
    setItem((prev) => ({ ...prev, systemType: val }));
  }, [handleClearInputValues, item.systemType]);

  const handleClientIdChange = useCallback((val: string) => {
    setClientId(val);
    setItem((prev) => ({ ...prev, keyInfo: [{ key: 'clientId', value: val }] }));
  }, []);

  const handleSecretKeyChange = useCallback((val: string) => {
    setSecretKey(val);
    setItem((prev) => ({ ...prev, keyInfo: [{ key: 'secretKey', value: val }] }));
  }, []);

  const status: SignItemStatus = useMemo(() => {
    if (isEditing) {
      return SignItemStatus.EDITING;
    }

    if (clientIdValue) {
      return SignItemStatus.CONTRACT_FINISHED;
    }

    return SignItemStatus.PREPARE;
  }, [isEditing, clientIdValue]);

  const fileStatus:FileImportStatus = useMemo(() => {
    if (!getFileImportStatus) return FileImportStatus.PREPARE;
    switch (getFileImportStatus.status) {
      case undefined:
        return FileImportStatus.PREPARE;
      case '0':
        return FileImportStatus.CONNECTING;
      case '1':
        return FileImportStatus.FINISHED;
      default:
        return FileImportStatus.PREPARE;
    }
  }, [getFileImportStatus]);

  const fileImportResult:FileImportResult = useMemo(() => {
    if (!getFileImportStatus) return FileImportResult.SUCCESS;
    switch (getFileImportStatus.result) {
      case '1':
        return FileImportResult.SUCCESS;
      case '2':
        return FileImportResult.ERROR;
      default:
        return FileImportResult.SUCCESS;
    }
  }, [getFileImportStatus]);

  useEffect(() => {
    fetchGetFileImportStatus();
  }, [fetchGetFileImportStatus]);

  useEffect(() => {
    if (item.organizationDigitalSignId === 0) {
      setIsEditing(true);
    }
  }, [item.organizationDigitalSignId]);

  const selectOptions: DropDownMenuItem<SystemType>[] = [
    {
      text: SystemTypeLabelMap[SystemTypeMap[SystemType.CLOUDSIGN]],
      value: SystemTypeMap[SystemType.CLOUDSIGN],
    },
    {
      text: SystemTypeLabelMap[SystemTypeMap[SystemType.GMO]],
      value: SystemTypeMap[SystemType.GMO],
    },
    {
      text: SystemTypeLabelMap[SystemTypeMap[SystemType.WEBAPI]],
      value: SystemTypeMap[SystemType.WEBAPI],
    },
  ];

  const removeButton = useMemo(() => (
    <Button className={styles.itemButton} color="red" size="smaller" onClick={handleRemove}>
      削除
    </Button>
  ), [handleRemove]);

  const cancelEditButton = useMemo(() => (
    <Button className={styles.itemButton} color="lighterGray" size="smaller" style={{ marginRight: '10px' }} onClick={handleCancelEdit}>
      キャンセル
    </Button>
  ), [handleCancelEdit]);

  const editButton = useMemo(() => (
    <Button className={styles.itemButton} color="lighterGray" size="smaller" onClick={handleEdit}>
      設定
    </Button>
  ), [handleEdit]);

  const handleApiKeyGenerate = useCallback(() => {
    setIsEditing(false);
  }, []);

  const rightSide = useMemo(() => {
    if (item.systemType && item.systemType === SystemType.WEBAPI) {
      return (
        <WebApi
          item={item}
          isEditing={isEditing}
          refresh={refresh}
          onGenerateApiKey={handleApiKeyGenerate}
          onEdit={handleEdit}
          onCancelEdit={handleCancelEdit}
          onSave={handleSaveApiKey}
        />
      );
    }

    if (status === SignItemStatus.PREPARE) {
      return (
        <>
          <div />
          <div className={styles.buttonContainer} style={{ justifyContent: isEditing ? 'flex-end' : 'flex-start' }}>
            <div className={styles.buttonRow}>
              {isEditing ? (
                cancelEditButton
              ) : null}
              {editButton}
            </div>
          </div>
        </>
      );
    }

    if (status === SignItemStatus.EDITING) {
      return (
        <>
          <div className={styles.keysContainer}>
            {item.systemType ? (
              <div className={mainStyles['d-flex']}>
                <div className={[styles.inputLabel].join(' ')}>
                  Client ID
                </div>
                <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')} style={{ width: '100%' }}>
                  <input type="text" className={mainStyles.input} style={{ width: '100%' }} value={clientId} onChange={(e) => handleClientIdChange(e.target.value)} />
                </div>
              </div>
            ) : null}
            {item.systemType === SystemType.GMO ? (
              <div className={mainStyles['d-flex']} style={{ width: '100%', marginTop: '20px' }}>
                <div className={[styles.inputLabel].join(' ')}>
                  Secret Key
                </div>
                <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')} style={{ width: '100%' }}>
                  <input type="text" className={mainStyles.input} style={{ width: '100%' }} value={secretKey} onChange={(e) => handleSecretKeyChange(e.target.value)} />
                </div>
              </div>
            )
              : null}
          </div>
          <div className={styles.buttonContainer} style={{ justifyContent: isEditing ? 'flex-end' : 'flex-start' }}>
            <div className={styles.buttonRow}>
              {isEditing ? (
                cancelEditButton
              ) : null}
              <Button size="smaller" color="primary" disabled={disableSaveButton} className={styles.itemButton} onClick={handleSave}>
                保存
              </Button>
            </div>
          </div>
        </>
      );
    }

    if (status === SignItemStatus.CONTRACT_FINISHED) {
      return (
        <>
          <div className={styles.contractLeftSection}>
            <div className={styles.contractUpperRow}>
              <FileImportExecButton
                status={fileStatus}
                handleFileCooperate={handleFileCooperate}
                handleReload={() => {
                  if (item.systemType === SystemType.CLOUDSIGN) {
                    createLog(LogFormName.SignSystemSettingCloudSign, LogControlName.Search);
                  }
                  if (item.systemType === SystemType.GMO) {
                    createLog(LogFormName.SignSystemSettingGMOSign, LogControlName.Search);
                  }
                  handleReload();
                }}
                isGettingFileCooperate={isGettingFileCooperate}
                setIsGettingFileCooperate={setIsGettingFileCooperate}
                loading={loadingDigitalSignGetFileImportStatus}
              />
              <button type="button" disabled className={[mainStyles.button, styles.itemButton, mainStyles['small-button'], mainStyles['btn-light'], styles.marginRight, styles['disabled-button']].join(' ')}>
                連携中
              </button>
              <div className={styles.textValue}>
                Client ID:
                {displayValue}
              </div>
            </div>
            {fileImportResult === FileImportResult.SUCCESS && fileStatus === FileImportStatus.CONNECTING ? (
              <div className={styles.contractLowerRow}>
                <FileImportConnectingStatusBar updateDate={getFileImportStatus ? getFileImportStatus.updateDate : ''} />
              </div>
            ) : null}

            {fileImportResult === FileImportResult.SUCCESS && fileStatus === FileImportStatus.FINISHED ? (
              <div className={styles.contractLowerRow}>
                <FileImportFinishedStatusBar updateDate={getFileImportStatus ? getFileImportStatus.updateDate : ''} />
              </div>
            ) : null}

            {fileImportResult === FileImportResult.ERROR ? (
              <div className={styles.contractLowerRow}>
                <FileImportErrorStatusBar isGettingFileCooperate={isGettingFileCooperate} updateDate={getFileImportStatus ? getFileImportStatus.updateDate : ''} cause={getFileImportStatus ? getFileImportStatus.cause : ''} />
              </div>
            ) : null}
          </div>
          <div className={styles.buttonContainer} style={{ justifyContent: isEditing ? 'flex-end' : 'flex-start' }}>
            <div className={styles.buttonRow}>
              {isEditing ? (
                cancelEditButton
              ) : null}
              {editButton}
            </div>
          </div>
        </>
      );
    }
    return null;
  }, [item, status, isEditing, refresh, handleApiKeyGenerate, handleEdit, handleCancelEdit, handleSaveApiKey, cancelEditButton, editButton, clientId, secretKey, disableSaveButton, handleSave, handleClientIdChange, handleSecretKeyChange, fileStatus, handleFileCooperate, isGettingFileCooperate, loadingDigitalSignGetFileImportStatus, displayValue, fileImportResult, getFileImportStatus, handleReload, createLog]);

  return (
    <>
      <div className={styles.item}>
        <div className={styles.leftContainer}>
          <div className={styles.inputContainer}>
            <div>
              <div className={styles.smallerLabel}>
                名称
              </div>
              {isEditing ? (
                <Input
                  value={item.name}
                  onChange={handleNameChange}
                />
              ) : (
                <div className={styles.text}>
                  {item.name}
                </div>
              )}
            </div>
            {isEditing && item.organizationDigitalSignId !== 0 ? (
              <div style={{ paddingTop: '20px' }}>
                {removeButton}
              </div>
            ) : null}
          </div>
          <div className={styles.selectContainer}>
            <div className={styles.smallerLabel}>
              種別
            </div>
            {(isEditing && data.organizationDigitalSignId === 0) ? (
              <SelectInput<SystemType>
                value={item.systemType}
                options={selectOptions}
                onChange={(val) => {
                  handleSelect(val);
                }}
                menuStyle={{ width: '100%' }}
              />
            ) : (
              <div className={styles.text}>
                {item.systemType ? SystemTypeLabelMap[SystemTypeMap[item.systemType]] : ''}
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightContainer} hidden={page !== 0 && page !== 2 && page !== 3}>
          {rightSide}
        </div>
        <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} textCenter />
      </div>
      {isLoading ? (
        <LoadingOverlay />
      ) : null}
    </>
  );
}

export default BaseSignItem;
