import { useState } from 'react';
import { ApiError } from '../../services/http';
import ManagementUserApi, {
  AdminUser,
  AdminUserFull,
  AdminUserRegisterForm,
  AdminUserSearchForm,
  AdminUserUpdateForm,
} from '../../services/http/managementUser.api';

/**
 * 管理者ユーザーを検索APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useSearchAdminUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: AdminUserSearchForm): Promise<AdminUser[]> {
    setLoading(true);
    try {
      return await ManagementUserApi.searchAdminUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request,
  };
}

/**
 * 管理者ユーザーの詳細を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useGetDetailAdminUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: { id: string; }): Promise<AdminUserFull> {
    setLoading(true);
    try {
      return await ManagementUserApi.getDetailAdminUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request,
  };
}

/**
 * 管理者ユーザーを登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useRegisterAdminUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: AdminUserRegisterForm): Promise<void> {
    setLoading(true);
    try {
      return await ManagementUserApi.registerAdminUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request,
  };
}

/**
 * 管理者ユーザーを更新APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useUpdateAdminUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: AdminUserUpdateForm): Promise<void> {
    setLoading(true);
    try {
      return await ManagementUserApi.updateAdminUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}

/**
 * 管理者ユーザーを削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useDeleteAdminUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: { id: string; }): Promise<void> {
    setLoading(true);
    try {
      return await ManagementUserApi.deleteAdminUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}
