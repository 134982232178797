/* eslint-disable import/prefer-default-export */
const defaultIconSize = 256;
const defaultImageQuality = 0.9;

/**
 * 画像を生成する関数
 *
 * @param url - 画像の URL
 * @returns 生成した画像を返すプロミス
 */
const createImage = (url: string): any => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = url;
});

/**
 * Canvasから画像をエクスポートする関数
 *
 * @param canvas - エクスポートする Canvas
 * @returns エクスポートした画像のURLデータ
 */
const exportFromCanvas = async (canvas: HTMLCanvasElement) => canvas.toDataURL('image/jpeg', defaultImageQuality);

/**
 * Canvasを使って画像を操作するフック
 *
 * @returns 画像を取得する関数
 */
export const useCanvasImage = () => {
  const getImage = async (imageSrc: string) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    let safeArea = 0;

    if (image.width < defaultIconSize || image.height < defaultIconSize) {
      safeArea = defaultIconSize;
    } else {
      safeArea = Math.max(image.width, image.height);
    }
    canvas.width = defaultIconSize;
    canvas.height = defaultIconSize;

    ctx.drawImage(
      image,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    ctx.putImageData(data, 0, 0);

    return exportFromCanvas(canvas);
  };

  return { getImage };
};
