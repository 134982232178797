import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';
import MessageModal, { MessageModalProps } from '../components/AlertModal/MessageModal';
import AlertModal, { AlertModalProps } from '../components/AlertModal';
import LoadingOverlay from '../components/LoadingOverlay';

type ModalContextType = {
  loadingOverlay: boolean,
  alertModal: AlertModalProps,
  messageModal: MessageModalProps,
  openAlertModal: (messageModalProps: AlertModalProps) => (void),
  openMessageModal: (messageModalProps: MessageModalProps) => (void),
  setLoadingOverlay: (state: boolean) => (void),
};

export const ModalContext = createContext<ModalContextType>(
  {
    alertModal: {
      text: '', open: false, onConfirm: () => {}, onCancel: () => {},
    },
    messageModal: { text: '', open: false, onConfirm: () => {} },
    openAlertModal: () => ({}),
    openMessageModal: () => ({}),
    setLoadingOverlay: () => ({}),
    loadingOverlay: false,
  },
);

export default function ModalProvider({ children }: { children: ReactNode[] | ReactNode }) {
  const [messageModal, setMessageModal] = useState<MessageModalProps>({ text: '', open: false, onConfirm: () => {} });
  const [alertModal, setAlertModal] = useState<AlertModalProps>({
    text: '', open: false, onConfirm: () => {}, onCancel: () => {},
  });
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const value = useMemo(() => ({
    loadingOverlay,
    messageModal,
    alertModal,
    openMessageModal: (messageModalProps: MessageModalProps) => {
      setMessageModal({
        ...messageModalProps,
        open: true,
        onConfirm: () => {
          messageModalProps.onConfirm();
          setMessageModal({ ...messageModal, open: false });
        },
      });
    },
    openAlertModal: (alertModalProps: AlertModalProps) => {
      setAlertModal({
        ...alertModalProps,
        open: true,
        onConfirm: () => {
          setAlertModal({ ...alertModal, open: false });
          alertModalProps.onConfirm();
        },
        onCancel: () => {
          setAlertModal({ ...alertModal, open: false });
          alertModalProps.onCancel();
        },
      });
    },
    setAlertModal,
    setLoadingOverlay,
  }), [messageModal, alertModal]);
  return (
    <ModalContext.Provider value={value}>
      {children}
      { value.loadingOverlay && <LoadingOverlay />}
      <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} />
      <MessageModal open={messageModal.open} text={messageModal.text} onConfirm={messageModal.onConfirm} />
    </ModalContext.Provider>
  );
}

export const useMessageModalContext = () => useContext(ModalContext);
