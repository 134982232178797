import { memo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

export interface IconCellRenderProps extends ICellRendererParams {
  src: string,
  size?: { width: number, height: number },
  align?: 'left' | 'center' | 'right',
}

const IconCellRender = memo(({
  src,
  size = { width: 15, height: 15 },
  align = 'center',
}: IconCellRenderProps) => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      justifyContent: align,
      alignItems: 'center',
    }}
  >
    <img
      src={src}
      alt="icon"
      style={{
        width: size.width,
        height: size.height,
      }}
    />
  </div>
));
export default IconCellRender;
