import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/router.hook';
import Button from '../../components/Button/button';
import styles from './setPasswordPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import { useResettingApi } from '../../hooks/api/password.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import usePageTitle from '../../hooks/title.hook';
import routes from '../../utils/routes';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * パスワード設定ページ
 */
function SetPasswordPage() {
  // Ccontext
  const navigator = useNavigate();

  // States
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  // Custom hooks
  const resettingApi = useResettingApi();
  const { token, uid } = useQuery({ token: '', uid: '' });
  const openMessageModal = useMessageModal();
  const { request: createLog } = useCreateLogApi();

  usePageTitle('パスワード設定');

  // Aux States
  const validForm = !!password && !!confirmPassword;

  const sendForm = async () => {
    try {
      Validator.validPasswordLength(password);
      Validator.validPasswordTypeChars(password);
      Validator.validPasswordChars(password);
      Validator.comparePassword(password, confirmPassword);
      await resettingApi.request({ id: uid, token, password });
      navigator(routes.setPasswordSuccess);
    } catch (e) {
      setError((e as Error).message);
      openMessageModal((e as ApiError)?.message);
    }
  };

  useEffectOnce(() => {
    createLog(LogFormName.SetPassword, LogControlName.Show);
  });

  return (
    <CustomFrame
      title="パスワード設定"
      desc={(
        <div>
          <div className={styles.formDesc}>
            <p className={styles.firstParagraph}>CLOUD CABINETで使用しているアカウントの新しいパースワードを設定してください</p>
            <p>{`${Validator.passwordLengthMin}~${Validator.passwordLengthMax}文字で、大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください`}</p>
            <p>{`利用可能な文字は半角英数字及び${Validator.passwordSymbolsLength()}種類の記号(${Validator.passwordSymbols})となります`}</p>
          </div>

          <div className={styles.form}>
            <div className={styles.formItem}>
              <div className={styles.formIteField}>パスワード</div>
              <div><input className={styles.formItemInput} type="password" onChange={(e) => setPassword(e.target.value)} /></div>
            </div>

            <div className={styles.formItem}>
              <div className={styles.formIteField}>パスワード(確認用)</div>
              <div><input className={styles.formItemInput} type="password" onChange={(e) => setConfirmPassword(e.target.value)} /></div>
            </div>
          </div>
          <div className={styles.errorText}>{error}</div>
          {resettingApi.loading && <LoadingOverlay />}
        </div>
      )}
      body={<Button content="設定" onClick={sendForm} disabled={!validForm} />}
    />
  );
}

export default SetPasswordPage;
