/* eslint-disable no-param-reassign */
import { v1 as uuid } from 'uuid';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import mainStyles from '../main.module.css';
import styles from './docRegisterScreenPage.module.css';
import { DocumentRegisterForm } from '../../services/http/documents.api';
import Button from '../../components/Button/button';
import { useDocumentRegister } from '../../hooks/api/document.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import FileInputButton from '../../components/ButtonFileInput';
import routes from '../../utils/routes';
import {
  CloseAlertModal,
  CloseAlertModalProps,
  CloseAlertModalTextType,
  closeModalInitialState,
} from '../../components/AlertModal';
import { useGetDocumentClassificationAndTypeApi } from '../../hooks/api/documentManagement.hook';
import { DocumentClassificationAndType } from '../../services/http/documentManagement.api';
import Spinner from '../../components/Spinner';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import DocRegisterSortChip from './DocRegisterSortChip';

/**
 * 文書登録フォーム一覧のプロップスインターフェース
 */
export interface DocumentRegisterFormListProps {
  /** 使用されるフォーム */
  forms: DocumentRegisterForm[];
  /** フォームが選択された時に実行されるハンドラー */
  onFormSelected: (form: DocumentRegisterForm) => void;
  /** フォームが拒否された時に実行されるハンドラー */
  onFormsRejected: (forms: DocumentRegisterForm[]) => void;
  /** フォームを追加するハンドラー */
  onAddForm: (form: DocumentRegisterForm[]) => void;
  /** フォームを削除するハンドラー */
  onRemoveForm: (form: DocumentRegisterForm) => void;
  /** 更新されたフォーム */
  updatedForm: DocumentRegisterForm;
  /** リクエスト中かどうか */
  isRequesting?: boolean;
}

/**
 * 文書登録フォーム一覧のコンポーネント
*/
function Component({
  forms, onAddForm, onFormSelected, onRemoveForm, updatedForm, onFormsRejected, isRequesting,
}: DocumentRegisterFormListProps) {
  const navigate = useNavigate();

  useEffect(() => {
    onFormSelected(forms[0]);
  }, []);

  const [loading, setLoading] = useState(false);
  const [closeAlertModal, setCloseAlertModal] = useState<CloseAlertModalProps>(closeModalInitialState);

  enum DocSortTypes {
    TOTAL = 'total',
    FILES = 'files',
    REQUIRED = 'required',
  }
  const [sortType, setSortType] = useState<DocSortTypes>(DocSortTypes.TOTAL);

  const documentRegister = useDocumentRegister();
  const openMessageModal = useMessageModal();
  const { request: createLog } = useCreateLogApi();

  const checkRequiredFields = (form: DocumentRegisterForm) => {
    if (!form.documentName || !form.documentTypeId) return false;
    return !form.tagList.some((tag) => tag.required && !tag.value);
  };

  const totalFiles = forms.length;
  const filesWithAttachments = forms.filter((form) => form.documentFile).length;
  const filesWithMissingRequiredFields = useMemo(() => forms.filter((form) => !checkRequiredFields(form)).length, [forms]);

  const changeSortType = useCallback((type: DocSortTypes) => {
    if (type === sortType) return setSortType(DocSortTypes.TOTAL);
    return setSortType(type);
  }, [DocSortTypes.TOTAL, sortType]);

  const createFileList = (files: File[]) => {
    const fileList = [];
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const newForm: DocumentRegisterForm = {
        internalId: uuid(),
        documentName: '',
        attachmentFileList: [],
        relationalDocumentList: [],
        tagList: [],
        documentTypeId: 0,
        barcodePrinting: '',
      };
      newForm.documentFile = file;
      newForm.documentFileName = file.name;
      newForm.documentName = file.name.split('.').slice(0, -1).join('.');
      fileList.push(newForm);
    }
    return fileList;
  };

  const createForm = useCallback(() => {
    const newForm: DocumentRegisterForm = {
      internalId: uuid(),
      documentName: '',
      attachmentFileList: [],
      relationalDocumentList: [],
      tagList: [],
      documentTypeId: 0,
      barcodePrinting: '',
    };
    onAddForm([newForm]);
  }, [onAddForm]);

  const alertModalFunction = useCallback((text: CloseAlertModalTextType, onClose: () => void) => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text,
      onCancel: () => { onClose(); },
    });
  }, [closeAlertModal]);

  const createForms = useCallback((files?: FileList) => {
    if (!files) return;
    const filesArray = Array.from(files);
    const isPdf = filesArray.every((file) => file.type === 'application/pdf');
    if (!isPdf) {
      alertModalFunction('PDF以外のファイルは無視されました', () => setCloseAlertModal(closeModalInitialState));
    }
    const pdfFiles = filesArray.filter((file) => file.type === 'application/pdf');
    if (!pdfFiles.length) return;
    const fileList = createFileList(pdfFiles);
    onAddForm(fileList);
  }, [alertModalFunction, onAddForm]);

  const getDocumentClassificationAndTypeApi = useGetDocumentClassificationAndTypeApi();
  const [documentClassificationTypeContractState, setDocumentClassificationTypeContractState] = useState<DocumentClassificationAndType>();

  useEffect(() => {
    getDocumentClassificationAndTypeApi.request().then(
      (response) => {
        const documentClassificationTypeContract = response.find((item) => item.name === '契約書');
        setDocumentClassificationTypeContractState(documentClassificationTypeContract);
      },
    );
  }, []);

  const deleteAttributesIfIsNotTypeContract = useCallback((form: DocumentRegisterForm) => {
    const formClassificationIsTypeContract = form.documentClassification === documentClassificationTypeContractState?.id;
    if (!formClassificationIsTypeContract) {
      // eslint-disable-next-line no-param-reassign
      delete form.paperFlg;
      // eslint-disable-next-line no-param-reassign
      delete form.electronicFlg;
    }
  }, [documentClassificationTypeContractState]);

  const MAX_CONCURRENT_REQUESTS = 1;
  const requestQueue:{ form:DocumentRegisterForm, resolve: (value: unknown) => void, reject: (reason?: any) => void }[] = [];
  let activeRequests = 0;

  const processQueue = () => {
    if (requestQueue.length > 0 && activeRequests < MAX_CONCURRENT_REQUESTS) {
      activeRequests += 1;
      const r = requestQueue.shift();
      if (!r) return;

      const { form, resolve, reject } = r;

      documentRegister.request(form)
        .then(() => {
          activeRequests -= 1;
          resolve(null);
          processQueue();
        })
        .catch((error) => {
          activeRequests -= 1;
          reject(error);
          processQueue();
        });
    }
  };

  const makeRequest = (form: DocumentRegisterForm) => new Promise((resolve, reject) => {
    requestQueue.push({ form, resolve, reject });
    processQueue();
  });

  const getErrorMessage = useCallback((numberOfErrors: number) => (
    <div>
      <div>
        {forms.length}
        件中/
        {numberOfErrors}
        件 失敗しました。
      </div>
      <br />
      <div>
        登録に失敗した文書のみリストに残っています。
      </div>
      <div>
        入力内容を確認し、もう一度実行してください。
      </div>
    </div>
  ), [forms]);

  const sendForm = useCallback(async () => {
    setLoading(true);
    const promises = forms.map(async (form) => {
      deleteAttributesIfIsNotTypeContract(form);
      await makeRequest(form);
    });
    const responses = await Promise.allSettled(promises);
    const rejectedPromises = forms.filter((form, index) => responses[index].status === 'rejected');
    const rejectedForms = forms.filter((form) => rejectedPromises.some((formFiltered) => formFiltered.documentName === form.documentName));

    if (rejectedForms.length > 0) {
      onFormsRejected(rejectedForms);
      alertModalFunction(getErrorMessage(rejectedForms.length), () => setCloseAlertModal(closeModalInitialState));
    } else {
      alertModalFunction('文書登録を完了しました。', () => navigate(routes.main));
    }
    setLoading(false);
  }, [alertModalFunction, deleteAttributesIfIsNotTypeContract, forms, getErrorMessage, makeRequest, navigate, onFormsRejected]);

  const checkAllForms = useMemo(() => forms.every(checkRequiredFields), [forms]);

  const sortedList = useMemo(() => {
    let sortedForms = forms;
    const formsSortedByRequired = [...forms].sort((a, b) => {
      const aRequired = checkRequiredFields(a);
      const bRequired = checkRequiredFields(b);
      if (aRequired && !bRequired) return 1;
      if (!aRequired && bRequired) return -1;
      return 0;
    });
    const formsSortedByFiles = [...forms].sort((a, b) => {
      const aHasFile = a.documentFile;
      const bHasFile = b.documentFile;
      if (aHasFile && !bHasFile) return -1;
      if (!aHasFile && bHasFile) return 1;
      return 0;
    });

    if (sortType === DocSortTypes.REQUIRED) sortedForms = formsSortedByRequired;
    if (sortType === DocSortTypes.FILES) sortedForms = formsSortedByFiles;
    if (sortType === DocSortTypes.TOTAL) sortedForms = forms;

    return sortedForms.map((form) => (
      <div
        key={uuid()}
        style={{
          backgroundColor: (updatedForm === form) ? '#F2F2F2' : 'transparent',
        }}
        role="button"
        tabIndex={0}
        className={styles.mainFrameLeftSectionListRow}
        onClick={() => onFormSelected(form)}
      >
        <div className={`${mainStyles['flex-1']} ${mainStyles['d-flex']} ${mainStyles['justify-content-center']}`}>
          {form.documentFile && (
          <img src="/images/Icon-feather-file.svg" alt="" />
          )}
        </div>
        <div title={form.documentName || '新規文書'} className={`${mainStyles['flex-4']} ${mainStyles['text-overflow-ellipsis']} `}>
          {form.documentName || '新規文書'}
        </div>
        <div className={`${mainStyles['d-flex']} ${mainStyles['justify-content-center']}`}>
          { checkRequiredFields(form) ? <img className={styles.button} src="/images/Icon-awesome-check.svg" alt="" /> : <img className={styles.button} src="/images/Icon-awesome-exclamation.svg" alt="" />}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`${mainStyles['flex-1']} ${mainStyles['d-flex']} ${mainStyles['justify-content-center']}`}
          onClick={(e) => {
            e.stopPropagation();
            onRemoveForm(form);
          }}
        >
          <img className={styles.button} src="../images/Close.svg" alt="" />
        </div>
      </div>
    ));
  }, [DocSortTypes, forms, onFormSelected, onRemoveForm, sortType, updatedForm]);

  return (
    <div className={styles.mainFrameLeftSection} style={{ position: 'relative' }}>
      <div className={styles.mainFrameLeftSectionTop}>
        <div className={`${styles.mainFrameLeftSectionHeader} ${mainStyles['d-flex']}  ${mainStyles['align-items-center']} ${mainStyles['justify-content-center']}`}>
          文書一覧
        </div>
        <div className={mainStyles['px-10px']}>
          <Button
            className={`${mainStyles['mt-10']}`}
            fullWidth
            size="smaller"
            color="lighterGray"
            onClick={() => createForm()}
          >
            1からレコードを追加
          </Button>
          <FileInputButton
            accept="application/pdf"
            className={`${mainStyles['mt-10']}`}
            fullWidth
            multiple
            color="lighterGray"
            fileButtonText="PDFファイルから追加"
            onSelect={(e) => createForms(e as FileList)}
          />
        </div>
      </div>
      <div className={`${styles.mainFrameLeftSectionList} ${mainStyles['mainframe-leftsection-list']}`}>
        {sortedList}
        <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
        {(isRequesting)
          ? (
            <div
              className={styles.spinnerContainer}
            >
              <Spinner />
            </div>
          )
          : null}
      </div>

      <div className={styles.mainFrameLeftSectionUpperFooter}>
        <div className={styles.mainFrameLeftSectionUpperFooterRow}>
          <div style={{ width: '50%' }}>
            <DocRegisterSortChip label="登録" value={totalFiles} isButton={false} />
          </div>
        </div>
        <div className={styles.mainFrameLeftSectionUpperFooterRow}>
          <DocRegisterSortChip
            isFocused={sortType === DocSortTypes.FILES}
            onClick={() => changeSortType(DocSortTypes.FILES)}
            icon="/images/Icon-feather-file.svg"
            value={filesWithAttachments}
          />
          <DocRegisterSortChip
            isFocused={sortType === DocSortTypes.REQUIRED}
            onClick={() => changeSortType(DocSortTypes.REQUIRED)}
            icon="/images/Icon-awesome-exclamation.svg"
            value={filesWithMissingRequiredFields}
          />
        </div>
      </div>
      <div className={styles.mainFrameLeftSectionFooter}>
        <Button color="lightGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
        <Button
          className={`${mainStyles['ml-10']}`}
          size="smaller"
          disabled={!checkAllForms || forms.length === 0}
          loading={loading || isRequesting}
          onClick={sendForm}
        >
          登録
        </Button>
      </div>
      {(loading || isRequesting) ? <LoadingOverlay /> : null}
    </div>
  );
}

export default Component;
