import { useState } from 'react';
import { ApiError } from '../../services/http';
import passwordApi, { ResettingForm, SendEmailForm } from '../../services/http/password.api';

/**
 * メール送信APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useSendEmailApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: SendEmailForm) {
    setLoading(true);
    try {
      await passwordApi.sendEmail(form);
      return;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request,
  };
}

/**
 * パスワードリセットAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useResettingApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: ResettingForm) {
    setLoading(true);
    try {
      await passwordApi.resetting(form);
      return;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request,
  };
}
