import axios from 'axios';

export interface ExportLabelForm {
  documentIdList: {
    documentId: number
  }[]
  labelPrintType: number
  printStartPosition: number
}

export interface ExportLabelResponse {
  documentLabelFile: string
}

export default {
  /**
   * ラベルをエクスポートします。
   *
   * @param form - エクスポートフォーム
   * @returns - エクスポートレスポンス
   */
  async export(form: ExportLabelForm): Promise<ExportLabelResponse> {
    const res = await axios.post('/Documents/exportLabel', form);
    const { data } = res;
    return data;
  },
};
