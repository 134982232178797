import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import GridControlApi, {
  GetGridSettingsRequest,
  GridSettingsResponse,
  SetGridSettingsRequest,
} from '../../services/http/gridControl.api';

/**
 * 列の設定を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useGetColumnSettingsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: GetGridSettingsRequest): Promise<GridSettingsResponse> => {
    setLoading(true);
    let result;
    try {
      const res = await GridControlApi.getColumnSettings(form);
      result = res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
    return result;
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 列設定の設定取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useSetColumnSettingsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const request = useCallback(async (form: SetGridSettingsRequest): Promise<void> => {
    let result;
    setLoading(true);
    try {
      const res = await GridControlApi.setColumnSettings(form);
      result = res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
    return result;
  }, []);
  return {
    loading, error, request,
  };
}
