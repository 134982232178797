import { useNavigate } from 'react-router-dom';
import routes from '../../utils/routes';
import styles from './breadcrumb.module.css';

interface Crumb {
  label: string;
  route?: string;
}

interface Props {
  crumbs: Crumb[];
}

function Breadcrumb({ crumbs }: Props) {
  const navigator = useNavigate();

  function isLast(index: number) {
    return index === crumbs.length - 1;
  }

  return (
    <div className={styles.box}>
      <div
        className={styles.item}
        onClick={() => navigator(routes.main)}
        role="button"
        tabIndex={0}
      >
        HOME
      </div>
      <div className={styles.divider}>
        {' '}
        {'>'}
        {' '}
      </div>
      {
        crumbs.map((crumb, i) => {
          const lastCrumb = !!isLast(i);
          return (
            <div className={styles.container} key={crumb.label}>
              <div
                className={lastCrumb ? styles['last-item'] : styles.item}
                onClick={lastCrumb ? undefined : () => navigator(crumb.route || '')}
                role="button"
                tabIndex={0}
              >
                {crumb.label}
              </div>
              { !lastCrumb
                ? (
                  <div className={styles.divider}>
                    {'>'}
                  </div>
                )
                : null}
            </div>
          );
        })
      }
    </div>
  );
}

export default Breadcrumb;
