import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import useAsyncState from '../AsyncState.hook';
// eslint-disable-next-line import/no-cycle
import organizationAuthenticationApi from '../../services/http/organizationAuthentication.api';
import { useAuthContext } from '../../store/auth.store';
import useEffectOnce from '../useEffectOnce.hook';
import useSet from '../useSet';
import SamlMetadata from '../../utils/SamlMetadata';

export interface DoneBy {
  name: string,
  at: Date,
}

export enum OrganizationAuthenticationType {
  EmailAndPassword = 'EmailAndPassword',
  Sso = 'Sso',
}

export interface OrganizationAuthentication {
  organizationId: number,
  settingName: string,
  entityId: string,
  loginUrl: string,
  callbackUrl: string,
  certificate: string,
  certificateExpiresAt: Date | null,
  tenantCode: string,
  types: Set<OrganizationAuthenticationType>
  createdBy: DoneBy,
  updatedBy: DoneBy
}

export function useGetOrganizationAuthentication() {
  const getOrganizationSamlAsync = useAsyncState(organizationAuthenticationApi.getSaml);
  const auth = useAuthContext();
  const { user } = auth;
  if (!user) throw new Error('Authentication Error');

  useEffectOnce(() => {
    getOrganizationSamlAsync.execute();
  });

  return {
    authentication: getOrganizationSamlAsync.data,
    loading: getOrganizationSamlAsync.loading,
    error: getOrganizationSamlAsync.error,
    execute: getOrganizationSamlAsync.execute,
  };
}

export function useHasSsoSupport() {
  const getOrganizationSaml = useGetOrganizationAuthentication();

  return {
    hasSsoSupport: !!getOrganizationSaml.authentication,
    loading: getOrganizationSaml.loading,
    error: getOrganizationSaml.error,
    execute: getOrganizationSaml.execute,
  };
}

export function useUpdateOrganizationAuthentication() {
  const getOrganizationSaml = useGetOrganizationAuthentication();
  const updateOrganizationSamlAsync = useAsyncState(organizationAuthenticationApi.updateSaml);

  const [organizationId, setOrganizationId] = useState(0);
  const [settingName, setSettingName] = useState('');
  const [entityId, setEntityId] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const [certificate, setCertificate] = useState('');
  const [certificateExpiresAt, setCertificateExpiresAt] = useState<Date | null>(null);
  const [metadataFile, setMetadataFile] = useState<File | null>(null);
  const [types, { add: AddType, remove: RemoveType }] = useSet<OrganizationAuthenticationType>();

  const auth = useAuthContext();
  const { user } = auth;
  if (!user) throw new Error('Authentication Error');

  function update() {
    const { authentication } = getOrganizationSaml;
    if (!authentication) throw new Error('Authentication Error');

    const newAuthentication: OrganizationAuthentication = {
      ...authentication,
      settingName,
      entityId,
      loginUrl,
      certificate,
      types,
    };
    return updateOrganizationSamlAsync.execute(newAuthentication);
  }

  const isEnabled = useMemo(() => types.has(OrganizationAuthenticationType.Sso), [types]);

  const isForced = useMemo(
    () => types.has(OrganizationAuthenticationType.Sso) && types.size === 1,
    [types],
  );

  const isValidate = useMemo(
    () => !!(entityId && loginUrl && certificate),
    [certificate, entityId, isEnabled, loginUrl],
  );

  useEffect(() => {
    if (getOrganizationSaml.authentication) {
      setOrganizationId(getOrganizationSaml.authentication.organizationId);
      setEntityId(getOrganizationSaml.authentication.entityId);
      setLoginUrl(getOrganizationSaml.authentication.loginUrl);
      setCertificate(getOrganizationSaml.authentication.certificate);
      setSettingName(getOrganizationSaml.authentication.settingName);
      setCertificateExpiresAt(getOrganizationSaml.authentication.certificateExpiresAt);
      getOrganizationSaml.authentication.types.forEach((type) => AddType(type));
    }
  }, [AddType, getOrganizationSaml.authentication]);

  useEffect(() => {
    if (!metadataFile) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result !== 'string') return;

      const metadata = SamlMetadata.fromXmlString(result);
      setEntityId(metadata.entityId);
      setLoginUrl(metadata.loginUrl);
      setCertificate(metadata.certificate);
    };
    reader.readAsText(metadataFile);
  }, [metadataFile]);

  return {
    form: {
      organizationId: { value: organizationId, set: setOrganizationId },
      settingName: { value: settingName, set: setSettingName },
      metadataFile: {
        value: metadataFile,
        set: setMetadataFile,
      },
      entityId: { value: entityId, set: setEntityId },
      loginUrl: { value: loginUrl, set: setLoginUrl },
      certificate: {
        value: certificate,
        set: (value: string) => {
          setCertificate(value);
          setCertificateExpiresAt(null);
        },
        expiresAt: certificateExpiresAt,
      },
      enable: {
        value: isEnabled,
        set: (enable: boolean) => {
          if (enable) {
            AddType(OrganizationAuthenticationType.Sso);
          } else {
            RemoveType(OrganizationAuthenticationType.Sso);
            AddType(OrganizationAuthenticationType.EmailAndPassword);
          }
        },
      },
      force: {
        value: isForced,
        set: (enable: boolean) => (enable
          ? RemoveType(OrganizationAuthenticationType.EmailAndPassword)
          : AddType(OrganizationAuthenticationType.EmailAndPassword)),
      },
    },
    isValidate,
    update,
    loading: updateOrganizationSamlAsync.loading,
    authentication: {
      loading: getOrganizationSaml.loading,
      error: getOrganizationSaml.error,
      value: getOrganizationSaml.authentication,
    },
    getOrganizationSaml,
  };
}
