import { useCallback, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styles from './docDetailModalFrame.module.css';
import { Document, DocumentDetail } from '../../services/http/documents.api';
// eslint-disable-next-line import/no-cycle
import DocDetailInfo from './docDetailInfo';
import DocDetailHistory from './docDetailHistory';
import { useDocumentGetDetail } from '../../hooks/api/document.hook';
import BaseTab, { BaseTabItem } from '../BaseTab/BaseTab';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';

/**
 * 文書詳細モーダルフレームのプロップスインターフェース
 */
export interface Props {
  /** 使用される文書の参照 */
  document: Document | null;
  /** モーダルが閉じられるときに呼び出される関数 */
  onClose: () => void
  /** 文書が削除されるときに呼び出される関数 */
  onRemoveDocument: (document: DocumentDetail) => void,
  /** 文書が更新されるときに呼び出される関数 */
  onDocumentUpdated: () => void

  onCancelAddingRelatedDocuments?: () => void;
}

/**
 * 文書詳細モーダルフレーム
 */
function DocDetailModalFrame({
  document: documentRef, onRemoveDocument, onClose, onDocumentUpdated, onCancelAddingRelatedDocuments = () => {},
}: Props) {
  // States
  const [document, setDocument] = useState<DocumentDetail | null>(null);
  const [isClosed, setIsClosed] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Hooks
  const documentGetDetail = useDocumentGetDetail();
  const openMessageModal = useMessageModal();

  // Methods
  const onDocUpdated = useCallback(() => {
    if (documentRef) {
      try {
        documentGetDetail.request({ id: documentRef.id }).then(setDocument);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [documentGetDetail, documentRef, openMessageModal]);

  const onRemoveVersion = useCallback((version: DocumentDetail['documents'][number]) => {
    if (!document) return;
    setDocument({ ...document, documents: document.documents.filter((item) => item !== version) });
    onDocUpdated();
  }, [document, onDocUpdated]);

  const onDocDeleted = useCallback((doc: DocumentDetail) => {
    onRemoveDocument(doc);
    onClose();
  }, [onClose, onRemoveDocument]);

  const spring = useSpring({
    left: (documentRef && !isClosed) ? window.innerWidth - 877 : window.innerWidth + 200,
    display: documentRef ? 'block' : 'none',
    maxWidth: '877px',
    zIndex: 100,
  });

  const onCloseModal = useCallback(() => {
    setIsEditing(false);
    setIsClosed(true);
    onClose();
    setModalClosed(true);
  }, [onClose]);

  // Effects
  useEffect(() => {
    if (documentRef) {
      try {
        documentGetDetail.request({ id: documentRef.id }).then(setDocument);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [documentRef]);

  useEffect(() => {
    if (!documentRef) setIsClosed(false);
  }, [documentRef, onClose]);

  return (

    <div style={isEditing ? {
      position: documentRef ? 'fixed' : 'unset',
      background: documentRef ? 'rgba(0, 0, 0, 0.5)' : '',
      zIndex: documentRef ? 99 : '',
      width: documentRef ? '100%' : '',
      height: documentRef ? '100%' : '',
      top: documentRef ? '0' : '',
      left: documentRef ? '0' : '',
    } : {}}
    >
      <animated.div id="modal" className={styles.bodyBackground} style={spring}>

        <div className={styles.modalContainer}>
          <div className={styles.modal}>

            <BaseTab modalClosed={modalClosed}>
              <BaseTabItem title="詳細情報">
                <DocDetailInfo
                  document={document}
                  onClose={onCloseModal}
                  onDocumentUpdated={onDocumentUpdated}
                  onRemoveDocumentVersion={onRemoveVersion}
                  onUpdateVersion={onDocUpdated}
                  onRemoveDocument={onDocDeleted}
                  setIsEditing={setIsEditing}
                  onCancelAddingRelatedDocuments={onCancelAddingRelatedDocuments}
                />
              </BaseTabItem>
              <BaseTabItem title="操作履歴">
                <DocDetailHistory
                  document={document}
                  onClose={() => {
                    setModalClosed(true);
                    setIsClosed(true);
                    onClose();
                  }}
                  setIsEditing={setIsEditing}
                />
              </BaseTabItem>
            </BaseTab>
          </div>
        </div>
      </animated.div>
    </div>
  );
}

export default DocDetailModalFrame;
