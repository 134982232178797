import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import styles from './setPasswordSuccessPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import routes from '../../utils/routes';
import usePageTitle from '../../hooks/title.hook';

/**
 * パスワード設定成功ページ
 */
function SetPasswordSuccessPage() {
  const navigate = useNavigate();

  usePageTitle('パスワード設定完了');

  return (
    <CustomFrame
      title="パスワード設定完了"
      desc={(
        <div className={styles.descTitle}>
          <p>
            CLOUD CABINETで使用しているアカウントのパスワードの設定が完了しました。
          </p>
        </div>
      )}
      body={<Button size="larger" content="ログイン画面へ" onClick={() => navigate(routes.login)} />}
    />
  );
}

export default SetPasswordSuccessPage;
