import style from './loadingOverlay.module.css';
import Spinner from '../Spinner';

function LoadingOverlay() {
  return (
    <div className={style.overlay}>
      <div className={style.container}>
        <Spinner />
      </div>
    </div>
  );
}

export default LoadingOverlay;
