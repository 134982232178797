import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import LogApi, {
  CreateDownloadFileLogForm, CreateUserDetailShowLogForm,
  LogCreateForm, LogDetail, LogGetDetailForm,
  LogList, LogSearchForm, LogSearchResponse, OperationDetailsCd,
} from '../../services/http/log.api';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import useAsyncState from '../AsyncState.hook';
import useEffectOnce from '../useEffectOnce.hook';

/**
 * ログ検索APIフックの変更
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 *   - `total`:ログ合計
 *   - `page`:ページネーションに使用する現在のページ
 *   - `pageLimit`:ページネーションに使用するページの最大数
 */
export function useSearchLogApi() {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(0);
  const [data, setData] = useState<LogList[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: LogSearchForm, p: number): Promise<LogSearchResponse> => {
    setLoading(true);
    try {
      const doc = await LogApi.search(form, p);
      setData(doc.logList);
      setTotal(doc.total);
      setPage(doc.page);
      setPageLimit(doc.pageLimit);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request, total, page, pageLimit,
  };
}

/**
 * 操作詳細コードを取得するAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useGetOperationDetailsCdApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OperationDetailsCd[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (): Promise<OperationDetailsCd[]> => {
    setLoading(true);
    try {
      const doc = await LogApi.getOperationDetailsCd();
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

export function useCreateLogApi() {
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (formName: string, controlName: string): Promise<void> => {
    try {
      const form: LogCreateForm = {
        formName,
        controlName,
      };
      return await LogApi.create(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }, []);
  return {
    error, request,
  };
}

export function useCreateDownloadFileLogApi() {
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (documentDataId: number, isPreview: number): Promise<void> => {
    try {
      const form: CreateDownloadFileLogForm = {
        documentDataId,
        isPreview,
      };
      return await LogApi.createDownloadFileLog(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }, []);
  return {
    error, request,
  };
}

export function useCreateUserDetailShowLogApi() {
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (userId: string): Promise<void> => {
    try {
      const form: CreateUserDetailShowLogForm = {
        userId,
      };
      return await LogApi.createUserDetailShowLog(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }, []);
  return {
    error, request,
  };
}

export function useLog(tagName: LogFormName) {
  const logApi = useAsyncState(LogApi.create);

  const add = useCallback((operation: LogControlName) => logApi.execute({
    formName: tagName,
    controlName: operation,
  }), [logApi]);

  useEffectOnce(() => {
    add(LogControlName.Show);
  });

  return {
    add,
  };
}

export function useGetLogDetailsApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LogDetail>();
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: LogGetDetailForm): Promise<LogDetail> => {
    setLoading(true);
    try {
      const log = await LogApi.getDetail({ logId: form.logId });
      setData(log);
      return log;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}
