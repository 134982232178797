import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import { useLocation, useNavigate } from 'react-router-dom';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './userListScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import UserDetailModal from './userDetailModal';
import UserRegisterModal from './userRegisterModal';
import { useDeleteUserApi, useSearchUserApi } from '../../hooks/api/user.hook';
import { useSearchUserGroupApi } from '../../hooks/api/userGroup.hook';
import SelectInput from '../../components/SelectInput';
import { User, UserGroup, UserSearchForm } from '../../services/http/user.api';
import Input from '../../components/Input/input';
import Button from '../../components/Button/button';
import AlertModal, { alertModalInitialState, AlertModalProps } from '../../components/AlertModal';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import BaseTable from '../../components/BaseTable';
import CheckboxCellRender from '../../components/BaseTable/partials/CheckBoxCell/CheckBoxCellRender';
import ButtonTableDownload from '../../components/BaseTable/ButtonTableDownload';
import routes from '../../utils/routes';
import Breadcrumb from '../../components/Breadcrumb';
import { ManagementType } from '../../store/auth.store';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import AGUtils from '../../utils/ag-grid.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import LoadingOverlay from '../../components/LoadingOverlay';

/**
 * テーブルセル表示のプロップスインターフェース
 */
export interface TableBtnCellRenderProps {
  /** ユーザデータ */
  data: User,
  /** 詳細ボタンクリックハンドラー */
  onInfoClick: (user: User) => void,
  /** 削除ボタンクリックハンドラー */
  onDeleteClick: (user: User) => void,
  /** 検索フォームデータ */
  searchForm: UserSearchForm,
}

/**
 * テーブルボタンセル表示のコンポーネント
 */
function TableBtnCellRender({
  data, onDeleteClick, onInfoClick, searchForm,
}: TableBtnCellRenderProps) {
  const navigator = useNavigate();

  return (
    <div>
      <button type="button" className={[styles['table-button'], mainStyles['btn-gray']].join(' ')} onClick={() => onInfoClick(data)}>変更</button>
      <button
        type="button"
        style={{ margin: '0px 3px' }}
        className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}
        onClick={(e) => {
          navigator(`${routes.nonDocumentPermissionScreen}?userId=${data.id}`, { state: { ...searchForm } });
          e.stopPropagation();
        }}
      >
        権限
      </button>
      { !data.passwordUpdate && (
        <button
          type="button"
          className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}
          onClick={() => onDeleteClick(data)}
        >
          削除
        </button>
      )}
    </div>
  );
}

/**
 * ユーザー一覧ページ
 */
function Component() {
  usePageTitle('ユーザー一覧');
  const navigate = useNavigate();
  const openMessageModal = useMessageModal();
  const { request: createLog } = useCreateLogApi();

  const { state } = useLocation();
  const { temporarySearchForm } = state as any || {};

  // Refs
  const gridRef = useRef<AgGridReact<User>>(null);
  const ref = useRef<HTMLInputElement>(null);

  const [searchForm, setSearchForm] = useState<UserSearchForm>({});
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [registerModal, setRegisterModal] = useState(false);

  const { request: searchUserApiRequest, loading: searchUserApiLoading } = useSearchUserApi();
  const { request: deleteUserApiRequest, loading: deleteUserApiLoading } = useDeleteUserApi();
  const { request: searchUserGroupApiRequest, data: searchUserGroupApiData, loading: searchUserGroupApiLoading } = useSearchUserGroupApi();

  const isLoading = searchUserApiLoading || deleteUserApiLoading || searchUserGroupApiLoading;

  const onSearchClick = useCallback(async (form?: UserSearchForm) => {
    createLog(LogFormName.UserListScreen, LogControlName.Search);
    try {
      const searchUsers = await searchUserApiRequest(form || searchForm);
      setUsers(searchUsers);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [searchForm, searchUserApiRequest]);

  useEffect(() => {
    if (temporarySearchForm) {
      setSearchForm(temporarySearchForm);
      onSearchClick(temporarySearchForm);
    }
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [temporarySearchForm]);

  const onDeleteUserClick = useCallback(async (user: User) => {
    setModal({
      open: true,
      text: 'ユーザ情報を削除します。よろしいですか？',
      onConfirm: async () => {
        setModal({ ...modal });
        await deleteUserApiRequest({ userId: user.id });
        setUsers(users.filter((item) => item.userId !== user.userId));
        onSearchClick();
      },
      onCancel: async () => setModal({ ...modal }),
    });
  }, [users, deleteUserApiRequest, modal]);

  const onImportCSV = useCallback(() => {
    setModal({
      acceptFileType: '.csv',
      chooseFileButtonText: 'CSVファイルを選択する',
      chooseFileButtonStyle: { width: '180px', backgroundColor: '#54c3f1', color: '#fff' },
      hideConfirmButton: true,
      showChooseFileButton: true,
      modalStyle: { width: '760px' },
      confirmText: 'CSVファイルを選択する',
      cancelText: '閉じる',
      open: true,
      text:
  <div>
    ・本機能ではユーザー登録用です。
    <br />
        &nbsp; &nbsp;  既存ユーザーの更新、削除は画面から実施ください。
    <br />
    <br />
    ・管理者ユーザーを登録する場合、管理者ユーザー列に任意の文字を入力してください。
    <br />
        &nbsp; &nbsp;  管理者ユーザー列に文字が入力されている場合、対象ユーザーが管理者ユーザーとして登録されます。
    <br />
        &nbsp; &nbsp;  管理者ユーザー列が空欄の場合、対象ユーザーが一般ユーザーとして登録されます。
  </div>,
      onConfirm: async () => {
        // setModal({ ...modal });
        ref.current?.click();
      },
      onCancel: async () => setModal({ ...modal }),
    });
  }, [modal]);

  const onFileSelected = (value: File | FileList | null) => {
    navigate(routes.userRegisterFromCsvScreen, { state: { file: value } });
  };

  const columns = useMemo((): ColDef<User>[] => [
    AGUtils.colAutoIncrement('no'),
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 140,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        searchForm,
        onInfoClick: (user: User) => {
          setSelectedUser({ ...user });
        },
        onDeleteClick: onDeleteUserClick,
      },
    },
    AGUtils.colUsername('userName', 'ユーザー名'),
    AGUtils.colEmail('email', 'メールアドレス'),
    {
      field: 'userAuthenticationType',
      headerName: 'ユーザー種別',
      resizable: true,
      sortable: true,
      width: 150,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => (data?.userAuthenticationType === ManagementType.Admin ? '管理ユーザー' : '一般ユーザー'),
    }, {
      field: 'userGroupList',
      headerName: 'ユーザーグループ',
      resizable: true,
      sortable: true,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => (data?.userGroupList.map((item: UserGroup) => item.userGroupName).join(', ')),
      width: 300,
    },
    AGUtils.colCheckboxReadOnly('emailConfirmed', '認証状態', 120),
    AGUtils.colCheckboxReadOnly('passwordUpdate', '初期パスワード変更', 170),
    {
      field: 'deleteFlg',
      headerName: '無効化',
      resizable: true,
      sortable: true,
      minWidth: 120,
      flex: 1,
      cellRenderer: CheckboxCellRender,
      cellRendererParams: {
        readOnly: true,
      },
    },

  ], [users]);

  useEffect(() => {
    searchUserGroupApiRequest({});
  }, []);

  useEffectOnce(() => {
    createLog(LogFormName.UserListScreen, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={styles.mainframe}>
        <Breadcrumb crumbs={[{ label: document.title }]} />
        <div className={styles.mainFrameBody}>
          <div className={styles['mainframe-leftsection']}>
            <div className={styles['leftsection-title-line']}>
              <div className={styles['leftsection-title']}>検索条件</div>
            </div>
            <div className={styles['leftsection-inputfield']}>
              <div className={styles.label}>ユーザー名</div>
              <Input value={searchForm.userName || ''} onChange={(value) => setSearchForm({ ...searchForm, userName: value })} />
            </div>
            <div className={styles['leftsection-inputfield']}>
              <div className={styles.label}>メールアドレス</div>
              <Input value={searchForm.email || ''} onChange={(value) => setSearchForm({ ...searchForm, email: value })} />
            </div>
            <div className={styles['leftsection-inputfield']}>
              <div className={styles.label}>ユーザー種別</div>
              <SelectInput<ManagementType | undefined>
                value={searchForm.userAuthenticationType}
                options={[{ value: undefined, text: '' }, { value: ManagementType.Admin, text: '管理ユーザー' }, { value: ManagementType.General, text: '一般ユーザー' }]}
                onChange={(value) => setSearchForm({ ...searchForm, userAuthenticationType: value })}
              />
            </div>
            <div className={styles['leftsection-inputfield']}>
              <div className={styles.label}>ユーザーグループ</div>
              <AutoCompleteInput
                value={searchForm.userGroupName}
                // options={searchUserGroupApiData.map((item) => ({ text: item.name, value: item.name }))}
                options={[{ text: ' ', value: '' }].concat(searchUserGroupApiData.map((item) => ({ text: item.name, value: item.name })))}
                onSelect={(value) => setSearchForm({ ...searchForm, userGroupName: value })}
                onTextInput={(value) => setSearchForm({ ...searchForm, userGroupName: value })}
              />
            </div>
            <div className={styles['leftsection-inputfield']}>
              <div className={styles.label}>有効／無効</div>
              <SelectInput
                value={searchForm.deleteFlg}
                options={[{ value: undefined, text: '' }, { value: Number(0), text: '有効' }, { value: Number(1), text: '無効' }]}
                onChange={(e) => setSearchForm({ ...searchForm, deleteFlg: e })}
              />
            </div>
            <div className={styles['leftsection-footer-buttons']}>
              <Button
                className={[mainStyles['mt-10'], mainStyles['ml-10']].join(' ')}
                size="smaller"
                color="lighterGray"
                onClick={() => setSearchForm({})}
              >
                条件リセット
              </Button>
              <Button
                className={[mainStyles['mt-10'], mainStyles['mr-10']].join(' ')}
                size="smaller"
                onClick={() => onSearchClick()}
              >
                検索
              </Button>
            </div>
          </div>
          <div className={styles['mainframe-rightsection']}>
            <div className={styles['mainframe-rightsection-top']}>
              <div className={styles['mainframe-rightsection-top-buttons']}>
                <Button className={[mainStyles['mr-10']].join(' ')} size="smaller" onClick={() => { setRegisterModal(true); }}>
                  新規登録
                </Button>
                <Button className={[mainStyles['mr-10']].join(' ')} size="smaller" onClick={onImportCSV}>
                  CSV アップロード
                </Button>
                {/* <button type="button" className={[styles.downloadButton, mainStyles.button, mainStyles['btn-gray']].join(' ')}>ダウンロード</button> */}
                <div className={styles.downloadButton}>
                  <ButtonTableDownload
                    tableRef={gridRef}
                    columnsToExport={['no', 'userName', 'email', 'userAuthenticationType', 'userGroupList', 'emailConfirmed', 'passwordUpdate', 'deleteFlg']}
                    logFormName={LogFormName.UserListScreen}
                  />
                </div>
              </div>
            </div>
            <BaseTable<User>
              tableRef={gridRef}
              formName="userTable"
              columnDefs={columns}
              rowData={users}
              sideBar
              createLogOnDownloadTableData={LogFormName.UserListScreen}
            />
          </div>
          {selectedUser && <UserDetailModal user={selectedUser} handleCancel={() => setSelectedUser(null)} handleSuccess={() => onSearchClick()} />}
          {registerModal && <UserRegisterModal open={registerModal} handleCancel={() => { setRegisterModal(false); }} handleSuccess={() => onSearchClick()} />}
          <AlertModal
            open={modal.open}
            text={modal.text}
            onCancel={modal.onCancel}
            onConfirm={modal.onConfirm}
            confirmText={modal.confirmText}
            cancelText={modal.cancelText}
            modalStyle={modal.modalStyle}
            showChooseFileButton={modal.showChooseFileButton}
            hideConfirmButton={modal.hideConfirmButton}
            chooseFileButtonStyle={modal.chooseFileButtonStyle}
            chooseFileButtonText={modal.chooseFileButtonText}
            onFileSelected={onFileSelected}
            acceptFileType={modal.acceptFileType}
          />
        </div>
        {isLoading && <LoadingOverlay />}
      </div>
    </MainFrame>
  );
}

export default Component;
