import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import folderPermissionsApi, {
  FolderControlList,
  FolderGetAuthorityForm,
  FolderSetAuthorityForm,
} from '../../services/http/folderPermissions.api';

/**
 * フォルダ権限取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useGetAuthorityApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [data, setData] = useState<FolderControlList[] | null>(null);

  const request = useCallback(async (form: FolderGetAuthorityForm): Promise<FolderControlList[]> => {
    setLoading(true);
    try {
      const res = await folderPermissionsApi.getAuthority(form);
      setData(res);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, data, request,
  };
}

/**
 * フォルダ権限設定APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `save()`:APIに保存を行う関数
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
// eslint-disable-next-line import/prefer-default-export
export function useSetAuthorityApi() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function save(form: FolderSetAuthorityForm): Promise<void> {
    setLoading(true);
    try {
      return await folderPermissionsApi.setAuthority(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, save,
  };
}
