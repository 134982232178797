import { MouseEventHandler, useMemo } from 'react';

type Size = 'sm' | 'smMd' | 'md' | 'lg';
interface BaseTextProps {
  className?: string,
  size?: Size,
  bold?: boolean,
  color?: string,
  justify?: 'left' | 'center' | 'right',
  children: React.ReactNode,
  onClick?: MouseEventHandler<HTMLDivElement>,
  style?: React.CSSProperties,
}

const sizeMap: Record<Size, number> = {
  sm: 12,
  smMd: 14,
  md: 16,
  lg: 20,
};

export default function BaseText(props: BaseTextProps) {
  const {
    className,
    size = 'md',
    bold,
    color = 'var(--darkGray)',
    justify,
    children,
    style,
    onClick,
  } = props;

  const customStyle = useMemo(() => {
    const res: React.CSSProperties = {};
    if (style) Object.assign(res, style);

    res.fontSize = sizeMap[size];
    res.color = color;
    res.fontWeight = bold ? 'bold' : 'normal';
    if (justify) res.textAlign = justify;
    return res;
  }, [size, bold, color, style]);

  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={className} style={customStyle} onClick={onClick}>
      {children}
    </div>
  );
}
