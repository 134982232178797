import axios from 'axios';

export interface FolderGetAuthorityForm {
  folderId?: number,
  organizationChangeHistoryId?: number,
}

export interface FolderControlList {
  controlType: number,
  controlTargetId: number | string,
  roleId: number,
}

export interface FolderGetAuthorityResponse {
  controlList: FolderControlList[],
}

export interface FolderSetAuthorityForm {
  folderId?: number,
  controlList?: FolderControlList[],
}

export interface FolderRole {
  roleList: FolderRoleList[],
}

export interface FolderRoleList {
  roleId: number,
  roleName: string,
  controlList: FolderRoleControlList[],
}

export interface FolderRoleControlList {
  controlCode: string,
  controlName: string,
}

export interface FolderAuthorityForm {
  organizingId: number,
  folderId: number,
  controlList: FolderControlList[],
}

export interface FolderAuthorityCancel {
  organizingId: number,
  folderList: FolderId[],
}

export interface FolderId {
  folderId: number,
}

export default {
  /**
   * 指定されたフォルダの権限一覧を取得します。
   *
   * @param form - フォルダ権限取得フォーム
   * @returns フォルダの権限一覧
   */
  async getAuthority(form: FolderGetAuthorityForm): Promise<FolderControlList[]> {
    const res = await axios.get('/Folder/getAuthority', { params: form });
    const { data } = res;
    return data.controlList.map((item:{
      controlType: number,
      controlTargetId: number | string,
      roleId: number,
    }): FolderControlList => ({
      controlType: item.controlType,
      controlTargetId: item.controlTargetId,
      roleId: item.roleId,
    }));
  },
  /**
   * 指定されたフォルダの権限を設定します。
   *
   * @param form - フォルダ権限設定フォーム
   * @returns void
   */
  async setAuthority(form: FolderSetAuthorityForm): Promise<void> {
    const res = await axios.post('/Folder/setAuthority', form);
    const { data } = res;
    return data;
  },
};
