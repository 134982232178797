import {
  ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { DropDownMenuItem } from '../../../DropdownMenu';
import mainStyles from '../../../../pages/main.module.css';

interface SelectCellEditorProps extends ICellEditorParams {
  options: DropDownMenuItem<any>[] | undefined,
  disabled?: boolean,
  onChange?: (value: string) => void,
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const SelectCellEditor = forwardRef((props: SelectCellEditorProps, ref:ForwardedRef<unknown>) => {
  const { disabled, onChange, options } = props;
  const [value, setValue] = useState(props.value);
  const refSelect = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refSelect.current?.focus();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
  }));

  const onChangeValue = (e: string) => {
    if (onChange) onChange(e);
    setValue(e);
    if (props.stopEditing) {
      props.stopEditing();
    }
  };

  return (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      <select
        style={{ backgroundColor: 'white', width: '80px' }}
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        disabled={disabled}
        className={mainStyles.select}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
});
export default SelectCellEditor;
