import React, { useState } from 'react';
import styles from './logDetailModal.module.css';
import mainStyles from '../main.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import Button from '../../components/Button/button';
import { LogDetail } from '../../services/http/log.api';
import BaseText from '../../components/BaseText/BaseText';
import Divider from '../../components/Divider/Divider';
import { useGetLogDetailsApi } from '../../hooks/api/log.hook';
import { ApiError } from '../../services/http';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import Formatter from '../../utils/formatters';
import { CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import { ERROR_MESSAGES } from '../../utils/messages';

/**
 * ログ詳細モーダルのプロップスインターフェース
 */
export interface Props {
  /** ログID */
  logId: number,
  /** キャンセルハンドラー */
  handleCancel: () => void,
}

/**
 * ログ詳細モーダルのコンポーネント
 */
function Component({ logId, handleCancel }: Props) {
  const logGetDetail = useGetLogDetailsApi();
  const openMessageModal = useMessageModal();

  // states
  const [logDetail, setLogDetail] = useState<LogDetail | null>(null);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);

  const scopedTextSelector = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
      e.preventDefault();
      const target = e.target as HTMLDivElement;

      if (target.classList.contains(styles['details-content-section-body'])) {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(target);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  };

  useEffectOnce(() => {
    try {
      logGetDetail.request({ logId })
        .then(setLogDetail)
        .catch(() => {
          setCloseAlertModal({
            ...closeAlertModal, open: true, text: ERROR_MESSAGES.UNEXPECTED_ERROR, onCancel: () => { setCloseAlertModal({ ...closeAlertModal, open: false }); handleCancel(); },
          });
        });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  });

  return (
    <ScreenModal
      hidden={!logId}
      handleCancel={handleCancel}
      closeOnOutsideClick={false}
      customStyles={{
        minWidth: '900px',
        maxWidth: '900px',
        maxHeight: '90%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
        overflowY: 'auto',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          ログ詳細
        </div>
      </div>
      <div className={styles['main-content']}>
        <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
          <div className={[styles.label, mainStyles['text-overflow-ellipsis']].join(' ')}>
            <span className={styles.labelTextWidth}>操作日時</span>
            ：
          </div>
          <div className={[styles.text, mainStyles['text-overflow-ellipsis'], mainStyles['ml-3']].join(' ')}>
            {logDetail && (Formatter.fromStringToFormattedString(logDetail.operationDatetime, Formatter.defaultDateTimeFormat) || '')}
          </div>
        </div>
        <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], styles.nameContainer].join(' ')}>
          <div className={[styles.label, mainStyles['text-overflow-ellipsis']].join(' ')}>
            <span className={styles.labelTextWidth}>ユーザー名</span>
            ：
          </div>
          <div className={[styles.text, mainStyles['text-overflow-ellipsis'], mainStyles['ml-3'], mainStyles['ml-3']].join(' ')}>
            { logDetail?.userName }
          </div>
        </div>
        <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
          <div className={[styles.label, mainStyles['text-overflow-ellipsis']].join(' ')}>
            <span className={styles.labelTextWidth}>メールアドレス</span>
            ：
          </div>
          <div className={[styles.text, mainStyles['text-overflow-ellipsis'], mainStyles['ml-3']].join(' ')}>
            { logDetail?.email }
          </div>
        </div>
        <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
          <div className={[styles.label, mainStyles['text-overflow-ellipsis']].join(' ')}>
            <span className={styles.labelTextWidth}>IPアドレス</span>
            ：
          </div>
          <div className={[styles.text, mainStyles['text-overflow-ellipsis'], mainStyles['ml-3']].join(' ')}>

            { logDetail?.ipAddress }
          </div>
        </div>
        <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], 'mt-2'].join(' ')}>
          <div className={[styles.label, mainStyles['text-overflow-ellipsis']].join(' ')}>
            <span className={styles.labelTextWidth}>操作内容</span>
            ：
          </div>
          <div className={[styles.text, mainStyles['text-overflow-ellipsis'], mainStyles['ml-3']].join(' ')}>
            { logDetail?.operationDetailsCd }
          </div>
        </div>
        <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], 'mt-2'].join(' ')}>
          <div className={[styles.label, mainStyles['text-overflow-ellipsis']].join(' ')}>
            <span className={styles.labelTextWidth}>対象</span>
            ：
          </div>
          <div className={[styles.text, mainStyles['text-overflow-ellipsis'], mainStyles['ml-3']].join(' ')}>
            { logDetail?.targetContent }
          </div>
        </div>

        <BaseText className="mt-4"> 操作内容詳細 </BaseText>
        <Divider className="mt-1" />
        { !logDetail?.detailBefore
            && <div style={{ marginTop: '27px' }} />}
        <div className={styles['details-content']}>
          { logDetail?.detailBefore
          && (
          <div className={styles['details-content-section']} style={{ maxWidth: '424px' }}>
            <BaseText className="mb-1"> 変更前 </BaseText>
            <div
              className={[styles['details-content-section-body']].join(' ')}
              title={logDetail?.detailBefore}
              onKeyDown={scopedTextSelector}
              tabIndex={0}
              role="textbox"
            >
              {logDetail?.detailBefore}
            </div>
          </div>
          )}
          <div className={styles['details-content-section']} style={{ maxWidth: logDetail?.detailBefore ? '424px' : '869px' }}>
            { logDetail?.detailBefore
                && <BaseText className="mb-1"> 変更後 </BaseText>}
            <div
              className={[styles['details-content-section-body']].join(' ')}
              title={logDetail?.detailAfter}
              onKeyDown={scopedTextSelector}
              tabIndex={0}
              role="textbox"
            >
              {logDetail?.detailAfter}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['mainframe-body-footer-buttons']}>
        <Button color="lighterGray" size="smaller" onClick={() => handleCancel()}>閉じる</Button>
      </div>
      <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />

    </ScreenModal>
  );
}

export default Component;
