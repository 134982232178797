import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import styles from './sideBar.module.css';
import {
  getUserType, ManagementType, useAuthContext, UserType,
} from '../../store/auth.store';
import { ReactComponent as FolderIcon } from '../../assets/images/Sidebar-Folder.svg';
import { ReactComponent as UsersCogIcon } from '../../assets/images/Sidebar-Icon-awesome-users-cog.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/Sidebar-Icon-awesome-user.svg';
import { ReactComponent as UserLockIcon } from '../../assets/images/Sidebar-Icon-awesome-user-lock.svg';
import { ReactComponent as FileSignatureIcon } from '../../assets/images/Sidebar-Icon-awesome-file-signature.svg';
import { ReactComponent as HistoryIcon } from '../../assets/images/Sidebar-Icon-awesome-history.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/Sidebar-Settings-and-Key.svg';
import { ReactComponent as CustomerIcon } from '../../assets/images/Sidebar-Icon-awesome-customer.svg';
import { ReactComponent as ConfirmIcon } from '../../assets/images/Sidebar-Icon-awesome-confirm.svg';
import routes from '../../utils/routes';

type ItemType = typeof items[number];

const items = [
  {
    tooltip: '文書一覧',
    icon: FolderIcon,
    route: routes.main,
  }, {
    tooltip: 'ユーザーグループ設定',
    icon: UsersCogIcon,
    route: routes.userGroupSetting,
  }, {
    tooltip: 'ユーザー設定',
    icon: UsersIcon,
    route: {
      [UserType.Terada]: routes.adminUserListScreen,
      [UserType.General]: routes.userListScreen,
    },
  }, {
    tooltip: 'マスタ設定',
    icon: SettingsIcon,
    route: {
      [UserType.Terada]: routes.masterDataSettingAdmin,
      [UserType.General]: routes.masterDataSettingGeneral,
    },
  }, {
    tooltip: 'API連携設定',
    icon: FileSignatureIcon,
    route: routes.signSystemSettingScreen,
  }, {
    tooltip: '確定済依頼',
    icon: ConfirmIcon,
    route: routes.confirmedRequestListScreen,
  }, {
    tooltip: '操作履歴',
    icon: HistoryIcon,
    route: routes.operationLogListScreen,
  }, {
    tooltip: '取引先',
    icon: CustomerIcon,
    route: routes.supplierListScreen,
  },
];

function findDefaultItem(location: Location) {
  return items.find((item) => {
    if (typeof item.route === 'string') return item.route === location.pathname;
    if (typeof item.route === 'object') return Object.values(item.route).includes(location.pathname);
    return false;
  });
}

interface Props {
  onClearSearch: () => void;
  onClearAdvancedSearch: () => void;
}

function SideBar({ onClearSearch, onClearAdvancedSearch }: Props) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState<ItemType>(findDefaultItem(location) || items[0]);

  const organizationReserve = '組織改編予約';

  const filteredItems = useMemo(() => {
    if (getUserType(user) === UserType.Terada) {
      return items.filter((item, i) => [2, 3, 7].includes(i));
    } if (user?.managementType === ManagementType.Admin) {
      return items.filter((item, i) => [0, 1, 2, 3, 4, 5, 6].includes(i));
    } if (user?.managementType === ManagementType.General) {
      return items.filter((item, i) => [0, 5].includes(i));
    }
    return [];
  }, [user]);

  const onItemClick = useCallback((item: ItemType) => {
    onClearSearch();

    if (item.tooltip === organizationReserve) return;

    onClearAdvancedSearch();

    if (item.route && item.route !== '' && user) {
      setSelected(item);
      if (typeof item.route === 'string') navigate(item.route);
      if (item.route === location.pathname) navigate(0);
      if (Array.isArray(item.route)) {
        if (item.route.includes(location.pathname)) {
          navigate(0);
        } else {
          navigate(item.route[0]);
        }
        return;
      }
      if (typeof item.route === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const r = item.route[getUserType(user)!];
        if (r === location.pathname) {
          navigate(0);
        } else {
          navigate(r);
        }
      }
    }
  }, []);

  useEffect(() => {
    const locationToUpper = location.pathname.toUpperCase();
    const selectedItem = items.filter((item) => {
      if (Array.isArray(item.route)) {
        return item.route.includes(location.pathname);
      }
      if (typeof item.route === 'string') return item.route === locationToUpper;
      if (typeof item.route === 'object') return Object.values(item.route).includes(locationToUpper);
      return false;
    });
    setSelected(selectedItem[0]);
  }, [location.pathname]);

  return (
    <div className={styles.sideBar}>
      {
        filteredItems.map((item) => (
          <div
            className={`${styles.item} ${selected === item && styles.itemSelected}`}
            key={item.tooltip}
            onClick={() => onItemClick(item)}
            onKeyPress={() => onItemClick(item)}
            tabIndex={0}
            role="button"
          >
            <div className={item.tooltip !== organizationReserve ? styles.itemOnHover : ''}><span>{item.tooltip !== organizationReserve ? item.tooltip : ''}</span></div>
            <item.icon className={`${styles.itemImg}`} fill={selected === item ? 'white' : '#cae7f1'} />
          </div>
        ))
      }
    </div>
  );
}

export default SideBar;
