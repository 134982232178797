import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import styles from './resetPasswordSentPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import routes from '../../utils/routes';
import usePageTitle from '../../hooks/title.hook';

/**
 * パスワードリセットのメール送信完了ページ
 */
function ResetPasswordSentPage() {
  const navigate = useNavigate();

  usePageTitle('パスワードリセット');

  return (
    <CustomFrame
      title="パスワードリセットメール送信完了"
      desc={(
        <div className={styles.descTitle}>
          <p className={styles.firstParagraph}>
            パスワードリセットメールを送信しました。メールの内容に従いパスワードをリセットして下さい
          </p>
          <div className={styles.descBody}>
            <p>メールが届かない場合以下をご確認ください</p>
            <div>
              <ul>
                <li>メールアドレスが設定されているか</li>
                <li>メールアドレスに間違いがないか</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      body={<Button content="ログイン画面へ" onClick={() => navigate(routes.login)} />}
    />
  );
}

export default ResetPasswordSentPage;
