import React, { useCallback, useEffect, useRef } from 'react';
import * as PDFJS from 'pdfjs-dist';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/pdf';
import styles from './pdfPreview.module.css';

function fileToArrayBuffer(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = function onerror(ev) {
      reject(ev.target?.error);
    };

    reader.onload = function onload(ev) {
      resolve(ev.target?.result);
    };

    reader.readAsArrayBuffer(file);
  });
}

interface PdfPreviewProps {
  className?: string;
  pdfFile: File | null | undefined,
  style?: React.CSSProperties,
  pageWidth?: number,
  pageHeight?: number,
  zoom?: number,
  scaleType?: 'page-width' | 'page-fit',
}

export default function PdfPreview({
  className, pdfFile, zoom = 1, pageHeight, pageWidth, style, scaleType,
}: PdfPreviewProps) {
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const refreshPdf = useCallback(async () => {
    const pdfContainer = pdfContainerRef.current;
    if (!pdfContainer) return;
    Array.from(pdfContainer.children).forEach((child) => pdfContainer.removeChild(child));
    if (!pdfFile) return;

    const buffer = await fileToArrayBuffer(pdfFile);

    const pdf = (await PDFJS.getDocument({
      data: buffer,
      cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${PDFJS.version}/cmaps/`,
      cMapPacked: true,
    }).promise) as PDFDocumentProxy;

    const pagePromises = Array(pdf.numPages).fill(1).map((_, i) => pdf.getPage(i + 1));
    const pages = await Promise.all(pagePromises);

    pages.forEach((page) => {
      const canvas = document.createElement('canvas');
      let viewport = page.getViewport({ scale: 1 });

      if (scaleType) {
        if (scaleType === 'page-width') {
          const scale = (pdfContainer.clientWidth / viewport.width) * window.devicePixelRatio;
          viewport = page.getViewport({ scale });
        } else if (scaleType === 'page-fit') {
          const scale = Math.min(
            pdfContainer.clientWidth / viewport.width,
            pdfContainer.clientHeight / viewport.height,
          );
          viewport = page.getViewport({ scale });
        }
      }
      canvas.style.maxWidth = pageWidth ? `${pageWidth}px` : '100%';
      canvas.style.maxHeight = pageHeight ? `${pageHeight}px` : '100%';

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      pdfContainer.appendChild(canvas);
      page.render({
        canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
        viewport,
      });
    });
  }, [pdfFile, pdfContainerRef.current, scaleType]);

  useEffect(() => {
    refreshPdf();
  }, [pdfFile, refreshPdf, pdfContainerRef.current]);

  return (
    <div
      className={[styles.container, className].join(' ')}
      ref={pdfContainerRef}
      style={({ ...style, transform: `scale(${zoom})`, width: '100% !important' })}
    />
  );
}
