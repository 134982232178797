import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styles from './editableLabel.module.css';

interface EditableLabelProps {
  itemId: number
  label: string
  isEditing: boolean
  onUpdate: (itemId: number, newValue: string) => void
  onCancel: (itemId: number) => void
}

function Component(props: EditableLabelProps) {
  const {
    itemId, label, isEditing, onUpdate, onCancel,
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(label);

  const handleCancel = useCallback(() => {
    if (!isEditing) return;
    setInputValue(label);
    onCancel(itemId);
  }, [isEditing, itemId, label, onCancel]);

  const handleConfirm = useCallback(() => {
    if (!isEditing) return;
    if (label === inputValue) {
      onCancel(itemId);
      return;
    }
    if (inputValue.length === 0) {
      handleCancel();
      return;
    }
    onUpdate(itemId, inputValue);
  }, [isEditing, label, inputValue, onUpdate, itemId, onCancel, handleCancel]);

  useEffect(() => {
    if (isEditing) {
      setInputValue(label);
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [isEditing, label]);

  useEffect(() => {
    setInputValue(label);
  }, [label]);

  useEffect(() => {
    const f = (e: KeyboardEvent) => {
      if (e.defaultPrevented || !isEditing) return;

      // keyCode is deprecated, but it's the only way to detect the Enter key in some browsers
      // 229 is used to detect the composition of a character (e.g. typing in Japanese)
      // isComposing would be enough, but it's not supported in some browsers. Safari returns false even when it's composing.
      if (e.isComposing || e.keyCode === 229) return;

      if (e.code === 'Enter') {
        handleConfirm();
      } else if (e.code === 'Esc' || e.code === 'Escape') {
        handleCancel();
      }
    };

    document.addEventListener('keydown', f);
    return () => {
      document.removeEventListener('keydown', f);
    };
  }, [handleConfirm, handleCancel, isEditing]);

  useEffect(() => {
    const f = (e: MouseEvent) => {
      if (!isEditing) return;

      const node = e.target as Node;
      if (ref.current && !ref.current.contains(node)) {
        handleConfirm();
      }
    };

    document.addEventListener('mousedown', f);
    return () => {
      document.removeEventListener('mousedown', f);
    };
  }, [handleConfirm, handleCancel, isEditing]);

  return (
    <>
      <label
        hidden={isEditing}
        title={label}
        className={styles.groupLabel}
      >
        {label}
      </label>
      <input
        ref={ref}
        className={styles['group-name-editable-input']}
        hidden={!isEditing}
        type="text"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onBlur={handleConfirm}
        value={inputValue}
      />
    </>
  );
}

export default Component;
