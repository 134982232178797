import axios from 'axios';
import { User } from './user.api';

export interface OrganizationSearchForm {
  customerName: string,
  tenantCode: string,
  ownerName: string,
  contractName: string,
  email: string,
  userName: string,
  existFirstUser: boolean
}

export interface OrganizationSearchUserForm {
  customerId: number
}

export interface Organization {
  customerId: number
  customerCd: string
  customerName: string
  tenantCode: string
  existFirstUser: boolean
  ownerCd: string
  ownerName: string
  contractGroupCode: string
  contractGroupName: string
  contractCode: string
  contractName: string
  registerDate: string
  registerUser: string
  updateDate: string
  updateUser: string
}

export interface OrganizationUser {
  userId: string
  userName: string
}

export default {
  /**
   * 組織の検索を行います。
   *
   * @param form - 組織検索フォーム
   * @returns - 検索結果の組織一覧
   */
  async searchOrganizations(form: OrganizationSearchForm): Promise<Organization[]> {
    const res = await axios.get('/Organization/search', {
      params: {
        customerName: form.customerName || null,
        tenantCode: form.tenantCode || null,
        ownerName: form.ownerName || null,
        contractName: form.contractName || null,
        email: form.email || null,
        userName: form.userName || null,
        existFirstUser: form.existFirstUser !== undefined ? form.existFirstUser : undefined,
      },
    });

    const { data } = res;
    return data.customerList.map((item: Organization):Organization => ({
      ...item,
    }));
  },
  /**
   * 組織のユーザーを検索します。
   *
   * @param form - 組織ユーザー検索フォーム
   * @returns - 検索結果の組織ユーザー一覧
   */
  async searchOrganizationUser(form: OrganizationSearchUserForm): Promise<OrganizationUser[]> {
    const res = await axios.get('/Organization/searchUser', { params: form });

    const { data } = res;
    return data.userList.map((item: User):OrganizationUser => ({
      userId: item.id,
      userName: item.userName,
    }));
  },
};
