import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetUserApi, useLoginApi, useLoginSso } from '../../hooks/api/auth.hook';
import styles from './loginPage.module.css';
import LoadingOverlay from '../../components/LoadingOverlay';
import usePageTitle from '../../hooks/title.hook';
import routes from '../../utils/routes';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import globalConfig from '../../rest/config';
import BaseText from '../../components/BaseText/BaseText';
import Divider from '../../components/Divider/Divider';

/**
 * ログインページ
 */
function LoginPage() {
  // CONTEXT
  const navigator = useNavigate();

  // States
  const [tenantCode, setTenantCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSsoLogin, setIsSsoLogin] = useState(false);

  // API's
  const login = useLoginApi();
  const loginSso = useLoginSso();
  const getUser = useGetUserApi();

  usePageTitle('ログイン');
  const openMessageModal = useMessageModal();

  // Aux States
  const apiError = login.error || getUser.error;
  const loading = login.loading || getUser.loading;
  const isFormValid = !!tenantCode && !!email && !!password;

  // Handlers
  const handleLogin = async () => {
    try {
      await login.request({ email, password, tenantCode });
      const user = await getUser.request();
      if (!user.passwordUpdate) {
        navigator(routes.setFirstPassword);
      } else {
        navigator(routes.main);
      }
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isSsoLogin) {
      window.onpopstate = () => {
        setIsSsoLogin(false);
      };

      return () => {
        window.onpopstate = null;
      };
    }
  }, [isSsoLogin]);

  return (
    <div id={styles.parentframe}>
      <div className={styles.mainframe}>
        <div>
          <img alt="" src="/Login-logo.png" className={styles.image} />
        </div>
        <div className={styles.inputsframe}>
          {
            isSsoLogin ? (
              <>
                <div className={styles.textaboveinputframe}>テナントコード</div>
                <div className={styles.inputframe}>
                  <input
                    placeholder="00000000"
                    value={loginSso.form.tenantCode.value}
                    onChange={(e) => loginSso.form.tenantCode.set(e.target.value)}
                    required
                  />
                </div>
                <div className={styles.errorText}>{apiError ? apiError.message : ''}</div>
                <div className={styles.loginbuttonframe}>
                  <button
                    className={styles.loginbutton}
                    type="button"
                    onClick={loginSso.execute}
                    disabled={!loginSso.isValid}
                  >
                    ログイン
                  </button>
                </div>
                <div
                  className={styles.loginlinkframe}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className={styles['sso-link']}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSsoLogin(false);
                    }}
                  >
                    戻る
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className={styles.textaboveinputframe}>テナントコード</div>
                <div className={styles.inputframe}>
                  <input
                    onChange={(e) => setTenantCode(e.target.value)}
                    placeholder="00000000"
                    value={tenantCode}
                    required
                  />
                </div>
                <div className={styles.textaboveinputframe}>メールアドレス</div>
                <div className={styles.inputframe}>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@cloudcabinet.jp"
                    type="email"
                    value={email}
                    required
                  />
                </div>
                <div className={styles.textaboveinputframe}>パスワード</div>
                <div className={styles.inputframe}>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    value={password}
                    required
                  />
                </div>
                <div className={styles.forgotpasswordtext}>
                  <Link className={styles.forgotpasswordlink} to="/DCC12011S">
                    パスワードを忘れた方はこちら
                  </Link>
                </div>
                <div className={styles.errorText}>{apiError ? apiError.message : ''}</div>
                <div className={styles.loginbuttonframe}>
                  <button
                    className={styles.loginbutton}
                    type="button"
                    onClick={handleLogin}
                    disabled={!isFormValid}
                  >
                    ログイン
                  </button>
                </div>
                <Divider className={styles.buttonsdivider} />
                <BaseText justify="center">または</BaseText>
                <div className={styles.loginbuttonframe}>
                  <button
                    className={styles['sso-button']}
                    type="button"
                    onClick={() => {
                      login.setError(null);
                      getUser.setError(null);
                      setIsSsoLogin(true);
                      window.history.pushState(null, '', routes.login);
                    }}
                  >
                    外部IDでログイン
                  </button>
                </div>
              </>
            )
          }
        </div>
      </div>
      <div className={styles.appVersion}>
        {globalConfig.appVersion}
      </div>
      {loading && <LoadingOverlay />}
    </div>
  );
}

export default LoginPage;
