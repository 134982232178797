import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useCallback, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import mainStyles from '../../pages/main.module.css';
import Formatter from '../../utils/formatters';

interface BaseDatePickerProps {
  className?: string,
  value: DateTime | null,
  onChange: (value: DateTime | null) => void,
  onBlur?: () => void,
  inputClassName?: string,
  parentDivClassName?: string,
  placeholder?: string,
  disablePast?: boolean,
  shouldDisableDate?: (date: DateTime) => boolean,
  onFocus?: () => void,
  onInput?: (value: string) => void,
}

function BaseDatePicker({
  className = '',
  value,
  onChange,
  disablePast = false,
  shouldDisableDate,
  inputClassName = '',
  parentDivClassName = '',
  placeholder = 'yyyy/mm/dd',
  onFocus = () => ({}),
  onInput = () => ({}),
  onBlur = () => ({}),
} : BaseDatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<DateTime | null>(value);
  const inputReference = useRef<HTMLInputElement | null>(null);

  const handleClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      onBlur();
    }
    onBlur();
  }, [isOpen, onBlur]);

  const handleChange = useCallback((val: DateTime | null) => {
    setCurrentValue(val);
    if (val && val.isValid) {
      const stringDate = val ? Formatter.fromDateTimeToString(val, Formatter.defaultDateFormat) : '';
      onInput(stringDate);
      onChange(val);
      return;
    }
    if (val && !val.isValid) {
      onChange(null);
      return;
    }
    onInput('');
  }, [onChange, onInput]);
  return (
    <ClickAwayListener
      onClickAway={() => handleClose()}
      mouseEvent="onMouseDown"
    >
      <div>
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale="ja"
        >
          <DatePicker
            className={className}
            inputFormat="yyyy/MM/dd"
            value={currentValue}
            disablePast={disablePast}
            shouldDisableDate={shouldDisableDate}
            onChange={(e) => {
              handleChange(e);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <div style={{ position: 'relative' }} className={[parentDivClassName].join(' ')}>
                <div ref={inputRef} style={{ position: 'absolute', right: 8, top: 15 }}>
                  {InputProps?.endAdornment}
                </div>
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...inputProps}
                  ref={inputReference}
                  onFocus={onFocus}
                  onBlur={() => {
                    setTimeout(() => {
                      onBlur();
                    }, 100);
                  }}
                  className={[mainStyles['date-picker-input'], inputClassName].join(' ')}
                  placeholder={placeholder}
                  onChange={(e) => {
                    setTimeout(() => {
                      if (inputReference && inputReference.current && inputReference.current.defaultValue) {
                        onInput(inputReference.current.defaultValue);
                      }
                    }, 50);
                    if (inputProps && inputProps.onChange) {
                      inputProps.onChange(e);
                    }
                  }}
                />
              </div>
            )}
            open={isOpen}
            onOpen={() => setIsOpen(!isOpen)}
            onClose={() => handleClose()}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
}

export default BaseDatePicker;
