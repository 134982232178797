import Button from '../Button/button';

interface CustomToolPanelProps {
  onReset: () => void;
}

export const Icons = {
  'custom-stats': `
    <span 
      style="
        background-color: #D6D6D6; 
        width: 20px; 
        height: 45px; 
        display: flex; 
        align-items: center; 
        justify-content: center" 
      class="ag-icon ag-icon-custom-stats">
        <i style="color: white;" class="fa fa-cog"></i>
    </span>
  `,
};

export default function CustomToolPanel({ onReset }: CustomToolPanelProps) {
  return (
    <div style={{ margin: '15px' }}>
      <h2>
        カラム設定
      </h2>
      <Button onClick={onReset} size="smaller" style={{ width: '220px' }}> 初期表示に戻す</Button>
      <div style={{ margin: '10px', fontSize: '11px', color: '#8e8e8e' }}>
        カラムの表示順と表示・非表示設定を初期状態に戻します。
      </div>
    </div>
  );
}
