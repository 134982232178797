import axios from 'axios';
import TagFormat from '../../utils/tagFormat';

interface GetSystemTagForm {
  documentClassificationId: number
}
interface GetTagForm {
  documentClassificationId: number
}
export interface GetDefaultTagsForm {
  documentTypeId?: number
}

export interface Tag {
  tagLabel: string,
  format: TagFormat,
  required: boolean,
  value: string,
}

export enum TagType {
  SYSTEM = 1,
  CUSTOMER = 2,
}

export interface BaseTag {
  tagSettingId: number
  tagLabel: string
  format: TagFormat
  require: boolean
  duplicate: boolean
}

export interface DocumentTag extends BaseTag {
  tagType: TagType
}

interface DocumentTagList {
  tagList: DocumentTag[]
}

export type {
  GetSystemTagForm,
  GetTagForm,
};

export interface AllTagsDocumentType {
  documentTypeId: number,
  documentTypeName: string,
  documentClassificationName: string,
}

export interface TagWithDocumentTypeList extends DocumentTag {
  documentTypeList: AllTagsDocumentType[],
}

export interface GetAllTagsResponse {
  tagList: TagWithDocumentTypeList[],
}

export interface RegisterTagForm {
  tagLabel: string,
  format: TagFormat,
  duplicate: boolean,
}

export interface UpdateTagForm {
  tagSettingId: number,
  tagLabel: string,
  format: TagFormat,
  duplicate: boolean,
}

export default {
  /**
   * システムタグを取得する関数
   *
   * @param form - システムタグ取得フォーム
   * @returns - システムタグのリスト
   */
  async getSystemTags(form: GetSystemTagForm): Promise<DocumentTag[]> {
    const res = await axios.get<DocumentTagList>('/Tag/getSystemTags', { params: form });
    const { data } = res;
    return data.tagList.map((item) => ({ ...item, duplicate: !!item.duplicate, require: !!item.require }));
  },
  /**
   * タグを取得する関数
   *
   * @param form - タグ取得フォーム
   * @returns - タグのリスト
   */
  async getTags(form: GetTagForm): Promise<BaseTag[]> {
    const res = await axios.get<DocumentTagList>('/Tag/getTags', { params: form });
    const { data } = res;
    return data.tagList.map((item) => ({ ...item, duplicate: !!item.duplicate, require: !!item.require }));
  },
  /**
   * デフォルトタグを取得する関数
   *
   * @param form - デフォルトタグ取得フォーム
   * @returns - デフォルトタグのリスト
   */
  async getDefaultTags(form: GetDefaultTagsForm): Promise<DocumentTag[]> {
    const res = await axios.get<DocumentTagList>('/Tag/getDefaultTags', { params: form });
    const { data } = res;
    return data.tagList.map((item) => ({ ...item, duplicate: !!item.duplicate, require: !!item.require }));
  },
  /**
   * 組織タグを取得する関数
   *
   * @returns - 組織タグのリスト
   */
  async getOrganizationTags(): Promise<DocumentTag[]> {
    const res = await axios.get<DocumentTagList>('/Tag/getOrganizationTags');
    const { data } = res;
    return data.tagList.map((item) => ({ ...item, duplicate: !!item.duplicate, require: !!item.require }));
  },
  async getAllTags(): Promise<GetAllTagsResponse> {
    const res = await axios.get<GetAllTagsResponse>('/Tag/getAllTags');
    const { data } = res;
    const formattedTagList = data.tagList.map((item) => ({ ...item, duplicate: !!item.duplicate, require: !!item.require }));
    return {
      tagList: formattedTagList,
    };
  },
  async registerTag(form: RegisterTagForm): Promise<void> {
    const formattedForm = {
      ...form,
      duplicate: form.duplicate ? 1 : 0,
    };
    await axios.post('/Tag/register', formattedForm);
  },
  async deleteTag(tagSettingId: number): Promise<void> {
    await axios.post('/Tag/delete', { tagSettingId });
  },
  async updateTag(form: UpdateTagForm): Promise<void> {
    const formattedForm = {
      ...form,
      duplicate: form.duplicate ? 1 : 0,
    };
    await axios.post('/Tag/update', formattedForm);
  },
};
