import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import UserAuthorityApi, { RegisterUserAuthorityRequest } from '../../services/http/userAuthority.api';
import { UserAuthorityPresetControl } from '../../services/http/userAuthorityPreset';

/**
 * ユーザー権限を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export default function useGetUserAuthorityApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserAuthorityPresetControl[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (userId: string): Promise<UserAuthorityPresetControl[]> => {
    setLoading(true);
    try {
      const doc = await UserAuthorityApi.getUserAuthority({ userId });
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

/**
 * ユーザー権限を登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useRegisterUserAuthorityApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (r: RegisterUserAuthorityRequest): Promise<void> => {
    setLoading(true);
    try {
      const doc = await UserAuthorityApi.registerUserAuthority(r);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}
