import { CSSProperties, useMemo } from 'react';
import styles from './spinner.module.css';

interface SpinnerProps {
  className?: string,
  size?: string | number,
  borderWidth?: number,
}

function Spinner({ className = '', size, borderWidth = 5 }: SpinnerProps) {
  const style: CSSProperties = useMemo(() => {
    const newStyle: CSSProperties = {
      borderWidth,
    };
    if (size) {
      newStyle.width = size;
      newStyle.height = size;
    }
    return newStyle;
  }, [size]);
  return (
    <div className={[styles.container, className].join(' ')}>
      <div className={styles.spin} style={style} />
    </div>
  );
}

export default Spinner;
