import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManagementType, UserType, useAuthContext } from '../../store/auth.store';
import styles from './topBar.module.css';
import routes from '../../utils/routes';

function TopBarTagBulkButton() {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const canNavigateToTagBulk = useMemo(() => {
    if (!user) return false;
    const isAdmin = user.userType === UserType.General && user.managementType === ManagementType.Admin;
    if (isAdmin) return true;
    return false;
  }, [user]);

  if (!canNavigateToTagBulk) return null;
  return (
    <div className={styles.topbarLeftSection}>
      <div className={styles.topButtonMain}>
        <button type="button" onClick={() => navigate(routes.tagBulk)}>
          <img className={styles.topbarRightsectionImg} style={{ height: '28px', width: '28px' }} src="images/squares-edit.svg" alt="" />
        </button>
      </div>
    </div>
  );
}

export default TopBarTagBulkButton;
