import React, { useState } from 'react';
import styles from './CopyButton.module.css';

interface CopyButtonProps {
  textToCopy: string;
  itemId: string;
  setCopiedItemId: (itemId: string) => void;
  copiedItemId: string;
}

function CopyButton({
  textToCopy, itemId, setCopiedItemId, copiedItemId,
}: CopyButtonProps) {
  const [isClicked, setIsClicked] = useState(false);

  const copyToClipboard = (text: string, id: string) => {
    navigator.clipboard.writeText(text);
    setCopiedItemId(id);
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
      setCopiedItemId('');
    }, 1500);
  };

  return (
    <div className={styles.copyContainer}>
      <img
        className={`${styles.button} ${isClicked ? 'clicked' : ''}`}
        width={15}
        src="/images/copy.svg"
        onClick={() => copyToClipboard(textToCopy, itemId)}
        alt="Copy"
        style={{ cursor: 'pointer' }}
      />
      {copiedItemId === itemId && <div className={styles.copiedMessage}>コピーしました！</div>}
    </div>
  );
}

export default CopyButton;
