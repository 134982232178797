import { useLocation } from 'react-router-dom';
import IpList from '../../components/IpList';

interface AdminIpListScreenNavProps {
  customerId: number;
  customerCd: string;
  customerName: string;
  customerTenantCode: string;
}

function Component() {
  const { state } = useLocation();
  const {
    customerId, customerCd, customerName, customerTenantCode,
  } = state as AdminIpListScreenNavProps;
  return (
    <IpList
      customerInfo={{
        customerCd, customerName, customerTenantCode, customerId,
      }}
    />
  );
}

export default Component;
