import React, {
  Children, ReactElement, ReactNode, useEffect, useMemo, useState,
} from 'react';
import styles from './baseTab.module.css';
import mainStyles from '../../pages/main.module.css';
import { Colors, HEX } from '../../utils/theme/theme';

interface BaseItemProps {
  title: string,
  children?: ReactNode,
}

function BaseTabItem({
  title,
  children,
}: BaseItemProps) {
  return (
    <div className={styles.tabItem} style={{ overflow: 'auto' }}>
      {children}
    </div>
  );
}

interface BaseTabProps {
  backgroundColor?: keyof typeof Colors | HEX | string
  selectedColor?: keyof typeof Colors | HEX | string
  children: ReactElement[]
  modalClosed?: boolean,
  isSmallHeader?: boolean;
}

function BaseTab({
  backgroundColor = '#d5d5d5', selectedColor = '#f4f4f4', isSmallHeader = false, children, modalClosed,
}: BaseTabProps) {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (modalClosed) {
      setActiveTab(0);
    }
  }, [modalClosed]);

  const headerWidth = useMemo(() => {
    if (isSmallHeader) return '';
    return '100%';
  }, [isSmallHeader]);

  function renderTabHeader(child: ReactElement, index: number) {
    if (!React.isValidElement(child)) {
      // Ignore non-elements. This allows people to more easily inline
      // conditionals in their route config.
    }
    return (
      <div
        className={`${mainStyles['flex-1']} ${styles.headerCol}`}
        role="button"
        tabIndex={0}
        onMouseDown={() => setActiveTab(index)}
        style={{
          backgroundColor: activeTab === index ? selectedColor : backgroundColor,
          borderRadius: isSmallHeader ? '5px 5px 0 0' : 'none',
        }}
      >
        <div style={{ padding: '0 1rem' }}>
          {(child.props as BaseItemProps).title}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.headerSection}
        style={{ borderRadius: isSmallHeader ? '5px 5px 0 0' : 'none' }}
      >
        <div
          className={`${mainStyles['d-flex']} ${mainStyles['h-100']}`}
          style={{ width: headerWidth, borderRadius: isSmallHeader ? '5px 5px 0 0' : 'none' }}
        >
          {Children.map(children, renderTabHeader)}
        </div>
      </div>
      {children[activeTab]}
    </div>
  );
}

BaseTab.TabItem = BaseTabItem;
export default BaseTab;
export { BaseTabItem };
