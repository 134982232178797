import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import {
  OrganizationAuthentication,
  OrganizationAuthenticationType,
} from '../../hooks/api/organizationAuthentication.hook';

export default {

  async getSaml(): Promise<OrganizationAuthentication> {
    const res = await axios.get('/Organization/authentication/sso/get');
    const { data } = res;
    return {
      organizationId: data.organizationId,
      settingName: data.settingName,
      entityId: data.entityId,
      loginUrl: data.loginUrl,
      callbackUrl: data.callbackUrl,
      certificate: data.certificate,
      certificateExpiresAt: data.certificateExpiresAt ? new Date(data.certificateExpiresAt) : null,
      types: new Set<OrganizationAuthenticationType>(data.types),
      tenantCode: data.tenantCode,
      createdBy: {
        name: data.createdBy.name,
        at: new Date(`${data.createdBy.at}+00:00`),
      },
      updatedBy: {
        name: data.updatedBy.name,
        at: new Date(`${data.updatedBy.at}+00:00`),
      },
    };
  },

  async updateSaml(form: OrganizationAuthentication): Promise<void> {
    const res = await axios.post('/Organization/authentication/sso/update', {
      enableSso: form.types.has(OrganizationAuthenticationType.Sso),
      forceSso: form.types.has(OrganizationAuthenticationType.Sso) && form.types.size === 1,
      entityId: form.entityId,
      settingName: form.settingName,
      certificate: form.certificate,
      loginUrl: form.loginUrl,
    });
    const { data } = res;
  },
};
