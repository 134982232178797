import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import CustomFrame from '../../components/CustomFrame/customFrame';
import usePageTitle from '../../hooks/title.hook';
import routes from '../../utils/routes';
import { useMailActivateApi } from '../../hooks/api/mailActivate.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';

/**
 * メールアクティベートページ
 */
function MailActivatedPage() {
  usePageTitle('メールアクティベート');
  const navigate = useNavigate();
  const openMessageModal = useMessageModal();

  const params = new URLSearchParams(window.location.search);
  const id = params.get('uid');
  const token = params.get('token');

  // Apis
  const requestMailActivation = useMailActivateApi();
  const apiError = requestMailActivation.error;

  // Methods
  useEffectOnce(() => {
    try {
      requestMailActivation.request({ id, token });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  });

  return (
    <CustomFrame
      title={<>メールアクティベート</>}
      desc={(
        <div>
          {requestMailActivation.loading && <LoadingOverlay />}
          {requestMailActivation.error && (
          <div style={{
            color: '#E50019', fontSize: '18px',
          }}
          >
            {apiError ? apiError.message : ''}
          </div>
          )}
          {!requestMailActivation.error && (
            <p>認証に成功しました。下記のログインボタンよりログインしてください</p>
          )}
        </div>
      )}
      body={!requestMailActivation.error && <Button size="larger" content="ログイン画面へ" onClick={() => navigate(routes.login)} />}
    />
  );
}

export default MailActivatedPage;
