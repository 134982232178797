import {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './autoComplete.module.css';
import { DropDownMenuItem } from '../DropdownMenu';
import DropdownMenu2 from '../DropdownMenu2';

export type AutocompleteOptions<T> = {
  text: string,
  value: T
};

interface AutoCompleteProps<T> {
  className?: string,
  value?: T,
  placeholder?: string,
  disabled?: boolean,
  style?: CSSProperties,
  options: AutocompleteOptions<T>[],
  onTextInput?: (text: string)=> void,
  onSelect: (value: T)=> void,
}

function AutoCompleteInput<T>({
  className = '',
  value,
  placeholder = '',
  style = {},
  disabled = false,
  options,
  onTextInput,
  onSelect,
}: AutoCompleteProps<T>) {
  const [text, setCurrentValue] = useState(options?.find((item) => item.value === value)?.text || '');
  const [openMenu, setShowOptions] = useState(false);

  const onSelectDropDown = useCallback((selectedValue: T, item: DropDownMenuItem<T>) => {
    if (onTextInput) onTextInput(item.text);
    else setCurrentValue(item.text);
    onSelect(selectedValue);
    setShowOptions(false);
  }, [onTextInput, onSelect]);

  const onTextInputHandler = (newValue: string) => {
    setCurrentValue(newValue);
    if (onTextInput) onTextInput(newValue);
  };

  const filterOptions = useMemo(() => {
    if (text === '') return options;
    return options
      .filter((item) => item.text.toLowerCase().includes(text.toLowerCase()));
  }, [options, text]);

  return (
    <div className={[styles.container, className].join(' ')} style={style}>
      <DropdownMenu2
        open={openMenu}
        className={styles.inputContainer}
        options={filterOptions}
        onSelect={onSelectDropDown}
      >
        <div className={styles.margin}>
          <input
            type="text"
            value={text}
            placeholder={placeholder}
            className={[styles.input].join(' ')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onTextInputHandler(e.target.value)}
            onBlur={() => setShowOptions(false)}
            style={{
              background: 'transparent', fontSize: '14px', display: 'flex', justifyContent: 'center',
            }}
          />
        </div>
      </DropdownMenu2>
      <div
        className={[styles.inputPosIcon].join(' ')}
        onClick={() => !disabled && setShowOptions(!openMenu)}
        onKeyPress={() => !disabled && setShowOptions(!openMenu)}
        role="button"
        tabIndex={0}
      />
    </div>
  );
}

export default AutoCompleteInput;
