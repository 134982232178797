export type HEX = `#${string}`;

/**
 * テーマのインターフェース
 */
export interface ThemeType {
  colors: { [key: string]: HEX }
}

/**
 * カラーコードのenum
 */
export enum Colors {
  primary = '#54C3F1',
  lightPrimary = '#bae3f9',
  error = '#E50019',
  warning = '#FFCC00',
  gray = '#555555',
  lightGray = '#D2D2D2',
  lighterGray = '#E5E5E5',
  white = '#FFFFFF',
  black = '#000000',
  red = '#E50019',
  orange = '#ED7D31',
  lighterOrange = '#FCE4D6',
}

/**
 * テーマの変数
 */
const theme: ThemeType = {
  colors: Colors,
} as const;

/**
 * HEXカラーコードをRGBに変換する関数
 *
 * @param hex - HEXカラーコード
 * @returns - RGBの値を持つオブジェクト
 */
export function hexToRgb(hex: HEX) {
  const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];
  return {
    r: parseInt(rgb[0], 16),
    g: parseInt(rgb[1], 16),
    b: parseInt(rgb[2], 16),
  };
}

/**
 * カラーコードをグレースケールに変換する関数
 *
 * @param color - HEXカラーコード
 * @returns - グレースケールの値
 */
export function colorToGray(color: HEX) {
  const rgb = hexToRgb(color);
  return Math.round((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000;
}

/**
 * カラーに対してコントラストのあるカラーを返す関数
 *
 * @param color - HEXカラーコード
 * @returns - コントラストのあるカラー
 */
export function contrastColor(color: HEX) {
  const gray = colorToGray(color);
  return gray > 127 ? theme.colors.black : theme.colors.white;
}

const style = document.createElement('style');
style.type = 'text/css';
document.getElementsByTagName('head')[0].appendChild(style);

Object.keys(theme.colors).forEach((key) => {
  document.documentElement.style.setProperty(`--${key}`, theme.colors[key]);
  style.sheet?.insertRule(`.${key}{background-color: var(--${key})`, 0);
  style.sheet?.insertRule(`.text-${key}{color: var(--${key})`, 0);
});

export default theme;
