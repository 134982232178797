import styles from './baseSignItem.module.css';
import mainStyles from '../../main.module.css';
import Formatter from '../../../utils/formatters';

/**
 * ファイルインポート接続ステータスバーコンポーネント
 *
 * @param updateDate - 更新日時
 */
function FileImportFinishedStatusBar({ updateDate }:{ updateDate: string }) {
  return (
    <div className={[styles.textLight, mainStyles['mt-1']].join(' ')}>
      <span className={mainStyles['mr-3']}>
        最終連携日
        {'  '}
        {Formatter.toDisplayDate(Formatter.fromStringToDate(updateDate), Formatter.defaultDateTimeFormat)}
      </span>
    </div>
  );
}

export default FileImportFinishedStatusBar;
