import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import mainStyles from '../../pages/main.module.css';

interface DatePickerProps {
  value: DateTime | null;
  onChange: (value: DateTime | null) => void;
  className?: string,
  placeholder?: string,
}

function TimePickerJp(
  {
    value,
    onChange,
    className = '',
    placeholder = 'hh:mm',
  }:DatePickerProps,
) {
  return (
    <LocalizationProvider
      inputFormat="hh:mm"
      dateAdapter={AdapterLuxon}
      adapterLocale="ja"
    >
      <TimePicker
        value={value}
        onChange={(e) => onChange(e)}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 8, top: 15 }}>
              {InputProps?.endAdornment}
            </div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input ref={inputRef} {...inputProps} className={[mainStyles['time-picker-input'], mainStyles['ml-10'], className].join(' ')} placeholder={placeholder} />
          </div>
        )}
      />
    </LocalizationProvider>
  );
}

export default TimePickerJp;
