import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { DateTime } from 'luxon';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './operationLoglistScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import { User } from '../../services/http/user.api';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import Button from '../../components/Button/button';
import Input from '../../components/Input/input';
import { LogList, LogSearchForm, OperationDetailsCd } from '../../services/http/log.api';
import { useCreateLogApi, useGetOperationDetailsCdApi, useSearchLogApi } from '../../hooks/api/log.hook';
import { useSearchUserApi } from '../../hooks/api/user.hook';
import DatePickerJp from '../../components/DatePickerJp';
import TimePickerJp from '../../components/TimePickerJp';
import PaginationJp from '../../components/BaseTable/partials/PaginationJp/mannual';
import BaseTable from '../../components/BaseTable';
import ButtonTableDownload from '../../components/BaseTable/ButtonTableDownload';
import Breadcrumb from '../../components/Breadcrumb';
import Formatter from '../../utils/formatters';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { SortList } from '../../services/http/documents.api';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import LogDetailModal from './logDetailModal';
import BaseText from '../../components/BaseText/BaseText';

/**
 * セル'ボタン'のコンポーネントインターフェース
 */
export interface Props {
  /** テーブルセルレンダリングされるログデータ */
  data: LogList;
  /** "詳細" ボタンがクリックされた時に実行されるハンドラー */
  onInfoClick: (logData: LogList) => void;
}

/**
 * カスタムカラムヘッダーのコンポーネント
 */
export function CustomColumnLastHeader({ displayName }: { displayName?: string }) {
  return <div className={styles.customColumnLastHeader}>{displayName}</div>;
}

/**
 * セル'ボタン'のコンポーネント
 */
export function TableBtnCellRender({
  data, onInfoClick,
}: Props) {
  const disableButton = !data.detailFlg;

  return (
    <div>
      <button
        className={[disableButton ? styles.tableButtonDisabled : styles.tableButton, mainStyles['btn-gray']].join(' ')}
        disabled={disableButton}
        type="button"
        onClick={() => onInfoClick(data)}
      >
        詳細情報
      </button>
    </div>
  );
}

export function newlineRenderer(params: { value: any; }) {
  return (
    <span>
      {params.value}
    </span>
  );
}
/**
 * 操作ログ一覧ページ
 */
function Component() {
  usePageTitle('操作ログ一覧');
  const openMessageModal = useMessageModal();
  const { request: createLog } = useCreateLogApi();

  // Refs
  const gridRef = useRef<AgGridReact<LogList>>(null);

  // States
  const [fromDate, setFromDate] = useState<DateTime | null>(null);
  const [toDate, setToDate] = useState<DateTime | null>(null);
  const [fromTime, setFromTime] = useState<DateTime | null>(null);
  const [toTime, setToTime] = useState<DateTime | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [operationCds, setOperationCds] = useState<OperationDetailsCd[]>([]);
  const [searchForm, setSearchForm] = useState<LogSearchForm>({});
  const [userName, setUserName] = useState('');
  const [logs, setLogs] = useState<LogList[]>([]);
  const [sortList, setSortList] = useState<SortList[] | undefined>(undefined);

  // APIs
  const {
    request: searchLogRequest, total: logTotal, page: logPage, loading: searchLogApiLoading, pageLimit: logPageLimit,
  } = useSearchLogApi();
  const searchUserApi = useSearchUserApi();
  const getOperationDetailsCdApi = useGetOperationDetailsCdApi();

  const paginationTotalPages = logPageLimit > 0
    ? Math.ceil(logTotal / logPageLimit)
    : 0;

  // Methods
  const handleSelectUser = (value:string) => {
    const selectedUser:User = users.find((item) => item.id === value) || {} as User;
    setUserName(selectedUser.userName);
    // The new requeriments says to send userName instead of id on userId parameter.
    setSearchForm({ ...searchForm, userId: selectedUser.userName ?? value });
  };

  const resetForm = () => {
    setFromDate(null);
    setToDate(null);
    setFromTime(null);
    setToTime(null);
    setSearchForm({});
    setUserName('');
  };

  const setInitialFromTime = () => {
    setFromTime(DateTime.fromObject({ hour: 0, minute: 0, second: 0 }));
  };

  const setInitialToTime = () => {
    setToTime(DateTime.fromObject({ hour: 23, minute: 59, second: 0 }));
  };

  // Callbacks
  const onSearchClick = useCallback(async (form: LogSearchForm, p: number, s?: SortList[]) => {
    const req = { ...form, page: p, sortList: s };
    if (req.userId === '') {
      delete req.userId;
    }
    if (req.operationDetailsCd === '') {
      delete req.operationDetailsCd;
    }
    if (req.sortList === undefined || req.sortList?.length === 0) {
      delete req.sortList;
    }
    try {
      const getLogs = await searchLogRequest(req, p);
      setLogs(getLogs.logList);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [searchLogRequest, openMessageModal]);

  // Initial Requests
  useEffectOnce(() => {
    try {
      searchUserApi.request(searchForm).then((res) => setUsers(res));
      getOperationDetailsCdApi.request().then((res) => setOperationCds(res));
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  // Memos

  useMemo(() => {
    if (fromDate) {
      const fromDateTime = `${Formatter.fromDateTimeToString(fromDate, Formatter.hyphenDateFormat)}T${fromTime && Formatter.fromDateTimeToString(fromTime, Formatter.defaultTimeFormat)}Z`;
      setSearchForm({ ...searchForm, operationDatetimeFrom: fromDateTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, fromTime]);

  useMemo(() => {
    if (toDate) {
      const toDateTime = `${Formatter.fromDateTimeToString(toDate, Formatter.hyphenDateFormat)}T${toTime && Formatter.fromDateTimeToString(toTime, Formatter.defaultTimeFormat)}Z`;
      setSearchForm({ ...searchForm, operationDatetimeTo: toDateTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate, toTime]);

  const paginationIndex = useMemo(() => (logPage - 1) * logPageLimit, [logPage, logPageLimit]);

  const [selectedLog, setSelectedLog] = useState<LogList | null>(null);

  const columns = useMemo(() => [
    AGUtils.colAutoIncrement('id', 'No', paginationIndex),
    AGUtils.colDate('operationDatetime', '操作日時'),
    AGUtils.colUsername('userName', '操作ユーザー名'),
    AGUtils.colEmail('email', 'メールアドレス'),
    AGUtils.colDefault('ipAddress', 'IPアドレス', 170),
    AGUtils.colDefault('operationDetailsCd', '操作内容', 250),
    {
      field: 'targetContent',
      headerName: '対象',
      cellRenderer: newlineRenderer,
      autoHeight: true,
      width: 200,
      resizable: true,
      cellClass: 'textFormat',
    },
    {
      field: 'buttons',
      headerName: '詳細',
      resizable: true,
      flex: 1,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onInfoClick: (data: LogList) => {
          setSelectedLog({ ...data });
        },
      },
      cellStyle: { display: 'flex', justifyContent: 'flex-end' },
      minWidth: 150,
      headerComponentFramework: CustomColumnLastHeader,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  // Effects
  useEffectOnce(() => {
    createLog(LogFormName.OperationLogListScreen, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={styles.mainframe}>
        <Breadcrumb crumbs={[{ label: document.title }]} />
        <div className={styles.mainFrameBody}>
          <div className={styles['mainframe-leftsection']}>
            <header>
              検索条件
            </header>
            <div>
              <label>操作日時</label>
            </div>
            <div className={styles['date-field']}>
              <label>FROM</label>
              <DatePickerJp
                value={(fromDate)}
                onChange={(newValue) => {
                  setInitialFromTime();
                  setFromDate(newValue);
                }}
              />
              <TimePickerJp
                value={fromTime}
                onChange={(newValue) => {
                  setFromTime(newValue);
                }}
              />
              {' '}
            </div>
            <div className={styles['date-field']}>
              <label>TO</label>
              <DatePickerJp
                value={toDate}
                onChange={(newValue) => {
                  setInitialToTime();
                  setToDate(newValue);
                }}
              />
              <TimePickerJp
                value={toTime}
                onChange={(newValue) => {
                  setToTime(newValue);
                }}
              />
            </div>
            <div>
              <label>操作ユーザー</label>
              <AutoCompleteInput
                value={userName}
                options={users.map((item) => ({ text: item.userName, value: item.id }))}
                onSelect={handleSelectUser}
                onTextInput={(value) => {
                  setUserName(value);
                  setSearchForm({ ...searchForm, userId: value });
                }}
                deselectOption
              />
            </div>
            <div>
              <label>メールアドレス</label>
              <Input className={`${styles['w-100']}`} value={searchForm.email || ''} onChange={(value) => setSearchForm({ ...searchForm, email: value })} />
            </div>
            <div>
              <label>操作内容</label>
              <AutoCompleteInput
                value={searchForm.operationDetailsCd}
                options={operationCds.map((item) => ({ text: item.operationDetailsCd, value: item.operationDetailsCd }))}
                onSelect={(value:string) => setSearchForm({ ...searchForm, operationDetailsCd: value })}
                onTextInput={(value) => setSearchForm({ ...searchForm, operationDetailsCd: value })}
                deselectOption
                dropDownItemStyle={{ minWidth: '280px' }}
              />
            </div>
            <footer>
              <Button
                className={mainStyles['ml-10']}
                size="smaller"
                color="lighterGray"
                onClick={() => resetForm()}
              >
                条件リセット
              </Button>
              <Button
                className={mainStyles['mr-10']}
                size="smaller"
                onClick={() => {
                  onSearchClick(searchForm, 1, sortList);
                }}
                loading={searchLogApiLoading}
              >
                検索
              </Button>
            </footer>
          </div>
          <div className={styles['mainframe-rightsection']}>
            <div className={styles['mainframe-rightsection-top']}>
              <div className={mainStyles['mr-3']}>
                <BaseText size="smMd" color="var(--danger)">※最終操作日時が2024年7月23日以前の操作は詳細情報が表示されません。</BaseText>
              </div>
              <ButtonTableDownload
                tableRef={gridRef}
                logFormName={LogFormName.OperationLogListScreen}
              />
            </div>
            <div className="ag-theme-alpine" style={{ height: '100%' }}>
              <BaseTable<LogList>
                tableRef={gridRef}
                formName="operationLogTable"
                rowData={logs}
                columnDefs={columns}
                headerHeight={42}
                groupHeaderHeight={42}
                suppressRowTransform
                pagination
                paginationPageSize={logPageLimit}
                suppressPaginationPanel
                sideBar
                createLogOnDownloadTableData={LogFormName.OperationLogListScreen}
                sortChangedHandler={(s) => {
                  setSortList(s);
                  if (sortList === undefined) {
                    return;
                  }
                  const f = { ...searchForm, sortList: s };
                  setSearchForm(f);
                  if (logPage === paginationTotalPages) {
                    return;
                  }
                  onSearchClick(f, 1, s);
                }}
              />
            </div>

            { logPage > 0 && (
            <PaginationJp
              totalPage={paginationTotalPages}
              pageInfo={{ pageLimit: logPageLimit, page: logPage, total: logTotal }}
              onBtFirst={() => {
                if (logPage === 1) {
                  return;
                }
                const f = { ...searchForm, page: 1 };
                setSearchForm(f);
                onSearchClick(f, 1, sortList);
              }}
              onBtLast={() => {
                if (logPage === paginationTotalPages) {
                  return;
                }
                const f = { ...searchForm, page: paginationTotalPages };
                setSearchForm(f);
                onSearchClick(f, paginationTotalPages, sortList);
              }}
              onBtNext={() => {
                const p = logPage + 1;
                if (p > paginationTotalPages) {
                  return;
                }
                const f = { ...searchForm, page: p };
                setSearchForm(f);
                onSearchClick(f, p, sortList);
              }}
              onBtPrevious={() => {
                const p = logPage - 1;
                if (p === 0) {
                  return;
                }
                const f = { ...searchForm, page: p };
                setSearchForm(f);
                onSearchClick(f, p, sortList);
              }}
            />
            )}
          </div>
          {selectedLog && <LogDetailModal logId={selectedLog.logId as number} handleCancel={() => { setSelectedLog(null); }} />}
        </div>
      </div>
    </MainFrame>
  );
}

export default Component;
