import { Buffer } from 'buffer';

export default {
  /**
   * ファイルをbase64文字列に変換します。
   *
   * @param file - 変換するファイル
   * @returns - base64文字列を返すプロミス
   */
  toBase64: (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      try {
        const r = reader.result as string;
        resolve(r.split(',')[1]);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
  }),

  /**
   * base64文字列をファイルに変換します。
   *
   * @param base64 - 変換するbase64文字列
   * @param filename - 生成するファイル名
   * @param options - FilePropertyBag オプション
   * @returns Fileインスタンスを返す
   */
  fromBase64: (base64: string, filename = 'file', options?: FilePropertyBag): File => new File([Buffer.from(base64, 'base64')], filename, options),

  /**
   * PDFファイルをブラウザでプレビューします。
   *
   * @param pdf - プレビューするPDFファイル
   */
  previewPdf: (pdf: File) => {
    // @ts-ignore
    const url = window.URL.createObjectURL(pdf);
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.href = url;
    a.click();
  },
};
