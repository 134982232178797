import { useEffect, useState } from 'react';
import { ApiError } from '../../services/http';
import supplierAPI, {
  ControlList,
  CreateFirstUserForm, OrganizationAuthorityType,
  RegisterAuthorityForm,
  Supplier,
  SupplierDetails,
  SupplierListSearchForm,
  SupplierPresetTemplate,
} from '../../services/http/supplier.api';
import useToggle from '../useToggle.hook';

/**
 * 供給者リスト検索APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useSearchSupplierListApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Supplier[] | null>([]);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: SupplierListSearchForm): Promise<Supplier[]> {
    setLoading(true);
    try {
      const doc = await supplierAPI.search(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, data, error, request,
  };
}

/**
 * 供給者詳細取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetSupplierDetailsApi(organizationId: number) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SupplierDetails | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: { id: number; }): Promise<SupplierDetails> {
    setLoading(true);
    try {
      const doc = await supplierAPI.getSupplierDetails(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (organizationId) {
      request({ id: organizationId });
    }
  }, [organizationId]);

  return {
    loading, data, error, request,
  };
}

/**
 * 最初のユーザを登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `create`:登録を行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useCreateFirstUserApi() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function create(form: CreateFirstUserForm): Promise<void> {
    setLoading(true);
    try {
      return await supplierAPI.createFirstUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, create,
  };
}

/**
 * 供給者プリセットテンプレートを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 */
export function useGetSupplierPresetTemplateApi() {
  const [data, setData] = useState<SupplierPresetTemplate[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: { organizationId: number }): Promise<SupplierPresetTemplate[]> {
    try {
      const doc = await supplierAPI.getSupplierPresetTemplateList(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }
  return {
    data, error, request,
  };
}

/**
 * 供給者権限を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 */
export function useGetSupplierAuthorityApi() {
  const [data, setData] = useState<ControlList[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: { organizationId: number }): Promise<ControlList[]> {
    try {
      const doc = await supplierAPI.getAuthorityList(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    }
  }
  return {
    data, error, request,
  };
}

/**
 * 権限を登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `create`:登録を行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useRegisterAuthorityApi(organizationId: number) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [hasSsoSupport, toggleSsoSupport, setSsoSupport] = useToggle();

  const supplierGetDetails = useGetSupplierDetailsApi(organizationId);

  async function create(form: RegisterAuthorityForm): Promise<void> {
    setLoading(true);
    try {
      const res = await supplierAPI.registerAuthority(form);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (supplierGetDetails.data) {
      setSsoSupport(supplierGetDetails.data.hasSsoSupport);
    }
  }, [supplierGetDetails.data]);

  return {
    loading,
    error,
    create,
    supplierGetDetails,
    form: {
      hasSsoSupport: {
        value: hasSsoSupport,
        toggle: toggleSsoSupport,
      },
    },
  };
}
