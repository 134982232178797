import axios from 'axios';

export interface SwitchUserForm {
  customerId: string
  changeUserId: string
}

export interface SwitchUserResponse {
  token: string
}

export default {
  /**
   * ユーザーを切り替える関数
   *
   * @param form - ユーザー切り替えフォーム
   * @returns - 切り替え結果
   */
  switch: async (form: SwitchUserForm): Promise<SwitchUserResponse> => {
    const res = await axios.post('/UserSwitching/switchUser', form);
    const { data } = res;
    return data;
  },
  /**
   * ユーザーの切り替えをキャンセルする関数
   *
   * @returns - キャンセル結果
   */
  cancelSwitch: async (): Promise<SwitchUserResponse> => {
    const res = await axios.post('/UserSwitching/cancelSwitchUser');
    const { data } = res;
    return data;
  },
};
