import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import ExportLabelApi, { ExportLabelForm, ExportLabelResponse } from '../../services/http/exportLabel.api';

/**
 * ラベルのエクスポートAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
// eslint-disable-next-line import/prefer-default-export
export function useExportLabelApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [data, setData] = useState<ExportLabelResponse | null>(null);

  const request = useCallback(async (form: ExportLabelForm): Promise<ExportLabelResponse> => {
    setLoading(true);
    try {
      const res = await ExportLabelApi.export(form);
      setData(res);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request, data,
  };
}
