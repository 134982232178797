import axios from 'axios';
import { SystemType } from '../../pages/signSystemSettingScreen/signSystemConstants';

export interface DigitalSignItemKeyInfo {
  key: string
  value: string
}

export interface DigitalSignItem {
  organizationDigitalSignId: number
  name: string
  systemType: SystemType
  keyInfo: DigitalSignItemKeyInfo[]
  registerDate: string,
  registerUser: string
}

export interface DigitalSignItemForm {
  organizationDigitalSignId: number,
  name: string,
  systemType: SystemType | null,
  keyInfo: {
    key: string,
    value: string,
  }[],
  registerDate: string,
  registerUser: string,
}

export interface DigitalSignGetStatusResponse {
  digitalSignList: DigitalSignItem[]
}

export interface DigitalSignRegisterRequest {
  name: string
  systemType: SystemType
  keyParamJson: string
}

export interface DigitalSignUpdateRequest {
  name: string
  organizationDigitalSignId: number
  systemType: SystemType
  keyParamJson: string
}

export interface GetFileImportStatusResponse {
  status: string
  result: string
  cause: string
  updateDate: string
}

export interface DigitalSignRemoveForm {
  organizationDigitalSignId: number
  systemType: SystemType
}

export interface DigitalSignExportApiKeyResponse {
  apiKey: string,
  registerDate: string,
  registerUser: string
}

export interface DigitalSignReExportApiKeyForm {
  organizationDigitalSignId: number,
  name: string,
}

export interface ExecFileImportForm {
  organizationDigitalSignId: number,
  systemType: SystemType,
}

export interface GetFileImportStatusForm {
  organizationDigitalSignId: number,
  systemType: SystemType,
}

export default {

  /**
   * デジタル署名のステータスを取得する関数
   *
   *  @returns - デジタル署名のステータスに関するレスポンス
   */
  async Get(): Promise<DigitalSignItem[]> {
    const res = await axios.get<DigitalSignGetStatusResponse>('/DigitalSign/search');
    const { data } = res;
    return data.digitalSignList;
  },

  /**
   * デジタル署名のステータスを登録する関数
   *
   * @param request - デジタル署名のステータス登録リクエスト
   * @returns - デジタル署名のステータス登録に関するレスポンス
   */
  async register(request: DigitalSignRegisterRequest):Promise<void> {
    const res = await axios.post('/DigitalSign/register', request);
    return res.data;
  },

  /**
   * デジタル署名のステータスを更新する関数
   *
   * @param request - デジタル署名更新リクエスト
   * @returns - デジタル署名更新に関するレスポンス
   */
  async update(request: DigitalSignUpdateRequest): Promise<void> {
    const res = await axios.post('/DigitalSign/update', request);
    return res.data;
  },

  /**
   * デジタル署名のステータスを削除する関数
   *
   * @param request - デジタル署名削除リクエスト
   * @returns - デジタル署名削除に関するレスポンス
   */
  async remove(request: DigitalSignRemoveForm): Promise<void> {
    const res = await axios.post('/DigitalSign/remove', request);
    return res.data;
  },

  /**
   * 文書ステータスインポートを取得する関数
   *
   * @param systemType - システム種別
   * @returns - 文書ステータスインポートに関するレスポンス
   * @throws ApiError
   */
  async getFileImportStatus(form: GetFileImportStatusForm): Promise<GetFileImportStatusResponse> {
    const res = await axios.get('/DigitalSign/getFileImportStatus', { params: form });
    const { data } = res;
    return {
      status: data.status,
      result: data.result,
      cause: data.cause,
      updateDate: data.updateDate,
    };
  },

  /**
   * ファイルのインポートを実行する関数
   *
   * @param request - システム種別
   * @returns - ファイルのインポートに関するレスポンス
   */
  async execFileImport(request: ExecFileImportForm): Promise<string> {
    const res = await axios.post('/DigitalSign/execFileImport', request);
    return res.data;
  },

  /**
   * APIキーのエキスポートを実行する関数
   *
   * @returns - APIキーのエキスポートに関するレスポンス
   */
  async exportApiKey({ name }: { name: string }): Promise<DigitalSignExportApiKeyResponse> {
    const res = await axios.get<DigitalSignExportApiKeyResponse>('/DigitalSign/exportApiKey', { params: { name } });
    const { data } = res;
    return data;
  },

  async reExportApiKey(form: DigitalSignReExportApiKeyForm): Promise<DigitalSignExportApiKeyResponse> {
    const res = await axios.get<DigitalSignExportApiKeyResponse>('/DigitalSign/reExportApiKey', { params: form });
    const { data } = res;
    return data;
  },

};
