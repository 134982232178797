import axios from 'axios';
import { ControlCodeItem } from '../../utils/controlCode';

export interface FolderSearchForm {
  organizationChangeHistoryId?: number,
}

export interface Folder {
  id: number,
  name: string,
  authorityList: ControlCodeItem[],
  reservedFlg: boolean,
}

export interface RoleList {
  roleList: RoleListItem[]
}

export interface RoleListItem {
  roleId: number,
  roleName: string,
  controlList: ControlCodeItem[]
}

export interface FolderCreateForm {
  name: string,
}
export interface FolderUpdateForm {
  id: number,
  name: string,
}
export interface FolderDeleteForm {
  id: number,
}

export default {
  /**
   * フォルダを検索します。
   *
   * @param form - 検索フォーム
   * @returns - 検索結果のフォルダの配列
   */
  async search(form: FolderSearchForm): Promise<Folder[]> {
    const res = await axios.get('/Folder/search', {
      params: {
        organizationChangeHistoryId: form.organizationChangeHistoryId,
      },
    });
    const { data } = res;
    return data.folderList.map((item: {
      folderId: number,
      folderName: string,
      authorityList: ControlCodeItem[],
      reservedFlg: number,
    }): Folder => ({
      id: item.folderId,
      name: item.folderName,
      authorityList: item.authorityList,
      reservedFlg: item.reservedFlg === 1,
    }
    ));
  },
  /**
   * フォルダを作成します。
   *
   * @param form - フォルダ作成フォーム
   * @returns - 空のPromise
   */
  async create(form: FolderCreateForm): Promise<void> {
    const res = await axios.post('/Folder/create', {
      folderName: form.name,
    });
    return res.data;
  },
  /**
   * 指定されたフォルダを更新します。
   *
   * @param form - フォルダ更新フォーム
   * @returns - 更新後のフォルダ情報
   */
  async update(form: FolderUpdateForm): Promise<void> {
    const res = await axios.post('/Folder/update', { folderId: form.id, folderName: form.name });
    return res.data;
  },
  /**
   * 指定されたフォルダを削除します。
   *
   * @param form - フォルダ削除フォーム
   * @returns void
   */
  async delete(form: FolderDeleteForm): Promise<void> {
    const res = await axios.post('/Folder/delete', { folderId: form.id });
    return res.data;
  },
  /**
   * フォルダに紐付くロールの一覧を取得します。
   *
   * @returns - ロールの一覧
   */
  async roles(): Promise<RoleListItem[]> {
    const res = await axios.get<RoleList>('/Folder/getRole');
    const { data } = res;
    return data.roleList;
  },
};
