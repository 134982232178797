import { useCallback, useState } from 'react';

export default function useAsyncState<Fn extends (...params: any[]) => Promise<any>>(asyncFunc: Fn) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Awaited<ReturnType<Fn>> | null>(null);

  const execute = useCallback(async (...form: Parameters<Fn>): Promise<ReturnType<Fn>> => {
    setLoading(true);
    try {
      const res = await asyncFunc(...form);
      setData(res);
      return res;
    } catch (e) {
      setError(e as Error);
      throw e;
    } finally {
      setLoading(false);
    }
  }, [asyncFunc]);

  return {
    loading, error, data, execute,
  };
}
