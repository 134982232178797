import React, { useCallback } from 'react';
import Input from '../Input/input';
import TagFormat from '../../utils/tagFormat';
import { Tag } from '../../services/http/tag.api';
import Formatter from '../../utils/formatters';
import BaseDatePicker from '../BaseDatePicker';

interface BaseTagInputProps {
  tag: Tag,
  onChange: (value: string, isValid: boolean) => void,
  onFocus: () => void,
  onBlur: () => void,
  className?: string,
}

function BaseTagInput({
  tag, onChange, onFocus, onBlur, className = '',
}: BaseTagInputProps) {
  const isNumberValid = useCallback((value: string) => {
    const validNumberRegex = /^[-+]?[0-9]*\.?[0-9]+$/;

    if (validNumberRegex.test(value)) {
      const parsedNumber = parseFloat(value);
      if (!Number.isNaN(parsedNumber) && Number.isFinite(parsedNumber)) {
        return true;
      }
    }
    return false;
  }, []);
  const handleChange = useCallback((value: string, isValid: boolean) => {
    onChange(value, isValid);
  }, [onChange]);

  if (tag.format === TagFormat.DATE) {
    return (
      <div className={className}>
        <BaseDatePicker
          inputClassName={className}
          value={tag.value ? Formatter.fromFormatToDateTime(tag.value, 'yyyy/MM/dd') : null}
          onFocus={onFocus}
          onChange={(value) => {
            if (!value) {
              handleChange('', false);
              return;
            }
            const stringDate = Formatter.fromDateTimeToString(value, Formatter.defaultDateFormat);
            handleChange(stringDate, value.isValid);
          }}
          onInput={(value) => {
            if (value === '') {
              handleChange(value, true);
              return;
            }
            const date = Formatter.fromFormatToDateTime(value, Formatter.defaultDateFormat);
            if (!date.isValid) {
              handleChange(value, false);
              return;
            }
            handleChange(value, true);
          }}
          onBlur={onBlur}
        />
      </div>
    );
  }
  return (
    <Input
      className={className}
      value={tag.value}
      placeholder={TagFormat.getPlaceholder(tag.format)}
      onChange={(value) => {
        if (tag.format === TagFormat.NUMBER) {
          const isValid = (value === '' ? true : isNumberValid(value));
          handleChange(value, isValid);
          return;
        }
        handleChange(value, true);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

export default BaseTagInput;
