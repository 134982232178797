import { useCallback } from 'react';

/**
 * ファイルをダウンロードするフック
 *
 * @returns ファイルをダウンロードする関数
 */
// eslint-disable-next-line import/prefer-default-export
export function useDownloadFile() {
  return useCallback((file: File, pdfName?: string) => {
    const link = document.createElement('a');
    link.download = pdfName || file.name;
    link.href = URL.createObjectURL(file);
    link.click();
  }, []);
}
