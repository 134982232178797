import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextareaAutosize from '@mui/base/TextareaAutosize';
import TagFormat from '../../utils/tagFormat';
import { Tag } from '../../services/http/tag.api';
import DatePickerJp from '../DatePickerJp';
import Formatter from '../../utils/formatters';
import mainStyles from '../../pages/main.module.css';

interface TagInputProps {
  tag: Tag,
  onChange: (value: string) => void,
  onFocus?: () => void,
  className?: string,
}

function TagInput({
  tag, onChange, onFocus = () => ({}), className = '',
}: TagInputProps) {
  if (tag.format === TagFormat.DATE) {
    return (
      <div className={className}>
        <DatePickerJp
          inputClassName={className}
          value={tag.value ? Formatter.fromFormatToDateTime(tag.value, 'yyyy/MM/dd') : null}
          onFocus={onFocus}
          onChange={() => {}}
          onInput={(value) => onChange(value)}
        />
      </div>
    );
  }
  return (
    <TextareaAutosize
      style={{ minHeight: '25px', paddingTop: '4px' }}
      className={[mainStyles.textareaAutosize, className].join(' ')}
      value={tag.value}
      placeholder={TagFormat.getPlaceholder(tag.format)}
      onChange={(e) => onChange(TagFormat.validate(tag.format, e.target.value))}
      onBlur={(e) => onChange(TagFormat.validate(tag.format, e.target.value))}
      onFocus={onFocus}
    />
  );
}

export default TagInput;
