import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import UserApi, {
  User,
  UserDeleteForm,
  UserDetail,
  UserFromCSVList,
  UserRegisterForm,
  UserSearchForm,
  UserUpdateForm,
} from '../../services/http/user.api';

/**
 * ユーザを検索APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useSearchUserApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: UserSearchForm): Promise<User[]> => {
    setLoading(true);
    try {
      const doc = await UserApi.search(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

/**
 * ユーザー詳細を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useUserGetDetailApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserDetail | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: { id: string }): Promise<UserDetail> => {
    setLoading(true);
    try {
      const doc = await UserApi.getDetail(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

/**
 * ユーザー登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useUserRegisterApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: UserRegisterForm): Promise<void> => {
    setLoading(true);
    try {
      return await UserApi.register(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * ユーザー更新APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useUserUpdateApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: UserUpdateForm): Promise<void> {
    setLoading(true);
    try {
      return await UserApi.update(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}

/**
 * ユーザー削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDeleteUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: UserDeleteForm): Promise<void> {
    setLoading(true);
    try {
      await UserApi.delete(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}

/**
 * CSVからユーザー登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useRegisterUsersFromCsvApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function store(form: UserFromCSVList): Promise<void> {
    setLoading(true);
    try {
      return await UserApi.registerUsersFromCsv(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    store, error, loading,
  };
}
