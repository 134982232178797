import {
  ChangeEvent,
  CSSProperties,
  HTMLProps,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './autoComplete.module.css';
import DropdownMenu, { DropDownMenuItem } from '../DropdownMenu';
import mainStyles from '../../pages/main.module.css';

interface AutoCompleteProps<T> {
  className?: string,
  posIconClassName? :string,
  inputClassName?: string,
  value?: string,
  placeholder?: string,
  disabled?: boolean,
  renderPreInput?: string,
  renderInput?: (props: HTMLProps<HTMLInputElement>) => JSX.Element,
  style?: CSSProperties,
  noTextUpdateOnSelected?: boolean
  ref?: RefObject<HTMLInputElement>,
  searchFields?: (keyof T)[],
  options: DropDownMenuItem<T>[],
  onTextInput?: (text: string)=> void,
  onSelect: (value: T)=> void,
  onFocus?: () => void,
  onMenuOpen?: () => void,
  deselectOption?: boolean,
  onDeselect?: ()=> void,
  menuStyle?: CSSProperties,
  dropDownItemStyle?: CSSProperties,
  searchIcon?: boolean,
  menuRight?: boolean,
}

function AutoCompleteInput<T>({
  className = '',
  posIconClassName = '',
  inputClassName = '',
  value = '',
  placeholder = '',
  style = {},
  disabled = false,
  renderInput = (props: HTMLProps<HTMLInputElement>) => (
    <input
      type="text"
  // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={{
        background: 'transparent', fontSize: '14px', display: 'flex', justifyContent: 'center',
      }}
    />
  ),
  renderPreInput = '',
  searchFields,
  options,
  noTextUpdateOnSelected,
  onTextInput,
  onSelect,
  deselectOption,
  onDeselect,
  onFocus = () => {},
  onMenuOpen = () => {},
  menuStyle,
  dropDownItemStyle,
  searchIcon,
  menuRight = false,
}: AutoCompleteProps<T>) {
  const [currentValue, setCurrentValue] = useState(value);
  const [openMenu, setShowOptions] = useState(false);
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  const onSelectDropDown = useCallback((selectedValue: T, item: DropDownMenuItem<T>) => {
    if (onTextInput && !noTextUpdateOnSelected) onTextInput(item.text);
    else setCurrentValue(item.text);
    onSelect(selectedValue);
    setShowOptions(false);
  }, [onTextInput, noTextUpdateOnSelected, onSelect]);

  const onTextInputHandler = (newValue: string) => {
    setCurrentValue(newValue);
    if (onTextInput) onTextInput(newValue);
  };

  const filterOptions = useMemo(() => options.filter((item) => {
    if (!item.text) return false;
    if (searchFields) {
      return searchFields.some((key: keyof T) => String(item.value[key]).includes(currentValue));
    }
    return item.text.includes(currentValue);
  }), [options, currentValue, searchFields]);

  const resetInput = () => {
    if (onDeselect) onDeselect();
    setCurrentValue('');
    if (onTextInput) onTextInput('');
  };

  useEffect(() => {
    if (value.trim().length === 0) {
      setCurrentValue('');
      return;
    }
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (openMenu && !menuIsOpened) onMenuOpen();
    setMenuIsOpened(openMenu);
  }, [menuIsOpened, onMenuOpen, openMenu]);

  return (
    <div className={[styles.container, className].join(' ')} style={style}>
      {searchIcon ? (
        <div className={mainStyles['ml-2']}>&#xF002;</div>
      ) : null}
      {renderPreInput
        && (
        <div className={styles.preInput}>
          {renderPreInput}
        </div>
        )}
      <DropdownMenu
        open={openMenu}
        className={styles.inputContainer}
        disabled={disabled}
        options={filterOptions}
        onSelect={onSelectDropDown}
        onOutsideClick={() => setShowOptions(false)}
        onDeselect={() => { resetInput(); }}
        deselectOption={deselectOption}
        menuStyle={menuStyle}
        dropDownItemStyle={dropDownItemStyle}
        right={menuRight}
      >
        <div className={styles.margin}>
          {renderInput({
            value: currentValue,
            className: [styles.input, inputClassName].join(' '),
            placeholder,
            disabled,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onTextInputHandler(e.target.value),
            onBlur: () => setShowOptions(false),
            onFocus: () => onFocus(),
          })}
        </div>
      </DropdownMenu>
      <div className={[styles.inputPosIcon, posIconClassName].join(' ')} onClick={() => !disabled && setShowOptions(!openMenu)} onKeyPress={() => !disabled && setShowOptions(!openMenu)} role="button" tabIndex={0} />
    </div>
  );
}

export default AutoCompleteInput;
