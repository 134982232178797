import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './topBar.module.css';
import SearchInputTop from '../SearchInputTop';
import DropdownMenu from '../DropdownMenu';
import routes from '../../utils/routes';
import AlertModal from '../AlertModal';
import { useGetUserApi, useLogoutApi } from '../../hooks/api/auth.hook';
import { ManagementType, useAuthContext, UserType } from '../../store/auth.store';
import { CancelUserSwitchHookApi } from '../../hooks/api/userSwitchHook';
import { useOutsideClick } from '../../hooks/window.hook';
import UserDetailModal from '../../pages/userListScreen/userDetailModal';
import { useUserGetDetailApi } from '../../hooks/api/user.hook';
import { User } from '../../services/http/user.api';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogFormName, LogControlName } from '../../utils/log.utils';

interface DropDownItem {
  text: string,
  value: string
}

const dropdownItems:DropDownItem[] = [
  { text: '個人設定', value: '' },
  { text: 'パスワード変更', value: routes.changePassword },
  { text: 'ログアウト', value: routes.login },
];

function SwitchingUserStatus(props: { organizationName: string, switchUserName: string, handleClick: () => void, handleClickOutSide: () => void }) {
  const {
    organizationName, switchUserName, handleClick, handleClickOutSide,
  } = props;

  const { ref } = useOutsideClick<HTMLDivElement>(() => {
    handleClickOutSide();
  });

  return (
    <div ref={ref} className={styles['switch-status-bar']} role="presentation" onClick={handleClick}>
      <span>
        {organizationName}
        {' '}
        {switchUserName}
        としてログイン中
      </span>
    </div>
  );
}

function TopBar({ children }: { children?: React.ReactNode }) {
  const [alertModal, setAlertModal] = useState(false);
  const [menuSingle, setMenuSingle] = useState(0);
  const [switchUserBarClicked, setSwitchUserBarClicked] = useState(false);
  const [detailedUser, setDetailedUser] = useState<User | null>(null);
  const [modalUser, setModalUser] = useState<User | null>(null);

  const logoutApi = useLogoutApi();
  const { request: cancelUserSwitchHookApiRequest } = CancelUserSwitchHookApi();
  const { request: getUserApiRequest } = useGetUserApi();
  const { request: userGetDetailApiRequest } = useUserGetDetailApi();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { request: createLog } = useCreateLogApi();

  const handleCancelSwitch = async () => {
    // request cancel switching user
    try {
      await cancelUserSwitchHookApiRequest();
      navigate(routes.adminUserListScreen);
    } catch (e) {
      console.error(e);
    }

    // get User again
    try {
      await getUserApiRequest();
    } catch (e) {
      console.error(e);
    }
  };

  const getDetailedUser = async () => {
    if (!user) return;
    try {
      const data = await userGetDetailApiRequest({ id: user.userId });
      setDetailedUser(data);
    } catch (e) {
      console.error(e);
    }
  };

  const onUserDropdownSelect = (route: string, value:DropDownItem) => {
    if (value.text === 'ユーザー切替解除') {
      handleCancelSwitch();
      return;
    }

    if (value.text === '個人設定') {
      setModalUser(detailedUser);
      return;
    }

    if (!route) return;
    if (route === routes.login) {
      setAlertModal(true);
    } else {
      navigate(route);
    }
  };

  const onLogoutModalConfirm = async () => {
    await createLog(LogFormName.Common, LogControlName.Create);

    logoutApi.request();
    window.location.href = routes.login;
  };

  const handleSwitchUserStatusClick = () => {
    setMenuSingle((a) => a + 1);
    setSwitchUserBarClicked(true);
  };

  const dropdownOptions:DropDownItem[] = useMemo(() => {
    if (user?.userType === UserType.Terada && user?.changeUser == null) {
      return [...dropdownItems, { text: 'ユーザー切替', value: routes.userSwitchScreen }];
    }

    if (user?.changeUser != null) {
      return [...dropdownItems, { text: 'ユーザー切替解除', value: '' }];
    }

    return [...dropdownItems];
  }, [user]);

  const isSwitching = user?.changeUser != null;

  useEffect(() => {
    getDetailedUser();
  }, []);

  return (
    <>
      <div className={[styles.topBar, isSwitching ? styles.topBarIsSwitching : ''].join(' ')}>
        {children}

        <div className={styles.topbarRightsection}>
          { isSwitching && <SwitchingUserStatus handleClickOutSide={() => setSwitchUserBarClicked(false)} handleClick={handleSwitchUserStatusClick} organizationName={user.changeUser?.customerName ?? ''} switchUserName={user.changeUser?.userName ?? ''} />}

          <div className={styles.topButtonMain}>
            <button type="button" onClick={() => window.open(process.env.REACT_APP_HELP_PAGE, '_blank')}>
              <img className={styles.topbarRightsectionImg} src="images/question.svg" alt="" />
            </button>
          </div>

          <div className={styles.topButtonMain}>
            <DropdownMenu toggleSingal={menuSingle} options={dropdownOptions} left menuStyle={switchUserBarClicked ? { right: '6.2rem' } : undefined} onSelect={onUserDropdownSelect}>
              <button type="button">
                <img className={styles.topbarRightsectionImg} src="images/person.svg" alt="" />
              </button>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {modalUser && <UserDetailModal user={modalUser} handleCancel={() => setModalUser(null)} handleSuccess={() => {}} />}
      <AlertModal
        open={alertModal}
        text="ログアウトします。よろしいですか？"
        onConfirm={onLogoutModalConfirm}
        onCancel={() => setAlertModal(false)}
      />
    </>
  );
}

export default TopBar;
