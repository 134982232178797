import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import OrganizationApi, {
  Organization, OrganizationSearchForm, OrganizationSearchUserForm, OrganizationUser,
} from '../../services/http/organization.api';

/**
 * 組織APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useOrganizationApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: OrganizationSearchForm): Promise<Organization[]> => {
    setLoading(true);
    try {
      return await OrganizationApi.searchOrganizations(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request,
  };
}

/**
 * ユーザー組織APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
export function useOrganizationUserApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: OrganizationSearchUserForm): Promise<OrganizationUser[]> => {
    setLoading(true);
    try {
      return await OrganizationApi.searchOrganizationUser(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request,
  };
}
