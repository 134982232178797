import { useState, useCallback } from 'react';
import { ApiError } from '../../services/http';
import TagBulkApi, { TagBulk, GetTagBulkForm, UpdateTagBulkForm } from '../../services/http/tagBulk.api';

export function useGetTagBulkApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TagBulk[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: GetTagBulkForm): Promise<TagBulk[]> => {
    setLoading(true);
    try {
      const doc = await TagBulkApi.getTagBulk(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

export function useUpdateTagBulkApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: UpdateTagBulkForm): Promise<void> => {
    setLoading(true);
    try {
      await TagBulkApi.updateTagBulk(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}
