import { memo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import DropdownMenu, { DropDownMenuItem } from '../../../DropdownMenu';

export interface OptionsCellRenderProps<T> extends ICellRendererParams {
  options: DropDownMenuItem<T>[]
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onSelect: (data: any, value: T) => void,
}

const OptionsCellRender = memo((props: OptionsCellRenderProps<any>) => (
  <DropdownMenu
    left
    options={props.options}
    onSelect={(value) => props.onSelect(props.data, value)}
  >
    <img
      src="/images/Three-Dots.svg"
      alt=""
      style={{
        width: '20px',
        height: '15px',
        cursor: 'pointer',
      }}
    />
  </DropdownMenu>
));

export default OptionsCellRender;
