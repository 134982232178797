import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';

/**
 * HTTPリクエストを実行するためのカスタムフック
 *
 * @template Response - APIからのレスポンスの型
 * @template Form - APIに送信するフォームの型
 * @param api - APIへのリクエストを行う関数
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `exec()`:APIにリクエストを行う関数
 *   - `request()`:APIにリクエストを行う関数
 */

// eslint-disable-next-line import/prefer-default-export
export function useHttp<Response, Form>(api: (form: Form)=> Promise<Response>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [data, setData] = useState<Response | null>(null);

  const exec = useCallback(async (form: Form): Promise<Response> => {
    setLoading(true);
    try {
      const res = await api(form);
      setData(res);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, [api]);

  const request = useCallback((form: Form): Promise<Response> => exec(form), [exec]);
  return {
    loading, error, data, exec, request,
  };
}
