import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './permissionTemplateCreationPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import BaseTable from '../../components/BaseTable';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import useGetUserAuthorityPresetApi, {
  useDeleteUserAuthorityPresetApi,
  useGetUserAuthorityPresetDetail,
  usePostUserAuthorityPresetApi,
  useUpdateUserAuthorityPresetApi,
} from '../../hooks/api/userAuthorityPreset.hook';
import {
  DeleteUserAuthorityPresetRequest,
  GetUserAuthorityPresetDetail,
  PostUserAuthorityPresetRequest,
  UpdateUserAuthorityPresetRequest,
  UserAuthorityPreset,
  UserAuthorityPresetControl,
} from '../../services/http/userAuthorityPreset';
import AlertModal, { alertModalInitialState, CloseAlertModal } from '../../components/AlertModal';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import Formatter from '../../utils/formatters';
import ControlCode from '../../utils/controlCode';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 権限情報のインターフェース
 */
export interface PrivilegeItem {
  id: string,
  name: string
}

/**
 * 権限情報のインターフェース
 */
export interface Privilege {
  id: string,
  children: PrivilegeItem[]
}

/**
 * 操作権限のプリセット生成
 */
export const presetListControlItems: Privilege[] = [
  {
    id: 'a',
    children: ControlCode.getNonDocumentControlCodes().map((d) => ({ name: ControlCode.describe(d), id: d })),
  },
];

/**
 * 操作権限リスト表示のコンポーネント
 *
 * @param inputControlList - 操作権限リスト
 * @param handleControlListChange - 操作権限リスト変更ハンドラー
 */
export function renderControlList(inputControlList: UserAuthorityPresetControl[] | null, handleControlListChange: (id: string) => void) {
  return presetListControlItems.map((item) => (
    <div key={item.id}>
      {item.children.map((i, _k) => (
        <div key={i.id} className={[styles.text, mainStyles['d-flex'], mainStyles['align-items-center'], _k > 0 ? mainStyles['mt-10'] : ''].join(' ')}>
          <input
            className={styles.checkbox}
            type="checkbox"
            onChange={() => {
              if (inputControlList != null) {
                handleControlListChange(i.id);
              }
            }}
            checked={inputControlList?.some((c) => c.controlCode === i.id) ?? false}
          />
          {i.name}
        </div>
      ))}
    </div>
  ));
}

/**
 * 権限テンプレート作成ページのプロップスインターフェース
 */
export interface FormProps {
  /** 編集中の権限プリセット */
  editingAuthorityPreset: UserAuthorityPreset | null,
  /** 編集中の権限プリセット詳細 */
  editingAuthorityPresetDetail: GetUserAuthorityPresetDetail | null,
  /** 表示フラグ */
  visible: boolean,
  /** 登録ハンドラー */
  handleRegister: (data: PostUserAuthorityPresetRequest) => void
  /** 更新ハンドラー */
  handleUpdate: (data: UpdateUserAuthorityPresetRequest) => void
  /** 削除ハンドラー */
  handleDelete: (data: DeleteUserAuthorityPresetRequest) => void
  /** キャンセルハンドラー */
  handleCancel: () => void
  /** クリアフラグ */
  clearSingle: number
}

/**
 * 権限テンプレート作成ページのフォームコンポーネント
 */
function Form({
  editingAuthorityPreset,
  editingAuthorityPresetDetail,
  visible,
  handleRegister,
  handleUpdate,
  handleDelete,
  handleCancel,
  clearSingle,
}: FormProps): JSX.Element {
  const title = useMemo(() => {
    if (editingAuthorityPreset == null) {
      return '権限テンプレ―ト登録';
    }
    return '権限テンプレ―ト詳細';
  }, [editingAuthorityPreset]);

  const [inputPresetName, setInputPresetName] = useState('');
  const [inputPresetExplanation, setInputPresetExplanation] = useState('');
  const [inputControlList, setInputControlList] = useState<UserAuthorityPresetControl[]>([]);

  useEffect(() => {
    if (editingAuthorityPreset == null) {
      setInputPresetName('');
      setInputPresetExplanation('');
      setInputControlList([]);
      return;
    }

    if (editingAuthorityPreset.presetName != null) {
      setInputPresetName(editingAuthorityPreset.presetName);
    }

    if (editingAuthorityPreset.presetExplanation != null) {
      setInputPresetExplanation(editingAuthorityPreset.presetExplanation);
    }

    if (editingAuthorityPreset.controlList != null) {
      setInputControlList([...editingAuthorityPreset.controlList]);
    }
  }, [editingAuthorityPreset]);

  const clearForm = useCallback(() => {
    setInputPresetName('');
    setInputPresetExplanation('');
    setInputControlList([]);
    return clearSingle;
  }, [clearSingle]);

  useEffect(() => {
    clearForm();
  }, [handleCancel]);

  useEffect(() => {
    clearForm();
  }, [clearForm]);

  const handleCancelForm = () => {
    setInputPresetName(editingAuthorityPreset?.presetName ?? '');
    setInputPresetExplanation(editingAuthorityPreset?.presetExplanation ?? '');
    setInputControlList([...editingAuthorityPreset?.controlList ?? []]);
  };

  const handleConfirm = () => {
    if (editingAuthorityPreset == null) {
      handleRegister({
        presetName: inputPresetName,
        presetExplanation: inputPresetExplanation,
        controlList: inputControlList,
      });
      return;
    }

    handleUpdate({
      id: editingAuthorityPreset.id,
      presetName: inputPresetName,
      presetExplanation: inputPresetExplanation,
      controlList: inputControlList,
    });
  };

  return (
    <div style={{ visibility: visible === true ? 'visible' : 'hidden' }} className={styles['mainframe-rightsection']}>
      <div className={[styles['rightframe-header'], mainStyles['pl-3']].join(' ')}>
        {title}
      </div>
      <div className={styles['rightframe-body']}>
        <div className={styles['rightframe-body-content']}>
          <div className={[styles.label, mainStyles['mt-20px']].join(' ')}>
            権限テンプレ―ト基本情報
          </div>
          <div className={[styles.label, mainStyles['mt-10'], mainStyles['d-flex'], mainStyles['ml-20px'], mainStyles['align-items-center']].join(' ')}>
            テンプレート
            <div className={[mainStyles['text-danger'], mainStyles['ml-10'], mainStyles['text-small']].join(' ')}>※</div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mb-20px'], mainStyles['ml-20px']].join(' ')}>
            <input className={[mainStyles.input, mainStyles['w-50']].join(' ')} value={inputPresetName} onChange={(e) => setInputPresetName(e.target.value)} />
          </div>
          <div className={[styles.label, mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['ml-20px']].join(' ')}>
            説明
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['ml-20px']].join(' ')}>
            <input className={[mainStyles.input, mainStyles['w-75']].join(' ')} value={inputPresetExplanation} onChange={(e) => setInputPresetExplanation(e.target.value)} />
          </div>
          <div className={[styles.label, mainStyles['mt-20px']].join(' ')}>
            権限テンプレ―ト設定
          </div>
          <div className={mainStyles['ml-20px']}>
            {
              renderControlList(inputControlList, (code: string) => {
                setInputControlList((list) => {
                  if (list.some((l) => l.controlCode === code)) {
                    return list.filter((l) => l.controlCode !== code);
                  }
                  return list.concat({ controlCode: code });
                });
              })
            }
          </div>
          { editingAuthorityPreset != null && (
          <>
            <div className={[styles.label, mainStyles['mt-20px'], mainStyles['mr-20px']].join(' ')}>
              登録更新情報
            </div>
            <div className={mainStyles['d-flex']}>
              <div className={[mainStyles['mr-5'], mainStyles['ml-20px'], styles.text].join(' ')}>
                <div>登録者</div>
                <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                  {editingAuthorityPresetDetail?.registerUser}
                </div>
                <div>更新者</div>
                <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                  {editingAuthorityPresetDetail?.updateUser}
                </div>
              </div>
              <div className={[mainStyles['ml-5'], styles.text].join(' ')}>
                <div>登録日時</div>
                <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                  {editingAuthorityPresetDetail && Formatter.toDisplayDate(editingAuthorityPresetDetail.registerDate, Formatter.defaultDateTimeFormat)}
                </div>
                <div>更新日時</div>
                <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                  {editingAuthorityPresetDetail && Formatter.toDisplayDate(editingAuthorityPresetDetail.updateDate, Formatter.defaultDateTimeFormat)}
                </div>
              </div>
            </div>
          </>
          )}
        </div>
      </div>
      <div className={[styles.footer, mainStyles['border-top']].join(' ')}>
        <div className={styles.footers}>
          <div>
            <Button size="smaller" color="lighterGray" onClick={handleCancel}>キャンセル</Button>
          </div>
          <div>
            {editingAuthorityPreset != null && <button type="button" className={[mainStyles.button, mainStyles['btn-danger'], mainStyles['mr-10']].join(' ')} onClick={() => handleDelete({ id: editingAuthorityPreset.id })}>削除</button>}
            <button type="button" className={[mainStyles.button, mainStyles['btn-primary']].join(' ')} onClick={handleConfirm}>保存</button>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * セルなしコンポーネント
 */
export function NoCell(props: { data: UserAuthorityPreset }) {
  const { data } = props;
  return <span>{data.id}</span>;
}

/**
 * テンプレート編集セルのプロップスインターフェース
 */
export interface TemplateEditCellProps {
  /** 使用されるデータ */
  data: UserAuthorityPreset,
  /** 変更ボタンクリック時のハンドラ */
  handleEdit: (data: UserAuthorityPreset) => void
}

/**
 * テンプレート編集セルコンポーネント
*/
export function TemplateEditCell({ data, handleEdit: handleClick }: TemplateEditCellProps) {
  return (
    <div className={[mainStyles['text-center']].join(' ')}>
      <button type="button" onClick={() => handleClick(data)} className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}>変更</button>
    </div>
  );
}

/**
 * 権限テンプレート作成ページ
 */
function Component() {
  usePageTitle('権限テンプレート作成');
  const [formVisible, setFormVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState<{ open: boolean, title: string, handleComfirm?:() => void }>({ open: false, title: '' });
  const [modal, setModal] = useState(alertModalInitialState);
  const [closeModalInfo, setCloseModalInfo] = useState<{ open: boolean, title: string, handleCancel:() => void }>({
    open: false,
    title: '',
    handleCancel() {
      setCloseModalInfo((c) => ({ ...c, open: false }));
    },
  });
  const gridRef = useRef<AgGridReact<UserAuthorityPreset>>(null);

  const [templateDataSearchValue, setTemplateDataSearchValue] = useState<string>('');
  const [editingAuthorityPreset, setEditingAuthorityPreset] = useState<UserAuthorityPreset | null>(null);
  const [authorityPresetDetails, setAuthorityPresetDetails] = useState<GetUserAuthorityPresetDetail | null>(null);
  const [clearFormSigle, setClearFormSingle] = useState(0);

  const getUserAuthorityPresetApi = useGetUserAuthorityPresetApi();
  const getUserAuthorityPresetDetailApi = useGetUserAuthorityPresetDetail();
  const postUserAuthorityPresetApi = usePostUserAuthorityPresetApi();
  const updateUserAuthorityPresetApi = useUpdateUserAuthorityPresetApi();
  const deleteUserAuthorityPresetApi = useDeleteUserAuthorityPresetApi();

  const { request: createLog } = useCreateLogApi();
  const { request: getUserAuthorityPresetApiRequest } = getUserAuthorityPresetApi;
  const { request: getUserAuthorityPresetDetailApiRequest } = getUserAuthorityPresetDetailApi;
  const { request: postUserAuthorityPresetRequest } = postUserAuthorityPresetApi;
  const { request: updateUserAuthorityPresetRequest } = updateUserAuthorityPresetApi;
  const { request: deleteUserAuthorityPresetRequest } = deleteUserAuthorityPresetApi;

  // get list
  useEffect(() => {
    const f = async () => {
      await getUserAuthorityPresetApiRequest();
    };
    f();
  }, [getUserAuthorityPresetApiRequest]);

  const handleOnEditClick = () => {
    setFormVisible(true);
  };

  const templateColumns = useMemo((): ColDef[] => [
    {
      headerName: 'No',
      resizable: true,
      field: 'id',
      width: 70,
      cellRenderer: NoCell,
    },
    {
      width: 80,
      cellRenderer: TemplateEditCell,
      cellRendererParams: {
        handleEdit: (data: UserAuthorityPreset) => {
          const f = async () => {
            const doc = await getUserAuthorityPresetDetailApiRequest(data.id);
            setAuthorityPresetDetails(doc);
            return null;
          };

          if (editingAuthorityPreset && editingAuthorityPreset?.id !== data.id) {
            setModalInfo({
              open: true,
              title: '入力内容は廃棄されます。遷移してよろしいですか?',
              handleComfirm: () => {
                setEditingAuthorityPreset(data);
                handleOnEditClick();
                f();
              },
            });
            return;
          }

          setEditingAuthorityPreset(data);
          handleOnEditClick();
          f();
        },
      },
    },
    {
      headerName: 'テンプレート名',
      resizable: true,
      field: 'presetName',
      cellClass: 'textFormat',
      maxWidth: 180,
    },
    {
      headerName: '説明',
      resizable: true,
      cellClass: 'textFormat',
      field: 'presetExplanation',
      flex: 1,
    },
  ], [getUserAuthorityPresetDetailApiRequest, editingAuthorityPreset]);

  const templateDatasSearchOptions = useMemo(() => [{ text: ' ', value: null }, ...getUserAuthorityPresetApi.data.map((u) => ({
    text: u.presetName,
    value: u,
  }))], [getUserAuthorityPresetApi.data]);

  const handleTemplateDataOptionSelected = useCallback((value: UserAuthorityPreset | null) => {
    if (value == null) {
      setTemplateDataSearchValue('');
      return;
    }
    if (value?.presetName == null) {
      setTemplateDataSearchValue('');
      return;
    }
    setTemplateDataSearchValue(value.presetName);
  }, []);

  const displayTemplateData = useMemo<(UserAuthorityPreset)[]>(() => getUserAuthorityPresetApi.data.filter((f) => f.presetName.includes(templateDataSearchValue)), [getUserAuthorityPresetApi.data, templateDataSearchValue]);
  const editingTemplateDataDetail = useMemo(() => {
    if (editingAuthorityPreset == null) {
      return null;
    }
    return authorityPresetDetails;
  }, [authorityPresetDetails, editingAuthorityPreset]);

  const handleRegister = (requestData: PostUserAuthorityPresetRequest) => {
    setModalInfo({
      open: true,
      title: '登録してよろしいですか？',
      handleComfirm: () => {
        postUserAuthorityPresetRequest(requestData).then(() => {
          getUserAuthorityPresetApiRequest();
          setEditingAuthorityPreset(null);
          setFormVisible(false);
          setClearFormSingle((s) => s + 1);
        }).catch((e) => {
          setCloseModalInfo((i) => ({
            ...i, title: e.message, open: true,
          }));
        });
      },
    });
  };

  const handleUpdate = (requestData: UpdateUserAuthorityPresetRequest) => {
    setModalInfo({
      open: true,
      title: '更新してよろしいですか？',
      handleComfirm: () => {
        updateUserAuthorityPresetRequest(requestData).then(() => {
          getUserAuthorityPresetApiRequest();
          setEditingAuthorityPreset(null);
          setFormVisible(false);
        }).catch((e) => {
          setCloseModalInfo((i) => ({
            ...i, title: e.message, open: true,
          }));
        }).finally(() => {
          getUserAuthorityPresetDetailApiRequest(requestData.id).then((doc) => {
            setAuthorityPresetDetails(doc);
          });
        });
      },
    });
  };

  const handleDelete = (requestData: DeleteUserAuthorityPresetRequest) => {
    setModalInfo({
      open: true,
      title: '削除してよろしいですか？',
      handleComfirm: () => {
        deleteUserAuthorityPresetRequest(requestData).then(() => {
          getUserAuthorityPresetApiRequest();
          setEditingAuthorityPreset(null);
          setFormVisible(false);
        }).catch((e) => {
          setCloseModalInfo((i) => ({
            ...i, title: e.message, open: true,
          }));
        }).finally(() => {
          setFormVisible(false);
          getUserAuthorityPresetApi.request();
        });
      },
    });
  };

  const handleCancel = useCallback(() => {
    setModal({
      open: true,
      text: '入力内容が廃棄されます。よろしいですか？',
      onConfirm: () => {
        setModal({ ...modal, open: false });
        setEditingAuthorityPreset(null);
        setFormVisible(false);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [modal]);

  const handleStartRegister = () => {
    setFormVisible(true);
    setEditingAuthorityPreset(null);
  };

  useEffectOnce(() => {
    createLog(LogFormName.PermissionTemplateCreation, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[
            { label: 'マスタ管理', route: routes.masterDataSettingGeneral },
            { label: '権限テンプレート' }]}
          />
          <div className={styles.mainFrameBody}>
            <div className={styles['mainframe-leftsection']}>
              <div className={[mainStyles['text-right'], mainStyles['mr-10']].join(' ')}>
                {/* <input type="text" className={[mainStyles.input, mainStyles['input-search'], mainStyles['w-25'], mainStyles['mt-10']].join(' ')} placeholder=" 検索" /> */}
                <div className={styles.templateSearchInputContainer}>
                  <AutoCompleteInput<UserAuthorityPreset | null>
                    placeholder="検索"
                    className={[styles.templateSearchInput, mainStyles['w-25'], mainStyles['mt-10']].join(' ')}
                    posIconClassName={styles.groupSearchInputPosIcon}
                    inputClassName={styles.groupSearchInput}
                    options={templateDatasSearchOptions}
                    onSelect={handleTemplateDataOptionSelected}
                    onTextInput={(s) => setTemplateDataSearchValue(s)}
                    value={templateDataSearchValue}
                  />
                </div>
                <div className={[mainStyles['mt-10'], mainStyles['mb-10']].join(' ')}>
                  <Button size="smaller" color="lighterGray" onClick={() => handleStartRegister()}>テンプレートを登録</Button>
                </div>
              </div>
              <div className={mainStyles['border-top']} />
              <div className={[mainStyles['mr-10'], mainStyles['mt-10'], mainStyles['ml-10'], styles['margin-bottom']].join(' ')} style={{ height: '100%', overflow: 'auto', flexFlow: '1' }}>
                <BaseTable<UserAuthorityPreset>
                  tableRef={gridRef}
                  formName="permissionCreateTemplateTable"
                  columnDefs={templateColumns}
                  rowData={displayTemplateData}
                  headerHeight={25}
                  createLogOnDownloadTableData={LogFormName.PermissionTemplateCreation}
                />
              </div>
            </div>
            <Form
              editingAuthorityPreset={editingAuthorityPreset}
              editingAuthorityPresetDetail={editingTemplateDataDetail}
              visible={formVisible}
              handleRegister={handleRegister}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              clearSingle={clearFormSigle}
            />
            <AlertModal
              open={modalInfo.open}
              text={modalInfo.title}
              confirmText="はい"
              cancelText="いいえ"
              onConfirm={() => {
                if (modalInfo.handleComfirm != null) {
                  modalInfo.handleComfirm();
                  setModalInfo((i) => ({ ...i, open: false }));
                }
              }}
              onCancel={() => {
                setModalInfo((i) => ({ ...i, open: false }));
              }}
            />
            <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} textCenter />
            <CloseAlertModal open={closeModalInfo.open} text={closeModalInfo.title} onCancel={closeModalInfo.handleCancel} />
          </div>
        </div>
      )}
    />
  );
}

export default Component;
