import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import DigitalSignApis, {
  DigitalSignExportApiKeyResponse,
  DigitalSignReExportApiKeyForm,
  DigitalSignRegisterRequest,
  DigitalSignRemoveForm,
  DigitalSignUpdateRequest,
  ExecFileImportForm,
  GetFileImportStatusForm,
  GetFileImportStatusResponse,
} from '../../services/http/digitalSign.api';

/**
 * デジタルサイン現在の状態取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalSignGetStatusApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async () => {
    setLoading(true);
    try {
      return await DigitalSignApis.Get();
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    request, loading, error,
  };
}

/**
 * デジタルサイン登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalSignRegisterApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (r: DigitalSignRegisterRequest):Promise<void> => {
    setLoading(true);
    try {
      return await DigitalSignApis.register(r);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, loading, error,
  };
}

/**
 * デジタルサイン更新APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalSignUpdateApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (r: DigitalSignUpdateRequest):Promise<void> => {
    setLoading(true);
    try {
      return await DigitalSignApis.update(r);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, loading, error,
  };
}

/**
 * デジタルサイン削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalSignRemoveApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (form: DigitalSignRemoveForm): Promise<void> => {
    setLoading(true);
    try {
      return await DigitalSignApis.remove(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, loading, error,
  };
}

/**
 * デジタルサインファイルのインポートステータスAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalGetFileImportStatusApi() {
  const [data, setData] = useState<GetFileImportStatusResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (form: GetFileImportStatusForm) => {
    setLoading(true);
    try {
      const res = await DigitalSignApis.getFileImportStatus(form);
      setData(res);
      return res;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, data, loading, error,
  };
}

/**
 * デジタル実行ファイルのインポートAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalExecFileImportApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (form: ExecFileImportForm):Promise<void> => {
    setLoading(true);
    try {
      const res = await DigitalSignApis.execFileImport(form);
      setData(res);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, data, loading, error,
  };
}

/**
 * デジタルエクスポートAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDigitalExportApiKeyApi() {
  const [data, setData] = useState<DigitalSignExportApiKeyResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (name: string):Promise<DigitalSignExportApiKeyResponse | null> => {
    setLoading(true);
    try {
      const d = await DigitalSignApis.exportApiKey({ name });
      setData(d);
      return d;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, data, loading, error,
  };
}

export function useDigitalReExportApiKeyApi() {
  const [data, setData] = useState<DigitalSignExportApiKeyResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  const request = useCallback(async (form: DigitalSignReExportApiKeyForm):Promise<DigitalSignExportApiKeyResponse | null> => {
    setLoading(true);
    try {
      const d = await DigitalSignApis.reExportApiKey(form);
      setData(d);
      return d;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    request, data, loading, error,
  };
}

export default useDigitalSignRegisterApi;
