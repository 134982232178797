import { useMemo } from 'react';
import styles from './docRegisterSortChip.module.css';

interface Props {
  label?: string;
  icon?: string;
  value?: string | number;
  isButton?: boolean;
  isFocused?: boolean;
  onClick?: () => void;
}

export default function DocRegisterSortChip({
  value = '',
  label = '',
  icon,
  isButton = true,
  isFocused = false,
  onClick = () => {},
}: Props) {
  const defaultChipBackgroundColor = '#F5F5F5';
  const focusedChipBackgroundColor = '#E5E5E5';

  const chipBackgroundColor = useMemo(() => {
    if (!isButton) return 'transparent';
    if (isFocused) return focusedChipBackgroundColor;
    if (!isFocused) return 'transparent';
    return defaultChipBackgroundColor;
  }, [isButton, isFocused]);

  return (
    <div
      style={{
        cursor: isButton ? 'pointer' : 'default',
        backgroundColor: chipBackgroundColor,
      }}
      className={styles.chip}
      role="button"
      tabIndex={0}
      onClick={() => onClick()}
    >
      <div className={styles.chipLabelSide}>
        {icon ? (
          <>
            <img className={styles.chipIcon} src={icon} alt="" />
            <span className={styles.px}>:</span>
          </>
        ) : (
          <>
            <span title={label} className={styles.chipLabel}>{label}</span>
            <span className={styles.px}>:</span>
          </>
        )}
      </div>
      <div className={styles.chipValueSide}>
        <span title={value.toString()} className={styles.chipValue}>
          {`${value} 件`}
        </span>
      </div>
    </div>
  );
}
