import axios from 'axios';
import Formatter from '../../utils/formatters';

export interface ReserveManagementSearchForm {
  dueDate_From?: string,
  dueDate_To?: string,
  requestType?: string,
}

export interface ReserveManagementList {
  reserveNo: string,
  type: string,
  schDate: string | Date,
  name: string,
  postNo: string,
  address1: string,
  address2: string,
  address3: string,
  staffName: string,
  phone: string,
  trdReserveNotes: string,
  customerNotes: string,
  trdReserveManagementCd2: string,
  createdBy: string,
  createDatetime: string | Date,
}

export interface ReserveManagementDetailList {
  ccControlNumber: number,
  documentName: string,
  documentClassificationName: string,
  documentTypeName: string,
  barcodePrinting?: string,
  itemCode?: string,
  folderName: string,
  registerUser: string,
}

export default {
  /**
   * 予約の管理情報を検索する関数
   *
   * @param form - 予約管理情報検索フォーム
   * @returns - 予約管理情報の一覧
   */
  async search(form: ReserveManagementSearchForm): Promise<ReserveManagementList[]> {
    const res = await axios.get('/ReserveManagement/search', { params: form });
    const { data } = res;
    return data.requestList.map((item: {
      reserveNo: string,
      type: string,
      schDate: string,
      name: string,
      postNo: string,
      address1: string,
      address2: string,
      address3: string,
      staffName: string,
      phone: string,
      trdReserveNotes: string,
      customerNotes: string,
      trdReserveManagementCd2: string,
      createdBy: string,
      createDatetime: string,
    }): ReserveManagementList => ({
      reserveNo: item.reserveNo,
      type: item.type,
      schDate: Formatter.fromStringToDate(item.schDate),
      name: item.name,
      postNo: item.postNo,
      address1: item.address1,
      address2: item.address2,
      address3: item.address3,
      staffName: item.staffName,
      phone: item.phone,
      trdReserveNotes: item.trdReserveNotes,
      customerNotes: item.customerNotes,
      trdReserveManagementCd2: item.trdReserveManagementCd2,
      createdBy: item.createdBy,
      createDatetime: Formatter.fromStringToDate(item.createDatetime),
    }));
  },
  /**
   * 予約の管理情報の詳細を取得する関数
   *
   * @param requestNumber -  予約番号
   * @returns - 予約管理情報の詳細の一覧
   */
  async getReserveManagementDetail(requestNumber: number): Promise<ReserveManagementDetailList[]> {
    const res = await axios.get('/ReserveManagement/searchDetail', {
      params: { requestNumber },
    });
    const { data } = res;
    return data.documentList.map((item: {
      ccControlNumber: number,
      documentName: string,
      documentClassificationName: string,
      documentTypeName: string,
      barcodePrinting?: string,
      itemCode?: string,
      folderName: string,
      registerUser: string,
    }): ReserveManagementDetailList => ({
      ccControlNumber: item.ccControlNumber,
      documentName: item.documentName,
      documentClassificationName: item.documentClassificationName,
      documentTypeName: item.documentTypeName,
      barcodePrinting: item.barcodePrinting,
      itemCode: item.itemCode,
      folderName: item.folderName,
      registerUser: item.registerUser,
    }));
  },
};
