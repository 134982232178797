import axios from 'axios';

interface SendEmailForm {
  tenantCode: string,
  email: string
}
interface ResettingForm {
  id: string
  password: string
  token: string
}

export type {
  SendEmailForm,
  ResettingForm,
};

export default {
  /**
    * 電子メールを送信する関数
    *
    * @param form - 送信フォーム
    * @returns - 空のプロミス
   */
  async sendEmail(form: SendEmailForm): Promise<void> {
    const res = await axios.post('/Password/sendMail', form);
    const { data } = res;
    return data;
  },

  /**
    * パスワードをリセットする関数
    *
    * @param form - リセットフォーム
    * @returns - 空のプロミス
   */
  async resetting(form: ResettingForm): Promise<void> {
    const res = await axios.post('/Password/resetting', form);
    const { data } = res;
    return data;
  },
};
