import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import UserGroupApi, { UserGroup, UserGroupSearchForm, UserGroupUpdateForm } from '../../services/http/userGroup.api';

/**
 * ユーザーグループ検索APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
// eslint-disable-next-line import/prefer-default-export
export function useSearchUserGroupApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserGroup[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: UserGroupSearchForm): Promise<UserGroup[]> => {
    setLoading(true);
    try {
      const doc = await UserGroupApi.search(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

/**
 * ユーザーグループを更新APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
// eslint-disable-next-line import/prefer-default-export
export function useUpdateUserGroupApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string>('');
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: UserGroupUpdateForm): Promise<string> => {
    setLoading(true);
    try {
      const doc = await UserGroupApi.update(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}
