import React, {
  CSSProperties, RefObject, useEffect, useRef, useState,
} from 'react';
import * as uuid from 'uuid';
import styles from './dropdownMenu.module.css';
import { useOutsideClick } from '../../hooks/window.hook';

export type DropDownMenuItem<T> = {
  text: string,
  value: T
};

interface DropdownMenuProps<T> {
  className?: string,
  style?: CSSProperties,
  open?: boolean,
  children: JSX.Element,
  options?: DropDownMenuItem<T>[],
  onSelect: (value: T, item: DropDownMenuItem<T>) => void
  onClose?: () => void
  dropDownItemStyle?: CSSProperties
}

export default function DropdownMenu2<T>({
  className = '',
  children,
  onSelect,
  options = [],
  style = {},
  open,
  onClose,
  dropDownItemStyle,
}: DropdownMenuProps<T>) {
  const [openMenu, setOpenMenu] = useState(false);

  const activatorRef = useRef<HTMLDivElement>(null);

  const { ref } = useOutsideClick(() => {
    setOpenMenu(false);
  });

  useEffect(() => {
    if (!openMenu || !ref.current || !activatorRef.current) return;

    const menuRect = ref.current.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    const activatorRect = activatorRef.current.getBoundingClientRect();
    const isDropdownBottomOffScreen = menuRect.bottom > screenHeight;
    const screenWidth = window.innerWidth;

    if (isDropdownBottomOffScreen) {
      ref.current.style.bottom = `${activatorRect.height}px`;
      ref.current.style.top = 'auto';
    } else {
      ref.current.style.bottom = '';
      ref.current.style.top = '';
    }

    if (menuRect.right > screenWidth) {
      ref.current.style.right = '0px';
      ref.current.style.left = 'auto';
    } else if (menuRect.left < 0) {
      ref.current.style.left = '0px';
      ref.current.style.right = 'auto';
    } else {
      ref.current.style.right = '';
      ref.current.style.left = '';
    }
  }, [openMenu, options]);

  const onMenuItemClick = (item: DropDownMenuItem<T>) => {
    setOpenMenu(false);
    onSelect(item.value, item);
  };
  const onKeyHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') setOpenMenu(!openMenu);
  };

  useEffect(() => {
    setOpenMenu(!!open);
  }, [open]);

  useEffect(() => {
    if (onClose && openMenu) onClose();
  }, [openMenu]);

  return (
    <div className={[styles.container, className].join(' ')} style={style}>
      <div
        ref={activatorRef}
        onClick={() => setOpenMenu(!openMenu)}
        onKeyPress={(e) => onKeyHandler(e)}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      { openMenu && (
      <div
        className={[styles.menu, styles.center].join(' ')}
        ref={ref as RefObject<HTMLDivElement>}
      >
        {options.map((item, i) => (
          <div
            className={styles.item}
            key={uuid.v1()}
            onClick={() => onMenuItemClick(item)}
            onKeyPress={() => onMenuItemClick(item)}
            role="button"
            tabIndex={i}
            style={dropDownItemStyle}
          >
            {item.text}
          </div>
        ))}
      </div>
      )}
    </div>
  );
}
