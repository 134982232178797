import SideBar from '../SideBar/sideBar';
import styles from './mainFrame.module.css';
import TopBar from '../TopBar/topBar';

/**
 * メインフレームのプロップスインターフェース
 */
export interface MainFrameProps {
  /** メインフレームのボディ部分 */
  body?: JSX.Element
  /** メインフレームのボディ部分 */
  children?: JSX.Element // add to use format <MainFrame><div></div></MainFrame>
  /** メインフレームのボディ部分にボーダーを付けるかどうか */
  borderBox?: boolean;
  /** トップバー表示するかどうか */
  topBarDisplay?: boolean

  onClearSearch?: () => void,
  onClearAdvancedSearch?: () => void,
}

/**
 * メインフレームのコンポーネント
 */
function MainFrame({
  body, children, borderBox, topBarDisplay, onClearSearch = () => ({}), onClearAdvancedSearch = () => ({}),
}: MainFrameProps) {
  return (
    <div className={[styles.bodyGrid, borderBox ? styles.borderBox : ''].join(' ')}>
      {topBarDisplay !== false && <TopBar />}
      <SideBar onClearSearch={onClearSearch} onClearAdvancedSearch={onClearAdvancedSearch} />
      {body || children}
    </div>
  );
}

export default MainFrame;
