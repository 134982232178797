import { useEffect, useRef } from 'react';
import { useMessageModalContext } from '../store/modal.store';
import config from '../rest/config';
import routes from '../utils/routes';
import { ErrorMessages } from '../services/http';
import { ERROR_MESSAGES } from '../utils/messages';

/**
 *  アラートモーダルを表示するフック
 *
 * @returns アラートモーダルを表示する関数
 *  @example
 * ```
 *  const openAlertModal = useAlertModal();
 *  try {
 *    await openAlertModal('Custom Text');
 *    // accepted
 *  } catch{
 *    // canceled
 *  }
 * ```
 */
export function useAlertModal() {
  const searchbarContext = useMessageModalContext();

  return async (text: string, confirmText?: string, cancelText?: string): Promise<void> => new Promise((resolve, reject) => {
    searchbarContext.openAlertModal({
      text,
      open: true,
      confirmText,
      cancelText,
      onConfirm: () => resolve(),
      onCancel: () => reject(),
    });
  });
}

/**
 * メッセージモーダルを表示するフック
 *
 * @returns メッセージモーダルを表示する関数
*  @example
 * ```
 *  const openMessageModal = useMessageModal();
 *  await openMessageModal('Custom Text');
 * ```
 */
export function useMessageModal() {
  const messageModalContext = useMessageModalContext();

  const ref = useRef((text: string | null | undefined): Promise<void> => new Promise((resolve, reject) => {
    let message = text || ERROR_MESSAGES.UNEXPECTED_ERROR;
    if (message === ErrorMessages.IpAuth) return;
    if (message === ErrorMessages.Network) {
      message = ERROR_MESSAGES.NETWORK_ERROR;
    } else if (message === ErrorMessages.Unknown) {
      message = ERROR_MESSAGES.UNEXPECTED_ERROR;
    }
    if (text === config.tokenErrorMsg) {
      messageModalContext.openMessageModal({
        text: message,
        open: true,
        onConfirm: () => {
          window.location.href = routes.login;
        },
      });
      return;
    }
    messageModalContext.openMessageModal({
      text: message,
      open: true,
      onConfirm: () => {
        resolve();
      },
    });
  }));

  return ref.current;
}

export function useLoadingOverlay(loadingState: boolean) {
  const searchbarContext = useMessageModalContext();

  useEffect(() => {
    searchbarContext.setLoadingOverlay(loadingState);
  }, [loadingState]);
}
