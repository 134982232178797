import React, {
  createContext, CSSProperties, useContext, useMemo, useState,
} from 'react';
import { v1 as uuid } from 'uuid';
import styles from './radioGroupInput.module.css';

type RadioValue = string | ReadonlyArray<string> | number | undefined;

interface RadioGroupContextType<T = RadioValue> {
  name: string,
  value: T,
}

export const RadioGroupContext = createContext<RadioGroupContextType>(
  {
    name: '',
    value: undefined,
  },
);

function RadioInput(props: { style?: CSSProperties, className?: CSSProperties, value: RadioValue, name?: string, disabled?: boolean, children: JSX.Element | JSX.Element[] | string }) {
  const {
    className, value, name, disabled = false, children, style = {},
  } = props;
  const radioGroupContext = useContext(RadioGroupContext);
  return (
    <>
      <input
        className={[className].join(' ')}
        type="radio"
        value={value}
        disabled={disabled}
        checked={radioGroupContext.value === value}
        name={name || radioGroupContext.name}
        onChange={() => ({})}
        style={style}
      />
      {children}
    </>
  );
}

interface RadioGroupInputProps<T = RadioValue> {
  className?: string,
  value: T,
  style?: CSSProperties,
  children?: React.ReactNode,
  onChange: (value: T)=> void
}

function RadioGroupInput<T extends RadioValue>({
  className = '', value, style = {}, children, onChange,
}: RadioGroupInputProps<T>) {
  const [name] = useState(uuid());

  const contextValue = useMemo<RadioGroupContextType<T>>(() => ({
    name,
    value,
  }), [name, value]);

  return (
    <div
      className={[styles['radio-group'], className].join(' ')}
      style={style}
      /* eslint-disable-next-line no-restricted-globals */
      onChange={(e) => onChange((event?.target as HTMLInputElement).value as unknown as T)}
    >
      <RadioGroupContext.Provider value={contextValue}>
        {children}
      </RadioGroupContext.Provider>
    </div>
  );
}

RadioGroupInput.RadioInput = RadioInput;

export default RadioGroupInput;
