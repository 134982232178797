import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './signSystemSettingScreenPage.module.css';
import usePageTitle from '../../hooks/title.hook';
import { useDigitalSignGetStatusApi } from '../../hooks/api/digitalSign.hook';
import Button from '../../components/Button/button';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import { BaseSignItem } from './partials/baseSignItem';
import { DigitalSignItemForm } from '../../services/http/digitalSign.api';
import LoadingOverlay from '../../components/LoadingOverlay';

/**
 * API連携設定ページ
 */
function Component() {
  usePageTitle('API連携設定');
  const openMessageModal = useMessageModal();

  const [page, setPage] = useState(0);
  const [digitalSigns, setDigitalSigns] = useState<DigitalSignItemForm[]>([]);

  const screenEndRef = useRef<HTMLDivElement>(null);

  const { request: digitalSignStatusRequest, loading: loadingGetDigitalSignStatus } = useDigitalSignGetStatusApi();

  const fetchDigitalSignStatus = useCallback(async () => {
    try {
      const res = await digitalSignStatusRequest();
      setDigitalSigns(res);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [digitalSignStatusRequest, openMessageModal]);

  const handleAdd = useCallback(() => {
    setDigitalSigns((prev) => ([...prev, {
      organizationDigitalSignId: 0, name: '', systemType: null, keyInfo: [], registerDate: '', registerUser: '',
    }]));
    setTimeout(() => {
      if (!screenEndRef || !screenEndRef.current) return;
      screenEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 10);
  }, []);

  useEffect(() => {
    fetchDigitalSignStatus();
  }, [fetchDigitalSignStatus]);

  useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);
    const pageNum = urlSearch.get('page');
    if (pageNum != null) {
      setPage(parseInt(pageNum, 10));
    }
  }, []);

  const refresh = useCallback(() => {
    fetchDigitalSignStatus();
  }, [fetchDigitalSignStatus]);

  const handleRemove = useCallback((item: DigitalSignItemForm) => {
    if (item.organizationDigitalSignId !== 0) return;
    setDigitalSigns((prev) => prev.filter((sign) => sign.organizationDigitalSignId !== item.organizationDigitalSignId));
  }, []);

  const signList = useMemo(() => {
    if (!digitalSigns) return null;
    return digitalSigns.map((sign) => (
      <BaseSignItem key={sign.organizationDigitalSignId} refresh={refresh} data={sign} page={page} onRemove={handleRemove} />
    ));
  }, [digitalSigns, handleRemove, page, refresh]);

  const addButtonDisabled = digitalSigns.some((sign) => sign.organizationDigitalSignId === 0);

  return (
    <>
      <MainFrame
        borderBox
      >
        <div className={styles.mainFrame}>
          <div className={styles.mainFrameBody}>
            <div className={styles.mainFrameHeader}>
              <Button size="smaller" color="lighterGray" onClick={() => handleAdd()} disabled={addButtonDisabled}>
                連携先の追加
              </Button>
            </div>
            <div className={styles.contentBox}>
              {signList}
              <div ref={screenEndRef} />
            </div>
          </div>
        </div>
      </MainFrame>
      {loadingGetDigitalSignStatus ? (
        <LoadingOverlay />
      ) : null}
    </>
  );
}

export default Component;
