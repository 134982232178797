import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import styles from './adminUserRegisterModal.module.css';
import mainStyles from '../main.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import AlertModal, { alertModalInitialState, AlertModalProps } from '../../components/AlertModal';
import { ApiError } from '../../services/http';
import { AdminUserRegisterForm } from '../../services/http/managementUser.api';
import { useRegisterAdminUserApi } from '../../hooks/api/managementUser.hook';
import Button from '../../components/Button/button';
import Input from '../../components/Input/input';
import ButtonFileInput from '../../components/ButtonFileInput';
import { useCanvasImage } from '../../hooks/image.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * エラーラベルのインターフェース
 */
export interface ErrorLabelType {
  message: string,
  field?: string,
}

/**
 * 管理者ユーザー登録モーダル コンポーネントの Props のインターフェース
 */
export interface Props {
  /** モーダルが開いているかどうか (true: 開いている, false: 閉じている) */
  open: boolean
  /** キャンセル時に実行されるハンドラー */
  handleCancel: () => void
  /** 登録アクションが成功した時に実行されるハンドラー */
  handleSuccess: () => void
}

/**
 * 管理者ユーザー登録モーダル コンポーネント
 */
export function Component({ open, handleCancel, handleSuccess }: Props) {
  const [form, setForm] = useState<AdminUserRegisterForm>({
    userName: '',
    email: '',
    icon: undefined,
    password: '',
  });
  const [userIcon, setUserIcon] = useState('');
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [error, setError] = useState<ErrorLabelType>({ message: '' });

  const registerAdminUserApi = useRegisterAdminUserApi();
  const { request: createLog } = useCreateLogApi();

  const userImage = useCanvasImage();
  const openMessageModal = useMessageModal();

  const onSaveUserClick = useCallback(async () => {
    try {
      Validator.validUserName(form.userName);
    } catch (e) {
      setError({ field: 'ユーザー名', message: (e as Error).message });
      return;
    }
    try {
      Validator.validateEmail(form.email);
    } catch (e) {
      setError({ field: 'メールアドレス', message: (e as Error).message });
      return;
    }
    try {
      Validator.validPasswordLength(form.password);
      Validator.validPasswordTypeChars(form.password);
      Validator.validPasswordChars(form.password);
    } catch (e) {
      setError({ field: 'パスワード', message: (e as Error).message });
      return;
    }
    setModal({
      text: 'このユーザー情報を登録します。よろしいですか？',
      open: true,
      onCancel: () => setModal({ ...modal, open: false }),
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        try {
          createLog(LogFormName.AdminUserRegister, LogControlName.Create);

          await registerAdminUserApi.request(form);
          handleSuccess();
          handleCancel();
        } catch (e) {
          setError({ message: (e as ApiError).message });
          openMessageModal((e as ApiError)?.message);
        }
      },
    });
  }, [form, modal]);

  const onFileSelected = useCallback(async (file: File | FileList | null) => {
    if (file instanceof FileList) return;
    setForm({ ...form, icon: file || undefined });
    if (file) {
      const fr = new FileReader();
      fr.onload = async () => {
        const finalUserImage = await userImage.getImage(fr.result as string);
        setUserIcon(finalUserImage as string);
        setForm({ ...form, icon: finalUserImage });
      };
      fr.readAsDataURL(file);
    } else {
      setUserIcon('');
    }
  }, [userIcon, form]);

  const onDeleteImageClick = useCallback(async () => {
    setUserIcon('');
    setForm({ ...form, icon: undefined });
  }, [userIcon, form]);

  const isFormValid = useMemo(() => form.userName && form.email && form.password, [form]);

  useEffect(() => {
    setUserIcon('');
    setError({ message: '' });
    setForm({
      email: '',
      icon: undefined,
      password: '',
      userName: '',
    });
  }, [open]);

  useEffectOnce(() => {
    createLog(LogFormName.AdminUserRegister, LogControlName.Show);
  });

  return (
    <ScreenModal
      hidden={!open}
      handleCancel={handleCancel}
      customStyles={{
        minWidth: '800px',
        maxHeight: '85%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
        overflowY: 'auto',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          管理者ユーザー登録
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['col-1']}>
          <div>
            <div className={[styles.label, mainStyles['mb-10']].join(' ')}>
              ユーザー名
            </div>
            <div className={mainStyles['d-flex']}>
              <Input className={styles['default-width']} value={form.userName} placeholder="20文字以内で入力してください" onChange={(value) => setForm({ ...form, userName: value })} />
              <div className={[styles.text, styles['margin-left'], mainStyles['d-flex'], mainStyles['flex-column'], mainStyles['justify-content-center']].join(' ')}>
                20文字以内で全角（個人名での登録を推奨しております）
              </div>
            </div>
          </div>
          <div>
            <div className={[styles.label, mainStyles['mb-10']].join(' ')}>
              メールアドレス
            </div>
            <div className={mainStyles['d-flex']}>
              <Input className={styles['default-width']} value={form.email} onChange={(value) => setForm({ ...form, email: value })} />
              <div className={[styles.text, styles['margin-left'], mainStyles['d-flex'], mainStyles['flex-column'], mainStyles['justify-content-center']].join(' ')}>
                登録済ユーザーとのメールアドレスの重複はできません
              </div>
            </div>
          </div>
          <div className={[styles.label, mainStyles['mb-10']].join(' ')}>
            パスワード
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mb-30px']].join(' ')}>
            <Input className={styles['default-width']} value={form.password} type="password" onChange={(value) => setForm({ ...form, password: value })} />
            <div className={[styles.text, styles['margin-left'], mainStyles['mt-1'], mainStyles['mb-3']].join(' ')}>
              <div>
                {`${Validator.passwordLengthMin}~${Validator.passwordLengthMax}文字で、大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください`}
              </div>
              <div>
                {`利用可能な文字は半角英数字及び${Validator.passwordSymbolsLength()}種類の記号(${Validator.passwordSymbols})となります`}
              </div>
              <div>
                ユーザー登録後、初期パスワードをユーザーへ連携してください
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <div className={styles['mainframe-body-footer-buttons']}>
          <Button size="smaller" color="lighterGray" onClick={handleCancel}>キャンセル</Button>
          <div className="text-red">
            {error.field ? `${error.field}: ` : ''}
            {error.message}
          </div>
          <Button size="smaller" onClick={onSaveUserClick} loading={registerAdminUserApi.loading} disabled={!isFormValid}>保存</Button>
        </div>
      </div>
      <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
    </ScreenModal>
  );
}

export default Component;
