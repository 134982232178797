import React, {
  CSSProperties, RefObject, useEffect, useState,
} from 'react';
import * as uuid from 'uuid';
import styles from './dropdownMenu.module.css';
import { useOutsideClick } from '../../hooks/window.hook';

export type DropDownMenuItem<T> = {
  text: string,
  value: T
};

interface DropdownMenuProps<T> {
  className?: string,
  style?: CSSProperties,
  menuStyle?: CSSProperties,
  open?: boolean,
  disabled?: boolean,
  children: JSX.Element,
  options?: DropDownMenuItem<T>[],
  left?: boolean
  right?: boolean
  onSelect: (value: T, item: DropDownMenuItem<T>)=>void
  onOutsideClick?: ()=>void
  onDeselect?: ()=>void
  deselectOption?: boolean
  toggleSingal? :number,
  dropDownItemStyle?: CSSProperties
  setMenuIsOpen?: (param: boolean)=>void
}

export default function DropdownMenu<T>({
  className = '', children, disabled = false, onSelect, options = [], left = false, right = false, style = {}, onOutsideClick, open, onDeselect, deselectOption, toggleSingal: toggleSinal, menuStyle, dropDownItemStyle, setMenuIsOpen,
}: DropdownMenuProps<T>) {
  const [openMenu, setOpenMenu] = useState(false);

  const { ref } = useOutsideClick(() => {
    setOpenMenu(false);
    if (onOutsideClick) onOutsideClick();
  });

  const onMenuItemClick = (item: DropDownMenuItem<T>) => {
    setOpenMenu(false);
    onSelect(item.value, item);
  };
  const onKeyHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled) setOpenMenu(!openMenu);
  };

  const resetInput = () => {
    setOpenMenu(false);
    if (onDeselect) {
      onDeselect();
    }
  };

  useEffect(() => {
    setOpenMenu(!!open);
  }, [open]);

  useEffect(() => {
    if (toggleSinal == null) {
      return;
    }
    if (toggleSinal === 0) {
      return;
    }
    setOpenMenu((o) => !o);
  }, [toggleSinal]);

  useEffect(() => {
    if (setMenuIsOpen) {
      setMenuIsOpen(openMenu);
    }
  }, [openMenu, setMenuIsOpen]);

  return (
    <div className={[styles.container, className].join(' ')} style={style}>
      <div
        onClick={() => (!disabled && setOpenMenu(!openMenu))}
        onKeyPress={(e) => onKeyHandler(e)}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      {openMenu && (
      <div
        style={{ ...menuStyle }}
        className={[styles.menu, styles.center, left ? styles.left : '', right ? styles.right : ''].join(' ')}
        ref={ref as RefObject<HTMLDivElement>}
      >
        {deselectOption && (
          <div
            className={styles.item}
            style={dropDownItemStyle}
            onClick={() => resetInput()}
            aria-hidden="true"
          >
            <div className={styles.item} />
          </div>
        )}
        {options.map((item, i) => (
          <div
            className={styles.item}
            key={uuid.v1()}
            onClick={() => onMenuItemClick(item)}
            onKeyPress={() => onMenuItemClick(item)}
            role="button"
            tabIndex={i}
            style={dropDownItemStyle}
          >
            {item.text}
          </div>
        ))}
      </div>
      )}
    </div>
  );
}
