import { useEffect, useState } from 'react';
import Button from '../../components/Button/button';
import CustomFrame from '../../components/CustomFrame/customFrame';
import usePageTitle from '../../hooks/title.hook';
import routes from '../../utils/routes';
import { useAuthContext } from '../../store/auth.store';

function IpErrorPage() {
  usePageTitle('グローバルIPアドレス制限');
  const { removeToken, removeUser } = useAuthContext();

  const navigateToLoginPage = () => {
    window.location.href = routes.login;
  };

  useEffect(() => {
    const storageErrorMessage = sessionStorage.getItem('errorMessage');
    if (!storageErrorMessage) navigateToLoginPage();

    removeToken();
    removeUser();
  }, [removeToken, removeUser]);

  return (
    <CustomFrame
      title="グローバルIPアドレス制限"
      desc={(
        <div>
          <div style={{ color: '#E50019', fontSize: '18px', textAlign: 'center' }}>
            アクセス元のIPアドレスからCLOUD CABINETを利用できません。
          </div>
          <div style={{ color: '#E50019', fontSize: '18px', textAlign: 'center' }}>
            システム管理者にお問合せください。
          </div>
        </div>
      )}
      body={<Button size="larger" content="ログイン画面へ" onClick={navigateToLoginPage} />}
    />
  );
}

export default IpErrorPage;
