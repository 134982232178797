import { useState } from 'react';
import { DocumentRegisterForm } from '../../services/http/documents.api';
import PdfPreview from '../../components/PdfPreview/PdfPreview';
import styles from './docRegisterScreenPage.module.css';
import mainStyles from '../main.module.css';
import ScrollDrag from '../../components/PdfPreview/ScrollDrag';

/**
 * 文書登録機能に付随するPDFプレビューのプロップスインターフェース
 */
export interface DocRegisterPdfPreviewProps {
  /** 使用されるフォーム */
  form: DocumentRegisterForm;
}

/**
 * 文書登録に付随するPDFプレビューコンポーネント
 */
function DocRegisterPdfPreview({ form }: DocRegisterPdfPreviewProps) {
  const [zoom, setZoom] = useState(1);
  return (
    <div className={styles.mainFrameRightSectionCol2}>
      <div className={styles.mainFrameRightSectionCol2Header}>
        <img className={styles.button} src="/images/Icon-feather-minus.svg" alt="" onClick={() => setZoom(zoom - 0.2)} />
        <div className={`${styles.pipeSeparator} ${mainStyles['ml-3']}`} />
        <img className={`${styles.button} ${mainStyles['ml-3']}`} src="/images/Icon-feather-plus.svg" alt="" onClick={() => setZoom(zoom + 0.2)} />

        <select className={`${styles.button} ${mainStyles.select} ${mainStyles['ml-3']}`}>
          <option>PDFプレビュー</option>
        </select>

      </div>
      <div className={styles.mainFrameRightSectionCol2Body}>
        <ScrollDrag className={styles.pdfContainer}><PdfPreview pdfFile={form.documentFile} zoom={zoom} /></ScrollDrag>
      </div>
    </div>
  );
}

export default DocRegisterPdfPreview;
