import { v1 as uuid } from 'uuid';
import styles from './relationalDocumentsDetailCellRenderer.module.css';
import { RelationalDocument } from '../../../../services/http/documents.api';

/**
 * 関連文書の詳細セル表示のプロップスインターフェース
 */
interface Props {
  /** 関連文書一覧 */
  relationalDocumentList?: RelationalDocument[];
}

/**
 * 関連文書の詳細セル表示のコンポーネント
 */
function RelationalDocumentsDetailCellRenderer({ relationalDocumentList }: Props) {
  return (
    <div className={styles['detail-row-container']}>
      {relationalDocumentList && relationalDocumentList.map((item) => (
        <div className={styles['detail-row']} key={uuid()}>
          <div>
            <label>文書名：</label>
            <span>{item.documentName}</span>
          </div>
          <div>
            <label>文書ID：</label>
            <span>{item.ccControlNumber}</span>
          </div>
          <div>
            <label>バーコード印字欄：</label>
            <span>{item.barcodePrinting}</span>
          </div>
          <div>
            <label>保管品バーコード：</label>
            <span>{item.itemCode}</span>
          </div>
          <div>
            <label>フォルダ：</label>
            <span>{item.folderName}</span>
          </div>
          {item.duplicateTagList && item.duplicateTagList.map((tag) => (
            <div className={styles.tag} key={uuid()}>
              <label>
                {tag.tagLabel}
                ：
              </label>
              <span>{tag.value}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default RelationalDocumentsDetailCellRenderer;
