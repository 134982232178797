import * as uuid from 'uuid';
import mainStyles from '../../main.module.css';
import { AllTagsDocumentType } from '../../../services/http/tag.api';

interface Props {
  header?: string;
  tagLabel: string;
  items: AllTagsDocumentType[];
  footer: string;
}

export default function SaveTagModalBody({
  header,
  tagLabel,
  items,
  footer,
}: Props) {
  const formattedItems = items.map((item) => `${item.documentClassificationName}：${item.documentTypeName}`);
  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {header ? (
        <>
          {header}
          <br />
          <br />
        </>
      ) : null}
      {`${tagLabel}は以下の文書種類で利用されています。`}
      <br />
      <br />
      {formattedItems.slice(0, 3).map((item) => (
        <div key={uuid.v1()} className={mainStyles['text-overflow-ellipsis']}>
          -
          {' '}
          {item}
        </div>
      ))}
      {items.length > 3 && (
      <div>
        その他
        {items.length - 3}
        件
      </div>
      )}
      <br />
      <div>
        {footer}
      </div>
    </div>
  );
}
