import { useEffect, useRef, useState } from 'react';
import styles from './docDetailHistory.module.css';
import mainStyles from '../../pages/main.module.css';
import { DocumentDetail } from '../../services/http/documents.api';
import Formatter from '../../utils/formatters';
import LogDetailModal from '../../pages/operationLogListScreen/logDetailModal';

/**
 * 文書詳細モーダル’操作ログ’のプロップスインターフェース
 */
export interface Props {
  /** モーダルが閉じられた時に実行されるハンドラー */
  onClose: (hidden:boolean) => void
  /** 使用される文書 */
  document: DocumentDetail | null;
  /** 文書が編集されているかどうか */
  setIsEditing: (isEditing: boolean) => void
}

/**
 * 文書詳細モーダル’操作ログ’のコンポーネント
 */
function DocDetailHistory({ onClose, document, setIsEditing }:Props) {
  const refDiv1 = useRef<HTMLDivElement>(null);
  const refDiv2 = useRef<HTMLDivElement>(null);

  const [isOpenDiv1, setIsOpenDiv1] = useState(true);
  const [isOpenDiv2, setIsOpenDiv2] = useState(true);
  const [heightDiv1, setHeightDiv1] = useState<number | undefined>(0);
  const [heightDiv2, setHeightDiv2] = useState<number | undefined>(0);
  const [selectedLogId, setSelectedLogId] = useState<number | ''>('');

  const onCollapse = (collapseDiv: number) => {
    if (collapseDiv === 1) {
      setIsOpenDiv1((prev) => !prev);
    }
    if (collapseDiv === 2) {
      setIsOpenDiv2((prev) => !prev);
    }
  };

  useEffect(() => {
    if (isOpenDiv1) {
      setHeightDiv1(refDiv1.current?.getBoundingClientRect().height);
    } else {
      setHeightDiv1(0);
    }
  }, [isOpenDiv1, document]);

  useEffect(() => {
    if (isOpenDiv2) setHeightDiv2(refDiv2.current?.getBoundingClientRect().height);
    else {
      setHeightDiv2(0);
    }
  }, [isOpenDiv2, document]);

  useEffect(() => {
    setIsEditing(false);
  }, []);

  const onShowLogDetailsClick = (id: number) => {
    setSelectedLogId(id);
  };

  return (
    <div className={styles.bodySection}>
      <div className={mainStyles['d-flex']}>
        <div className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-end']].join(' ')}>
          <img
            className={[mainStyles['mr-10'], styles.topButtonsImg].join(' ')}
            src="/images/Close-333333.svg"
            alt=""
            onClick={() => {
              onClose(true);
            }}
          />
        </div>
      </div>
      <div className={styles.bodySectionMain}>
        <div className={styles.card}>
          <div className={[styles.collapse, styles.cardTitle, mainStyles['align-items-center'], mainStyles['d-flex']].join(' ')} onClick={() => onCollapse(1)} aria-hidden>
            <div style={{ width: '25px' }} className={[mainStyles['d-flex'], mainStyles['justify-content-center']].join(' ')}>
              {isOpenDiv1 ? (
                <img src="/images/provisory-images/triangle-down.svg" alt="" />
              ) : (
                <img src="/images/provisory-images/triangle-right-black.svg" alt="" />
              )}

            </div>
            <div>
              依頼履歴
            </div>
          </div>
          {isOpenDiv1 && (
          <div style={{ height: heightDiv1 }} className={styles.collapseContent}>
            <div ref={refDiv1}>

              <div className={[styles.cardSubTitle, mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                <div className={mainStyles['flex-2']} style={{ marginLeft: '25px' }}>依頼日時</div>
                <div className={mainStyles['flex-2']}>依頼内容</div>
                <div className={mainStyles['flex-1']}>操作者</div>
                <div className={mainStyles['flex-2']} />
              </div>
              {document?.requestHistory.map((item) => (

                <div key={item.date + item.type + document.id} className={[styles.cardBody, mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                  <div className={mainStyles['flex-2']} style={{ marginLeft: '25px' }}>
                    { Formatter.toDisplayDate(Formatter.fromStringToDate(item.date), Formatter.defaultDateTimeFormat) }
                  </div>
                  <div className={mainStyles['flex-2']}>{ item.type}</div>
                  <div className={mainStyles['flex-1']}>{ item.user}</div>
                  <div className={mainStyles['flex-2']} />
                </div>
              ))}
            </div>
          </div>
          )}
        </div>
        <div className={styles.card}>
          <div className={[styles.collapse, styles.cardTitle, mainStyles['align-items-center'], mainStyles['d-flex']].join(' ')} onClick={() => onCollapse(2)} aria-hidden>
            <div style={{ width: '25px' }} className={[mainStyles['d-flex'], mainStyles['justify-content-center']].join(' ')}>
              {isOpenDiv2 ? (
                <img src="/images/provisory-images/triangle-down.svg" alt="" />
              ) : (
                <img src="/images/provisory-images/triangle-right-black.svg" alt="" />
              )}
            </div>
            <div>
              登録更新履歴
            </div>
          </div>
          {isOpenDiv2 && (
          <div style={{ height: heightDiv2 }} className={styles.collapseContent}>
            <div ref={refDiv2}>
              <div className={[styles.cardSubTitle, mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                <div className={mainStyles['flex-2']} style={{ marginLeft: '25px' }}>日時</div>
                <div className={mainStyles['flex-2']}>内容</div>
                <div className={mainStyles['flex-1']}>操作者</div>
                <div className={[mainStyles['flex-2'], styles.detailButtonHeader].join(' ')}>
                  <span>詳細</span>
                </div>
                <div />
              </div>
              {document?.versionUpdateHistory.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${item.registDate}-${index}-${document.id}`} className={[styles.cardBody, mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                  <div className={mainStyles['flex-2']} style={{ marginLeft: '25px' }}>
                    { Formatter.toDisplayDate(Formatter.fromStringToDate(item.registDate), Formatter.defaultDateTimeFormat) }
                  </div>
                  <div className={mainStyles['flex-2']}>
                    { item.updateType }
                  </div>
                  <div className={mainStyles['flex-1']}>
                    {item.requestUser}
                  </div>
                  <div className={[mainStyles['flex-2'], styles.detailButtonContainer].join(' ')}>
                    <button
                      className={[!item.logId ? styles.tableButtonDisabled : styles.tableButton, mainStyles['btn-gray']].join(' ')}
                      disabled={!item.logId}
                      type="button"
                      onClick={() => item.logId && onShowLogDetailsClick(item.logId)}
                    >
                      詳細
                    </button>
                  </div>
                  {selectedLogId === item.logId && <LogDetailModal logId={selectedLogId} handleCancel={() => { setSelectedLogId(''); }} />}
                </div>
              ))}
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DocDetailHistory;
