import React, { FocusEvent, KeyboardEventHandler, RefObject } from 'react';
import styles from './TextArea.module.css';

export interface InputProps {
  className?: string,
  value?: string
  maxLength?: number,
  rows?: number,
  disabled?: boolean,
  placeholder?: string,
  readOnly?: boolean,
  onChange?: (e: string) => void
  onClick?: () => void,
  onFocus?: () => void,
  style?: React.CSSProperties,
  title?: string,
}

function TextArea(props: InputProps) {
  const {
    className,
    value,
    maxLength,
    placeholder,
    readOnly,
    disabled,
    rows,
    onChange = () => ({}),
    onClick = () => ({}),
    onFocus = () => ({}),
    style,
    title,
  } = props;
  return (
    <textarea
      className={[styles.input, className, disabled && styles.disabled].join(' ')}
      value={value || ''}
      maxLength={maxLength}
      readOnly={readOnly}
      rows={rows}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      onClick={onClick}
      onFocus={onFocus}
      style={{ ...style, resize: 'none' }}
      title={title}
    />
  );
}

export default TextArea;
