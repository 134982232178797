import { useCallback, useState } from 'react';
import { v1 as uuid } from 'uuid';
import IpApi, {
  GetIpListForm, GetIpListResponse, IpInfo, SaveIpListForm,
} from '../../services/http/ip.api';
import { ApiError } from '../../services/http';

export interface IpInfoWithKey extends IpInfo {
  key: string,
}

export interface IpListForm {
  customerId?: number,
  firewallUseFlg: number,
  blockingUseFlg: number,
  allowIpAddressList: IpInfoWithKey[],
}

export interface IpListResponse {
  firewallUseFlg: number,
  blockingUseFlg: number,
  allowIpAddressList: IpInfoWithKey[],
}

export function useGetIpListApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IpListResponse | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: GetIpListForm): Promise<IpListResponse> => {
    setLoading(true);
    try {
      const res = await IpApi.getIpList(form);
      const ipListWithKey = res.allowIpAddressList.map((ip) => ({ ...ip, key: uuid() }));
      const resWithKey = { ...res, allowIpAddressList: ipListWithKey };
      setData(resWithKey);
      return resWithKey;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

export function useSaveIpListApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: IpListForm): Promise<void> => {
    setLoading(true);
    try {
      const formattedIpList: IpInfo[] = form.allowIpAddressList.map((ip) => ({ explanation: ip.explanation, ipAddress: ip.ipAddress }));
      await IpApi.saveIpList({
        ...form,
        allowIpAddressList: formattedIpList,
      });
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}
