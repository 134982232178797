import {
  CSSProperties,
} from 'react';
import styles from './checkbox.module.css';

interface CheckboxProps {
  className?: string,
  style?: CSSProperties,
  label?: string,
  disabled?: boolean,
  value?: boolean,
  onChange: (value: boolean) => void,
}

function Checkbox({
  className = '', style = {}, label, disabled, value = false, onChange,
}: CheckboxProps) {
  return (
    <div className={[className].join(' ')} style={style}>
      <input
        className={[styles.inputContainer].join((' '))}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span>{label}</span>
    </div>
  );
}

export default Checkbox;
