import React, { FocusEvent, KeyboardEventHandler, RefObject } from 'react';
import styles from './input.module.css';

export interface InputProps {
  className?: string,
  type?: string
  value?: string
  maxLength?: number,
  disabled?: boolean,
  placeholder?: string,
  inputRef?: RefObject<HTMLInputElement>
  readOnly?: boolean,
  onChange?: (e: string) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onClick?: () => void,
  onFocus?: () => void,
  style?: React.CSSProperties,
  title?: string,
}

function Input(props: InputProps) {
  const {
    className, type = 'text', value, inputRef, maxLength, placeholder, readOnly, disabled, onChange = () => ({}), onBlur = () => ({}), onClick = () => ({}), onFocus = () => ({}), onKeyPress, onKeyDown, style, title,
  } = props;
  return (
    <input
      className={[styles.input, className, disabled && styles.disabled].join(' ')}
      type={type}
      value={value || ''}
      ref={inputRef}
      maxLength={maxLength}
      readOnly={readOnly}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      style={style}
      onKeyDown={onKeyDown}
      title={title}
    />
  );
}

export default Input;
