import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import styles from './userRegisterModal.module.css';
import mainStyles from '../main.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import AlertModal, { alertModalInitialState, AlertModalProps } from '../../components/AlertModal';
import { UserRegisterForm } from '../../services/http/user.api';
import { ApiError } from '../../services/http';
import { useUserRegisterApi } from '../../hooks/api/user.hook';
import Button from '../../components/Button/button';
import { useSearchUserGroupApi } from '../../hooks/api/userGroup.hook';
import { UserGroup } from '../../services/http/userGroup.api';
import RadioGroupInput from '../../components/RadioGroupInput';
import Input from '../../components/Input/input';
import { ManagementType } from '../../store/auth.store';
import { useCanvasImage } from '../../hooks/image.hook';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import AutoCompleteInput2 from '../../components/AutoCompleteInput2';

/**
 * タイプオプション
 */
export type Optional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

/**
 * エラーラベルのプロップスインターフェース
 */
export interface ErrorLabelType {
  message: string,
  field?: string,
}

/**
 * ユーザー登録モーダルのプロップスインターフェース
 */
export interface Props {
  /** 非表示フラグ */
  open: boolean
  /** キャンセルハンドラー */
  handleCancel: () => void
  /** 登録完了ハンドラー */
  handleSuccess: () => void
}

/* eslint-disable react/jsx-props-no-spreading */
/**
 * ユーザー登録モーダルのコンポーネント
 */
function Component({ open, handleCancel, handleSuccess }: Props) {
  const [form, setForm] = useState<UserRegisterForm>({
    email: '',
    icon: undefined,
    password: '',
    userAuthenticationType: ManagementType.General,
    userGroupId: undefined,
    userName: '',
  });
  const [userGroups, setUserGroups] = useState<Optional<UserGroup, 'id'>[]>([]);
  const [, openFileSelector] = useState(false);
  const [userIcon, setUserIcon] = useState('');
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [error, setError] = useState<ErrorLabelType>({ message: '' });

  const { request: userRegisterApiRequest, loading: userRegisterApiLoading } = useUserRegisterApi();
  const { request: userGroupSearchApi } = useSearchUserGroupApi();

  const userImage = useCanvasImage();
  const { request: createLog } = useCreateLogApi();

  const onSaveUserClick = useCallback(async () => {
    try {
      Validator.validUserName(form.userName);
    } catch (e) {
      setError({ field: 'ユーザー名', message: (e as Error).message });
      return;
    }
    try {
      Validator.validateEmail(form.email);
    } catch (e) {
      setError({ field: 'メールアドレス', message: (e as Error).message });
      return;
    }
    try {
      Validator.validPasswordLength(form.password);
      Validator.validPasswordTypeChars(form.password);
      Validator.validPasswordChars(form.password);
    } catch (e) {
      setError({ field: 'パスワード', message: (e as Error).message });
      return;
    }
    setModal({
      text: 'このユーザー情報を登録します。よろしいですか？',
      open: true,
      onCancel: () => setModal({ ...modal, open: false }),
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        try {
          await userRegisterApiRequest(form);
          handleSuccess();
          handleCancel();
        } catch (e) {
          setError({ message: (e as ApiError).message });
        }
      },
    });
  }, [form, modal, handleCancel, userRegisterApiRequest]);

  const onFileSelected = useCallback(async (file: File | FileList | null) => {
    openFileSelector(false);
    if (file instanceof FileList) return;
    setForm({ ...form, icon: file || undefined });
    if (file) {
      const fr = new FileReader();
      fr.onload = async () => {
        const finalUserImage = await userImage.getImage(fr.result as string);
        setUserIcon(finalUserImage as string);
        setForm({ ...form, icon: finalUserImage });
      };
      fr.readAsDataURL(file);
    } else {
      setUserIcon('');
    }
  }, [form]);

  const onDeleteImageClick = useCallback(async () => {
    setUserIcon('');
    setForm({ ...form, icon: undefined });
  }, [form]);

  const isFormValid = useMemo(() => form.userName && form.email && form.password, [form]);

  useEffect(() => {
    setUserIcon('');
    setError({ message: '' });
    setForm({
      email: '',
      icon: undefined,
      password: '',
      userAuthenticationType: ManagementType.General,
      userGroupId: 0,
      userName: '',
    });
    userGroupSearchApi({}).then((res) => setUserGroups([{ name: '', id: undefined, users: [] }, ...res]));
  }, [open, userGroupSearchApi]);

  useEffectOnce(() => {
    createLog(LogFormName.UserRegister, LogControlName.Show);
  });

  return (
    <ScreenModal
      hidden={!open}
      customStyles={{
        minWidth: '800px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          ユーザー登録
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['col-1']}>
          <div>
            <div className={[styles.label, mainStyles['mb-10']].join(' ')} style={{ marginBottom: '10px' }}>
              ユーザー名
            </div>
            <div className={mainStyles['d-flex']}>
              <Input className={styles['default-width']} value={form.userName} placeholder="20文字以内で入力してください" onChange={(value) => setForm({ ...form, userName: value })} />
              <div className={[styles.text, styles['margin-left'], mainStyles['d-flex'], mainStyles['flex-column'], mainStyles['justify-content-center']].join(' ')}>
                20文字以内で全角（個人名での登録を推奨しております）
              </div>
            </div>
          </div>
          <div>
            <div className={[styles.label, mainStyles['mb-10']].join(' ')} style={{ marginBottom: '10px' }}>
              メールアドレス
            </div>
            <div className={mainStyles['d-flex']}>
              <Input className={styles['default-width']} value={form.email} onChange={(value) => setForm({ ...form, email: value })} />
              <div className={[styles.text, styles['margin-left'], mainStyles['d-flex'], mainStyles['flex-column'], mainStyles['justify-content-center']].join(' ')}>
                登録済ユーザーとのメールアドレスの重複はできません
              </div>
            </div>
          </div>
          <div className={[styles.label, mainStyles['mb-10']].join(' ')} style={{ marginBottom: '10px' }}>
            パスワード
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mb-30px']].join(' ')}>
            <Input className={styles['default-width']} type="password" value={form.password} onChange={(value) => setForm({ ...form, password: value })} />
            <div className={[styles.text, styles['margin-left'], mainStyles['mt-1']].join(' ')}>
              <div>
                {`${Validator.passwordLengthMin}~${Validator.passwordLengthMax}文字で、大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください`}
              </div>
              <div>
                {`利用可能な文字は半角英数字及び${Validator.passwordSymbolsLength()}種類の記号(${Validator.passwordSymbols})となります`}
              </div>
              <div>
                ユーザー登録後、初期パスワードをユーザーへ連携してください
              </div>
            </div>
          </div>
          <div className={styles.label}>
            ユーザー種別
          </div>
          <div className={[styles.text, mainStyles['mt-2'], mainStyles['d-flex']].join(' ')}>
            <RadioGroupInput value={form.userAuthenticationType} onChange={(value) => setForm({ ...form, userAuthenticationType: Number(value) })}>
              <RadioGroupInput.RadioInput value={ManagementType.Admin}>管理ユーザー</RadioGroupInput.RadioInput>
              <RadioGroupInput.RadioInput value={ManagementType.General}>一般ユーザー</RadioGroupInput.RadioInput>
            </RadioGroupInput>
          </div>
          <div className={mainStyles['mb-3']}>
            <div className={styles.label}>
              ユーザーグループ
            </div>
            <AutoCompleteInput2
              className={[mainStyles['mt-10'], styles['default-width']].join(' ')}
              value={form.userGroupId}
              onSelect={(userGroupId) => setForm({ ...form, userGroupId })}
              options={userGroups.map((userGroup) => ({ text: userGroup.name, value: userGroup.id }))}
            />
          </div>
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <div className={styles['mainframe-body-footer-buttons']}>
          <Button size="smaller" color="lighterGray" onClick={handleCancel}>キャンセル</Button>
          <div className="text-red">
            {error.field ? `${error.field}: ` : ''}
            {error.message}
          </div>
          <Button size="smaller" onClick={onSaveUserClick} loading={userRegisterApiLoading} disabled={!isFormValid}>保存</Button>
        </div>
      </div>
      <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
    </ScreenModal>
  );
}

export default Component;
