import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card/card';
import usePageTitle from '../../hooks/title.hook';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './masterDataSettingGeneralPage.module.css';
import routes from '../../utils/routes';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useGetIpListApi } from '../../hooks/api/ip.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import { useHasSsoSupport } from '../../hooks/api/organizationAuthentication.hook';

/**
 * マスターデータ設定ページ（一般ユーザー）
 */
function Component() {
  usePageTitle('マスタ管理');
  const navigate = useNavigate();
  const [firewallEnabled, setFirewallEnabled] = useState<boolean>(false);
  const [displayPanel, setDisplayPanel] = useState<boolean>(false);

  const { request: createLog } = useCreateLogApi();
  const { request: ipGetListApiRequest } = useGetIpListApi();
  const hasSso = useHasSsoSupport();
  const openMessageModal = useMessageModal();

  const getIpList = useCallback(async () => {
    try {
      const res = await ipGetListApiRequest({});
      const booleanFirewallFlg = res.firewallUseFlg === 1;
      setFirewallEnabled(booleanFirewallFlg);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      setDisplayPanel(true);
    }
  }, [ipGetListApiRequest, openMessageModal]);

  useEffectOnce(() => {
    createLog(LogFormName.MasterDataSettingGeneral, LogControlName.Show);
  });

  useEffect(() => {
    getIpList();
  }, [getIpList]);

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.container}>
          <div className={styles.mainContainer}>
            {displayPanel && (
              <>
                <div className={styles.row}>
                  <Card cardImageType="truck" text="配送先一覧" buttonText="設定する" handleButtonClick={() => navigate(routes.shippingAddressList)} />
                  <Card cardImageType="tag" text="タグメンテナンス" buttonText="設定する" handleButtonClick={() => navigate(routes.tagMaintenance)} />
                  <Card cardImageType="files-line" text="文書種類一覧" buttonText="設定する" handleButtonClick={() => navigate(routes.docTypeList)} />
                </div>
                <div className={styles.lowerRow}>
                  <Card cardImageType="lock" text="権限テンプレート" buttonText="設定する" handleButtonClick={() => navigate(routes.permissionTemplateCreation)} />
                  {firewallEnabled && (
                    <Card cardImageType="ip-block" text="グローバルIPアドレス制限" buttonText="設定する" handleButtonClick={() => navigate(routes.userIpListScreen)} />
                  )}
                  {
                    hasSso.hasSsoSupport
                      && (
                      <Card
                        cardImageType="sso"
                        text="シングルサインオン"
                        buttonText="設定する"
                        handleButtonClick={() => navigate(routes.organizationAuthenticationSaml)}
                      />
                      )
                  }
                </div>
              </>
            )}
          </div>
        </div>
      )}
    />
  );
}

export default Component;
