import { useState } from 'react';
import { ApiError } from '../../services/http';
import reserveApi, {
  DisposeReserveForm,
  RemoteScanReserveForm,
  ShipReserveForm,
  ShipReserveGetCalendar,
  ShortestDesiredDate,
} from '../../services/http/reserve.api';

/**
 * 出庫予約APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useShipReserveApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: ShipReserveForm): Promise<void> {
    setLoading(true);
    try {
      return await reserveApi.ship(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    request, error, loading,
  };
}

/**
 * 廃棄予約APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDisposeReserveApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: DisposeReserveForm): Promise<void> {
    setLoading(true);
    try {
      return await reserveApi.storeDispose(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    request, error, loading,
  };
}

/**
 * リモートスキャン予約APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useRemoteScanReserveApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: RemoteScanReserveForm): Promise<void> {
    setLoading(true);
    try {
      return await reserveApi.storeRemoteScan(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    request, error, loading,
  };
}

/**
 * 出庫予約カレンダー取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useShipReserveGetCalendarApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ShortestDesiredDate>();
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: ShipReserveGetCalendar): Promise<ShortestDesiredDate> {
    setLoading(true);
    try {
      const doc = await reserveApi.getCalendar(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, data, error, request,
  };
}
