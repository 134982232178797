import axios from 'axios';
import Formatter from '../../utils/formatters';

export interface StoreReserveForm {
  documentList?: DocumentId[],
  deliveryCd?: string,
  deliveryName?: string,
  deliveryPostNo?: string,
  deliveryAddress1?: string,
  deliveryAddress2?: string,
  deliveryAddress3?: string,
  customerStaffName?: string,
  deliveryPhone?: string,
  deliveryFax?: string,
  comments1?: string,
  trdContact?: string,
  userMemo?: string,
}

export interface ShipReserveForm {
  documentList?: DocumentId[],
  deliveryCd?: string,
  deliveryName?: string,
  deliveryPostNo?: string,
  deliveryAddress1?: string,
  deliveryAddress2?: string,
  deliveryAddress3?: string,
  customerStaffName?: string,
  deliveryPhone?: string,
  deliveryFax?: string,
  comments1?: string,
  trdContact?: string,
  userMemo?: string,
}

export interface DisposeReserveForm {
  documentList?: DocumentId[],
  disposalReportIssueDiv?: number,
  emergencyContacts?: string,
  trdContact?: string,
  userMemo?: string,
}

export interface RemoteScanReserveForm {
  documentList?: DocumentId[],
  speedScanFlg: number,
  emergencyContacts: string,
  trdContact?: string,
  userMemo?: string,
}

export interface DocumentId {
  documentId: number | null,
}

export interface ShipReserveGetCalendar {
  deliveryCd: string,
  deliveryPostNo: string,
  deliveryAddress1: string,
  deliveryAddress2: string,
  deliveryAddress3: string,
}

export interface ShortestDesiredDate {
  shortestDesiredDate: Date,
}

export default {
  /**
   * 予約を確認する関数
   *
   * @param form - 予約フォーム
    * @returns - 空のプロミス
   */
  async ship(form: ShipReserveForm): Promise<void> {
    const res = await axios.post('/ShipReserve/confirm', form);
    const { data } = res;
    return data;
  },
  /**
   * 廃棄物の処理を予約する関数
   *
   * @param form - 廃棄物処理予約フォーム
   * @returns - 空のプロミス
   */
  async storeDispose(form: DisposeReserveForm): Promise<void> {
    const res = await axios.post('/DisposeReserve/confirm', form);
    const { data } = res;
    return data;
  },
  /**
   * リモートスキャン予約確認
   *
   * @param form - リモートスキャン予約フォーム
   * @returns - 空のプロミス
   */
  async storeRemoteScan(form: RemoteScanReserveForm): Promise<void> {
    const res = await axios.post('/OnDemandScanReserve/confirm', form);
    const { data } = res;
    return data;
  },
  /**
   * 予約可能なカレンダーを取得する関数
   *
   * @param form - カレンダー取得フォーム
   * @returns - 予約可能な最短の日付
   */
  async getCalendar(form: ShipReserveGetCalendar): Promise<ShortestDesiredDate> {
    const res = await axios.get('/ShipReserve/getCalendar', { params: form });
    const { data } = res;
    return {
      shortestDesiredDate: Formatter.fromStringToDateWithFormat(data.getCalendarData.shortestDesiredDate, Formatter.hyphenDateFormat),
    };
  },
};
