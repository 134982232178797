import style from './alertCloseModal.module.css';
import Button from '../../components/Button/button';

/**
 * モーダルを閉じるアラートの初期状態
 */
export const alertModalInitialState = {
  open: false, text: '', onClose: () => {},
};

/**
 * モーダルを閉じるアラートのプロパティインターフェイス
 */
export interface AlertModalProps {
  /** モーダルが開いているかどうか (true: 開いている, false: 閉じている) */
  open: boolean,
  /** モーダルに表示するテキスト */
  text: string,
  /** モーダルを閉じるハンドラー */
  onClose: () => void;
}

/**
 * モーダルを閉じるアラートコンポーネント
 */
function AlertCloseModal({
  open = false, text, onClose,
}: AlertModalProps) {
  return (
    <div className={style.alertBox} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.modalContainer}>
        <div className={style.modal}>
          <div className={style.alertMessage}>{text}</div>
          <div className={style.alertButtons}>
            <Button content="閉じる" size="smaller" onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertCloseModal;
