import { useCallback, useEffect } from 'react';
import {
  BrowserRouter, Navigate, Outlet, Route, Routes, useLocation,
} from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import routes from './utils/routes';
import AuthProvider, { ManagementType, useAuthContext, UserType } from './store/auth.store';
import LoginPage from './pages/login/loginPage';
import MainPage from './pages/mainPage/mainPage';
import SetFirstPasswordPage from './pages/setFirstPassword/setFirstPasswordPage';
import ResetPasswordPage from './pages/resetPassword/resetPasswordPage';
import ResetPasswordSentPage from './pages/resetPassword/resetPasswordSentPage';
import SetPasswordPage from './pages/setPassword/setPasswordPage';
import SetPasswordSuccessPage from './pages/setPasswordSuccess/setPasswordSuccessPage';
import ChangePasswordPage from './pages/changePassword/changePasswordPage';
import SetFirstPasswordSuccessPage from './pages/setFirstPasswordSuccess/setFirstPasswordSuccessPage';
import MailActivatedPage from './pages/mail_activated/mailActivatedPage';
import MasterDataSettingAdmin from './pages/master-data-setting/masterDataSettingAdminPage';
import MasterDataSettingGeneral from './pages/master-data-setting/masterDataSettingGeneralPage';
import ShippingAddressListPage from './pages/shipping-address-list/shippingAddressListPage';
import CategoryListPage from './pages/category-list/categoryListPage';
import DocTypeListPage from './pages/docTypeList/docTypeListPage';
import UserGroupSettingPage from './pages/userGroupSetting/userGroupSettingPage';
import DocRegisterScreenPage from './pages/docRegisterScreen/docRegisterScreenPage';
import CSVImportScreenPage from './pages/csvImportScreenPage/csvImportScreenPage';
import UserListScreenPage from './pages/userListScreen/userListScreenPage';
import SupplierListScreenPage from './pages/supplierListScreen/supplierListScreenPage';
import SupplierPermissionScreenPage from './pages/supplierPermissionScreen/supplierPermissionScreenPage';
import PermissionTemplateCreationPage from './pages/permissionTemplateCreationPage/permissionTemplateCreationPage';
import AdminUserListScreenPage from './pages/adminUserListScreen/adminUserListScreenPage';
import WareHousingRequestScreenPage from './pages/wareHousingRequestScreen/wareHousingRequestScreenPage';
import DisposalRequestScreenPage from './pages/disposalRequestScreen/disposalRequestScreenPage';
import IssueRequestScreenPage from './pages/issueRequestScreen/issueRequestScreenPage';
import OperationLogListScreenPage from './pages/operationLogListScreen/operationLogListScreenPage';
import RemoteScanScreenPage from './pages/remoteScanScreen/remoteScanScreenPage';
import ConfirmedRequestListScreenPage from './pages/confirmedRequestListScreen/confirmedRequestListScreenPage';
import NonDocumentPermissionScreenPage from './pages/nonDocumentPermissionScreen/nonDocumentPermissionScreenPage';
import SignSystemSettingScreenPage from './pages/signSystemSettingScreen/signSystemSettingScreenPage';
import UserSwitchScreenPage from './pages/userSwitchScreen/userSwitchScreenPage';
import AdminIpListScreenPage from './pages/adminIpListScreen/adminIpListScreenPage';
import IpListScreenPage from './pages/ipListScreen/ipListScreenPage';
import ModalProvider from './store/modal.store';
import UserRegisterFromCsvScreenPage from './pages/csvImportScreenPage/userRegisterFromCsvScreenPage';
import { useGetUserApi } from './hooks/api/auth.hook';
import { useMessageModal } from './hooks/modal.hook';
import { ApiError } from './services/http';
import ControlCode from './utils/controlCode';
import IpErrorPage from './pages/ipErrorScreen/ipErrorPage';
import TagBulkScreenPage from './pages/tagBulkScreen/tagBulkScreenPage';
import TagMaintenancePage from './pages/tagMaintenancePage';
import LoginSsoCallbackPage from './pages/loginSsoCallback/loginSsoCallbackPage';
import LoginErrorPage from './pages/loginError/loginErrorPage';
import OrganizationAuthorizationSamlPage
  from './pages/OrganizationAuthenticationSamlPage/OrganizationAuthorizationSamlPage';

const controlCodeManagedRoutes: { [key: string]: ControlCode } = {};
controlCodeManagedRoutes[routes.issueRequestScreen] = ControlCode.Warehousing;
controlCodeManagedRoutes[routes.disposalRequestScreen] = ControlCode.Destruction;
controlCodeManagedRoutes[routes.wareHousingRequestScreen] = ControlCode.Receiving;
controlCodeManagedRoutes[routes.remoteScanScreen] = ControlCode.Scan;

function UnauthorizedRoute() {
  const { user } = useAuthContext();

  if (user) {
    if (user.userType === UserType.Terada) {
      return <Navigate to={routes.adminUserListScreen} />;
    }
    return <Navigate to={routes.main} />;
  }

  return <Outlet />;
}

function AuthRoute() {
  const { token, user } = useAuthContext();
  const getUser = useGetUserApi();
  const location = useLocation();
  const openMessageModal = useMessageModal();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        await getUser.request();
      } catch (e) {
        const isLoginPage = window.location.pathname === routes.login;

        if (isLoginPage) {
          return;
        }

        openMessageModal((e as ApiError)?.message);
      }
    };
    getUserInfo();
  }, [location]);

  if (!token) {
    return <Navigate to={routes.login} />;
  } if (!user?.passwordUpdate) {
    // compare pathname to avoid infinite redirects
    return location.pathname === routes.setFirstPassword ? <Outlet /> : <Navigate to={routes.setFirstPassword} />;
  }
  return <Outlet />;
}

function RoleRoute({ userType, managementType }: { userType: UserType, managementType?: Array<ManagementType> }) {
  const { user, hasPermission } = useAuthContext();
  const location = useLocation();

  const hasControlCodePermission = useCallback(() => {
    const cc = controlCodeManagedRoutes[location.pathname];
    if (cc) {
      return hasPermission(cc);
    }
    return true;
  }, [hasPermission, location.pathname]);

  // Don't use getUserType from here. Check against the raw value.

  // If user do not have permission, return to main.
  // Terada user cannot access main route, but it's ok. It will be correctly redirect on the next loop.
  if (!hasControlCodePermission()) return <Navigate to={routes.main} />;

  // Terada user
  if (user?.userType === UserType.Terada) {
    // When switch user is enabled
    if (user?.changeUser != null) {
      // If it's trying to access Terada route, redirect to a non-Terada route
      if (managementType === undefined) return <Navigate to={routes.main} />;
      // If it's a non-Terada route, accept the request if the switched user has auth to display the screen
      if (managementType.includes(user?.managementType)) return <Outlet />;

    // Switch user is disabled and the route is a Terada route
    } else if (managementType === undefined) {
      // Let it pass, it's a normal case
      return <Outlet />;
    }

    // It's a Terada user trying to access a non-Terada route
    // In this case redirect to a valid route
    return <Navigate to={routes.adminUserListScreen} />;
  }

  // General (non-Terada) users

  // Impossible case
  if (managementType === undefined) return <Navigate to={routes.main} />;
  // If the user and managementType match, let it pass
  if (user?.userType === userType && managementType.includes(user?.managementType)) return <Outlet />;

  // Any other case will redirect the user to the main screen, which he has permission to see
  // (compare pathname to avoid infinite redirects)
  return location.pathname === routes.main ? <Outlet /> : <Navigate to={routes.main} />;
}

function App() {
  return (
    <AuthProvider>
      <DndProvider backend={HTML5Backend}>
        <ModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<UnauthorizedRoute />}>
                <Route path="/" element={<Navigate to={routes.login} />} />
                <Route path={routes.login} element={<LoginPage />} />
                <Route path={routes.loginError} element={<LoginErrorPage />} />
                <Route path={routes.loginSsoCallback} element={<LoginSsoCallbackPage />} />
                <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
                <Route path={routes.resetPasswordSent} element={<ResetPasswordSentPage />} />
                <Route path={routes.setPassword} element={<SetPasswordPage />} />
                <Route path={routes.setPasswordSuccess} element={<SetPasswordSuccessPage />} />
                <Route path="*" element={<Navigate to={routes.login} />} />
              </Route>
              <Route path={routes.ipError} element={<IpErrorPage />} />
              <Route path={routes.mailActivate} element={<MailActivatedPage />} />
              <Route path="/" element={<AuthRoute />}>
                <Route path={routes.changePassword} element={<ChangePasswordPage />} />
                <Route path={routes.setFirstPassword} element={<SetFirstPasswordPage />} />
                <Route path={routes.setFirstPasswordSuccess} element={<SetFirstPasswordSuccessPage />} />

                <Route path="/" element={<RoleRoute userType={UserType.Terada} />}>
                  <Route path={routes.masterDataSettingAdmin} element={<MasterDataSettingAdmin />} />
                  <Route path={routes.adminUserListScreen} element={<AdminUserListScreenPage />} />
                  <Route path={routes.categoryList} element={<CategoryListPage />} />
                  <Route path={routes.supplierListScreen} element={<SupplierListScreenPage />} />
                  <Route path={routes.supplierPermissionScreen} element={<SupplierPermissionScreenPage />} />
                  <Route path={routes.userSwitchScreen} element={<UserSwitchScreenPage />} />
                  <Route path={routes.adminIpListScreen} element={<AdminIpListScreenPage />} />
                  <Route path="*" element={<Navigate to={routes.adminUserListScreen} />} />
                </Route>

                <Route path="/" element={<RoleRoute userType={UserType.General} managementType={[ManagementType.Admin]} />}>
                  <Route path={routes.masterDataSettingGeneral} element={<MasterDataSettingGeneral />} />
                  <Route path={routes.organizationAuthenticationSaml} element={<OrganizationAuthorizationSamlPage />} />
                  <Route path={routes.shippingAddressList} element={<ShippingAddressListPage />} />
                  <Route path={routes.docTypeList} element={<DocTypeListPage />} />
                  <Route path={routes.userGroupSetting} element={<UserGroupSettingPage />} />
                  <Route path={routes.userListScreen} element={<UserListScreenPage />} />
                  <Route path={routes.signSystemSettingScreen} element={<SignSystemSettingScreenPage />} />
                  <Route path={routes.permissionTemplateCreation} element={<PermissionTemplateCreationPage />} />
                  <Route path={routes.operationLogListScreen} element={<OperationLogListScreenPage />} />
                  <Route path={routes.nonDocumentPermissionScreen} element={<NonDocumentPermissionScreenPage />} />
                  <Route path={routes.userRegisterFromCsvScreen} element={<UserRegisterFromCsvScreenPage />} />
                  <Route path={routes.userIpListScreen} element={<IpListScreenPage />} />
                  <Route path={routes.tagBulk} element={<TagBulkScreenPage />} />
                  <Route path={routes.tagMaintenance} element={<TagMaintenancePage />} />
                  <Route path="*" element={<Navigate to={routes.main} />} />
                </Route>
                <Route path="/" element={<RoleRoute userType={UserType.General} managementType={[ManagementType.Admin, ManagementType.General]} />}>
                  <Route path={routes.main} element={<MainPage />} />
                  <Route path={routes.confirmedRequestListScreen} element={<ConfirmedRequestListScreenPage />} />
                  <Route path={routes.docRegisterScreen} element={<DocRegisterScreenPage />} />
                  <Route path={routes.csvImportScreen} element={<CSVImportScreenPage />} />
                  <Route path={routes.wareHousingRequestScreen} element={<WareHousingRequestScreenPage />} />
                  <Route path={routes.disposalRequestScreen} element={<DisposalRequestScreenPage />} />
                  <Route path={routes.issueRequestScreen} element={<IssueRequestScreenPage />} />
                  <Route path={routes.remoteScanScreen} element={<RemoteScanScreenPage />} />
                  <Route path="*" element={<Navigate to={routes.main} />} />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to={routes.login} />} />
            </Routes>
          </BrowserRouter>
        </ModalProvider>
      </DndProvider>
    </AuthProvider>

  );
}

export default App;
