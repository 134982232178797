import { CSSProperties } from 'react';
import styles from './screenModal.module.css';

interface Props {
  hidden: boolean
  children: React.ReactNode
  customStyles?: CSSProperties
  handleCancel?: () => void;
  closeOnOutsideClick?: boolean;
}

function Component({
  children, hidden, customStyles, handleCancel, closeOnOutsideClick = true,
}:Props) {
  return (
    <div className={styles.alertBox} style={{ display: hidden ? 'none' : 'block' }}>
      <div
        className={styles.modalContainer}
        role="dialog"
        onClick={() => {
          if (closeOnOutsideClick) {
            handleCancel?.();
          }
        }}
      >
        <div className={[styles.modal1].join(' ')} style={customStyles} role="dialog" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Component;
