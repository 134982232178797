import axios from 'axios';

export interface MailActivateForm {
  id: string | null,
  token: string | null,
}

export default {
  /**
   * 指定されたメールアドレスを有効化します。
   *
   * @param form - メールアドレス有効化フォーム
   * @returns - 空のプロミス
   */
  async mailActivate(form: MailActivateForm): Promise<void> {
    const res = await axios.post('/UseRegistration/confirmMailAddress', form);
    const { data } = res;
    return data;
  },
};
