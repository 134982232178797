import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { AgGridReact } from 'ag-grid-react';
import { useLocation } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu';
import Button from '../Button/button';
import Formatter from '../../utils/formatters';
import { LogControlName } from '../../utils/log.utils';
import { useCreateLogApi } from '../../hooks/api/log.hook';

export interface DownloadTableButtonProps<T> {
  className?: string;
  style?: React.CSSProperties;
  tableRef: React.RefObject<AgGridReact<T>>;
  filePrefix?: string;
  columnsToExport?: string[];
  logFormName?: string;
}

const dropdownItems = [
  { text: 'CSV', value: 'csv' },
  { text: 'Excel', value: 'excel' },
];

export default function ButtonTableDownload<T>({
  className, style, tableRef, filePrefix = '', columnsToExport, logFormName,
}: DownloadTableButtonProps<T>) {
  const { pathname } = useLocation();
  const { request: createLog } = useCreateLogApi();

  // Export default columns or columns passed by props, to avoid exporting unnecessary columns.
  const allColumns = tableRef.current?.columnApi?.getColumns();
  const columns = columnsToExport || allColumns?.map((column) => column.getColId());

  const routePrefix = useMemo(() => pathname.split('/')[1], [pathname]);

  const downloadOptions = useCallback((type: string) => {
    if (logFormName) createLog(logFormName, LogControlName.Download);
    const fileNameDownload = `${filePrefix || routePrefix}_${Formatter.fromLocalDateTimeToString(DateTime.local(), Formatter.defaultDateTimeFormat)}`;
    if (type === 'excel') tableRef.current?.api.exportDataAsExcel({ fileName: fileNameDownload, columnKeys: columns });
    else if (type === 'csv') tableRef.current?.api.exportDataAsCsv({ fileName: fileNameDownload, columnKeys: columns });
  }, [tableRef.current]);

  return (
    <div className={className} style={style}>
      <DropdownMenu options={dropdownItems} left onSelect={downloadOptions}>
        <Button size="smaller" color="lighterGray">ダウンロード</Button>
      </DropdownMenu>
    </div>
  );
}
