import axios from 'axios';

export interface UserGroupSearchForm {
  id?: number,
}

export interface UserGroupUpdateFormUser {
  userId: string
}

export interface UserGroupUpdateFormUserGroupList {
  userGroupId?: number
  userGroupName: string
  process: string
  userList: UserGroupUpdateFormUser[]
}

export interface UserGroupUpdateForm {
  userGroupList: UserGroupUpdateFormUserGroupList[]
}

export interface UserGroupUser {
  id: string,
  name: string,
  userIcon: string,
}

export interface UserGroup {
  id: number,
  name: string,
  users: UserGroupUser[],
}

export default {
  /**
   * ユーザーグループを検索する関数
   *
   * @param form - ユーザーグループ検索フォーム
   * @returns - 検索結果のユーザーグループリスト
   */
  async search(form: UserGroupSearchForm): Promise<UserGroup[]> {
    const res = await axios.post('/UserGroup/search', form);
    const { data } = res;
    return data.userGroupList.map((item: {
      id: number,
      name: string,
      userList: {
        id: string,
        name: string,
        userIcon: string,
      }[]
    }): UserGroup => ({
      id: item.id,
      name: item.name,
      users: item.userList,
    }
    ));
  },

  /**
   * ユーザーグループを更新する関数
   *
   * @param form - ユーザーグループ更新フォーム
   * @returns - 更新結果のメッセージ
   */
  async update(form: UserGroupUpdateForm): Promise<string> {
    const res = await axios.post('/UserGroup/update', {
      userGroupList: form.userGroupList.map((item: UserGroupUpdateFormUserGroupList):UserGroupUpdateFormUserGroupList => ({
        ...item,
        userGroupId: item.userGroupId,
      })),
    });
    const { data } = res;
    return data;
  },

};
