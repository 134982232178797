import axios from 'axios';

export interface IpInfo {
  ipAddress: string,
  explanation: string,
}

export interface GetIpListForm {
  customerId?: number,
}

export interface GetIpListResponse {
  firewallUseFlg: number,
  blockingUseFlg: number,
  allowIpAddressList: IpInfo[],
}

export interface SaveIpListForm {
  customerId?: number,
  firewallUseFlg: number,
  blockingUseFlg: number,
  allowIpAddressList: IpInfo[],
}

export default {
  async getIpList(form: GetIpListForm): Promise<GetIpListResponse> {
    const res = await axios.get('/AllowIpAddress/search', { params: { customerId: form.customerId } });
    const { data } = res;
    return data;
  },

  async saveIpList(list: SaveIpListForm): Promise<void> {
    const res = await axios.post('/AllowIpAddress/register', list);
    const { data } = res;
    return data;
  },
};
