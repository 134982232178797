/**
 * タグのフォーマットを表す列挙型です。
 */
enum TagFormat {
  NUMBER = 1,
  STRING = 2,
  DATE = 3,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace TagFormat {

  /**
   * TagFormatに対応するタイトルを返します。
   *
   * @param tagFormat - TagFormat
   * @returns - タイトル
   */
  export function getPlaceholder(tagFormat: TagFormat): string {
    switch (tagFormat) {
      case TagFormat.NUMBER: return '半角数字';
      case TagFormat.STRING: return 'テキスト';
      case TagFormat.DATE: return 'YYYY/MM/DD';

      default:
        return '不明';
    }
  }

  /**
   * TagFormatと、タイトルをキー・バリュー形式で返します。
   *
   * @returns - TagFormatに対応する文字列マップを返す
   */
  export function getTextMap() {
    return { [TagFormat.STRING]: '文字列', [TagFormat.NUMBER]: '数値', [TagFormat.DATE]: '日付' };
  }

  /**
   * 特定のTagFormatに対応する値の検証を行い、適切な値を返します。
   *
   * @param tagFormat - TagFormat
   * @param value - 入力値
   * @returns -  検証済みの値を返す
   */
  export function validate(tagFormat: TagFormat, value: string): string {
    if (tagFormat === TagFormat.NUMBER) {
      const validNumberRegex = /^[-+]?[0-9]*\.?[0-9]+$/;

      if (validNumberRegex.test(value)) {
        const parsedNumber = parseFloat(value);
        if (!Number.isNaN(parsedNumber) && Number.isFinite(parsedNumber)) {
          return parsedNumber.toString();
        }
      }
      return '';
    }
    return value;
  }

  /**
   * TagFormatに対応する型名を返します。
   *
   * @param tagType - TagFormat
   * @returns 型名を返す
   */
  export function getType(tagType: TagFormat): string {
    switch (tagType) {
      case TagFormat.NUMBER: return '数値';
      case TagFormat.STRING: return '文字列';
      case TagFormat.DATE: return '日付';
      default: return '不明';
    }
  }

  export function getValidErrorText(tagFormat: TagFormat): string {
    switch (tagFormat) {
      case TagFormat.NUMBER: return '値が不正です';
      case TagFormat.DATE: return '日付が不正です';
      default: return '不明';
    }
  }

}

export default TagFormat;
