import { useNavigate } from 'react-router-dom';
import styles from './loginPage.module.css';
import globalConfig from '../../rest/config';
import { useQuery } from '../../hooks/router.hook';
import routes from '../../utils/routes';
import Button from '../../components/Button/button';
import mainStyles from '../main.module.css';

/**
 */
function LoginErrorPage() {
  const navigator = useNavigate();

  const query = useQuery();

  return (
    <div id={styles.parentframe}>
      <div className={styles.mainframe}>
        <div className={styles.errorText}>{query.error}</div>
        <Button content="ログイン画面に戻る" className={mainStyles['mt-4']} onClick={() => navigator(routes.login)} />
      </div>
      <div className={styles.appVersion}>
        {globalConfig.appVersion}
      </div>
    </div>
  );
}

export default LoginErrorPage;
