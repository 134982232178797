import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './shippingAddressListPage.module.css';
import stylesMain from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import {
  useCollectAndDeliverDeleteApi,
  useCollectAndDeliverRegisterApi,
  useCollectAndDeliverSearchApi,
  useCollectAndDeliverUpdateApi,
} from '../../hooks/api/collectAndDeliver.hook';
import { CollectAndDeliver, CollectAndDeliverUpdateForm } from '../../services/http/collectAndDeliver.api';
import Button from '../../components/Button/button';
import AlertModal, { alertModalInitialState } from '../../components/AlertModal';
import BaseTable from '../../components/BaseTable';
import ButtonTableDownload from '../../components/BaseTable/ButtonTableDownload';
import Breadcrumb from '../../components/Breadcrumb';
import routes from '../../utils/routes';
import Formatter from '../../utils/formatters';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';

/**
 * テーブル セル’ボタン’表示のプロップスインターフェース
 */
export interface TableBtnCellRenderProps {
  /** 取り扱うデータ */
  data: CollectAndDeliver,
  /** ボタンクリック時のハンドラー */
  onClick: (user: CollectAndDeliver) => void
}

/**
 * テーブル セル’ボタン’表示のコンポーネント
 */
function TableBtnCellRender({ data, onClick }: TableBtnCellRenderProps) {
  return (
    <div>
      <button
        type="button"
        className={`${styles.tableButton} ${stylesMain['btn-gray']}`}
        style={{ cursor: 'pointer' }}
        onClick={() => onClick(data)}
      >
        変更
      </button>
    </div>
  );
}

/**
 * 配送先一覧ページ
 */
function Component() {
  usePageTitle('配送先一覧');
  const openMessageModal = useMessageModal();

  // Refs
  const gridRef = useRef<AgGridReact<CollectAndDeliver>>(null);

  // States
  const [collectAndDelivers, setCollectAndDelivers] = useState<CollectAndDeliver[]>([]);
  const [tableData, setTableData] = useState<CollectAndDeliver[]>([]);
  const [form, setForm] = useState<CollectAndDeliverUpdateForm | null>(null);
  const [modal, setModal] = useState(alertModalInitialState);

  const [selectedCollectAndDeliver, setSelectedCollectAndDeliver] = useState<CollectAndDeliver | undefined>(undefined);
  const [searchedCollectAndDeliver, setSearchedCollectAndDeliver] = useState<CollectAndDeliver | undefined>(undefined);

  // API's
  const { request: collectAndDeliverSearchApiRequest } = useCollectAndDeliverSearchApi();
  const collectAndDeliverRegisterApi = useCollectAndDeliverRegisterApi();
  const collectAndDeliverUpdateApi = useCollectAndDeliverUpdateApi();
  const collectAndDeliverDeleteApi = useCollectAndDeliverDeleteApi();
  const { request: createLog } = useCreateLogApi();

  // Methods
  const resetPage = useCallback(async () => {
    setSelectedCollectAndDeliver(undefined);
    setForm(null);
    const res = await collectAndDeliverSearchApiRequest();
    setCollectAndDelivers(res);
  }, [collectAndDeliverSearchApiRequest]);

  // Callbacks
  const onClickSave = useCallback(async () => {
    if (!form) return;
    const isRegister = !selectedCollectAndDeliver?.ownerCd;

    setModal({
      open: true,
      text: isRegister ? '新規登録を行います。よろしいですか？' : '配送先情報を更新します。よろしいですか？',
      async onConfirm() {
        setModal({ ...modal, open: false });
        try {
          if (isRegister) {
            await collectAndDeliverRegisterApi.request({
              deliveryAddress1: form.deliveryAddress1,
              deliveryAddress2: form.deliveryAddress2,
              deliveryAddress3: form.deliveryAddress3,
              deliveryName: form.deliveryName,
              deliveryCd: form.deliveryCd,
              deliveryPhone: form.deliveryPhone,
              deliveryPostNo: form.deliveryPostNo,
              deliveryFax: form.deliveryFax,
              deliveryName4: form.deliveryName4,
              deliverySName: form.deliverySName,
              deliveryName3: form.deliveryName3,
            });
          } else {
            await collectAndDeliverUpdateApi.request(form);
          }
        } catch (e) {
          openMessageModal((e as ApiError)?.message);
        }
        resetPage();
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [form, selectedCollectAndDeliver]);

  const onClickDelete = useCallback(async () => {
    if (!form) return;
    if (!selectedCollectAndDeliver) return;
    const deleteForm = {
      deliveryCd: form.deliveryCd,
      updateDatetime: form.updateDatetime,
    };
    setModal({
      open: true,
      text: '配送先情報を削除します。よろしいですか？',
      async onConfirm() {
        setModal({ ...modal, open: false });
        try {
          await collectAndDeliverDeleteApi.request(deleteForm);
        } catch (e) {
          openMessageModal((e as ApiError)?.message);
        }
        setCollectAndDelivers(collectAndDelivers.filter((item) => item.ownerCd !== selectedCollectAndDeliver.ownerCd && item.deliveryCd !== selectedCollectAndDeliver.deliveryCd));
        resetPage();
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [form]);

  const onClickCancel = useCallback(() => {
    setModal({
      open: true,
      text: '入力内容は破棄されます。遷移してよろしいですか？',
      onConfirm: () => {
        setModal({ ...modal, open: false });
        setForm(null);
        setSelectedCollectAndDeliver(undefined);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [modal]);

  const isFormValid = useMemo(() => {
    if (!form) return false;
    const {
      deliveryCd, deliveryName, deliveryPostNo, deliveryAddress1, deliveryAddress2, deliveryName3, deliveryPhone,
    } = form;
    return deliveryName && deliveryPostNo && deliveryAddress1 && deliveryAddress2 && deliveryName3 && deliveryPhone && deliveryCd;
  }, [form]);

  const colDef = useMemo((): ColDef<CollectAndDeliver>[] => [
    AGUtils.colAutoIncrement('no'),
    {
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      field: 'button',
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onClick(item: CollectAndDeliver) {
          setSelectedCollectAndDeliver(item);
        },
      },
      width: 60,
    },
    AGUtils.colDefault('deliveryCd', '配送先コード'),
    {
      headerName: '配送先名称',
      resizable: true,
      field: 'deliveryName',
      cellClass: 'textFormat',
      flex: 1,
    }, {
      headerName: '住所',
      resizable: true,
      field: 'address',
      valueGetter: ({ data }) => [data?.deliveryPostNo, data?.deliveryAddress1, data?.deliveryAddress2, data?.deliveryAddress3].join(' '),
      flex: 1,
      cellClass: 'textFormat',
    },
  ], []);

  useEffect(() => {
    resetPage();
  }, []);

  useEffect(() => {
    setTableData(collectAndDelivers);
  }, [collectAndDelivers]);

  const onCollectAndDeliverSelected = useCallback(async (selected: CollectAndDeliver) => {
    setSearchedCollectAndDeliver(selected);
  }, []);

  const collectAndDeliverListOptions = useMemo(() => {
    const result: DropDownMenuItem<CollectAndDeliver>[] = [];

    collectAndDelivers.forEach((collect) => {
      result.push({
        text: `${collect.deliveryCd}：${collect.deliveryName}`,
        value: collect,
      });
    });

    return result;
  }, [collectAndDelivers]);

  // Sets table data every time a searchedCollectAndDeliver is selected
  useEffect(() => {
    if (!searchedCollectAndDeliver) return setTableData(collectAndDelivers);
    createLog(LogFormName.ShippingAddressList, LogControlName.Search);

    return setTableData(collectAndDelivers.filter((item) => item.deliveryCd === searchedCollectAndDeliver.deliveryCd || item.deliveryName === searchedCollectAndDeliver.deliveryName));
  }, [searchedCollectAndDeliver, collectAndDelivers]);

  useEffect(() => {
    if (selectedCollectAndDeliver) {
      setForm({
        deliveryCd: selectedCollectAndDeliver.deliveryCd,
        deliveryName: selectedCollectAndDeliver.deliveryName,
        deliveryPostNo: selectedCollectAndDeliver.deliveryPostNo,
        deliveryAddress1: selectedCollectAndDeliver.deliveryAddress1,
        deliveryAddress2: selectedCollectAndDeliver.deliveryAddress2,
        deliveryPhone: selectedCollectAndDeliver.deliveryPhone,
        deliveryName3: selectedCollectAndDeliver.deliveryName3,
        deliveryAddress3: selectedCollectAndDeliver.deliveryAddress3,
        actFlg: selectedCollectAndDeliver.actFlg,
        deliveryFax: selectedCollectAndDeliver.deliveryFax,
        deliveryName4: selectedCollectAndDeliver.deliveryName4,
        updateDatetime: selectedCollectAndDeliver.updateDatetime,
        updateUser: selectedCollectAndDeliver.updateUser,
      });
    }
  }, [selectedCollectAndDeliver]);

  useEffectOnce(() => {
    createLog(LogFormName.ShippingAddressList, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={`${styles.mainContent} ${stylesMain['pt-74px']}`}>
        <Breadcrumb crumbs={[
          { label: 'マスタ管理', route: routes.masterDataSettingGeneral },
          { label: document.title }]}
        />
        <div className={`${styles.mainContentGrid} ${stylesMain['h-100']}`}>
          <div className={`${styles.borderRightGrey} ${stylesMain['h-100']}`}>
            <div className={`${styles.tableContainer}`}>
              <div>
                <div style={{ display: 'flex', justifyItems: 'end' }}>
                  <AutoCompleteInput
                    className={`${styles.searchSelect} ${stylesMain['w-25']}`}
                    value={searchedCollectAndDeliver ? `${searchedCollectAndDeliver.deliveryCd}：${searchedCollectAndDeliver.deliveryName}` : ''}
                    options={collectAndDeliverListOptions}
                    onSelect={onCollectAndDeliverSelected}
                    deselectOption
                    searchIcon
                    onDeselect={() => {
                      setSearchedCollectAndDeliver(undefined);
                    }}
                  />
                </div>
                <div className={stylesMain['mt-10']}>
                  <Button
                    color="lighterGray"
                    size="smaller"
                    onClick={() => {
                      setForm({
                        actFlg: '1',
                        deliveryAddress1: '',
                        deliveryAddress2: '',
                        deliveryAddress3: '',
                        deliveryCd: '',
                        deliveryFax: '',
                        deliveryName: '',
                        deliveryName3: '',
                        deliveryName4: '',
                        deliveryPhone: '',
                        deliveryPostNo: '',
                        updateUser: '',
                      });
                      setSelectedCollectAndDeliver(undefined);
                    }}
                  >
                    配送先を登録
                  </Button>
                </div>
              </div>
              <div className={` ${styles.borderTopGrey}`} style={{ flex: 1 }}>
                <div className={`${stylesMain['text-right']}`}>
                  <div style={{ display: 'flex', justifyContent: 'end', padding: '10px' }}>
                    <ButtonTableDownload
                      tableRef={gridRef}
                      columnsToExport={['no', 'deliveryCd', 'deliveryName', 'address']}
                      logFormName={LogFormName.ShippingAddressList}
                    />
                  </div>
                </div>
                <div className={`${styles['table-height']}`}>
                  <BaseTable<CollectAndDeliver>
                    tableRef={gridRef}
                    formName="shippingAddressTable"
                    className={styles.table}
                    columnDefs={colDef}
                    rowData={tableData}
                    createLogOnDownloadTableData={LogFormName.ShippingAddressList}
                  />
                </div>
              </div>
            </div>
          </div>
          {form
            && (
              <div className={`${stylesMain['h-100']} ${styles.dGrid}`}>
                <div className={stylesMain['m-4']}>
                  <div className={styles.formWrapper}>
                    <div className={styles.title}>
                      配送先詳細
                    </div>
                    <div className={`${styles.form} ${stylesMain['w-100']}`}>
                      <div className={styles.input_field}>
                        {selectedCollectAndDeliver?.ownerCd && form.deliveryCd ? (
                          <>
                            <label>配送先コード</label>
                            {selectedCollectAndDeliver.deliveryCd}
                          </>
                        ) : (
                          <>
                            <label>
                              配送先コード
                              <span
                                className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}
                              >
                                ※
                              </span>
                            </label>
                            <input
                              value={form.deliveryCd}
                              onChange={(e) => setForm({
                                ...form,
                                deliveryCd: e.target.value,
                              })}
                            />
                          </>
                        )}
                      </div>
                      <div className={styles.input_field}>
                        <label>
                          配送先名称
                          <span className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}>※</span>
                        </label>
                        <input value={form.deliveryName} onChange={(e) => setForm({ ...form, deliveryName: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>
                          郵便番号
                          <span className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}>※</span>
                        </label>
                        <input value={form.deliveryPostNo} onChange={(e) => setForm({ ...form, deliveryPostNo: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>
                          都道府県
                          <span className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}>※</span>
                        </label>
                        <input value={form.deliveryAddress1} onChange={(e) => setForm({ ...form, deliveryAddress1: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>
                          市区町村番地
                          <span className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}>※</span>
                        </label>
                        <input value={form.deliveryAddress2} onChange={(e) => setForm({ ...form, deliveryAddress2: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>建物名</label>
                        <input value={form.deliveryAddress3} onChange={(e) => setForm({ ...form, deliveryAddress3: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>
                          担当者名
                          <span className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}>※</span>
                        </label>
                        <input value={form.deliveryName3} onChange={(e) => setForm({ ...form, deliveryName3: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>
                          電話番号
                          <span className={`${stylesMain['text-small']} ${stylesMain['text-danger']} ${stylesMain['float-right']} ${stylesMain['mr-3']}`}>※</span>
                        </label>
                        <input value={form.deliveryPhone} onChange={(e) => setForm({ ...form, deliveryPhone: e.target.value })} />
                      </div>
                      <div className={styles.input_field}>
                        <label>FAX番号</label>
                        <input value={form.deliveryFax} onChange={(e) => setForm({ ...form, deliveryFax: e.target.value })} />
                      </div>
                      {form.deliveryCd && selectedCollectAndDeliver?.ownerCd
                        && (
                          <div className={styles.input_field}>
                            <label>この配送先を無効にする</label>
                            <input type="checkbox" className={styles.inputCheckbox} checked={form.actFlg === '0'} onChange={(e) => setForm({ ...form, actFlg: e.target.checked ? '0' : '1' })} />
                          </div>
                        )}
                      <div className={styles.input_field}>
                        <label>配送先備考</label>
                        <textarea
                          className={styles.textArea}
                          value={form.deliveryName4}
                          onChange={(e) => setForm({ ...form, deliveryName4: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  {form.deliveryCd && selectedCollectAndDeliver?.ownerCd
                    && (
                      <div className={styles.registrationUpdateRow}>
                        <div className={`${stylesMain['mt-4']} ${styles.title}`}>
                          登録更新情報
                        </div>
                        <div className={styles.row}>
                          <div>
                            <label>登録者</label>
                            <div className={`${stylesMain['mb-3']} ${styles.text}`}>
                              {selectedCollectAndDeliver.registerUser}
                            </div>
                            <label>更新者</label>
                            <div className={`${stylesMain['mb-3']} ${styles.text}`}>
                              {selectedCollectAndDeliver.updateUser}
                            </div>
                          </div>
                          <div>
                            <label>登録日時</label>
                            <div className={`${stylesMain['mb-3']} ${styles.text}`}>
                              {selectedCollectAndDeliver.createDatetime && Formatter.toDisplayDate(selectedCollectAndDeliver.createDatetime, Formatter.defaultDateTimeFormat)}
                            </div>
                            <label>更新日時</label>
                            <div className={`${stylesMain['mb-3']} ${styles.text}`}>
                              {selectedCollectAndDeliver.updateDatetime && Formatter.toDisplayDate(selectedCollectAndDeliver.updateDatetime, Formatter.defaultDateTimeFormat)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className={`${styles.row} ${styles.footerStyle}`}>
                  <div>
                    <Button size="smaller" color="lighterGray" onClick={onClickCancel}>キャンセル</Button>
                  </div>
                  <div className={stylesMain['text-right']}>
                    {selectedCollectAndDeliver && selectedCollectAndDeliver.ownerCd && <Button size="smaller" color="lighterGray" onClick={onClickDelete}>削除</Button>}
                    <Button className={`${stylesMain['ml-10']}`} disabled={!isFormValid} size="smaller" onClick={onClickSave}>
                      保存
                    </Button>
                  </div>
                </div>
              </div>
            )}
        </div>
        <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} textCenter />
      </div>
    </MainFrame>
  );
}

export default Component;
