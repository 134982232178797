import axios from 'axios';
import { SortList } from './documents.api';
import { Paginated } from '../../components/BaseTable/partials/PaginationJp/mannual';

export interface LogSearchForm {
  operationDatetimeFrom?: string,
  operationDatetimeTo?: string,
  userId?: string,
  email?: string,
  operationDetailsCd?: string
  sortList?: SortList[]
}

export interface LogList {
  logId?: number,
  operationDatetime: string,
  userName: string,
  email: string,
  ipAddress: string,
  operationDetailsCd: string,
  resultCd: string,
  targetContent: string,
  detailFlg: boolean,
}

export interface LogSearchResponse extends Paginated {
  logList: LogList[]
}

export interface OperationDetailsCd {
  operationDetailsCd: string,
}

export interface LogCreateForm {
  formName: string,
  controlName: string,
}

export interface CreateDownloadFileLogForm {
  documentDataId: number
  isPreview: number
}

export interface CreateUserDetailShowLogForm {
  userId: string
}

export interface LogGetDetailForm {
  logId?: number,
}

export interface LogDetail {
  operationDatetime: string,
  userName: string,
  email: string,
  ipAddress: string,
  operationDetailsCd: string,
  targetContent?: string,
  detailBefore?: string,
  detailAfter?: string,
}

export default {
  /**
   * 指定された条件でログを検索します。
   *
   * @param form - ログ検索フォーム
   * @param p - ページ番号
   * @returns - 検索結果
   */
  async search(form: LogSearchForm, p: number): Promise<LogSearchResponse> {
    const res = await axios.post('/LogSearch/getLog', { ...form, page: p });
    const { data } = res;
    return data;
  },

  /**
  * 操作詳細コードの一覧を取得します。
   *
  * @returns - 操作詳細コードの一覧
  */
  async getOperationDetailsCd(): Promise<OperationDetailsCd[]> {
    const res = await axios.get('/LogSearch/getOperationDetailsCd');
    const { data } = res;
    return data.operationDetailsCdList.map((item: {
      operationDetailsCd: string,
    }): OperationDetailsCd => ({
      operationDetailsCd: item.operationDetailsCd,
    }));
  },

  /**
   * ログを作成します。
   * @param form - ログ作成フォーム
   * @returns - 空のPromise
   */
  async create(form: LogCreateForm): Promise<void> {
    const res = await axios.post('/Log/setLog', { formName: form.formName, controlName: form.controlName });
    return res.data;
  },

  /**
   * ログをダウンロードします。
   * @param form - ログダウンロードフォーム
   * @returns - 空のPromise
   */
  async createDownloadFileLog(form: CreateDownloadFileLogForm): Promise<void> {
    const res = await axios.post('/Log/setLogDownloadFile', { documentDataId: form.documentDataId, isPreview: form.isPreview });
    return res.data;
  },

  /**
   * ユーザ詳細表示ログを作成します。
   * @param form - ユーザ詳細表示ログ作成フォーム
   * @returns - 空のPromise
   */
  async createUserDetailShowLog(form: CreateUserDetailShowLogForm): Promise<void> {
    const res = await axios.post('/Log/setLogUserDetail', { userId: form.userId });
    return res.data;
  },

  async getDetail(form: LogGetDetailForm): Promise<LogDetail> {
    const res = await axios.get<LogDetail>('/LogSearch/getDetail', { params: { logId: form.logId } });
    return res.data as LogDetail;
  },
};
