import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './loginPage.module.css';
import globalConfig from '../../rest/config';
import { useQuery } from '../../hooks/router.hook';
import { useAuthContext } from '../../store/auth.store';
import routes from '../../utils/routes';
import { useGetUserApi } from '../../hooks/api/auth.hook';

/**
 * ログインページ
 */
function LoginSsoCallbackPage() {
  const navigator = useNavigate();

  const query = useQuery();
  const authContext = useAuthContext();
  const getUser = useGetUserApi();

  async function redirect() {
    if (!query.token) return;
    authContext.setToken(query.token);
    const user = await getUser.request();
    navigator(routes.main);
  }

  useEffect(() => {
    redirect();
  }, []);

  // Handler
  return (
    <div />
  );
}

export default LoginSsoCallbackPage;
