import axios from 'axios';
import { DateTime } from 'luxon';
import * as uuid from 'uuid';
import { ManagementType } from '../../store/auth.store';

export enum IconChangeStatus {
  notChanged = 0,
  changed = 1,
  deleted = 2,
}

export interface UserSearchForm {
  userName?: string,
  email?: string,
  userAuthenticationType?: ManagementType,
  userGroupName?: string,
  deleteFlg?: number,
}

export interface UserDeleteForm {
  userId: string,
}

export interface UserRegisterForm {
  userName: string,
  email: string,
  password: string,
  icon?: File | string,
  userAuthenticationType: ManagementType,
  userGroupId?: number,
}

export interface UserUpdateForm {
  id: string,
  userName: string,
  email: string,
  iconChangeStatus: IconChangeStatus,
  icon?: File | string,
  userAuthenticationType: ManagementType,
  userMailSettingGroupList: UserMailSettingGroup[],
  deleteFlg: boolean,
}

export interface UserGroup {
  id: number,
  userGroupName: string,
}

export interface UserMailSettingGroup {
  id: string,
  mailSettingGroupName: string,
  userMailSettingList: UserMailSetting[],
}

export interface UserMailSetting {
  id: string,
  mailType: number,
  mailSettingName: string,
  mailStopFlg: boolean,
}

export interface User {
  id: string,
  userId: string,
  userName: string,
  email: string,
  userAuthenticationType: ManagementType,
  icon: string,
  emailConfirmed: boolean,
  passwordUpdate: boolean,
  deleteFlg: boolean,
  registerDate: Date,
  registerUser: string,
  updateDate: Date,
  updateUser: string,
  userGroupList: UserGroup[],
}

export interface UserDetail extends User {
  userMailSettingGroupList: UserMailSettingGroup[],
}

export interface UserFromCSV {
  userName?: string,
  email?: string,
  adminFlg?: string,
  password?: string,
}

export interface UserFromCSVList {
  file: UserFromCSV[]
}

function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export default {
  /**
   * ユーザーの検索関数
   *
   * @param form - 検索フォーム
   * @returns - 検索結果のユーザー一覧
   */
  async search(form: UserSearchForm): Promise<User[]> {
    const res = await axios.get('/User/search', {
      params: {
        userName: form.userName || undefined,
        email: form.email || null,
        userAuthenticationType: form.userAuthenticationType || null,
        userGroupName: form.userGroupName || null,
        deleteFlg: form.deleteFlg !== undefined ? form.deleteFlg : undefined,
      },
    });
    const { data } = res;
    return data.userList.map((item: {
      id: string,
      userId: string,
      userName: string,
      email: string,
      userAuthenticationType: number,
      icon: string,
      emailConfirmed: number,
      passwordUpdate: number,
      deleteFlg: number,
      registerDate: string,
      registerUser: string,
      updateDate: string,
      updateUser: string,
      userGroupList: UserGroup[],
    }): User => ({
      id: item.id,
      userId: item.userId,
      userName: item.userName,
      email: item.email,
      userAuthenticationType: item.userAuthenticationType === ManagementType.Admin ? ManagementType.Admin : ManagementType.General,
      icon: item.icon,
      emailConfirmed: !!item.emailConfirmed,
      passwordUpdate: !!item.passwordUpdate,
      deleteFlg: !!item.deleteFlg,
      registerDate: DateTime.fromISO(item.registerDate).toJSDate(),
      registerUser: item.registerUser,
      updateDate: DateTime.fromISO(item.updateDate).toJSDate(),
      updateUser: item.updateUser,
      userGroupList: item.userGroupList,
    }
    ));
  },
  /**
   * ユーザーの詳細情報を取得する関数
   *
   * @param form - ユーザーID
   * @returns - ユーザーの詳細情報
   */
  async getDetail(form: { id: string }): Promise<UserDetail> {
    const res = await axios.get('/User/getDetail', {
      params: { id: form.id },
    });
    const { data } = res;
    return {
      id: data.id,
      userId: '',
      userName: data.userName,
      email: data.email,
      userAuthenticationType: data.userAuthenticationType === ManagementType.Admin ? ManagementType.Admin : ManagementType.General,
      icon: data.icon,
      emailConfirmed: !!data.emailConfirmed,
      passwordUpdate: !!data.passwordUpdate,
      deleteFlg: !!data.deleteFlg,
      registerDate: DateTime.fromISO(data.registerDate).toJSDate(),
      registerUser: data.registerUser,
      updateDate: DateTime.fromISO(data.updateDate).toJSDate(),
      updateUser: data.updateUser,
      userGroupList: data.userGroupList,
      userMailSettingGroupList: data.userMailSettingGroupList.map((mailSettingGroup: { mailSettingGroupName: string, userMailSettingList: { mailType: number, mailSettingName: string, mailStopFlg: number }[] }) => ({
        id: uuid.v1(),
        mailSettingGroupName: mailSettingGroup.mailSettingGroupName,
        userMailSettingList: mailSettingGroup.userMailSettingList.map((mailSetting: { mailType: number, mailSettingName: string, mailStopFlg: number }) => ({
          id: uuid.v1(),
          mailType: mailSetting.mailType,
          mailSettingName: mailSetting.mailSettingName,
          mailStopFlg: Boolean(mailSetting.mailStopFlg),
        })),
      })),
    };
  },
  /**
   * ユーザーの登録関数
   *
   * @param form - ユーザー登録フォーム
   * @returns - 空のプロミス
   */
  async register(form: UserRegisterForm): Promise<void> {
    let userRegisterForm;
    if (typeof form.icon === 'string') {
      userRegisterForm = {
        userName: form.userName,
        email: form.email,
        password: form.password,
        icon: form.icon,
        userAuthenticationType: form.userAuthenticationType,
        userGroupId: form.userGroupId ? form.userGroupId : undefined,
      };
    } else {
      userRegisterForm = {
        userName: form.userName,
        email: form.email,
        password: form.password,
        icon: form.icon ? await toBase64(form.icon) : undefined,
        userAuthenticationType: form.userAuthenticationType,
        userGroupId: form.userGroupId ? form.userGroupId : undefined,
      };
    }
    const res = await axios.post('/User/register', userRegisterForm);
    return res.data;
  },
  /**
   * ユーザーの更新関数
   *
   * @param form - ユーザー更新フォーム
   * @returns - 空のプロミス
   */
  async update(form: UserUpdateForm): Promise<void> {
    let userUpdateForm;
    if (typeof form.icon === 'string') {
      userUpdateForm = {
        id: form.id,
        userName: form.userName,
        email: form.email,
        iconChangeStatus: form.iconChangeStatus.toString(),
        icon: form.icon,
        userAuthenticationType: form.userAuthenticationType,
        deleteFlg: form.deleteFlg ? 1 : 0,
        userMailSettingList: form.userMailSettingGroupList.map((mailSettingGroup) => mailSettingGroup.userMailSettingList).flat().map((mailSetting) => ({
          mailType: mailSetting.mailType,
          mailSettingName: mailSetting.mailSettingName,
          mailStopFlg: mailSetting.mailStopFlg ? 1 : 0,
        })),
      };
    } else {
      userUpdateForm = {
        id: form.id,
        userName: form.userName,
        email: form.email,
        iconChangeStatus: form.iconChangeStatus.toString(),
        icon: form.icon ? await toBase64(form.icon) : undefined,
        userAuthenticationType: form.userAuthenticationType,
        deleteFlg: form.deleteFlg ? 1 : 0,
        userMailSettingList: form.userMailSettingGroupList.map((mailSettingGroup) => mailSettingGroup.userMailSettingList).flat().map((mailSetting) => ({
          mailType: mailSetting.mailType,
          mailSettingName: mailSetting.mailSettingName,
          mailStopFlg: mailSetting.mailStopFlg ? 1 : 0,
        })),
      };
    }
    const res = await axios.post('/User/update', userUpdateForm);
    return res.data;
  },

  /**
   * ユーザーの削除関数
   *
   * @param form - ユーザーID
   * @returns - 空のプロミス
   */
  async delete(form: UserDeleteForm): Promise<void> {
    return axios.post('/User/delete', form);
  },
  /**
   * CSV一覧からユーザーを登録する関数
   *
   * @param form - CSV一覧からユーザーフォーム
   * @returns - 空のプロミス
   */
  async registerUsersFromCsv(form: UserFromCSVList): Promise<void> {
    const res = await axios.post('/User/importCsv', form);
    const { data } = res;
    return data;
  },
};
