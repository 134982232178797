import axios from 'axios';
import { DateTime } from 'luxon';

export interface GetUserAuthorityPresetResponse {
  presetList: UserAuthorityPreset[]
}

export interface UserAuthorityPreset {
  id: number
  presetName: string
  presetExplanation: string
  controlList: UserAuthorityPresetControl[]
}

export interface UserAuthorityPresetControl {
  controlCode: string
}

export interface GetUserAuthorityPresetDetailRequest {
  id: number
}

export interface GetUserAuthorityPresetDetail {
  id: number
  presetName: string
  presetExplanation: string
  registerDate: Date
  registerUser: string
  updateDate: Date,
  updateUser: string,
  controlList: UserAuthorityPresetControl[]
}

export interface PostUserAuthorityPresetRequest {
  presetName: string
  presetExplanation: string
  controlList: UserAuthorityPresetControl[]
}

export interface UpdateUserAuthorityPresetRequest {
  id: number
  presetName: string
  presetExplanation: string
  controlList: UserAuthorityPresetControl[]
}

export interface DeleteUserAuthorityPresetRequest {
  id: number
}

export default {
  /**
   * ユーザー権限プリセットを取得する関数
   *
   * @returns - 取得されたユーザー権限プリセット
   */
  Get: async (): Promise<GetUserAuthorityPresetResponse> => {
    const res = await axios.get('/UserAuthorityPreset/getPreset');
    const { data } = res;
    return data as GetUserAuthorityPresetResponse;
  },
  /**
   * ユーザー権限プリセットの詳細を取得する関数
   *
   * @param request - ユーザー権限プリセット詳細取得リクエスト
   * @returns - 取得されたユーザー権限プリセット詳細
   */
  GetDetail: async (request: GetUserAuthorityPresetDetailRequest): Promise<GetUserAuthorityPresetDetail> => {
    const res = await axios.get('/UserAuthorityPreset/getDetail', { params: request });
    const { data } = res;
    return {
      id: data.id,
      presetName: data.presetName,
      presetExplanation: data.presetExplanation,
      registerDate: DateTime.fromISO(data.registerDate).toJSDate(),
      registerUser: data.registerUser,
      updateDate: DateTime.fromISO(data.updateDate).toJSDate(),
      updateUser: data.updateUser,
      controlList: data.controlList,
    };
  },
  /**
   * ユーザー権限プリセットを登録する関数
   *
   * @param request - ユーザー権限プリセット登録リクエスト
   * @returns - 登録されたプリセットID
   */
  Post: async (request: PostUserAuthorityPresetRequest): Promise<string> => {
    const res = await axios.post('/UserAuthorityPreset/register', request);
    const { data } = res;
    return data as string;
  },
  /**
   * ユーザー権限プリセットを更新する関数
   *
   * @param request - ユーザー権限プリセット更新リクエスト
   * @returns - 更新されたプリセットID
   */
  Update: async (request: UpdateUserAuthorityPresetRequest): Promise<string> => {
    const res = await axios.post('/UserAuthorityPreset/update', request);
    const { data } = res;
    return data as string;
  },
  /**
   * ユーザー権限プリセットを削除する関数
   *
   * @param request - ユーザー権限プリセット削除リクエスト
   * @returns - 削除されたプリセットID
   * @throws ApiError
   */
  Delete: async (request: DeleteUserAuthorityPresetRequest): Promise<string> => {
    const res = await axios.post('/UserAuthorityPreset/delete', request);
    const { data } = res;
    return data as string;
  },
};
