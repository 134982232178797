import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import UserSwitchApi, { SwitchUserForm, SwitchUserResponse } from '../../services/http/userSwitch.api';
import { useAuthContext } from '../../store/auth.store';

/**
 * ユーザー切替APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function UserSwitchHookApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const authContext = useAuthContext();

  const request = useCallback(async (form: SwitchUserForm): Promise<SwitchUserResponse> => {
    setLoading(true);
    try {
      const doc = await UserSwitchApi.switch(form);
      authContext.setToken(doc.token);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, error, request,
  };
}

/**
 * ユーザー切替をキャンセルAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function CancelUserSwitchHookApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const authContext = useAuthContext();

  const request = useCallback(async (): Promise<SwitchUserResponse> => {
    setLoading(true);
    try {
      const doc = await UserSwitchApi.cancelSwitch();
      authContext.setToken(doc.token);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}
