import { ColumnState } from 'ag-grid-community';
import axios from 'axios';

export interface GridSettingsResponse {
  settingJson: ColumnState[];
}

export interface GetGridSettingsRequest {
  formName: string;
}
export interface SetGridSettingsRequest {
  formName: string;
  settingJson: ColumnState[];
}

export default {
  /**
   * 指定されたグリッドの列設定を取得します。
   *
   * @param form - グリッド列設定取得フォーム
   * @returns - グリッド列設定
   */
  async getColumnSettings(form: GetGridSettingsRequest): Promise<GridSettingsResponse> {
    const res = await axios.get('/GridControl/getColumnSettings', { params: form });
    const { data } = res;
    return { settingJson: JSON.parse(data.settingJson) };
  },
  /**
   * 指定されたグリッドの列設定を設定します。
   *
   * @param form - グリッド列設定設定フォーム
   * @returns void
   */
  async setColumnSettings(form: SetGridSettingsRequest): Promise<void> {
    const res = await axios.post('/GridControl/setColumnSettings', {
      formName: form.formName,
      settingJson: JSON.stringify(form.settingJson),
    });
    const { data } = res;
    return data;
  },
};
