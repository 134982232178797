/**
 * システムページのルーティング定義
 */
export default {
  mailActivate: '/DCC12004S',
  loginTenant: '/loginTenant',
  login: '/DCB07001S',
  loginError: '/DCC15017S',
  loginSsoCallback: '/login/sso/callback',
  resetPassword: '/DCC12011S',
  resetPasswordSent: '/DCC12011S-sent',
  main: '/DCD16001C',
  changePassword: '/DCC12010S',
  setPassword: '/DCC12012S',
  setPasswordSuccess: '/DCC12012S-success',
  setFirstPassword: '/DCC12005S',
  setFirstPasswordSuccess: '/DCC12005S-success',
  masterDataSettingAdmin: '/DTC22002S',
  masterDataSettingGeneral: '/DCC22001S',
  shippingAddressList: '/DCD20006S',
  categoryList: '/DTD16005S',
  docTypeList: '/DCD16006S',
  userGroupSetting: '/DCC12009S',
  docRegisterScreen: '/DCD16003S',
  csvImportScreen: '/DCD16003S-csv',
  userRegisterFromCsvScreen: '/DCC12001S-csv',
  userListScreen: '/DCC12001S',
  signSystemSettingScreen: '/DCD19001S',
  supplierListScreen: '/DTC09001S',
  supplierPermissionScreen: '/DTC09003S',
  permissionTemplateCreation: '/DCC12007S',
  organizationAuthenticationSaml: '/DCC15016S',
  adminUserListScreen: '/DTC11001S',
  wareHousingRequestScreen: '/DCD20001S',
  disposalRequestScreen: '/DCD20003S',
  issueRequestScreen: '/DCD20002S',
  operationLogListScreen: '/DCA06001S',
  remoteScanScreen: '/DCD20004S',
  confirmedRequestListScreen: '/DCD20005S',
  nonDocumentPermissionScreen: '/DCC12006S',
  organizingChangeReserveDateScreen: '/DCC12008S',
  organizingChangeReserveUserGroupPermissionScreen: '/DCC12008S-user-group',
  organizingChangeReserveFolderPermissionScreen: '/DCC12008S-folder-permission',
  userSwitchScreen: '/DTA02001S',
  adminIpListScreen: '/DTC15013S',
  userIpListScreen: '/DCC15014S',
  ipError: '/DCC15015S',
  tagBulk: '/DCD16007S',
  tagMaintenance: '/DCD16008S',
};
