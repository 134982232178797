import { useDrag, useDrop } from 'react-dnd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './userGroupSettingPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import UserRegisterModal from '../userListScreen/userRegisterModal';
import UserDetailModal from '../userListScreen/userDetailModal';
import { useSearchUserApi } from '../../hooks/api/user.hook';
import { User } from '../../services/http/user.api';
import { useSearchUserGroupApi, useUpdateUserGroupApi } from '../../hooks/api/userGroup.hook';
import {
  UserGroup,
  UserGroupUpdateForm,
  UserGroupUpdateFormUserGroupList,
  UserGroupUser,
} from '../../services/http/userGroup.api';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import AlertModal, { alertModalInitialState, CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import Breadcrumb from '../../components/Breadcrumb';
import EditableLabel from '../../components/EditableLabel';
import { ApiError } from '../../services/http';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useMessageModal } from '../../hooks/modal.hook';

/**
 * ユーザーをユーザーグループ所属ユーザーに変換する関数
 * @param user - ユーザー
 * @returns - ユーザーグループ所属ユーザー
 */
function convertUserIntoUserGroupUser(user:User):UserGroupUser {
  return {
    id: user.id,
    name: user.userName,
    userIcon: user.icon,
  };
}

/**
 * ユーザーグループ所属ユーザーをユーザーに変換する関数
 * @param userGroupUser - ユーザーグループ所属ユーザー
 * @returns - ユーザー
 */
function convertUserGroupUserIntoUser(userGroupUser: UserGroupUser): User {
  return {
    id: `${userGroupUser.id}`,
    userId: `${userGroupUser.id}`,
    userName: userGroupUser.name,
    email: '',
    userAuthenticationType: 2,
    icon: userGroupUser.userIcon,
    emailConfirmed: true,
    passwordUpdate: true,
    deleteFlg: false,
    registerDate: new Date(),
    registerUser: '',
    updateDate: new Date(),
    updateUser: '',
    userGroupList: [],
  };
}

/**
 * アイテムをドラッグのプロップスインターフェース
 */
export interface DragItemProps {
  /** ユーザーの情報を含むオブジェクト */
  user:User
  /** ユーザーグループID */
  groupId?: string // if groupId has value, it means it has already attached to the dropItems
}

/**
 * ドラッグアイテムコンポーネント
 */
export function DragItem({ user, groupId, handleItemClick }:DragItemProps & { handleItemClick: (user: User) => void }) {
  const [, drag] = useDrag(() => ({
    type: 'User',
    item: {
      id: user.id,
      userName: user.userName,
      groupId, // if groupId is undefined, it is from left menu, if not it is from a drop item.
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <div className={styles['user-frame']} ref={drag}>
      <div className={styles['user-frame-user-icon']} role="presentation" onClick={() => handleItemClick(user)}>
        <img src="/images/provisory-images/user.svg" alt="" />
      </div>
      <div className={styles['user-frame-username']} title={user.userName}>
        {user.userName}
      </div>
    </div>
  );
}

/**
 * ドロップアイテムのプロップスインターフェース
 */
interface DropItemProps {
  /** ユーザーの情報を含むオブジェクト */
  user: UserGroupUser
  /** グループID */
  groupId: number // this indicate which group the drop item is dropped on
}

/**
 * ドロップアイテムのコンポーネント
 */
function DropItem({
  user, groupId, handleRemove, handleItemClick,
}:DropItemProps & { handleRemove: (selectedUser: UserGroupUser) => void, handleItemClick: (user: User) => void }, selectedUserGroupId?: number) {
  const [, drag] = useDrag(() => ({
    type: 'User',
    item: {
      id: user.id,
      userName: user.name,
      groupId, // If we drag from drop item, take groupId to indicate where it is from
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <div ref={drag} role="presentation">
      <div className={styles['mainframe-rightsection-mainsection-content-userframe']}>
        <div className={styles['mainframe-rightsection-mainsection-content-userframe-leftsection']}>
          <img src="/images/provisory-images/user.svg" alt="" onClick={() => handleItemClick(convertUserGroupUserIntoUser(user))} />
          <span className={styles['dropItem-username-text']} title={user.name}>{user.name}</span>
        </div>
        <div className={styles['mainframe-rightsection-mainsection-content-userframe-rightsection']}>
          <img
            src="/images/provisory-images/xmark-solid-gray.svg"
            title="削除"
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              handleRemove(user);
            }}
          />
        </div>
      </div>
    </div>
  );
}

/**
 * ドロップコンポーネントのプロップスインターフェース
 */
export interface DropComponentProps {
  /** ユーザーグループ */
  userGroup: UserGroup
  /** ドロップされたときのハンドラー */
  UserOnDrop: (groupId: number, selectedUser: UserGroupUser, selectedUserGroupId?: number) => void
  /** ユーザーが削除されたときのハンドラー */
  RemoveUserOnClick: (groupId: number, selectedUser: UserGroupUser) => void
  /** ユーザーがクリックされたときのハンドラー */
  handleItemClick: (user: User) => void,
}

/**
 * ドロップのコンポーネント
*/
export function DropComponent({
  userGroup, UserOnDrop, RemoveUserOnClick, handleItemClick,
}: DropComponentProps) {
  const [, dropArea] = useDrop(() => ({
    accept: 'User',
    drop: (item:User & { groupId?: number }) => {
      UserOnDrop(userGroup.id, convertUserIntoUserGroupUser(item), item.groupId);
    },
  }), [UserOnDrop]);

  return (
    <div ref={dropArea}>
      <div className={styles['mainframe-rightsection-mainsection-content']}>
        {userGroup.users.map((user) => (<DropItem user={user} groupId={userGroup.id} key={user.id} handleRemove={(u) => { RemoveUserOnClick(userGroup.id, u); }} handleItemClick={handleItemClick} />))}
      </div>
    </div>
  );
}

/**
 * カスタマイズされたユーザグループタイプ
 */
declare type CustomizedUserGroup = UserGroup & {
  deleted?: boolean
  created?: boolean
  editingName?: string
  defaultUsers?: UserGroupUser[]
};

/**
 * ユーザーグループ管理ページ
 */
function Component() {
  usePageTitle('ユーザーグループ管理');

  // useState hooks
  const [registerModal, setRegisterModal] = useState(false);
  const [defaultUsers, setDefaultUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [defaultUserGroups, setDefaultUserGroups] = useState<CustomizedUserGroup[]>([]);
  const [defaultUserGroupsHash, setDefaultUserGroupsHash] = useState<string>('');
  // 表示用
  const [userGroups, setUserGroups] = useState<CustomizedUserGroup[]>([]);

  const [userGroupSearchValue, setUserGroupSearchValue] = useState('');
  const [focusGroupId, setFocusGroupId] = useState<number | null>(null);
  const [checkedGroupIds, setCheckedGroupIds] = useState<number[]>([]);
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);

  const [hasChanges, setHasChanges] = useState(false);
  const openMessageModal = useMessageModal();

  // useApi hooks
  const { request: searchUserApiRequest } = useSearchUserApi();
  const { request: searchUserGroupApiRequest } = useSearchUserGroupApi();
  const { request: updateUserGroupApiRequest } = useUpdateUserGroupApi();
  const { request: createLog } = useCreateLogApi();

  // useEffect hooks
  useEffect(() => {
    searchUserApiRequest({ deleteFlg: 0 }).then((res) => {
      setUsers(res);
      setDefaultUsers(res);
    }).catch((e) => {
      console.error(e);
    });
  }, [searchUserApiRequest, registerModal, selectedUser]);

  const getHash = useCallback((group: CustomizedUserGroup) => group.name + group.users.map((u) => u.id).join('') + group.id + group.deleted, []);
  const getAllGroupsHashes = useCallback((group: CustomizedUserGroup[]): string => group.map((g) => getHash(g)).join(''), [getHash]);

  const reloadGroupInfo = useCallback(async () => {
    try {
      createLog(LogFormName.UserGroupSetting, LogControlName.Search);
      const res = await searchUserGroupApiRequest({});
      const processedUserGroups = res.map((_res) => ({ ..._res, editingName: _res.name, defaultUsers: [..._res.users] }));
      setDefaultUserGroups(processedUserGroups);
      setDefaultUserGroupsHash(getAllGroupsHashes(processedUserGroups));
      getAllGroupsHashes(processedUserGroups);
    } catch (e) {
      console.error(e);
    }
  }, [getAllGroupsHashes, searchUserGroupApiRequest]);

  useEffect(() => {
    setUserGroups([...defaultUserGroups.filter((userGroup) => userGroup.deleted !== true)]);
  }, [defaultUserGroups]);

  // ユーザーグループ検索に入力する時に
  const userGroupsSearchResult = useCallback((_userGroups: CustomizedUserGroup[], _users: User[], searchValue: string): (_checkedGroupIds: number[]) => User[] => {
    // チェックボックスにチェックを入れると、サイドのユーザー一覧に選択中ユーザーグループの所属ユーザーのみ表示される
    const f = (__users: User[], __userGroups: CustomizedUserGroup[], _checkedGroupIds: number[]): User[] => {
      if (_checkedGroupIds.length === 0) {
        return __users;
      }
      return __users.filter((d) => _checkedGroupIds.some((id) => __userGroups.find((u) => u.id === id)?.users.some((u) => `${u.id}` === d.id)));
    };

    if (searchValue.trim() === '') {
      return f.bind(null, _users, _userGroups);
    }

    const targetGroupsUsers = _userGroups.filter((u) => u.name.indexOf(searchValue) > -1).map((g) => g.users).flat();
    const searchResult = _users.filter((u) => targetGroupsUsers.some((_u) => u.id === `${_u.id}`));

    return f.bind(null, searchResult, _userGroups);
  }, []);

  // 検索、及びチェックボックスにより、左側のユーザーリストが変更されてます。
  useEffect(() => {
    setUsers(userGroupsSearchResult(defaultUserGroups, defaultUsers, userGroupSearchValue)(checkedGroupIds));
  }, [defaultUserGroups, defaultUsers, userGroupSearchValue, checkedGroupIds, userGroupsSearchResult]);

  // useCallback hooks
  const UserOnDrop = useCallback((groupId: number, _selectedUser: UserGroupUser, _selectedUserGroupId?: number) => {
    const tmpDefaultUserGroups = JSON.parse(JSON.stringify(defaultUserGroups)) as CustomizedUserGroup[];
    let findEmptyPosition = false;

    const res = tmpDefaultUserGroups.map((_userGroup) => {
      if (_userGroup.id !== groupId) {
        // it is not the group that is dropping on, but it is where the drag item from
        if (_selectedUserGroupId != null && _selectedUserGroupId === _userGroup.id) {
          // eslint-disable-next-line no-param-reassign
          _userGroup.users = _userGroup.users.filter((u) => u.id !== _selectedUser.id);
          return _userGroup;
        }
        return _userGroup;
      }

      if (_userGroup.users.some((u) => u.id === _selectedUser.id)) {
        return _userGroup;
      }
      findEmptyPosition = true;
      return {
        ..._userGroup,
        users: _userGroup.users.concat(_selectedUser),
      };
    });

    // if target drop group contains the user, do not update userGroups
    if (!findEmptyPosition) {
      return;
    }

    setDefaultUserGroups(res);
  }, [defaultUserGroups]);

  const RemoveUserOnClick = useCallback((groupId: number, _selectedUser: UserGroupUser) => {
    const tmpDefaultUserGroups = JSON.parse(JSON.stringify(defaultUserGroups)) as CustomizedUserGroup[];

    const res = tmpDefaultUserGroups.map((userGroup) => {
      if (userGroup.id !== groupId) {
        return userGroup;
      }

      return {
        ...userGroup,
        users: userGroup.users.filter((u) => u.id !== _selectedUser.id),
      };
    });
    setDefaultUserGroups(res);
  }, [defaultUserGroups]);

  const DeleteUserGroupOnClick = useCallback((groupId:number, name: string, _defaultUserGroups: CustomizedUserGroup[]) => {
    setAlertModal({
      open: true,
      text: `${name}が紐づくフォルダ権限は削除されます。\n削除してよろしいですか？`,
      onCancel: () => {
        setAlertModal({ ...alertModal, open: false });
      },
      onConfirm: () => {
        createLog(LogFormName.UserGroupSetting, LogControlName.Delete);

        // 既存のものをdeleteフラグつけます。
        if (groupId < 0) {
          // Remove the group from the list if it is not created yet
          const groupListWithoutDeleted = _defaultUserGroups.filter((d) => d.id !== groupId);
          setDefaultUserGroups(groupListWithoutDeleted);
        } else {
          setDefaultUserGroups(_defaultUserGroups.map((d) => {
            if (d.id !== groupId) {
              return d;
            }
            return { ...d, deleted: true };
          }));
        }

        // 未定なものをそのままフィルタリングします。
        setAlertModal({ ...alertModal, open: false });
      },
    });
  }, [alertModal]);

  const ResetOnClick = useCallback(() => {
    reloadGroupInfo();
  }, [reloadGroupInfo]);

  const compareGroupNames = useCallback((a: CustomizedUserGroup, b: CustomizedUserGroup) => (a.name !== b.name ? setHasChanges(true) : null), []);

  useEffect(() => {
    const updatedHashes = getAllGroupsHashes(defaultUserGroups);
    setHasChanges(updatedHashes !== defaultUserGroupsHash);
  }, [compareGroupNames, defaultUserGroups, defaultUserGroupsHash, getAllGroupsHashes]);

  const RegisterUserOnClick = useCallback(() => {
    setRegisterModal(true);
  }, []);

  const EditUserOnClick = useCallback((user: User) => {
    setSelectedUser(user);
  }, []);

  const endsWithNumber = useCallback((str: string): boolean => /[0-9]+$/.test(str), []);

  const getNumberAtEnd = useCallback((str: string) => {
    if (endsWithNumber(str)) {
      return Number(str.match(/[0-9]+$/)?.[0]);
    }
    return 0;
  }, [endsWithNumber]);

  const AddUserGroupOnClick = useCallback(() => {
    const nameMask = '新規グループ';
    const hasDefaultName = userGroups.some((_userGroup) => _userGroup.name === nameMask);
    let incrementedNum = 0;
    if (hasDefaultName) {
      incrementedNum = userGroups.filter((_userGroup) => _userGroup.name.indexOf(nameMask) > -1).map((u) => getNumberAtEnd(u.name)).sort((a, b) => b - a)[0] + 1;
    }
    const name = hasDefaultName ? `新規グループ${incrementedNum}` : nameMask;
    let id = -1;
    if (userGroups.length > 0) {
      id = userGroups.map((u) => u.id).sort((a, b) => a - b)[0] >= 1 ? -1 : userGroups.map((u) => u.id).sort((a, b) => a - b)[0] - 1;
    }
    const newUserGroup = {
      id, name, users: [], created: true, editingName: name,
    };
    setDefaultUserGroups([...defaultUserGroups, newUserGroup]);
    setFocusGroupId(id);
  }, [userGroups, defaultUserGroups, getNumberAtEnd]);

  // サーバー側に渡すためのデータにあるprocessを処理します
  const determineProcess = (created:boolean, deleted?: boolean): '1' | '2' | '3' => {
    if (created === true) {
      return '1';
    }

    if (deleted === true) {
      return '3';
    }

    return '2';
  };

  // サーバー側に渡すデータに変換します
  const convertUserGroupUpdateFormUserList = useCallback((userGroup: CustomizedUserGroup): UserGroupUpdateFormUserGroupList => ({
    userGroupId: userGroup.id < 0 ? undefined : userGroup.id,
    userGroupName: userGroup.name,
    process: determineProcess(userGroup.id < 0, userGroup.deleted),
    userList: userGroup.users.map((u) => ({
      userId: u.id,
    })),
  }), []);

  // サーバー側に渡すデータに変換します
  const convertUserGroupUpdateForm = useCallback((_defaultUserGroups: CustomizedUserGroup[]):UserGroupUpdateForm => ({ userGroupList: [..._defaultUserGroups.map((userGroupItem) => convertUserGroupUpdateFormUserList(userGroupItem))] }), [convertUserGroupUpdateFormUserList]);

  // 保存ボタンをクリックする時
  const RegisterUserGroupOnClick = useCallback(() => {
    setAlertModal({
      open: true,
      text: '保存してよろしいですか？',
      onConfirm: async () => {
        const f = async (form: UserGroupUpdateForm) => updateUserGroupApiRequest(form);
        const data: UserGroupUpdateForm = convertUserGroupUpdateForm(defaultUserGroups);
        try {
          await f(data);
          setHasChanges(false);
        } catch (e) {
          setAlertModal({ ...alertModal, open: false });
          setCloseAlertModal({
            ...alertModal,
            text: (e as ApiError).message,
            open: true,
            onCancel() {
              setCloseAlertModal({ ...alertModal, open: false });
            },
          });
          return;
        }

        setAlertModal({ ...alertModal, open: false });
        setCloseAlertModal({
          ...closeAlertModal, open: true, text: '保存が完了しました', onCancel: () => { setCloseAlertModal({ ...closeAlertModal, open: false }); },
        });

        // 保存せいこうしたら、取得し直します。
        reloadGroupInfo();
      },
      onCancel: () => {
        setAlertModal({
          ...alertModal, open: false,
        });
      },
    });
  }, [defaultUserGroups, updateUserGroupApiRequest, alertModal, convertUserGroupUpdateForm, closeAlertModal, reloadGroupInfo]);

  // グループ名を修正する時に
  const handleUserGroupNameOnChange = useCallback((groupId: number, v: string, _defaultUserGroups: CustomizedUserGroup[]) => {
    if (_defaultUserGroups.some((g) => g.id !== groupId && g.name === v && g.deleted !== true)) {
      openMessageModal('このグループ名はすでに登録済みです');
      return;
    }
    setDefaultUserGroups(_defaultUserGroups.map((_userGroup) => {
      if (_userGroup.id !== groupId) {
        return _userGroup;
      }

      return { ..._userGroup, editingName: v, name: v };
    }));
  }, []);

  // グループ名の修正にFocusする時
  const handleUserGroupNameOnFocus = useCallback((_groupId: number) => {
    setDefaultUserGroups(defaultUserGroups.map((_userGroup) => {
      if (_userGroup.id !== _groupId) {
        return _userGroup;
      }
      return { ..._userGroup };
    }));

    setFocusGroupId(_groupId);
  }, [defaultUserGroups]);

  // ユーザー名を修正する時に
  const handleUserSearch = useCallback((value: string) => {
    setUsers(defaultUsers.filter((u) => u.userName.indexOf(value) > -1 || u.email.indexOf(value) > -1));
  }, [defaultUsers]);

  // ユーザーグループ検索の候補を選択された時に
  const handleUserGroupsSearchSelected = useCallback((_userGroup: CustomizedUserGroup | null) => {
    if (_userGroup == null) {
      return;
    }
    setUserGroupSearchValue(_userGroup.name);
  }, []);

  // ユーザーグループのチェックボックスをクリックする時に
  const handleUserGroupOnChecked = useCallback((groupId:number, checked: boolean) => {
    setCheckedGroupIds((v) => {
      if (checked) {
        if (v.some((_v) => _v === groupId)) {
          return v;
        }
        return [...v, groupId];
      }
      return v.filter((_v) => _v !== groupId);
    });
  }, []);

  // useMemo hooks
  // ユーザーグループ検索の候補
  const userGroupsSearchOptions = useMemo(() => [{ text: ' ', value: null }, ...userGroups.map((u) => ({
    text: u.name,
    value: u,
  }))], [userGroups]);

  useEffectOnce(() => {
    createLog(LogFormName.UserGroupSetting, LogControlName.Show);
    reloadGroupInfo();
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles.mainFrameBody}>
            <div className={styles['mainframe-leftsection']}>
              <div className={styles['mainframe-leftsection-top']}>
                <input type="text" className={`${mainStyles.input} ${mainStyles['input-search']}`} placeholder="&#xF002; 検索" onChange={(e) => handleUserSearch(e.target.value)} />
                <div className={mainStyles['mt-10']}>
                  <AutoCompleteInput<CustomizedUserGroup | null>
                    placeholder="グループ名"
                    className={styles.groupSearchInputContainer}
                    posIconClassName={styles.groupSearchInputPosIcon}
                    inputClassName={styles.groupSearchInput}
                    options={userGroupsSearchOptions}
                    onSelect={handleUserGroupsSearchSelected}
                    onTextInput={(s) => setUserGroupSearchValue(s)}
                    value={userGroupSearchValue}
                  />
                </div>
                <button type="button" className={`${mainStyles.button} ${mainStyles['btn-gray']} ${mainStyles['mt-10']} ${mainStyles['w-100']}`} onClick={RegisterUserOnClick}>
                  ユーザを追加
                </button>
              </div>
              <div className={styles['mainframe-leftsection-list']}>
                {users.map((u) => (
                  <DragItem user={u} key={u.id} handleItemClick={EditUserOnClick} />
                ))}
              </div>
            </div>
            <div className={styles['mainframe-rightsection']}>
              <div className={styles['mainframe-rightsection-list']}>
                {userGroups.map((userGroup) => (
                  <div className={styles['mainframe-rightsection-mainsection']} key={userGroup.id}>
                    <div className={`${styles['mainframe-rightsection-mainsection-checkbox-line']} ${mainStyles['mb-10']}`}>
                      <div className={styles['mainframe-rightsection-mainsection-checkbox-line-leftsection']}>
                        <input type="checkbox" className={[styles.checkbox, mainStyles['mr-2']].join(' ')} onChange={(e) => handleUserGroupOnChecked(userGroup.id, e.target.checked)} />
                        <EditableLabel itemId={userGroup.id} label={userGroup.name} isEditing={userGroup.id === focusGroupId} onUpdate={(itemId: number, newValue: string) => { handleUserGroupNameOnChange(itemId, newValue, defaultUserGroups); setFocusGroupId(null); }} onCancel={() => { setFocusGroupId(null); }} />
                      </div>
                      <div className={styles['mainframe-rightsection-mainsection-checkbox-line-rightsection']}>
                        {userGroup.users.length === 0 && <div><img src="/images/provisory-images/xmark-solid-gray.svg" title="削除" alt="" onClick={() => DeleteUserGroupOnClick(userGroup.id, userGroup.name, defaultUserGroups)} /></div>}
                        <div>
                          <img
                            src="/images/provisory-images/pen-to-square-solid.svg"
                            title="グループ名編集"
                            alt=""
                            onClick={() => {
                              handleUserGroupNameOnFocus(userGroup.id);
                            }}
                          />
                        </div>
                        <div className={styles['mainframe-rightsection-mainsection-checkbox-line-rightsection-num']}><span>{`${userGroup.users.length} `}</span></div>
                      </div>
                    </div>
                    <DropComponent userGroup={userGroup} UserOnDrop={UserOnDrop} RemoveUserOnClick={RemoveUserOnClick} handleItemClick={EditUserOnClick} />
                  </div>
                ))}

              </div>
              <div className={styles['mainframe-rightsection-footer-buttons']}>
                <button type="button" className={`${mainStyles.button} ${mainStyles['btn-gray']} ${mainStyles['add-user-group-button-width']}`} onClick={AddUserGroupOnClick}>ユーザーグループを追加</button>
                {hasChanges ? (
                  <div>
                    <button type="button" className={`${mainStyles.button} ${mainStyles['btn-gray']} ${mainStyles['mr-10']}`} onClick={ResetOnClick}>リセット</button>
                    <button type="button" className={`${mainStyles.button} ${mainStyles['btn-primary']}`} onClick={RegisterUserGroupOnClick}>保存</button>
                  </div>
                ) : null}
              </div>
            </div>
            {registerModal && <UserRegisterModal open={registerModal} handleCancel={() => { setRegisterModal(false); }} handleSuccess={() => reloadGroupInfo()} />}
            {selectedUser && <UserDetailModal user={selectedUser} handleCancel={() => { setSelectedUser(null); }} handleSuccess={() => reloadGroupInfo()} />}
            <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
            <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
          </div>
        </div>
        )}
    />
  );
}

export default Component;
