import axios from 'axios';
import { ControlCodeItemOnlyCode } from '../../utils/controlCode';

interface LoginForm { email: string, password: string, tenantCode: string }

interface LoginResponse { token: string, }

interface ChangeUser {
  customerName: string
  userId: string
  userName :string
}

interface UserResponse {
  userId: string,
  userName: string,
  email: string,
  userType: number,
  authorityList: [ControlCodeItemOnlyCode],
  managementType: number,
  passwordUpdate: boolean,
  changeUser?: ChangeUser
}

interface ChangeInitPasswordForm {
  password: string,
}
interface SetPasswordForm {
  password: string,
  newPassword: string,
}

export type {
  LoginForm,
  LoginResponse,
  UserResponse,
  ChangeInitPasswordForm,
  SetPasswordForm,
};

export default {
  /**
   * ログインを実行する関数
   *
   * @param form - ログインフォーム
   * @returns - ログインに関するレスポンス
   */
  async login(form: LoginForm): Promise<LoginResponse> {
    const res = await axios.post<LoginResponse>('/AuthenticationApproval/login', form);
    const { data } = res;
    return data;
  },

  async loginSso(tenantCode: string) {
    window.location.href = `${process.env.REACT_APP_URL}/api/AuthenticationApproval/loginSso/${tenantCode}`;
  },

  /**
   *
   */
  async logout(): Promise<void> {
    await axios.post<LoginResponse>('/AuthenticationApproval/logout');
  },
  /**
   * ユーザー情報を取得する関数
   *
   * @returns - ユーザー情報に関するレスポンス
   */
  async getUser(): Promise<UserResponse> {
    return axios.get<UserResponse>('/AuthenticationApproval/getUser').then((res) => {
      const data = { ...res.data };
      return data;
    });
  },

  /**
   * ユーザーの初期パスワードを変更する関数
   *
   * @param form - パスワード変更フォーム
   */
  async changeInitPassword(form: ChangeInitPasswordForm): Promise<void> {
    return axios.post('/AuthenticationApproval/changeInitPassword', form);
  },

  /**
   * ユーザーのパスワードを変更する関数
   *
   * @param form - パスワード変更フォーム
   */
  async setPassword(form: SetPasswordForm): Promise<void> {
    return axios.post('/AuthenticationApproval/setPassword', form);
  },
};
