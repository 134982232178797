import {
  useState, useRef, useMemo, useEffect, useCallback,
} from 'react';
import { v1 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import styles from './ipList.module.css';
import {
  IpInfoWithKey, IpListForm, useGetIpListApi, useSaveIpListApi,
} from '../../hooks/api/ip.hook';
import { GetIpListForm } from '../../services/http/ip.api';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import MainFrame from '../MainFrame/mainFrame';
import AGUtils from '../../utils/ag-grid.utils';
import AlertModal, { alertModalInitialState } from '../AlertModal';
import SelectInput from '../SelectInput';
import BaseTable from '../BaseTable';
import Button from '../Button/button';
import mainStyles from '../../pages/main.module.css';
import InputCellRender from '../BaseTable/partials/InputCellRender';
import routes from '../../utils/routes';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

interface TableBtnCellRenderProps {
  disabled: boolean;
  data: IpInfoWithKey;
  onDeleteClick: (data: IpInfoWithKey) => void;
}

function TableBtnCellRender({
  data, onDeleteClick, disabled,
}: TableBtnCellRenderProps) {
  return (
    <div>
      <button
        type="button"
        style={{
          margin: '0px 3px',
          cursor: disabled ? 'default' : 'pointer',
          color: disabled ? 'white' : '',
        }}
        disabled={disabled}
        className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}
        onClick={() => onDeleteClick(data)}
      >
        削除
      </button>
    </div>
  );
}

interface EditableInputCellRendererProps {
  value: string;
  showEditIcon?: boolean;
}

export function EditableInputCellRenderer({ value, showEditIcon = false }: EditableInputCellRendererProps) {
  return (
    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>{value}</span>
      {showEditIcon ? (
        <img src="images/pen-to-square-solid.svg" alt="edit" style={{ width: '15px' }} />
      ) : null}
    </span>
  );
}

enum DropdownOptionsValue {
  USE = 1,
  NOT_USE = 0,
}

interface CustomerInfo {
  customerId: number;
  customerCd: string;
  customerName: string;
  customerTenantCode: string;
}

interface IpListProps {
  isTerada?: boolean;
  customerInfo?: CustomerInfo;
}

function Component({
  isTerada = true,
  customerInfo,
}: IpListProps) {
  const saveIpListFormInitialState = {
    firewallUseFlg: isTerada ? 0 : 1,
    blockingUseFlg: 0,
    allowIpAddressList: [],
    customerId: undefined,
  };
  const firewallDropdownOptions = [{ value: DropdownOptionsValue.USE, text: '利用する' }, { value: DropdownOptionsValue.NOT_USE, text: '利用しない' }];
  const blockingDropdownOptions = [{ value: DropdownOptionsValue.USE, text: '制限する' }, { value: DropdownOptionsValue.NOT_USE, text: '制限しない' }];

  const gridRef = useRef<AgGridReact>(null);
  const [form, setForm] = useState<IpListForm>(saveIpListFormInitialState);
  const [modal, setModal] = useState(alertModalInitialState);
  const bothFlags = form.firewallUseFlg === DropdownOptionsValue.USE && form.blockingUseFlg === DropdownOptionsValue.USE;
  const noneFlags = form.firewallUseFlg === DropdownOptionsValue.NOT_USE && form.blockingUseFlg === DropdownOptionsValue.NOT_USE;
  const onlyFirewallFlag = form.firewallUseFlg === DropdownOptionsValue.USE && form.blockingUseFlg === DropdownOptionsValue.NOT_USE;
  const onlyBlockingFlag = form.firewallUseFlg === DropdownOptionsValue.NOT_USE && form.blockingUseFlg === DropdownOptionsValue.USE;
  const fireWallFlag = form.firewallUseFlg === DropdownOptionsValue.USE;
  const blockingFlag = form.blockingUseFlg === DropdownOptionsValue.USE;
  const disableDeleteButton = !blockingFlag;
  const disableInput = !blockingFlag;

  const { request: ipGetListApiRequest } = useGetIpListApi();
  const { request: ipSaveListApiRequest } = useSaveIpListApi();
  const { request: createLog } = useCreateLogApi();
  const openMessageModal = useMessageModal();
  const navigate = useNavigate();

  const deleteIpFromList = useCallback((data: IpInfoWithKey) => {
    setForm({ ...form, allowIpAddressList: form.allowIpAddressList.filter((item) => item.key !== data.key) });
  }, [form]);

  const addIpToList = useCallback(() => {
    const newIpInfo: IpInfoWithKey = { ipAddress: '', explanation: '', key: uuid() };
    setForm({ ...form, allowIpAddressList: [...form.allowIpAddressList, newIpInfo] });
  }, [form]);

  const getIpList = useCallback(async () => {
    if (isTerada && !customerInfo) return;
    try {
      const reqBody: GetIpListForm = isTerada ? { customerId: customerInfo?.customerId } : {};
      const res = await ipGetListApiRequest(reqBody);
      setForm(isTerada ? { ...res, customerId: customerInfo?.customerId } : { ...res });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [customerInfo, ipGetListApiRequest, isTerada, openMessageModal]);

  const isIpv4CIDR = useCallback((ip: string) => {
    const ipv4CidrRegex = /^((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){3}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\/(3[0-2]|[1-2]\d|\d)$/;
    return ipv4CidrRegex.test(ip);
  }, []);

  const saveListRequest = useCallback(async () => {
    if (isTerada && !customerInfo) return;
    const userIsTerada = isTerada && customerInfo;
    try {
      const reqBody: IpListForm = userIsTerada ? {
        allowIpAddressList: form.allowIpAddressList,
        blockingUseFlg: form.blockingUseFlg,
        firewallUseFlg: form.firewallUseFlg,
        customerId: customerInfo.customerId,
      } : {
        allowIpAddressList: form.allowIpAddressList,
        firewallUseFlg: form.firewallUseFlg,
        blockingUseFlg: form.blockingUseFlg,
      };
      await ipSaveListApiRequest(reqBody);
      openMessageModal('保存しました');
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [isTerada, form, customerInfo, ipSaveListApiRequest, openMessageModal, createLog]);

  const onSaveClick = useCallback(async () => {
    if (isTerada && !customerInfo) return;

    if (bothFlags && form.allowIpAddressList.length === 0) {
      openMessageModal('登録するデータがありません。');
      return;
    }

    const listHasBadFormattedIp = form.allowIpAddressList.map((item, index) => {
      if (!isIpv4CIDR(item.ipAddress)) {
        openMessageModal(`${index + 1}行目 IPアドレス（CIDR）:CIDRの正しい\n形式（xxx.xxx.xxx.xxx/xx）で入力してください。`);
        return true;
      }
      return false;
    });
    if (listHasBadFormattedIp.some((item) => item)) return;

    setModal({
      text: '保存してよろしいですか？',
      open: true,
      onCancel: () => setModal({ ...modal, open: false }),
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        await saveListRequest();
      },
    });
  }, [isTerada, customerInfo, bothFlags, form, openMessageModal, isIpv4CIDR, modal, saveListRequest]);

  const onCancelClick = useCallback(() => {
    navigate(isTerada ? routes.supplierListScreen : routes.masterDataSettingGeneral);
  }, [isTerada, navigate]);

  const onIpChange = useCallback(({ row, ip }: { row: IpInfoWithKey, ip: string }) => {
    const newAllowIpAddressList = form.allowIpAddressList.map((item) => {
      if (item.key === row.key) {
        return { ...item, ipAddress: ip };
      }
      return item;
    });
    setForm({ ...form, allowIpAddressList: newAllowIpAddressList });
  }, [form]);

  const onExplanationChange = useCallback(({ row, explanation }: { row: IpInfoWithKey, explanation: string }) => {
    const newAllowIpAddressList = form.allowIpAddressList.map((item) => {
      if (item.key === row.key) {
        return { ...item, explanation };
      }
      return item;
    });
    setForm({ ...form, allowIpAddressList: newAllowIpAddressList });
  }, [form]);

  const colDef = useMemo((): ColDef<IpInfoWithKey>[] => [
    AGUtils.colAutoIncrement('no'),
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 80,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onDeleteClick(data: IpInfoWithKey) { deleteIpFromList(data); },
        disabled: disableDeleteButton,
      },
    },
    {
      headerName: 'CIDR',
      resizable: true,
      field: 'ipAddress',
      width: 190,
      cellRenderer: disableInput ? null : InputCellRender,
      cellRendererParams: {
        trim: true,
        onTextInput: (e: IpInfoWithKey, value: string) => {
          onIpChange({ row: e, ip: value });
        },
      },
    },
    {
      headerName: '説明',
      resizable: true,
      field: 'explanation',
      width: 300,
      cellRenderer: disableInput ? null : InputCellRender,
      cellRendererParams: {
        trim: true,
        onTextInput: (e: IpInfoWithKey, value: string) => {
          onExplanationChange({ row: e, explanation: value });
        },
      },
    },
  ], [deleteIpFromList, onExplanationChange, onIpChange, disableDeleteButton, disableInput]);

  useEffect(() => {
    if (onlyBlockingFlag) {
      setForm({ ...form, blockingUseFlg: DropdownOptionsValue.NOT_USE });
    }
  }, [form, onlyBlockingFlag]);

  useEffect(() => {
    getIpList();
  }, [getIpList]);

  useEffectOnce(() => {
    createLog(isTerada ? LogFormName.AdminIpListScreen : LogFormName.UserIpListScreen, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={styles.mainframe}>
        <div className={styles.mainHeader} style={{ minHeight: isTerada ? '180px' : '105px' }}>
          <div className={styles.selectContainer}>
            {isTerada ? (
              <div className={[styles.selectBox, styles.upperSelectBox].join(' ')} style={{ marginBottom: isTerada ? '10px' : '0' }}>
                <div className={styles.label}>グローバルIPアドレス制限機能</div>
                <SelectInput
                  value={form.firewallUseFlg}
                  options={firewallDropdownOptions}
                  onChange={(value) => setForm({ ...form, firewallUseFlg: value })}
                />
              </div>
            ) : null}
            {fireWallFlag ? (
              <div className={styles.selectBox}>
                <div className={styles.label}>グローバルIPアドレス制限</div>
                <SelectInput
                  value={form.blockingUseFlg}
                  options={blockingDropdownOptions}
                  onChange={(value) => setForm({ ...form, blockingUseFlg: value })}
                />
              </div>
            ) : null}
          </div>
          {isTerada && customerInfo ? (
            <div className={styles.headerInfoBox}>
              <div className={styles.infoTextBox}>
                <div className={styles.infoTitle}>取引先コード:</div>
                <div className={styles.infoText} title={customerInfo.customerCd}>{customerInfo.customerCd}</div>
              </div>
              <div className={styles.infoTextBox}>
                <div className={styles.infoTitle}>取引先名称:</div>
                <div className={styles.infoText} title={customerInfo.customerName}>{customerInfo.customerName}</div>
              </div>
              <div className={styles.infoTextBox}>
                <div className={styles.infoTitle}>テナントコード:</div>
                <div className={styles.infoText} title={customerInfo.customerTenantCode}>{customerInfo.customerTenantCode}</div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles['table-container']}>
          <div className={styles['table-header']}>
            {!noneFlags ? (
              <>
                <div className={styles.label}>許可IPアドレス一覧</div>
                <Button
                  disabled={onlyFirewallFlag}
                  size="smaller"
                  onClick={addIpToList}
                >
                  IPアドレスの追加
                </Button>
              </>
            ) : null}
          </div>
          <div className={styles['table-box']}>
            {!noneFlags ? (
              <BaseTable
                tableRef={gridRef}
                formName="allowIpAddressListTable"
                rowData={form.allowIpAddressList}
                columnDefs={colDef}
                suppressRowClickSelection
                rowSelection="multiple"
                masterDetail
                detailRowAutoHeight
                embedFullWidthRows
                stopEditingWhenCellsLoseFocus
                createLogOnDownloadTableData={isTerada ? LogFormName.AdminIpListScreen : LogFormName.UserIpListScreen}
              />
            ) : null}
          </div>
          <div className={styles['table-footer']}>
            <Button
              size="smaller"
              color="lighterGray"
              onClick={onCancelClick}
            >
              キャンセル
            </Button>
            <Button
              size="smaller"
              onClick={onSaveClick}
            >
              保存
            </Button>
          </div>
        </div>
        <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
      </div>
    </MainFrame>
  );
}

export default Component;
