import axios from 'axios';
import { BaseTag } from './tag.api';
import TagFormat from '../../utils/tagFormat';

export interface DocumentClassification {
  id: number | null
  name: string
  displayOrder: number
  systemTags: BaseTag[]
}

export interface DocumentType {
  documentTypeId: number | null,
  documentTypeName: string,
  documentClassificationId: number | null,
  displayOrder?: number,
  defaultTags: {
    tagSettingId: number | null
    tagLabel: string
    format: TagFormat
    require: boolean
    duplicate: boolean
  }[],
}

export const DocumentTypeDefault: DocumentType = {
  documentTypeId: null,
  documentClassificationId: null,
  documentTypeName: '',
  defaultTags: [],
};

export interface DocumentClassificationAndType {
  id: number | null
  name: string,
  types: DocumentType[],
}

export const DocumentClassificationAndTypeDefault: DocumentClassificationAndType = {
  id: null,
  name: '',
  types: [],
};

export interface DeleteDocumentClassificationForm {
  id: number
}
export interface DeleteDocumentTypeForm {
  documentTypeId: number
}

export interface GetDocumentTypeForm {
  documentClassificationId: number,
}

export default {
  /**
   * 文書分類を取得する関数
   *
   * @returns - 文書分類のリスト
   */
  async getDocumentClassification(): Promise<DocumentClassification[]> {
    const res = await axios.get('/DocumentManagement/getDocumentClassification');
    const { data } = res;
    return data.documentClassificationList.map((item : {
      documentClassificationId: number
      documentClassificationName: string
      displayOrder: number
    }) => ({
      id: item.documentClassificationId,
      name: item.documentClassificationName,
      displayOrder: item.displayOrder,
      systemTags: [],
    }));
  },

  /**
   * 文書分類とタイプを取得する関数
   *
   * @returns - 文書分類とタイプのリスト
   */
  async getDocumentClassificationAndType(): Promise<DocumentClassificationAndType[]> {
    const res = await axios.get('/DocumentManagement/getDocumentClassificationAndDocumentType');
    const { data } = res;
    return data.documentClassificationList.map((item: {
      documentClassificationId: number
      documentClassificationName: string
      documentTypeList: [{ documentTypeId: number, documentTypeName: string }]
    }): DocumentClassificationAndType => ({
      id: item.documentClassificationId,
      name: item.documentClassificationName,
      types: item.documentTypeList.map((type) => ({
        documentTypeId: type.documentTypeId, documentTypeName: type.documentTypeName, documentClassificationId: item.documentClassificationId, defaultTags: [],
      })),
    }));
  },

  /**
   * 文書のタイプを取得する関数
   *
   * @param form - 文書分類ID
   * @returns - 文書タイプのリスト
   */
  async getDocumentType(form: GetDocumentTypeForm): Promise<DocumentType[]> {
    const res = await axios.get('/DocumentManagement/getDocumentType', {
      params: form,
    });
    const { data } = res;
    return data.documentTypeList.map((item: {
      documentTypeId: number
      documentTypeName: string
      displayOrder: number
    }) => ({
      documentTypeId: item.documentTypeId,
      documentTypeName: item.documentTypeName,
      displayOrder: item.displayOrder,
    }));
  },

  /**
   * 文書分類を登録する関数
   *
   * @param doc - 文書分類登録するのフォーム
   * @returns - 登録した文書分類
   */
  async registerDocumentClassification(doc: DocumentClassification): Promise<DocumentClassification> {
    const form = {
      documentClassificationId: (doc.id !== null && doc.id < 0) ? null : doc.id,
      documentClassificationName: doc.name,
      displayOrder: doc.displayOrder,
      systemTagList: doc.systemTags.map((tag) => ({
        tagSettingId: tag.tagSettingId || null,
        tagLabel: tag.tagLabel,
        format: tag.format,
        duplicate: tag.duplicate ? 1 : 0,
        required: tag.require ? 1 : 0,
      })),
    };
    const res = await axios.post('/DocumentManagement/registerDocumentClassification', form);
    const { data } = res;
    return data.documentClassificationList;
  },

  /**
   * 文書タイプを登録する関数
   *
   * @param doc - 文書タイプ登録するのフォーム
   * @returns - 登録した文書タイプ
   */
  async setDocumentType(doc: DocumentType): Promise<DocumentType> {
    const form = {
      documentClassificationId: doc.documentClassificationId,
      documentTypeId: doc.documentTypeId,
      documentTypeName: doc.documentTypeName,
      defaultTagList: doc.defaultTags.map((tag) => ({
        tagLabel: tag.tagLabel,
        format: tag.format,
        duplicate: tag.duplicate ? 1 : 0,
        required: tag.require ? 1 : 0,
      })),
    };
    const res = await axios.post('/DocumentManagement/setDocumentType', form);
    const { data } = res;
    return data.documentClassificationList;
  },

  /**
   * 文書分類を削除する関数
   *
   * @param form - 文書分類ID
   */
  async deleteDocumentClassification(form: DeleteDocumentClassificationForm): Promise<void> {
    await axios.post('/DocumentManagement/deleteDocumentClassification', { documentClassificationId: form.id });
  },

  /**
   * 文書タイプを削除する関数
   *
   * @param form - 文書タイプID
   */
  async deleteDocumentType(form: DeleteDocumentTypeForm): Promise<void> {
    await axios.post('/DocumentManagement/deleteDocumentType', form);
  },
};
