import mainStyles from '../../../../pages/main.module.css';
import styles from './buttonCellRender.module.css';

export interface ButtonCellRenderProps<T> {
  data: T,
  label: string,
  onClick: (row: T) => void,
  disabled?: boolean,
}

function ButtonCellRender<T>({
  data,
  label,
  onClick,
  disabled = false,
}: ButtonCellRenderProps<T>) {
  return (
    <div>
      <button
        type="button"
        style={{
          margin: '0px 3px',
          cursor: disabled ? 'default' : 'pointer',
          color: disabled ? 'white' : '',
        }}
        disabled={disabled}
        className={[styles.tableButton, mainStyles['btn-gray']].join(' ')}
        onClick={() => onClick(data)}
      >
        {label}
      </button>
    </div>
  );
}

export default ButtonCellRender;
