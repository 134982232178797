import { memo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

export interface CheckboxCellRenderProps extends ICellRendererParams {
  readOnly?: boolean,
  disabled?: boolean,
  align?: 'left' | 'center' | 'right',
}

const CheckboxCellRender = memo(({
  readOnly = false,
  disabled = false,
  align = 'left',
  value,
  setValue,
}: CheckboxCellRenderProps) => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      justifyContent: align,
      alignItems: 'center',
    }}
  >
    <input
      type="checkbox"
      checked={!!value}
      readOnly={readOnly}
      disabled={disabled}
      onClick={() => !readOnly}
      onChange={(e) => {
        if (!readOnly && setValue) setValue(!value);
      }}
    />
  </div>
));
export default CheckboxCellRender;
