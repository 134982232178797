import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import styles from './adminUserDetailModal.module.css';
import mainStyles from '../main.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import { AdminUser, AdminUserFull, AdminUserUpdateForm } from '../../services/http/managementUser.api';
import AlertModal, { alertModalInitialState, AlertModalProps } from '../../components/AlertModal';
import { ApiError } from '../../services/http';
import ButtonFileInput from '../../components/ButtonFileInput';
import Button from '../../components/Button/button';
import Input from '../../components/Input/input';
import { IconChangeStatus } from '../../services/http/user.api';
import { useGetDetailAdminUserApi, useUpdateAdminUserApi } from '../../hooks/api/managementUser.hook';
import { ManagementType, useAuthContext } from '../../store/auth.store';
import Formatter from '../../utils/formatters';
import { useCanvasImage } from '../../hooks/image.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 管理者ユーザー更新のデフォルトフォーム
 */
export const DefaultAdminUserUpdateForm: AdminUserUpdateForm = {
  deleteFlg: false,
  email: '',
  icon: undefined,
  iconChangeStatus: IconChangeStatus.notChanged,
  id: '',
  userName: '',
};

/**
 * エラーラベルのインターフェース
 */
export interface ErrorLabelType {
  message: string,
  field?: string,
}

/**
 * 管理者ユーザー詳細モーダル コンポーネントの Props のインターフェース
 */
export interface Props {
  /** 管理者ユーザーの参照 */
  adminUser: AdminUser | null | undefined,
  /** キャンセルボタン押下時のハンドラ */
  handleCancel: () => void,
  /** 保存ボタン押下時のハンドラ */
  handleSuccess: () => void,
}

/**
 * 管理者ユーザー詳細モーダル コンポーネント
 */
export function Component({ adminUser: adminUserRef, handleCancel, handleSuccess }: Props) {
  const { user: currentUser } = useAuthContext();

  const [adminUser, setAdminUser] = useState<AdminUserFull | null>(null);
  const [form, setForm] = useState<AdminUserUpdateForm>(DefaultAdminUserUpdateForm);
  const [fileSelector, openFileSelector] = useState(false);
  const [userIcon, setUserIcon] = useState('');
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [error, setError] = useState<ErrorLabelType>({ message: '' });

  const adminUserGetDetailApi = useGetDetailAdminUserApi();
  const adminUserUpdateApi = useUpdateAdminUserApi();
  const { request: createLog } = useCreateLogApi();

  const userImage = useCanvasImage();
  const openMessageModal = useMessageModal();

  const isFormValid = useMemo(() => form.userName && form.email, [form]);

  const onSaveUserClick = useCallback(async () => {
    if (!adminUser) return;
    try {
      Validator.validUserName(form.userName);
    } catch (e) {
      setError({ field: 'ユーザー名', message: (e as Error).message });
      return;
    }
    try {
      Validator.validateEmail(form.email);
    } catch (e) {
      setError({ field: 'メールアドレス', message: (e as Error).message });
      return;
    }
    setModal({
      text: 'ユーザー情報を変更します。よろしいですか？',
      open: true,
      onCancel: () => setModal({ ...modal, open: false }),
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        try {
          createLog(LogFormName.AdminUserDetail, LogControlName.Edit);

          await adminUserUpdateApi.request(form);
          handleSuccess();
          handleCancel();
        } catch (e) {
          setError({ message: (e as ApiError).message });
          openMessageModal((e as ApiError)?.message);
        }
      },
    });
  }, [form, modal]);

  const onFileSelected = useCallback(async (file: File | FileList | null) => {
    openFileSelector(false);
    if (file instanceof FileList) return;
    setForm({ ...form, icon: file || undefined, iconChangeStatus: file ? IconChangeStatus.changed : IconChangeStatus.deleted });
    if (file) {
      const fr = new FileReader();
      fr.onload = async () => {
        const finalUserImage = await userImage.getImage(fr.result as string);
        setUserIcon(finalUserImage as string);
        setForm({ ...form, icon: finalUserImage, iconChangeStatus: IconChangeStatus.changed });
      };
      fr.readAsDataURL(file);
    } else {
      setUserIcon('');
    }
  }, [userIcon, form, fileSelector]);

  const onDeleteImageClick = useCallback(async () => {
    setUserIcon('');
    setForm({ ...form, icon: undefined, iconChangeStatus: IconChangeStatus.deleted });
  }, [userIcon, form]);

  // ------------------EFFECTS-------------------
  useEffect(() => {
    setUserIcon('');
    setError({ message: '' });
    setAdminUser(null);
    setForm(DefaultAdminUserUpdateForm);
    if (!adminUserRef) return;
    try {
      adminUserGetDetailApi.request({ id: adminUserRef.id }).then((res) => {
        setAdminUser(res);
        setUserIcon(res.icon || '');
        setForm({
          deleteFlg: res.deleteFlg,
          email: res.email,
          // icon: res.icon,
          iconChangeStatus: IconChangeStatus.notChanged,
          id: res.id,
          userName: res.userName,
        });
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
      setError({ message: (e as ApiError).message });
    }
  }, [adminUserRef]);

  useEffectOnce(() => {
    createLog(LogFormName.AdminUserDetail, LogControlName.Show);
  });

  return (
    <ScreenModal
      hidden={!adminUserRef}
      handleCancel={handleCancel}
      customStyles={{
        minWidth: '800px',
        maxHeight: '85%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
        overflowY: 'auto',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          管理者ユーザー詳細
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['col-1']}>
          <div className={styles.modalTabItem}>
            <div className={[styles.label, mainStyles['mb-10']].join(' ')}>
              ユーザー名
            </div>
            <Input className={styles['default-width']} value={form.userName} placeholder="20文字以内で入力してください" onChange={(value) => setForm({ ...form, userName: value })} />
          </div>
          <div className={styles.modalTabItem}>
            <div className={[styles.label, mainStyles['mb-10']].join(' ')}>
              メールアドレス
            </div>
            <div className={mainStyles['d-flex']}>
              <Input className={styles['default-width']} value={form.email} onChange={(value) => setForm({ ...form, email: value })} />
            </div>
          </div>
          <div className={styles.modalTabItem}>
            <div className={mainStyles['mt-4']}>
              <input type="checkbox" className={mainStyles['m-0']} checked={!!adminUser?.emailConfirmed} onClick={() => false} onChange={() => ({})} />
              <div className={[styles.text, mainStyles['d-inline'], mainStyles['ml-2']].join(' ')}>
                認証状態
              </div>
            </div>
          </div>
          <div className={styles.modalTabItem}>
            <div className={mainStyles['mt-4']}>
              <input type="checkbox" className={mainStyles['m-0']} checked={!!adminUser?.passwordUpdate} onClick={() => false} onChange={() => ({})} />
              <div className={[styles.text, mainStyles['d-inline'], mainStyles['ml-2']].join(' ')}>
                初期パスワード変更済み
              </div>
            </div>
          </div>
          <div className={styles.modalTabItem}>
            <div className={mainStyles['mt-4']}>
              {currentUser?.userId !== adminUser?.id && (
              <>
                <input
                  type="checkbox"
                  className={mainStyles['m-0']}
                  checked={form.deleteFlg}
                  onClick={() => currentUser?.managementType === ManagementType.Admin}
                  onChange={() => setForm({ ...form, deleteFlg: !form.deleteFlg })}
                />
                <div className={[styles.text, mainStyles['d-inline'], mainStyles['ml-2']].join(' ')}>
                  無効にする
                </div>
              </>
              )}
            </div>
          </div>
          <div className={[mainStyles['mt-4'], styles.label].join(' ')}>
            登録更新情報
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-3']].join(' ')}>
            <div className={[mainStyles['mr-5'], mainStyles['ml-3'], styles.text].join(' ')}>
              <div>登録者</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {adminUser?.registerUser}
              </div>
              <div>更新者</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {adminUser?.updateUser}
              </div>
            </div>
            <div className={[styles.text, mainStyles['ml-5']].join(' ')}>
              <div>登録日時</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {adminUser && Formatter.toDisplayDate(adminUser.registerDate, Formatter.defaultDateTimeFormat)}
              </div>
              <div>更新日時</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {adminUser && Formatter.toDisplayDate(adminUser.updateDate, Formatter.defaultDateTimeFormat)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['modal-foooter']}>
        <div className={styles['mainframe-body-footer-buttons']}>
          <Button size="smaller" color="lighterGray" onClick={handleCancel}>キャンセル</Button>
          <div className="text-red">
            {error.field ? `${error.field}: ` : ''}
            {error.message}
          </div>
          <Button size="smaller" onClick={onSaveUserClick} loading={adminUserUpdateApi.loading} disabled={!isFormValid}>保存</Button>
        </div>
      </div>
      <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
    </ScreenModal>
  );
}

export default Component;
