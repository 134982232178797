import { useLocation, useParams as useParamsRouter } from 'react-router-dom';

/**
 * クエリのタイプ
 */
type QueryType = { [key: string]: string };

/**
 * ルーターのクエリフック
 */
function useQuery<T extends QueryType = QueryType>(defaultValue?: T): T {
  const urlParams = new URLSearchParams(useLocation().search);
  const result: QueryType = {};

  if (defaultValue) {
    Object.keys(defaultValue).forEach((key: string) => {
      result[key] = defaultValue[key];
    });
  }

  urlParams.forEach((value: string, key: string) => {
    result[key] = value;
  });
  return result as T;
}

/**
 * ルーターパラメータを使用する関数
 */
function useParams() {
  return useParamsRouter();
}

export { useQuery, useParams };
