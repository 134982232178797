import {
  useCallback, useEffect, useState,
} from 'react';
import styles from './userDetailModal.module.css';
import mainStyles from '../main.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import {
  IconChangeStatus, User, UserDetail, UserMailSettingGroup, UserUpdateForm,
} from '../../services/http/user.api';
import { useUserGetDetailApi, useUserUpdateApi } from '../../hooks/api/user.hook';
import Input from '../../components/Input/input';
import RadioGroupInput from '../../components/RadioGroupInput';
import { ManagementType, useAuthContext } from '../../store/auth.store';
import AlertModal, { alertModalInitialState, AlertModalProps } from '../../components/AlertModal';
import Button from '../../components/Button/button';
import { ApiError } from '../../services/http';
import Formatter from '../../utils/formatters';
import { useCanvasImage } from '../../hooks/image.hook';
import Validator from '../../utils/validators';
import { useCreateUserDetailShowLogApi } from '../../hooks/api/log.hook';
import BaseTab, { BaseTabItem } from '../../components/BaseTab/BaseTab';
import UserModalTabItem from './userModalTabItem';
import useEffectOnce from '../../hooks/useEffectOnce.hook';

/**
 * デフォルトのユーザー更新フォーム
 */
export const DefaultUserUpdateForm: UserUpdateForm = {
  deleteFlg: false,
  email: '',
  icon: undefined,
  iconChangeStatus: IconChangeStatus.changed,
  id: '',
  userAuthenticationType: ManagementType.Admin,
  userName: '',
  userMailSettingGroupList: [],
};

/**
 * ユーザー詳細モーダルのプロップスインターフェース
 */
export interface Props {
  /** ユーザー参照 */
  user: User | null | undefined,
  /** キャンセルハンドラー */
  handleCancel: () => void,
  /** 成功ハンドラー */
  handleSuccess: () => void,
}

/**
 * ユーザー詳細モーダルのコンポーネント
 */
function Component({ user: userRef, handleCancel, handleSuccess }: Props) {
  const { user: currentUser } = useAuthContext();

  const [user, setUser] = useState<UserDetail | null>(null);
  const [form, setForm] = useState<UserUpdateForm>(DefaultUserUpdateForm);
  const [, openFileSelector] = useState(false);
  const [userIcon, setUserIcon] = useState('');
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [error, setError] = useState<string>('');

  const { request: userGetDetailApiRequest } = useUserGetDetailApi();
  const { request: userUpdateApiRequest, loading: userUpdateApiLoading } = useUserUpdateApi();
  const { request: createUserDetailShowLog } = useCreateUserDetailShowLogApi();

  const userImage = useCanvasImage();

  const onSaveUserClick = useCallback(async () => {
    if (!user) return;
    try {
      Validator.validUserName(form.userName);
    } catch (e) {
      setError((e as Error).message);
      return;
    }
    setModal({
      text: 'ユーザー情報を変更します。よろしいですか？',
      open: true,
      onCancel: () => setModal({ ...modal, open: false }),
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        try {
          await userUpdateApiRequest(form);
          handleSuccess();
          handleCancel();
        } catch (e) {
          setError((e as ApiError).message);
        }
      },
    });
  }, [form, modal, handleCancel, user, userUpdateApiRequest]);

  const onFileSelected = useCallback(async (file: File | FileList | null) => {
    openFileSelector(false);
    if (file instanceof FileList) return;
    setForm({ ...form, icon: file || undefined, iconChangeStatus: file ? IconChangeStatus.changed : IconChangeStatus.deleted });
    if (file) {
      const fr = new FileReader();
      fr.onload = async () => {
        const finalUserImage = await userImage.getImage(fr.result as string);
        setUserIcon(finalUserImage as string);
        setForm({ ...form, icon: finalUserImage, iconChangeStatus: IconChangeStatus.changed });
      };
      fr.readAsDataURL(file);
    } else {
      setUserIcon('');
    }
  }, [form]);

  const onDeleteImageClick = useCallback(async () => {
    setUserIcon('');
    setForm({ ...form, icon: undefined, iconChangeStatus: IconChangeStatus.deleted });
  }, [form]);

  useEffect(() => {
    setUserIcon('');
    setError('');
    setUser(null);
    setForm(DefaultUserUpdateForm);
    if (!userRef) return;
    userGetDetailApiRequest({ id: userRef.id }).then((res) => {
      setUser(res);
      setUserIcon(res.icon || '');
      setForm({
        deleteFlg: res.deleteFlg,
        email: res.email,
        icon: res.icon,
        iconChangeStatus: IconChangeStatus.notChanged,
        id: res.id,
        userAuthenticationType: res.userAuthenticationType,
        userName: res.userName,
        userMailSettingGroupList: res.userMailSettingGroupList,
      });
    }).catch((e: unknown) => {
      setError((e as ApiError).message);
    });
  }, [userRef, userGetDetailApiRequest]);

  const handleChangeMailSetting = useCallback((newItem: UserMailSettingGroup) => {
    const newUserMailSettingGroupList = form.userMailSettingGroupList.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      }
      return item;
    });
    setForm({ ...form, userMailSettingGroupList: newUserMailSettingGroupList });
  }, [form]);

  useEffectOnce(() => {
    if (userRef) createUserDetailShowLog(userRef?.id);
  });

  return (
    <ScreenModal
      hidden={!userRef}
      handleCancel={handleCancel}
      customStyles={{
        minWidth: '800px',
        maxHeight: '90%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
        overflowY: 'auto',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          ユーザー詳細
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles.tabsContainer}>
          <BaseTab isSmallHeader>
            <BaseTabItem title="詳細情報">
              <div className={styles.modalTabContainer}>
                <div className={styles.modalTabItem}>
                  <div className={[styles.label, mainStyles['mb-10']].join(' ')} style={{ marginBottom: '10px' }}>
                    ユーザー名
                  </div>
                  <Input
                    className={styles['default-width']}
                    value={form.userName}
                    placeholder="20文字以内で入力してください"
                    onChange={(value) => setForm({ ...form, userName: value })}
                  />
                </div>
                <div className={styles.modalTabItem}>
                  <div className={[styles.label, mainStyles['mb-10']].join(' ')} style={{ marginBottom: '10px' }}>
                    メールアドレス
                  </div>
                  <div className={mainStyles['d-flex']}>
                    <Input
                      className={styles['default-width']}
                      value={form.email}
                      onChange={(value) => setForm({ ...form, email: value })}
                    />
                  </div>
                </div>
                <div className={styles.modalTabItem}>
                  <div className={styles.label}>
                    ユーザー種別
                  </div>
                  <div className={[styles.text, mainStyles['mt-2'], mainStyles['d-flex']].join(' ')}>
                    <RadioGroupInput<number> value={form.userAuthenticationType} onChange={currentUser?.managementType === ManagementType.Admin ? (value: number) => setForm({ ...form, userAuthenticationType: parseInt(value.toString(), 10) }) : () => {}}>
                      <RadioGroupInput.RadioInput value={ManagementType.Admin}>管理ユーザー</RadioGroupInput.RadioInput>
                      <RadioGroupInput.RadioInput value={ManagementType.General}>一般ユーザー</RadioGroupInput.RadioInput>
                    </RadioGroupInput>
                  </div>
                </div>
                <div className={styles.modalTabItem}>
                  <div className={mainStyles['mb-3']}>
                    <div className={styles.label}>
                      ユーザーグループ
                    </div>
                    {user?.userGroupList.map((group) => (<div className={styles.text} key={group.id}>{group.userGroupName}</div>))}
                  </div>
                  <div className={[mainStyles['mt-2'], styles.checkBox].join(' ')}>
                    <input type="checkbox" className={mainStyles['m-0']} checked={!!user?.emailConfirmed} onClick={() => false} onChange={() => ({})} />
                    <div className={[styles.text, mainStyles['d-inline'], mainStyles['ml-2']].join(' ')}>
                      認証状態
                    </div>
                  </div>
                  <div className={[mainStyles['mt-2'], styles.checkBox].join(' ')}>
                    <input type="checkbox" className={mainStyles['m-0']} checked={!!user?.passwordUpdate} onClick={() => false} onChange={() => ({})} />
                    <div className={[styles.text, mainStyles['d-inline'], mainStyles['ml-2']].join(' ')}>
                      初期パスワード変更済み
                    </div>
                  </div>
                  <div className={[mainStyles['mt-2'], styles.checkBox].join(' ')}>
                    { currentUser?.userId !== user?.id && (
                    <>
                      <input
                        type="checkbox"
                        className={mainStyles['m-0']}
                        checked={form.deleteFlg}
                        onClick={() => currentUser?.managementType === ManagementType.Admin}
                        onChange={currentUser?.managementType === ManagementType.Admin ? () => setForm({ ...form, deleteFlg: !form.deleteFlg }) : () => {}}
                      />
                      <div className={[styles.text, mainStyles['d-inline'], mainStyles['ml-2']].join(' ')}>
                        無効にする
                      </div>
                    </>
                    )}
                  </div>
                </div>
              </div>
            </BaseTabItem>
            <BaseTabItem title="通知設定">
              <div className={styles.modalTabContainer}>
                {form.userMailSettingGroupList.map((item) => (
                  <UserModalTabItem key={item.id} item={item} onChange={(val) => handleChangeMailSetting(val)} />
                ))}
              </div>
            </BaseTabItem>
          </BaseTab>
        </div>
        <div className={styles.updateInfoContainer}>
          <div className={[mainStyles['mt-2'], styles.label].join(' ')}>
            登録更新情報
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-2']].join(' ')}>
            <div className={[mainStyles['mr-5'], mainStyles['ml-3'], styles.text].join(' ')}>
              <div>登録者</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {user?.registerUser}
              </div>
              <div>更新者</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {user?.updateUser}
              </div>
            </div>
            <div className={[mainStyles['ml-5'], styles.text].join(' ')}>
              <div>登録日時</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {user && Formatter.toDisplayDate(user.registerDate, Formatter.defaultDateTimeFormat)}
              </div>
              <div>更新日時</div>
              <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
                {user && Formatter.toDisplayDate(user.updateDate, Formatter.defaultDateTimeFormat)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <div className={styles['mainframe-body-footer-buttons']}>
          <Button color="lighterGray" size="smaller" onClick={() => handleCancel()}>キャンセル</Button>
          <div className="text-red">{error}</div>
          {user && <Button size="smaller" onClick={onSaveUserClick} loading={userUpdateApiLoading}>保存</Button>}
        </div>
      </div>
      <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
    </ScreenModal>
  );
}

export default Component;
