import React, { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LogControlName } from '../utils/log.utils';
import { useCreateLogApi } from './api/log.hook';

interface UseExportAndLogParams {
  tableRef?: React.RefObject<AgGridReact>,
  logFormName?: string;
}

function useExportAndLog({ tableRef, logFormName }: UseExportAndLogParams) {
  const { request: createLog } = useCreateLogApi();

  const createLogOnDownloadTableData = useCallback((_logFormName: string) => {
    createLog(_logFormName, LogControlName.Download);
  }, [createLog]);

  const handleExportCsv = useCallback(() => {
    if (tableRef && tableRef.current && tableRef.current.api) {
      tableRef.current.api.exportDataAsCsv();
      if (logFormName) {
        createLogOnDownloadTableData(logFormName);
      }
    }
  }, [createLogOnDownloadTableData, tableRef, logFormName]);

  const handleExportExcel = useCallback(() => {
    if (tableRef && tableRef.current && tableRef.current.api) {
      tableRef.current?.api.exportDataAsExcel();
      if (logFormName) {
        createLogOnDownloadTableData(logFormName);
      }
    }
  }, [createLogOnDownloadTableData, tableRef, logFormName]);

  const getContextMenuItems = useCallback(() => {
    const defaultItems = [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      'separator',
    ];

    const exportMenuItems = [
      {
        name: 'CSV Export',
        action: handleExportCsv,
        icon: '<span class="ag-icon ag-icon-csv"></span>',
      },
      {
        name: 'Excel Export',
        action: handleExportExcel,
        icon: '<span class="ag-icon ag-icon-excel"></span>',
      },
    ];

    return [...defaultItems, {
      name: 'Export',
      subMenu: exportMenuItems,
      icon: '<span class="ag-icon ag-icon-save"></span>',
    }];
  }, [handleExportExcel, handleExportCsv]);

  return {
    getContextMenuItems,
  };
}

export default useExportAndLog;
