import { useState, useCallback } from 'react';
import { ApiError } from '../../services/http';
import DocumentManagementApi, {
  DeleteDocumentClassificationForm,
  DeleteDocumentTypeForm,
  DocumentClassification,
  DocumentClassificationAndType,
  DocumentType,
  GetDocumentTypeForm,
} from '../../services/http/documentManagement.api';

/**
 * 文書分類取得するAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetDocumentClassificationApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (): Promise<DocumentClassification[]> => {
    setLoading(true);
    try {
      const doc = await DocumentManagementApi.getDocumentClassification();
      doc.sort((a, b) => (a.displayOrder >= b.displayOrder ? 1 : -1));
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 文書分類とタイプ取得するAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetDocumentClassificationAndTypeApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (): Promise<DocumentClassificationAndType[]> => {
    setLoading(true);
    try {
      return await DocumentManagementApi.getDocumentClassificationAndType();
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 文書タイプ取得するAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetDocumentTypeApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: GetDocumentTypeForm): Promise<DocumentType[]> => {
    setLoading(true);
    try {
      return await DocumentManagementApi.getDocumentType(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 文書分類登録APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useRegisterDocumentClassificationApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const request = useCallback(async (form: DocumentClassification): Promise<DocumentClassification> => {
    setLoading(true);
    try {
      const doc = await DocumentManagementApi.registerDocumentClassification(form);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 文書タイプの設定APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useSetDocumentTypeApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  async function request(form: DocumentType): Promise<DocumentType> {
    setLoading(true);
    try {
      return await DocumentManagementApi.setDocumentType(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}

/**
 * 文書分類削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDeleteDocumentClassificationApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: DeleteDocumentClassificationForm): Promise<void> => {
    setLoading(true);
    try {
      await DocumentManagementApi.deleteDocumentClassification(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 文書タイプ削除APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDeleteDocumentTypeApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: DeleteDocumentTypeForm): Promise<void> {
    setLoading(true);
    try {
      await DocumentManagementApi.deleteDocumentType(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}
