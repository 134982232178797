import FolderApi from '../../services/http/folder.api';
import { useHttp } from './api.hook';

/**
 * フォルダ検索フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderSearch() {
  return useHttp(FolderApi.search);
}

/**
 * フォルダ作成フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderCreate() {
  return useHttp(FolderApi.create);
}

/**
 * フォルダ更新フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderUpdate() {
  return useHttp(FolderApi.update);
}

/**
 * フォルダ削除フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderDelete() {
  return useHttp(FolderApi.delete);
}

/**
 * フォルダ権限フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderRoles() {
  return useHttp(FolderApi.roles);
}
