import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './adminUserListScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import AdminUserRegisterModal from './adminUserRegisterModal';
import AdminUserDetailModal from './adminUserDetailModal';
import AlertModal, { alertModalInitialState, AlertModalProps } from '../../components/AlertModal';
import { useDeleteAdminUserApi, useSearchAdminUserApi } from '../../hooks/api/managementUser.hook';
import { AdminUser, AdminUserSearchForm } from '../../services/http/managementUser.api';
import Input from '../../components/Input/input';
import SelectInput from '../../components/SelectInput';
import Button from '../../components/Button/button';
import BaseTable from '../../components/BaseTable';
import Breadcrumb from '../../components/Breadcrumb';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import AGUtils from '../../utils/ag-grid.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import ButtonTableDownload from '../../components/BaseTable/ButtonTableDownload';

/**
 * セル'ボタン'のコンポーネントインターフェース
 */
export interface Props {
  /** テーブルセルレンダリングされるユーザーデータ */
  data: AdminUser;
  /** "変更" ボタンがクリックされた時に実行されるハンドラー */
  onInfoClick: (user: AdminUser) => void;
  /** "削除" ボタンがクリックされた時に実行されるハンドラー */
  onDeleteClick: (user: AdminUser) => void;
}

/**
 * セル'ボタン'のコンポーネント
 */
export function TableBtnCellRender({
  data, onInfoClick, onDeleteClick,
}: Props) {
  return (
    <div>
      <button type="button" className={[styles['table-button'], mainStyles['btn-gray']].join(' ')} onClick={() => onInfoClick(data)}>変更</button>
      <button
        type="button"
        style={{ margin: '0px 3px' }}
        className={[styles['table-button'], mainStyles['btn-gray']].join(' ')}
        onClick={() => onDeleteClick(data)}
      >
        削除
      </button>
    </div>
  );
}

/**
 * 管理者ユーザー一覧ページ
 */
export function Component() {
  usePageTitle('管理者ユーザー一覧');
  const openMessageModal = useMessageModal();
  const gridRef = useRef<AgGridReact<AdminUser>>(null);

  const [registerDisplay, setRegisterDisplay] = useState(false);
  const [searchForm, setSearchForm] = useState<AdminUserSearchForm>({});
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [selectedAdminUser, setSelectedAdminUser] = useState<AdminUser | null>(null);
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);

  const searchAdminUserApi = useSearchAdminUserApi();
  const deleteAdminUserApi = useDeleteAdminUserApi();
  const { request: createLog } = useCreateLogApi();

  const onSearchClick = useCallback(async () => {
    try {
      createLog(LogFormName.AdminUserListScreen, LogControlName.Search);
      const searchUsers = await searchAdminUserApi.request(searchForm);
      setAdminUsers(searchUsers);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [searchForm]);

  const onDeleteUserClick = useCallback(async (user: AdminUser) => {
    setModal({
      open: true,
      text: 'ユーザ情報を削除します。よろしいですか？',
      onConfirm: async () => {
        setModal({ ...modal });
        try {
          createLog(LogFormName.AdminUserListScreen, LogControlName.Delete);
          await deleteAdminUserApi.request({ id: user.id });
        } catch (e) {
          openMessageModal((e as ApiError)?.message);
        }
        setAdminUsers(adminUsers.filter((item) => item.id !== user.id));
        onSearchClick();
      },
      onCancel: async () => setModal({ ...modal }),
    });
  }, [adminUsers]);

  const columns = useMemo(() => [
    AGUtils.colAutoIncrement('id'),
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 100,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onInfoClick: (user: AdminUser) => {
          setSelectedAdminUser({ ...user });
        },
        onDeleteClick: onDeleteUserClick,
      },
    },
    AGUtils.colUsername('userName', 'ユーザー名'),
    AGUtils.colEmail('email', 'メールアドレス'),
    AGUtils.colCheckboxReadOnly('emailConfirmed', '認証状態', 120),
    AGUtils.colCheckboxReadOnly('passwordUpdate', '初期パスワード変更', 170),
    AGUtils.colCheckboxReadOnly('deleteFlg', '無効化', 120),
    {
      field: '',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
    },
  ], [adminUsers]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffectOnce(() => {
    createLog(LogFormName.AdminUserListScreen, LogControlName.Show);
  });

  const columnsToExport = useMemo(() => [
    'id',
    'userName',
    'email',
    'emailConfirmed',
    'passwordUpdate',
    'deleteFlg',
  ], []);

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles.mainFrameBody}>
            <div className={styles['mainframe-leftsection']}>
              <div className={styles['leftsection-title-line']}>
                <div className={styles['leftsection-title']}>検索条件</div>
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>ユーザー名</div>
                <Input value={searchForm.userName || ''} onChange={(userName) => setSearchForm({ ...searchForm, userName })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>メールアドレス</div>
                <Input value={searchForm.email || ''} onChange={(email) => setSearchForm({ ...searchForm, email })} />
              </div>
              <div className={styles['leftsection-inputfield']}>
                <div className={styles.label}>有効／無効</div>
                <SelectInput<boolean | undefined>
                  value={searchForm.deleteFlg}
                  onChange={(value) => setSearchForm({ ...searchForm, deleteFlg: value })}
                  options={[{ text: '', value: undefined }, { text: '有効', value: false }, { text: '無効', value: true }]}
                />
              </div>
              <div className={styles['leftsection-footer-buttons']}>
                <Button size="smaller" color="lighterGray" className={mainStyles['ml-10']} onClick={() => setSearchForm({})}>条件リセット</Button>
                <Button size="smaller" className={mainStyles['mr-10']} onClick={onSearchClick}>検索</Button>
              </div>
            </div>
            <div className={styles['mainframe-rightsection']}>
              <div className={styles['mainframe-rightsection-top']}>
                <div className={styles['mainframe-rightsection-top-buttons']}>
                  <button type="button" onClick={() => { setRegisterDisplay(true); }} className={[mainStyles.button, mainStyles['top-button'], mainStyles['btn-primary'], mainStyles['mr-10']].join(' ')}>新規登録</button>
                  <ButtonTableDownload tableRef={gridRef} columnsToExport={columnsToExport} logFormName={LogFormName.AdminUserListScreen} />
                </div>
              </div>

              <BaseTable<AdminUser>
                tableRef={gridRef}
                formName="adminUserTable"
                columnDefs={columns}
                rowData={adminUsers}
                sideBar
                createLogOnDownloadTableData={LogFormName.AdminUserListScreen}
              />
            </div>
            {registerDisplay && <AdminUserRegisterModal open={registerDisplay} handleCancel={() => { setRegisterDisplay(false); }} handleSuccess={() => onSearchClick()} />}
            {selectedAdminUser && <AdminUserDetailModal adminUser={selectedAdminUser} handleCancel={() => { setSelectedAdminUser(null); }} handleSuccess={() => onSearchClick()} />}
            <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
          </div>
        </div>
      )}
    />
  );
}

export default Component;
