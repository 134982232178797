import mainStyles from '../../../../pages/main.module.css';
import styles from './buttonGroupCellRender.module.css';

export interface ButtonGroupItem<T> {
  data: T,
  label: string,
  onClick: (row: T) => void,
  disabled?: boolean,
  hidden?: boolean,
  style?: React.CSSProperties,
  className?: string,
}

export interface ButtonGroupCellRenderProps<T> {
  items: ButtonGroupItem<T>[],
}

function ButtonGroupCellRender<T>({
  items,
}: ButtonGroupCellRenderProps<T>) {
  return (
    <div>
      {items.map((item) => (
        <button
          key={item.label}
          type="button"
          className={[styles.tableButton, mainStyles['btn-gray'], item.className].join(' ')}
          style={{
            display: item.hidden ? 'none' : 'inline-block',
            margin: '0px 5px',
            cursor: item.disabled ? 'default' : 'pointer',
            color: item.disabled ? 'white' : '',
            ...item.style,
          }}
          disabled={item.disabled}
          onClick={() => item.onClick(item.data)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
}

export default ButtonGroupCellRender;
