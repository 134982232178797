import React, {
  CSSProperties, useCallback, useRef, useState,
} from 'react';
import Button from '../Button/button';
import { Colors, HEX } from '../../utils/theme/theme';
import styles from './buttonFileInput.module.css';

// eslint-disable-next-line no-lone-blocks
{ /* eslint-disable react/jsx-props-no-spreading */ }

interface FileInputButtonProps {
  className?: string,
  multiple?: boolean,
  icon?: boolean
  accept?: string,
  fullWidth?: boolean,
  onSelect: (value: File | FileList | null) => void,
  color?: keyof typeof Colors | HEX | string,
  style?: CSSProperties,
  fileButtonText?: string,
  loading?: boolean,
  isFolderUpload?: boolean,
}

function FileInputButton({
  className,
  multiple,
  accept,
  onSelect,
  color = 'lighterGray',
  style = {},
  fullWidth = false,
  fileButtonText = 'ファイルを選択',
  icon = false,
  loading = false,
  isFolderUpload,
}: FileInputButtonProps) {
  const ref = useRef<HTMLInputElement>(null);

  const acceptFolder = isFolderUpload ? { directory: '', webkitdirectory: '' } : {};

  const onChange = useCallback((files: FileList | null) => {
    if (!files) onSelect(null);
    else if (isFolderUpload) {
      onSelect(files);
    } else if
    (multiple) {
      onSelect(files);
    } else {
      onSelect(files?.item(0) || null);
    }
  }, [onSelect]);

  const [inputKey, setInputKey] = useState('');

  const resetFileInput = () => {
    const randomString = Math.random().toString(36);
    setInputKey(randomString);
  };

  return (
    <>
      <Button
        className={[icon ? styles.smallButton : '', className].join(' ')}
        color={color}
        fullWidth={fullWidth}
        size="smaller"
        onClick={() => ref.current?.click()}
        style={style}
        loading={loading}
      >
        {icon ? <img src="/images/Icon-metro-attachment.svg" alt="" /> : fileButtonText}
      </Button>
      <input
        {...acceptFolder}
        key={inputKey || ''}
        ref={ref}
        type="file"
        style={{ display: 'none' }}
        multiple={multiple}
        accept={accept}
        onChange={(e) => {
          onChange(e.target.files);
          resetFileInput();
        }}
      />
    </>
  );
}

export default FileInputButton;
