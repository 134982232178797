import { useCallback, useEffect, useRef } from 'react';
import style from './alertModal.module.css';
import Button from '../Button/button';

export interface MessageModalProps {
  open: boolean,
  text: string,
  onConfirm: () => void;
  confirmText?: string;
}

export const messageModalInitialState = {
  open: false, text: '',
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
function MessageModal({
  open = false, text, onConfirm, confirmText = 'OK',
}: MessageModalProps) {
  const onKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onConfirm();
    }
  }, [onConfirm]);

  const prevKeyOpen = usePrevious(onKeyPress);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', onKeyPress, false);
    } else {
      document.removeEventListener('keydown', onKeyPress, false);
      if (prevKeyOpen) document.removeEventListener('keydown', prevKeyOpen, false);
    }
  }, [open]);
  return (
    <div className={style.alertBox} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.modalContainer}>
        <div className={style.modal}>
          <div className={style.alertMessage} style={{ whiteSpace: 'pre-line' }}>{text}</div>
          <div className={style.alertButtons}>
            <Button content={confirmText} size="smaller" onClick={onConfirm} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageModal;
