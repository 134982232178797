enum ControlCode {
  Receiving = 'receiving',
  Issuing = 'issuing',
  Warehousing = 'warehousing',
  Destruction = 'destruction',
  Scan = 'scan',
  Read = 'read',
  Write = 'write',
  Delete = 'delete',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace ControlCode {

  /**
   * 列挙型の値を対応する日本語に変換します。
   *
   * @param controlCode - ControlCodeの列挙型の値
   * @returns - ControlCodeの説明文字列
   */
  export function describe(controlCode: ControlCode): string {
    switch (controlCode) {
      case ControlCode.Receiving: return '入庫';
      case ControlCode.Issuing: return '発行中';
      case ControlCode.Warehousing: return '出庫';
      case ControlCode.Destruction: return '廃棄';
      case ControlCode.Scan: return 'スキャン';
      case ControlCode.Read: return '読み';
      case ControlCode.Write: return '書き';
      case ControlCode.Delete: return '削除';
      default:
        return '不明';
    }
  }

  /**
   * 非文書の権限コード一覧を返します。
   *
   * @returns - ドキュメント以外のControlCodeの配列
   */
  export function getNonDocumentControlCodes(): ControlCode[] {
    return [
      ControlCode.Receiving,
      ControlCode.Warehousing,
      ControlCode.Destruction,
      ControlCode.Scan,
    ];
  }

  /**
   * フォルダーに対するコ権限コード一覧を返します。
   *
   * @returns - フォルダーに対するControlCodeの配列
   */
  export function getFolderControlCodes(): ControlCode[] {
    return [
      ControlCode.Read,
      ControlCode.Write,
      ControlCode.Delete,
    ];
  }
}

/**
 * 権限コードとその名前のインターフェース。
 */
export interface ControlCodeItem {
  controlCode: string,
  controlName?: string
}

/**
 * 権限コードのみのインターフェース。
 */
export interface ControlCodeItemOnlyCode {
  controlCode: string
}

export default ControlCode;
