import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import styles from './baseSignItem.module.css';
import mainStyles from '../../main.module.css';
import { useDigitalExportApiKeyApi, useDigitalReExportApiKeyApi } from '../../../hooks/api/digitalSign.hook';
import AlertModal, { CustomizeAlertModal } from '../../../components/AlertModal';
import stylesWebApi from './webApi.module.css';
import { DigitalSignExportApiKeyResponse, DigitalSignItemForm } from '../../../services/http/digitalSign.api';
import { useCreateLogApi } from '../../../hooks/api/log.hook';
import { useMessageModal } from '../../../hooks/modal.hook';
import { ApiError } from '../../../services/http';
import Formatter from '../../../utils/formatters';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../components/Button/button';

/**
 * 電子署名情報(WebAPI)のプロップスインターフェース
 */
export interface WebAPIProps {
  isEditing: boolean
  /** 電子署名情報のステータス */
  item: DigitalSignItemForm | null
  /** リフレッシュハンドラー */
  refresh: () => void
  onGenerateApiKey: () => void
  onEdit: () => void
  onCancelEdit: () => void
  onSave: (data: DigitalSignExportApiKeyResponse) => void
}

/**
 * 電子署名情報(WebAPI)のコンポーネント
*/
export function WebApi({
  isEditing, item, refresh, onGenerateApiKey, onEdit, onCancelEdit, onSave,
}: WebAPIProps) {
  const [open, setOpen] = useState(false);
  const [reExecDialogOpen, setReExecDialogOpen] = useState(false);
  const [exportApiKeyData, setExportApiKeyData] = useState<DigitalSignExportApiKeyResponse | null>(null);

  const { request: exportApiKey, loading: loadingExportApiKey } = useDigitalExportApiKeyApi();
  const { request: reExportApiKey, loading: loadingReExportApiKey } = useDigitalReExportApiKeyApi();
  const { request: createLog } = useCreateLogApi();
  const openMessageModal = useMessageModal();

  const disableUpdateButton = useMemo(() => {
    if (!item) return true;
    if (item.name === '') return true;
    if (item.keyInfo.length === 0) return true;
    return false;
  }, [item]);

  const disableRegisterButton = useMemo(() => {
    if (!item) return true;
    if (item.name === '') return true;
    return false;
  }, [item]);

  const disableSaveButton = useMemo(() => {
    if (!item) return true;
    if (!exportApiKeyData) return true;
    if (item.name === '') return true;
    return false;
  }, [exportApiKeyData, item]);

  useEffect(() => {
    const s = item;
    if (s) {
      const apiKey = s.keyInfo.find((k) => k.key.toLowerCase() === 'apikey')?.value;
      if (apiKey) {
        setExportApiKeyData({
          apiKey,
          registerDate: s.registerDate,
          registerUser: s.registerUser,
        });
      }
    }
  }, [item]);

  const handleApiKeyReExec = useCallback(async () => {
    try {
      if (!item) return;
      const res = await reExportApiKey({ organizationDigitalSignId: item.organizationDigitalSignId, name: item.name });
      setExportApiKeyData(res);
      setOpen(true);
      onGenerateApiKey();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [createLog, item, onGenerateApiKey, openMessageModal, reExportApiKey]);

  const handleApiKeyExec = useCallback(async () => {
    try {
      if (!item) return;
      const res = await exportApiKey(item.name);
      setExportApiKeyData(res);
      setOpen(true);
      onGenerateApiKey();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [createLog, exportApiKey, item, onGenerateApiKey, openMessageModal]);

  const handleClip = (res: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(res);
    }
  };

  const handleSave = useCallback(() => {
    if (exportApiKeyData) {
      onSave(exportApiKeyData);
    }
  }, [exportApiKeyData, onSave]);

  const leftSide = useMemo(() => {
    if (isEditing && !exportApiKeyData) {
      return (
        <div className={stylesWebApi.leftButtonContainer} style={{ marginBottom: item && item.keyInfo.length === 0 ? '22px' : '' }}>
          <Button
            size="smaller"
            color="primary"
            disabled={disableRegisterButton}
            className={styles.itemButton}
            onClick={handleApiKeyExec}
          >
            キー発行
          </Button>
        </div>
      );
    }
    if (!exportApiKeyData) {
      return (
        <div />
      );
    }
    return (
      <div className={[stylesWebApi.textContainer].join(' ')}>
        <div className={stylesWebApi.textTitleContainer}>
          APIキー発行済
          {isEditing && exportApiKeyData ? (
            <div className={stylesWebApi.updateKeyContainer}>
              <Button
                size="smaller"
                color="primary"
                className={styles.itemButton}
                disabled={disableUpdateButton}
                onClick={() => {
                  setReExecDialogOpen(true);
                }}
              >
                再発行
              </Button>
            </div>
          ) : null}
        </div>
        <div style={{ paddingLeft: '20px', marginTop: '5px' }}>
          発行日:
          {' '}
          {Formatter.fromStringToFormattedString(exportApiKeyData.registerDate, Formatter.defaultDateTimeFormat)}
        </div>
        <div style={{ paddingLeft: '20px', marginTop: '5px' }}>
          発行者:
          {' '}
          {exportApiKeyData.registerUser}
        </div>
        <div className={stylesWebApi.alert} style={{ marginTop: '5px' }}>
          ※APIキーを紛失した場合は再発行ボタンから再発行を行ってください
        </div>
      </div>
    );
  }, [createLog, disableRegisterButton, disableUpdateButton, exportApiKeyData, handleApiKeyExec, isEditing, item]);

  const upperLeftButton = useMemo(() => {
    if (!isEditing) {
      return (
        <Button color="lighterGray" size="smaller" className={styles.itemButton} onClick={onEdit}>設定</Button>
      );
    }
    return (
      <Button color="primary" size="smaller" className={styles.itemButton} disabled={disableSaveButton} onClick={handleSave}>保存</Button>
    );
  }, [isEditing, disableSaveButton, handleSave, onEdit]);

  return (
    <>
      {leftSide}
      <div className={styles.buttonContainer} style={{ justifyContent: isEditing ? 'flex-end' : 'center', paddingBottom: isEditing ? '' : '22px' }}>
        <div className={styles.buttonRow}>
          {isEditing ? (
            <Button className={styles.itemButton} style={{ marginRight: '10px' }} size="smaller" color="lighterGray" onClick={onCancelEdit}>
              キャンセル
            </Button>
          ) : null}
          {upperLeftButton}
        </div>
      </div>
      <CustomizeAlertModal
        open={open}
        text=""
        hideConfirmButton
        onConfirm={() => {}}
        onCancel={() => {
          setOpen(false);
          refresh();
        }}
        cancelText="閉じる"
      >
        <div className={[mainStyles['p-4']].join(' ')}>
          <div className={[mainStyles['d-flex'], mainStyles['mt-3'], stylesWebApi['api-key-alert-title']].join(' ')}>
            <div>
              APIキー:
              {exportApiKeyData ? exportApiKeyData.apiKey : ''}
            </div>
            <img
              className={stylesWebApi.clipboard}
              alt=""
              src="/images/Icon metro-clipboard.svg"
              onClick={() => {
                handleClip(exportApiKeyData ? exportApiKeyData.apiKey : '');
                refresh();
              }}
            />
          </div>
          <div className={stylesWebApi['api-key-alert-content']}>
            <p>
              上記APIキーをイタンジ株式会社のご担当者様へお知らせください。
              このダイアログを閉じるとAPIキーの確認ができなくなりますので
              必ず上記APIキーを記録してください。
            </p>
            <p className={stylesWebApi.alert}>第三者へ漏洩しないよう、APIキーのお取り扱いにはご注意ください。</p>
          </div>
        </div>
      </CustomizeAlertModal>
      <AlertModal
        open={reExecDialogOpen}
        text={'APIキーを再発行すると以前発行したAPIキーは無効になります。\n APIキーを再発行してよろしいですか？'}
        onConfirm={() => {
          handleApiKeyReExec();
          setReExecDialogOpen(false);
        }}
        onCancel={() => {
          setReExecDialogOpen(false);
        }}
      />
      {loadingExportApiKey || loadingReExportApiKey ? (
        <LoadingOverlay />
      ) : null}
    </>
  );
}

export default WebApi;
